import React, { useEffect, useState } from "react";
import {
  makeStyles,
  Dialog,
  DialogContent,
  IconButton,
  FormControl,
  Typography,
  TextField,
  Box,
  Button,
  Grid,
  Avatar,
  Select,
  MenuItem,
  Checkbox,
} from "@material-ui/core";
import { AiOutlineCamera } from "react-icons/ai";
import { IoClose } from "react-icons/io5";
import * as Yup from "yup";
import FormHelperText from "@material-ui/core/FormHelperText";
import "react-phone-input-2/lib/style.css";
import {
  dataPostHandler,
  getDataHandlerAPI,
  uploadFiles1,
} from "src/apiConfig/service";
import ButtonCircularProgress from "src/component/ButtonCircularProgress";
import AttachementIcon from "src/icons/AttachementIcon";
import { MdDelete } from "react-icons/md";
import { Form, Formik } from "formik";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  cancelBtn1: {
    background: "#000000",
    top: "6px",
    right: "10px",
    position: "absolute",
    "&:hover": {
      backgroundColor: "#4057a7 !important",
    },
  },
  mainmodalBox: {
    "& .formControl": {
      width: "100%",
      backgroundColor: "transparent",
      border: "none",
      color: "#fff",
      "&:focus-visible": {
        outline: "none",
      },
    },
    "& .helpText": {
      textAlign: "end",
      marginTop: "0px",
    },
    "& h6": {
      fontWeight: "700",
    },
    "& p": {
      color: "rgba(0, 0, 0, 0.4)",
      background: "#fff",
    },

    "& button": {
      height: "42px",
    },
  },
  cancelBtn: {
    position: "absolute",
    top: "5px",
    right: 0,
    "& svg": {
      color: "#fff",
      fontWeight: "700",
    },
    "& .MuiIconButton-root:hover": {
      backgroundColor: "#4057a7!important",
    },
  },
  commentControl: {
    "& .MuiInputBase-input": {
      background: "#fff",
    },
    "& .MuiInput-root": {
      backgroundColor: "#fff",
    },
  },

  coverPic: {
    background: "#f4f4f4",
    paddingBottom: "180px",
    "& .MuiAvatar-root": {
      width: "100%",
      height: "180px",
      borderRadius: "0",
    },
    "& .profile1": {
      position: "absolute",
      width: "100%",
      zIndex: 111,
    },
  },
  inputBox: {
    textAlign: "center",
    paddingTop: "18px",
    paddingBottom: "10px",
  },
  dateControl: {
    background: "#fff",
    "& .MuiInputBase-input": {
      backgroundColor: "#fff !important",
    },
    "& .MuiInput-root": {
      backgroundColor: "#fff !important",
    },
  },

  checkmainBox: {
    marginTop: "10px",
    display: "flex",
    alignItems: "flex-start",
    "& .MuiCheckbox-root": {
      padding: "0px 8px 0px 0px",
    },
  },
}));

function PageAdd({
  openCreateEvent,
  handleCloseCreateEvent,
  type,
  pageId,
  pageListHandler,
}) {
  const classes = useStyles();
  const [bgImage, setBgImage] = useState("");
  const [backgroundImg, setBackgroundImg] = useState("");
  const [logo, setLogo] = useState("");
  const [logoImg, setLogoImg] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [backgroundCon, setBackgroundCon] = useState(false);
  const [logoCon, setLogoCon] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [orgnationList, setOrgnationList] = useState([]);

  useEffect(() => {
    if (pageId) {
      setBgImage(pageId?.backGroundImage ? pageId?.backGroundImage : "");
      setLogo(pageId?.logo ? pageId?.logo : "");
      setBackgroundImg(pageId?.backGroundImage ? pageId?.backGroundImage : "");
      setLogoImg(pageId?.logo ? pageId?.logo : "");
    }
  }, [pageId]);

  const formValidationSchema = Yup.object().shape({
    orgName: Yup.string()
      .required("Organization name is required.")
      .matches(
        /^[a-zA-Z]+(([',. -][a-zA-Z])?[a-zA-Z]*)*$/g,
        "Please enter a valid organization name."
      )
      .max(50, "Should not exceed 50 characters."),
    url: Yup.string()
      .required("Website URL is required.")
      .matches(
        /[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?/gi,
        "Please enter valid website URL."
      )
      .max(100, "Should not exceed 100 characters."),
    industry: Yup.string()
      .required("Industry  is required.")
      .max(100, "Should not exceed 100 characters."),
    tagline: Yup.string()
      .required("Tagline is required.")
      .max(100, "Should not exceed 100 characters."),
    orgSize: Yup.string().required("Please select the organization size."),
    orgType: Yup.string().required("Please select the organization type."),
  });

  const createEventHandler = async (values) => {
    try {
      setIsSubmit(true);
      if (backgroundImg !== "" && logoImg !== "") {
        setIsSubmit(false);
        setIsLoading(true);
        const formData = new FormData();
        formData.append("organizationName", values.orgName);
        formData.append("headLine", values.tagline);
        formData.append("websiteUrl", values.url);
        formData.append("industry", values.orgType);
        formData.append("organizationSize", values.orgSize);
        formData.append("organizationTypeId", values.industry);
        formData.append(
          "logo",

          (type === "pageUpdate" && logoCon) || type === "add"
            ? await uploadFiles1(logoImg)
            : type === "pageUpdate" && logoImg
        );
        formData.append(
          "backGroundImage",
          (type === "pageUpdate" && backgroundCon) || type === "add"
            ? await uploadFiles1(backgroundImg)
            : type === "pageUpdate" && backgroundImg
        );

        if (type === "pageUpdate") {
          formData.append("pageId", pageId?._id);
        }

        const checkMethod =
          type === "pageUpdate"
            ? dataPostHandler("editPage", formData)
            : dataPostHandler("createPage", formData);
        const res = await checkMethod;

        if (res) {
          setIsLoading(false);
          handleCloseCreateEvent();
          pageListHandler();
        } else {
          setIsLoading(false);
        }
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const originationListHandler = async () => {
    try {
      const res = await getDataHandlerAPI(
        "listOrganizationTypeWithoutPagiantion"
      );

      setOrgnationList(res);
    } catch (error) {}
  };

  useEffect(() => {
    if (openCreateEvent) {
      originationListHandler();
    }
  }, [openCreateEvent]);

  return (
    <Dialog
      open={openCreateEvent}
      onClose={() => {
        handleCloseCreateEvent();
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="xs"
      fullWidth
      className={classes.root}
    >
      <Box className="bgcolorbox">
        <Box>
          <Typography variant="h5">
            {type === "pageUpdate" ? "Update" : "Create"} a Page
          </Typography>
        </Box>
      </Box>
      <Box className={classes.coverPic}>
        <Box style={{ position: "relative" }}>
          <>
            <input
              style={{ display: "none" }}
              type="file"
              disabled={isLoading}
              id="raised-button-filee"
              accept="image/*"
              onChange={(e) => {
                setBgImage(URL.createObjectURL(e.target.files[0]));
                setBackgroundImg(e.target.files[0]);
              }}
            />
            <Box
              style={{
                position: "absolute",
                width: "100%",
                height: "100%",
                // zIndex:"-1",
                top: "0px",
              }}
            >
              {bgImage === "" ? (
                <>
                  <label htmlFor="raised-button-filee">
                    <Box
                      style={{
                        cursor: "pointer",
                        textAlign: "center",
                        padding: "44px 0px 34px 0px",
                      }}
                    >
                      <AiOutlineCamera
                        style={{ color: "#000", fontSize: "60px" }}
                      />
                      <Typography
                        variant="h5"
                        style={{ color: "rgba(50, 50, 50, 0.8)" }}
                      >
                        Upload cover image
                      </Typography>
                    </Box>
                  </label>
                </>
              ) : (
                <>
                  <Box className="profile1">
                    <Avatar src={bgImage ? bgImage : <></>} />
                    <IconButton
                      disabled={isLoading}
                      size="small"
                      className={classes.cancelBtn1}
                      onClick={() => {
                        setBgImage("");
                        setBackgroundImg("");
                        type === "pageUpdate" && setBackgroundCon(true);
                      }}
                    >
                      <IoClose color="#ffffff" />
                    </IconButton>
                  </Box>
                </>
              )}
            </Box>
          </>
        </Box>
        {isSubmit && backgroundImg === "" && (
          <FormHelperText error>Please select a file.</FormHelperText>
        )}
      </Box>

      <DialogContent>
        <IconButton
          className={classes.cancelBtn}
          onClick={() => {
            handleCloseCreateEvent();
          }}
        >
          <IoClose />
        </IconButton>

        <Formik
          initialValues={{
            orgName: pageId?.organizationName ? pageId?.organizationName : "",
            url: pageId?.websiteUrl ? pageId?.websiteUrl : "",
            industry: pageId?.organizationTypeId
              ? pageId?.organizationTypeId
              : "",
            tagline: pageId?.headLine ? pageId?.headLine : "",
            orgSize: pageId?.organizationSize ? pageId?.organizationSize : "",
            orgType: pageId?.industry ? pageId?.industry : "",
          }}
          initialStatus={{
            success: false,
            successMsg: "",
          }}
          validationSchema={formValidationSchema}
          onSubmit={(values) => createEventHandler(values)}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values,
            setFieldValue,
          }) => (
            <Form className={classes.root}>
              <Box className={classes.mainmodalBox}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12}>
                    <Box>
                      <Typography variant="body2">Name</Typography>
                    </Box>
                    <Box>
                      <FormControl fullWidth className={classes.commentControl}>
                        <TextField
                          name="orgName"
                          fullWidth
                          disabled={isLoading}
                          placeholder="Add your organization name"
                          value={values.orgName}
                          error={Boolean(touched.orgName && errors.orgName)}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          inputProps={{ maxLength: 50 }}
                        />
                        <FormHelperText error className={classes.helperText}>
                          {touched.orgName && errors.orgName}
                        </FormHelperText>
                      </FormControl>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Box>
                      <Typography variant="body2">Website URL</Typography>
                    </Box>
                    <Box>
                      <FormControl fullWidth className={classes.commentControl}>
                        <TextField
                          fullWidth
                          name="url"
                          disabled={isLoading}
                          placeholder="E.g., http//:"
                          value={values.url}
                          error={Boolean(touched.url && errors.url)}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          inputProps={{ maxLength: 100 }}
                        />
                        <FormHelperText error className={classes.helperText}>
                          {touched.url && errors.url}
                        </FormHelperText>
                      </FormControl>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Box>
                      <Typography variant="body2">Industry</Typography>
                    </Box>
                    <Box>
                      <FormControl fullWidth className={classes.commentControl}>
                        {/* <TextField
                          fullWidth
                          name="industry"
                          disabled={isLoading}
                          placeholder="E.g.: Information Services"
                          value={values.industry}
                          error={Boolean(touched.industry && errors.industry)}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          inputProps={{ maxLength: 100 }}
                        /> */}

                        <Select
                          // value={sortBy}
                          onChange={handleChange}
                          inputProps={{ "aria-label": "Without label" }}
                          displayEmpty
                          fullWidth
                          name="industry"
                          value={values?.industry}
                          disabled={isLoading}
                          error={Boolean(touched.industry && errors.industry)}
                          onBlur={handleBlur}
                          MenuProps={{
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "left",
                            },
                            transformOrigin: {
                              vertical: "top",
                              horizontal: "left",
                            },
                            getContentAnchorEl: null,
                          }}
                        >
                          {orgnationList &&
                            orgnationList.map((item) => (
                              <MenuItem value={item?._id}>
                                {item?.name}
                              </MenuItem>
                            ))}
                        </Select>

                        <FormHelperText error className={classes.helperText}>
                          {touched.industry && errors.industry}
                        </FormHelperText>
                      </FormControl>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Typography variant="body2">Organization Size</Typography>
                    <FormControl fullWidth className={classes.commentControl}>
                      <Select
                        // value={sortBy}
                        onChange={handleChange}
                        inputProps={{ "aria-label": "Without label" }}
                        displayEmpty
                        fullWidth
                        name="orgSize"
                        value={values?.orgSize}
                        disabled={isLoading}
                        error={Boolean(touched.orgSize && errors.orgSize)}
                        onBlur={handleBlur}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                          getContentAnchorEl: null,
                        }}
                      >
                        <MenuItem value="50">0-50</MenuItem>
                        <MenuItem value="250">51-250</MenuItem>
                        <MenuItem value="500">251-500</MenuItem>
                        <MenuItem value="More">501-More</MenuItem>
                      </Select>
                      <FormHelperText error className={classes.helperText}>
                        {touched.orgSize && errors.orgSize}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Box>
                      <Typography variant="body2">Organization Type</Typography>
                    </Box>
                    <Box>
                      <FormControl fullWidth className={classes.commentControl}>
                        <Select
                          // value={sortBy}
                          onChange={handleChange}
                          inputProps={{ "aria-label": "Without label" }}
                          displayEmpty
                          fullWidth
                          name="orgType"
                          value={values?.orgType}
                          disabled={isLoading}
                          error={Boolean(touched.orgType && errors.orgType)}
                          onBlur={handleBlur}
                          MenuProps={{
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "left",
                            },
                            transformOrigin: {
                              vertical: "top",
                              horizontal: "left",
                            },
                            getContentAnchorEl: null,
                          }}
                        >
                          {[
                            "Public Sector Organizations",
                            "Private Sector Organizations",
                            "Non-Profit and Social Organizations",
                            "Cooperative Organizations",
                            "Foreign Entities",
                          ].map((item) => (
                            <MenuItem value={item}>{item}</MenuItem>
                          ))}
                        </Select>
                        <FormHelperText error className={classes.helperText}>
                          {touched.orgType && errors.orgType}
                        </FormHelperText>
                      </FormControl>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Box>
                      <Typography variant="body2">Tagline</Typography>
                    </Box>
                    <Box>
                      <FormControl fullWidth className={classes.commentControl}>
                        <TextField
                          fullWidth
                          name="tagline"
                          disabled={isLoading}
                          placeholder="Add Tagline"
                          value={values.tagline}
                          error={Boolean(touched.tagline && errors.tagline)}
                          onBlur={handleBlur}
                          inputProps={{ maxLength: 100 }}
                          onChange={handleChange}
                        />
                        <FormHelperText error className={classes.helperText}>
                          {touched.tagline && errors.tagline}
                        </FormHelperText>
                      </FormControl>
                    </Box>
                  </Grid>
                </Grid>

                <Box display="flex" alignItems="center" mt={1}>
                  <Box
                    className="addImagegIcon"
                    style={{ position: "relative" }}
                  >
                    {logo === "" ? (
                      <IconButton
                        disabled={isLoading}
                        style={{
                          border: "1px dashed #4057A7",
                          padding: "10px 10px 6px 10px",
                        }}
                      >
                        <label htmlFor="raised-button-file1">
                          <AttachementIcon
                            color="#4057A7 !important"
                            stroke="#4057A7 !important"
                          />
                          <input
                            style={{ display: "none" }}
                            id="raised-button-file1"
                            type="file"
                            accept="image/*"
                            disabled={isLoading}
                            onChange={(e) => {
                              setLogo(URL.createObjectURL(e.target.files[0]));
                              setLogoImg(e.target.files[0]);
                            }}
                          />
                        </label>
                      </IconButton>
                    ) : (
                      <figure className="figure" style={{ margin: "0px" }}>
                        <Avatar
                          className={classes.imgsection}
                          src={logo ? logo : <></>}
                        />
                      </figure>
                    )}

                    {isSubmit && logoImg === "" && (
                      <FormHelperText error>
                        Please select a file.
                      </FormHelperText>
                    )}
                  </Box>
                  &nbsp;&nbsp;&nbsp;
                  <Box display="flex" className={classes.profile}>
                    {logo === "" ? (
                      <Typography variant="h6" color="primary">
                        Upload Logo
                      </Typography>
                    ) : (
                      <IconButton
                        disabled={isLoading}
                        onClick={() => {
                          setLogoImg("");
                          setLogo("");
                          type === "pageUpdate" && setLogoCon(true);
                        }}
                      >
                        <MdDelete style={{ color: "#4057A7" }} />
                      </IconButton>
                    )}
                  </Box>
                </Box>

                <Box className={classes.checkmainBox}>
                  <Checkbox
                    checked={isChecked}
                    disabled={isLoading}
                    onChange={() => setIsChecked(!isChecked)}
                  />
                  <Typography variant="body2">
                    Are you sure you want to add this page.
                  </Typography>
                </Box>

                <Box className={classes.inputBox}>
                  <Button
                    variant="contained"
                    color="primary"
                    size="medium"
                    disabled={isLoading || !isChecked}
                    type="submit"
                  >
                    {type === "pageUpdate" ? "Update Page" : "Create Page"}
                    {isLoading && <ButtonCircularProgress />}
                  </Button>
                </Box>
              </Box>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
}

export default PageAdd;
