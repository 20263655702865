import React, { useContext, useState } from "react";
import {
  makeStyles,
  Dialog,
  DialogContent,
  IconButton,
  Typography,
  Box,
  Button,
  Avatar,
  FormHelperText,
} from "@material-ui/core";
import { IoClose } from "react-icons/io5";
import { AuthContext } from "src/context/Auth";
import { toast } from "react-toastify";
import TokenABI from "src/ABI/TokenABI.json";
import web3 from "web3";
import { getContract } from "src/utils";
import { toeknAddress, tokenName } from "src/constants";
import Axios from "axios";
import apiConfigs from "src/apiConfig/ApiConfig";
import ButtonCircularProgress from "./ButtonCircularProgress";

const useStyles = makeStyles((theme) => ({
  courseModal: {
    padding: "8px 24px",
    "& .MuiAvatar-root": {
      width: "65px",
      height: "65px",
      borderRadius: "100px",
    },
  },
  coverPic: {
    background: "#f4f4f4",
    paddingBottom: "180px",
    "& .MuiAvatar-root": {
      width: "100%",
      height: "180px",
      borderRadius: "0",
    },
    "& .profile1": {
      position: "absolute",
      width: "100%",
      zIndex: 111,
    },
  },
  root: {
    "& .MuiDialogContent-root": {
      padding: "0px !important",
    },
  },
  cancelBtn: {
    position: "absolute",
    top: "5px",
    right: 0,
    "& svg": {
      color: "#fff",
      fontWeight: "700",
    },
    "& .MuiIconButton-root:hover": {
      backgroundColor: "#4057a7!important",
    },
  },
}));

function CourseModal({
  openCreateEvent,
  handleCloseCreateEvent,
  courseData,
  getSkillUpAdCourseList,
}) {
  const classes = useStyles();
  const auth = useContext(AuthContext);
  const [isSubmit, setIsSubmit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const courseBuyFun = async () => {
    try {
      setIsLoading(true);
      const contractObjToken = getContract(
        toeknAddress,
        TokenABI,
        auth?.library,
        auth?.account
      );
      const toSrngpr = courseData?.coursePrice.toString();
      const transferToken = await contractObjToken.transfer(
        courseData?.userId?.walletAddress,
        web3.utils.toWei(toSrngpr, "ether")
      );
      await transferToken.wait();
      purchasedCourseApi();
    } catch (error) {
      console.log(error);
      toast.error(error.message);
      setIsLoading(false);
    }
  };

  const purchasedCourseApi = async () => {
    try {
      const response = await Axios({
        method: "POST",
        url: `${apiConfigs.buyCourse}/${courseData?._id}`,
        headers: {
          token: window.sessionStorage.getItem("token"),
        },
        data: {
          amount: courseData?.coursePrice.toString(),
        },
      });
      if (response.data.responseCode === 200) {
        toast.success(response.data.responseMessage);
        setIsLoading(false);
        getSkillUpAdCourseList();
        handleCloseCreateEvent();
      } else {
        toast.error(response.data.responseMessage);
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      toast.error(error.response.data.responseMessage);
    }
  };

  return (
    <Dialog
      open={openCreateEvent}
      onClose={() => !isLoading && handleCloseCreateEvent()}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="xs"
      fullWidth
      className={classes.root}
    >
      <Box className="bgcolorbox">
        <Box>
          <Typography variant="h5">Buy course</Typography>
        </Box>
      </Box>

      <DialogContent>
        <IconButton
          className={classes.cancelBtn}
          disabled={isLoading}
          onClick={() => handleCloseCreateEvent()}
        >
          <IoClose />
        </IconButton>
        <Box className={classes.coverPic}>
          <Box style={{ position: "relative" }}>
            <Box className="profile1">
              {courseData.type === "video" ? (
                <video
                  controls={true}
                  autoplay={false}
                  loop
                  muted
                  playsinline="true"
                  width="100%"
                  height="179px"
                >
                  <source src={courseData?.file} />
                </video>
              ) : (
                <Avatar src={courseData?.file ? courseData?.file : <></>} />
              )}
            </Box>
          </Box>
        </Box>

        <Box className={classes.courseModal}>
          <Box style={{ display: "flex", alignItems: "flex-start" }} mt={1}>
            <Avatar src={courseData?.logo} />
            <Box ml={1}>
              <Typography variant="body2">{courseData?.title}</Typography>
              <Box>
                <Typography variant="h6" style={{ color: "#4057A7" }}>
                  {courseData?.coursePrice} {tokenName}
                </Typography>
              </Box>
            </Box>
          </Box>

          <Box mt={1}>
            <Typography variant="body2">
              <div
                dangerouslySetInnerHTML={{
                  __html: courseData?.description,
                }}
              ></div>
            </Typography>
          </Box>

          <Box align="center" my={2}>
            <FormHelperText error className="displayFlexCenter">
              {isSubmit &&
                Number(auth?.tokenBalance) <= Number(courseData?.coursePrice) &&
                "Your balance is low."}
            </FormHelperText>
            <Button
              variant="contained"
              color="primary"
              size="medium"
              disabled={isLoading}
              onClick={() =>
                auth?.account
                  ? auth?.userData?.kycStatus === "APPROVE"
                    ? Number(auth?.tokenBalance) >=
                      Number(courseData?.coursePrice)
                      ? (() => {
                          if (
                            courseData?.userId?.walletAddress !== auth?.account
                          ) {
                            setIsSubmit(false);
                            courseBuyFun();
                          } else {
                            toast.warn("Owners cannot buy own your address.");
                          }
                        })()
                      : setIsSubmit(true)
                    : toast.warn(
                        "Please complete your KYC to access this feature."
                      )
                  : auth.setOpenMetamask(true)
              }
            >
              {auth?.account ? "Buy Now" : "Connect Wallet"}{" "}
              {isLoading && <ButtonCircularProgress />}
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default CourseModal;
