import React, { useContext, useState } from "react";
import {
  Typography,
  Dialog,
  DialogContent,
  Button,
  Box,
  IconButton,
  makeStyles,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { AuthContext } from "src/context/Auth";
import { IoClose } from "react-icons/io5";
import ButtonCircularProgress from "src/component/ButtonCircularProgress";
import { toast } from "react-toastify";
import { dataPostHandler } from "src/apiConfig/service";

const useStyles = makeStyles((theme) => ({
  cancelBtn: {
    position: "absolute",
    top: 0,
    right: 0,
    "& svg": {
      color: "#fff",
      fontWeight: "700",
    },
  },
  maindialouge: {
    width: "100%",
  },
}));

export default function ConfirmationDialog({
  open,
  handleClose,
  title,
  desc,
  confirmationHandler,
  isLoading,
}) {
  const history = useHistory();
  const auth = useContext(AuthContext);
  const classes = useStyles();
  const [isLoadings, setIsLoadings] = useState(false);
  const isLoadCheck = isLoading ? isLoading : isLoadings;

  const logoutHandler = async () => {
    try {
      setIsLoadings(true);
      const response = await dataPostHandler("logout", {
        deviceToken: localStorage.getItem("pushToekn"),
      });
      if (response.data.responseCode === 200) {
        setIsLoadings(false);
        handleRemoveLocalData();
      } else {
        setIsLoadings(false);
        handleRemoveLocalData();
      }
    } catch (error) {
      setIsLoadings(false);
      handleRemoveLocalData();
    }
  };
  const handleRemoveLocalData = () => {
    auth.userLogIn(false, null);
    window.sessionStorage.removeItem("ConnectWallet");
    window.sessionStorage.removeItem("token");
    window.sessionStorage.removeItem("userChatData");
    history.push("/");
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={() => !isLoadCheck && handleClose()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="xs"
        className={classes.maindialouge}
      >
        <Box className="bgcolorbox">
          <Box>
            <Typography variant="h5">{title ? title : "Logout"}</Typography>
          </Box>
        </Box>
        <DialogContent>
          <IconButton
            className={classes.cancelBtn}
            disabled={isLoading || isLoadings}
            onClick={handleClose}
          >
            <IoClose />
          </IconButton>
          {title === "Logout" && (
            <Box align="center" mt={2}>
              <img src="images/Logout.png" alt="Logout" />
            </Box>
          )}
          <Typography
            variant="h5"
            style={{
              color: "rgba(50, 50, 50, 0.8)",
              fontSize: "16px",
              margin: "20px 0px",
              textAlign: "center",
              fontWeight: "700",
            }}
          >
            {desc ? desc : "Are you sure you want to logout?"}
          </Typography>
          <Box mb={2} align="center">
            <Button
              variant="contained"
              color="secondary"
              disabled={isLoading || isLoadings}
              onClick={handleClose}
              size="medium"
              style={{ padding: "7px 50px" }}
            >
              No
            </Button>
            <Button
              style={{ marginLeft: "13px", padding: "7px 50px" }}
              variant="contained"
              color="primary"
              size="medium"
              onClick={title === "Logout" ? logoutHandler : confirmationHandler}
              disabled={isLoading || isLoadings}
            >
              Yes {(isLoading || isLoadings) && <ButtonCircularProgress />}
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}
