import {
  Paper,
  Box,
  makeStyles,
  IconButton,
  Typography,
  Dialog,
  DialogContent,
  TextField,
  Button,
  Grid,
  FormHelperText,
  Checkbox,
} from "@material-ui/core";
import { IoClose } from "react-icons/io5";
import React, { useState, useEffect, useContext } from "react";
import { KeyboardDateTimePicker } from "@material-ui/pickers";
import { AiOutlinePlus } from "react-icons/ai";
import { dataPostHandler, getDataHandlerAPI } from "src/apiConfig/service";
import { Calendar, dateFnsLocalizer } from "react-big-calendar";
import format from "date-fns/format";
import getDay from "date-fns/getDay";
import parse from "date-fns/parse";
import startOfWeek from "date-fns/startOfWeek";
import ToDolist from "./ToDolist";
import ButtonCircularProgress from "src/component/ButtonCircularProgress";
import { handleSubmitBlcDataFun } from "src/blockchainfunction";
import { toast } from "react-toastify";
import { AuthContext } from "src/context/Auth";
import { Pagination } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  BoxImg: {
    paddingTop: "6px",
    paddingBottom: "20px",
    paddingLeft: "10px",
    display: "flex",
    alignItems: "center",
    "& .circle": {
      borderRadius: "50%",
      border: "1px dashed #4057A7",
    },
    "& p": {
      color: "#4057A7",
      paddingLeft: "8px",
    },
  },
  cancelBtn: {
    position: "absolute",
    top: 0,
    right: 0,
    "& svg": {
      color: "#fff",
      fontWeight: "700",
    },
  },
  formStyle: {
    padding: "12px",
  },
  ButtonBoxe: {
    padding: "7px 35px !important",
  },
  mainCalendor: {
    height: "500px",
    marginTop: "50px",
  },
}));

const Tododata = () => {
  const classes = useStyles();
  const auth = useContext(AuthContext);
  const [handleOpen, setHandleOpen] = React.useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingList, setIsLoadingList] = useState(true);
  const [isSubmit, setIsSubmit] = useState(false);
  const [toDoList, setToDoList] = useState([]);
  const [isChecked, setIsChecked] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [totalCount, setTotalCount] = useState("");

  const locales = {
    "en-US": require("date-fns/locale/en-US"),
  };

  const localizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek,
    getDay,
    locales,
  });

  const [newEvent, setNewEvent] = useState({
    title: "",
    desc: "",
    start: null,
    end: null,
  });

  const [allEvents, setAllEvents] = useState();

  const toDoListHandler = async () => {
    try {
      const res = await getDataHandlerAPI("listTo_Do", {
        page: page,
        limit: 16,
      });
      if (res) {
        setIsLoadingList(false);
        setToDoList(res?.docs);
        setAllEvents(
          res?.docs?.map((item) => ({
            title: item?.topic || "",
            start: new Date(item?.date),
            end: new Date(item?.time),
          })) || []
        );
        setTotalPage(res?.pages);
        setTotalCount(res?.total);
      } else {
        setIsLoadingList(false);
        setToDoList([]);
      }
    } catch (error) {
      setIsLoadingList(false);
      setToDoList([]);
    }
  };
  useEffect(() => {
    toDoListHandler();
  }, [page]);

  const handleSubmitDataFun = async (dataToSend) => {
    try {
      setIsLoading(true);
      const response = await handleSubmitBlcDataFun(dataToSend, auth);
      if (response) {
        craeteToDoHandler(dataToSend);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const craeteToDoHandler = async (dataToSend) => {
    try {
      setIsLoading(true);
      const response = await dataPostHandler("createTo_Do", dataToSend);
      if (response.data.responseCode === 200) {
        setIsLoading(false);
        setHandleOpen(false);
        toDoListHandler();
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };
  return (
    <>
      <Box className="scrollDiv">
        <Paper elevation={0}>
          <Box p={2}>
            <Calendar
              localizer={localizer}
              events={allEvents && allEvents}
              startAccessor="start"
              endAccessor="end"
              className={classes.mainCalendor}
            />
          </Box>
          <Box className={classes.BoxImg}>
            <Box className="circle">
              <IconButton
                style={{ padding: "5px" }}
                onClick={() => setHandleOpen(true)}
              >
                <AiOutlinePlus style={{ color: "#4057A7", fontSize: "24px" }} />
              </IconButton>
            </Box>
            <Box
              onClick={() => setHandleOpen(true)}
              style={{ cursor: "pointer" }}
            >
              <Typography variant="body2">Add To Do</Typography>
            </Box>
          </Box>
          <Box p={2}>
            <Grid container spacing={2}>
              {toDoList &&
                toDoList.map((data) => (
                  <Grid item xs={12} sm={6}>
                    <ToDolist data={data} toDoListHandler={toDoListHandler} />
                  </Grid>
                ))}
              {!isLoadingList && totalPage > 1 && (
                <Pagination
                  variant="outlined"
                  shape="rounded"
                  count={totalPage}
                  page={page}
                  onChange={(e, v) => setPage(v)}
                />
              )}
            </Grid>
          </Box>
        </Paper>
      </Box>

      <Dialog
        open={handleOpen}
        onClose={() => !isLoading && setHandleOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="sm"
        fullWidth
        className={classes.root}
      >
        <Box className="bgcolorbox">
          <Box>
            <Typography variant="h5">Create To Do</Typography>
          </Box>
        </Box>
        <DialogContent>
          <IconButton
            className={classes.cancelBtn}
            onClick={() => setHandleOpen(false)}
            disabled={isLoading}
          >
            <IoClose />
          </IconButton>

          <Box className={classes.mainmodalBox}>
            <Box py={1}>
              <Typography variant="body2">Tittle :</Typography>
            </Box>

            <TextField
              variant="outlined"
              fullWidth
              placeholder="Topic"
              type="text"
              value={newEvent.title}
              disabled={isLoading}
              inputProps={{ maxLength: 256 }}
              onChange={(e) =>
                setNewEvent({ ...newEvent, title: e.target.value })
              }
              error={isSubmit && newEvent.title === ""}
            />

            <FormHelperText error>
              {isSubmit && newEvent.title === "" && "Please enter title."}
            </FormHelperText>

            <Box py={1}>
              <Typography variant="body2">Description :</Typography>
            </Box>
            <TextField
              variant="outlined"
              fullWidth
              placeholder="Description"
              multiline
              type="text"
              minRows={6}
              value={newEvent.desc}
              inputProps={{ maxLength: 600 }}
              disabled={isLoading}
              onChange={(e) =>
                setNewEvent({ ...newEvent, desc: e.target.value })
              }
              error={isSubmit && newEvent.desc === ""}
            />
            <FormHelperText
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography color="error">
                {isSubmit &&
                  newEvent.desc === "" &&
                  "Please enter description."}
              </Typography>
              <Typography className="helpText">
                {newEvent?.desc?.length} /600
              </Typography>
            </FormHelperText>
            <FormHelperText error></FormHelperText>

            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Box py={1}>
                  <Typography variant="body2">From Date :</Typography>
                </Box>
                <KeyboardDateTimePicker
                  inputVariant="outlined"
                  format="DD/MM/YYYY h:mm"
                  placeholder="DD/MM/YYYY H:MM"
                  disablePast
                  InputProps={{ readOnly: true }}
                  fullWidth
                  value={newEvent.start}
                  onChange={({ _d }) => {
                    setNewEvent({ ...newEvent, start: _d });
                  }}
                  error={isSubmit && newEvent.start === ""}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  disabled={isLoading}
                />

                <FormHelperText error>
                  {isSubmit &&
                    newEvent.start === "" &&
                    "Please enter start date."}
                </FormHelperText>
              </Grid>
              <Grid item xs={6}>
                <Box py={1}>
                  <Typography variant="body2">To Date :</Typography>
                </Box>
                <KeyboardDateTimePicker
                  inputVariant="outlined"
                  format="DD/MM/YYYY h:mm"
                  placeholder="DD/MM/YYYY H:MM"
                  disablePast
                  InputProps={{ readOnly: true }}
                  fullWidth
                  value={newEvent.end}
                  minDate={newEvent.start}
                  onChange={({ _d }) => {
                    setNewEvent({ ...newEvent, end: _d });
                  }}
                  error={isSubmit && newEvent.end === ""}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  disabled={isLoading || !newEvent.start}
                />
                <FormHelperText error>
                  {isSubmit && newEvent.end === "" && "Please enter end date."}
                </FormHelperText>
              </Grid>
            </Grid>
            <Box className="displayFlexStart">
              <Checkbox
                size="small"
                disabled={isLoading}
                checked={isChecked}
                style={{ padding: "5px 5px 5px 0px" }}
                onChange={() => setIsChecked(!isChecked)}
              />
              <Typography variant="body1">Send to Blockchain</Typography>
            </Box>

            <Box display="flex" mt={3} mb={2} justifyContent="center">
              <Box pr={2}>
                <Button
                  variant="outlined"
                  color="secondary"
                  className={classes.ButtonBoxe}
                  onClick={() => setHandleOpen(false)}
                >
                  Cancel
                </Button>
              </Box>
              <Box>
                <Button
                  className={classes.ButtonBoxe}
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    if (auth?.userData?.kycStatus === "APPROVE") {
                      setIsSubmit(true);
                      if (
                        newEvent?.title !== "" &&
                        newEvent?.desc !== "" &&
                        newEvent?.start !== "" &&
                        newEvent?.end !== ""
                      ) {
                        setIsSubmit(false);
                        const dataToSend = {
                          topic: newEvent?.title,
                          time: newEvent?.end,
                          date: newEvent?.start,
                          description: newEvent?.desc,
                        };
                        isChecked
                          ? auth?.account
                            ? handleSubmitDataFun(dataToSend)
                            : auth.setOpenMetamask(true)
                          : craeteToDoHandler(dataToSend);
                      }
                    } else {
                      toast.warn(
                        "Please complete your KYC to access this feature."
                      );
                    }
                  }}
                  disabled={isLoading}
                >
                  {isChecked && !auth?.account ? "Connect Wallet" : "Create"}
                  {isLoading && <ButtonCircularProgress />}
                </Button>
              </Box>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Tododata;
