import React from "react";

const Alumni = () => {
  return (
    <svg
      width="12"
      height="15"
      viewBox="0 0 12 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.4627 3.09747C9.92606 3.29803 9.43664 3.47098 8.95947 3.67092C8.88404 3.70281 8.82638 3.85492 8.81964 3.95918C8.78529 4.51914 8.84049 5.09443 8.73255 5.63783C8.40933 7.26864 6.99564 8.24749 5.29798 8.08312C3.81068 7.93899 2.65581 6.61116 2.61226 4.9963C2.60245 4.64855 2.60061 4.3008 2.61226 3.95366C2.61962 3.76966 2.55093 3.68196 2.37981 3.62063C1.72663 3.38696 1.0802 3.13672 0.42947 2.8963C0.193956 2.80982 0.00628118 2.69023 0.000148014 2.40565C-0.00598515 2.12107 0.179238 1.9935 0.415365 1.90518C2.07745 1.28451 3.73709 0.656472 5.40469 0.0499015C5.60372 -0.0166338 5.81898 -0.0166338 6.01801 0.0499015C7.69543 0.658925 9.36488 1.29003 11.0374 1.91254C11.2827 2.00393 11.4268 2.1548 11.4256 2.43263C11.4207 3.60775 11.4256 4.78286 11.4183 5.95859C11.4183 6.26525 11.2343 6.46458 10.9718 6.48052C10.7093 6.49647 10.4928 6.28856 10.4652 5.97822C10.4584 5.89665 10.4652 5.81446 10.4652 5.73289C10.4652 4.98669 10.4652 4.24049 10.4652 3.49429L10.4627 3.09747ZM1.84807 2.40013C3.15076 2.8871 4.37064 3.34709 5.59543 3.7942C5.69356 3.83038 5.8334 3.79788 5.9395 3.75863C6.99073 3.3704 8.0395 2.97358 9.0895 2.57922C9.22443 2.52893 9.35935 2.47679 9.54764 2.40504C9.40535 2.3437 9.3244 2.30261 9.24098 2.27072C8.46821 1.97939 7.67028 1.74204 6.92878 1.38632C6.0959 0.986435 5.33846 0.986435 4.50189 1.38632C3.67944 1.77516 2.79625 2.04379 1.85052 2.40013H1.84807ZM7.87329 4.06896C7.28696 4.2873 6.76319 4.45719 6.26211 4.67676C6.08844 4.76013 5.89807 4.8029 5.70543 4.80184C5.51279 4.80077 5.32291 4.75591 5.15017 4.67063C4.72084 4.47743 4.27557 4.33392 3.83521 4.17139C3.75671 4.14195 3.67268 4.12661 3.56781 4.09902C3.56781 4.45474 3.55554 4.77367 3.56781 5.08646C3.58912 5.62319 3.80976 6.13271 4.18663 6.51547C4.56349 6.89824 5.06952 7.12676 5.60586 7.1564C6.66445 7.22509 7.64023 6.43943 7.80644 5.34895C7.87206 4.94907 7.85489 4.53692 7.87574 4.06896H7.87329Z"
        fill="#A9A9A9"
      />
      <path
        d="M11.4236 12.6965C11.4236 12.9627 11.4285 13.2289 11.4236 13.4938C11.4169 13.7968 11.2341 13.9937 10.9654 14.0011C10.6968 14.0084 10.4895 13.8207 10.4748 13.5202C10.4543 13.1832 10.453 12.8454 10.4711 12.5082C10.5324 11.5545 10.1031 10.8345 9.35733 10.3212C8.89305 10.0016 8.35272 9.78941 7.83386 9.55697C7.74247 9.51587 7.55602 9.57291 7.472 9.64896C7.16534 9.93048 6.88015 10.242 6.58146 10.5364C6.03929 11.0706 5.38979 11.0688 4.84639 10.5328C4.54771 10.2384 4.26252 9.92987 3.95708 9.64345C3.88287 9.57353 3.73506 9.51158 3.65043 9.53673C2.8341 9.77838 2.09689 10.1617 1.52896 10.8173C1.16097 11.2418 0.953055 11.7269 0.963481 12.2985C0.970841 12.6763 0.970228 13.0553 0.963481 13.4331C0.955508 13.8011 0.761085 14.0121 0.463013 14.0004C0.176594 13.9894 0.00548402 13.7833 0.00855061 13.4276C0.0128438 12.8658 -0.0270266 12.2967 0.049638 11.7441C0.181501 10.7897 0.734713 10.0525 1.5216 9.54041C2.10486 9.16016 2.75866 8.88048 3.40203 8.60265C3.83626 8.41497 4.24472 8.55971 4.57898 8.89029C4.89116 9.19695 5.20457 9.5079 5.50571 9.82806C5.66272 9.99488 5.7817 9.98813 5.93503 9.82499C6.23678 9.50545 6.54835 9.19511 6.86298 8.88723C7.16964 8.58793 7.52781 8.453 7.95406 8.57627C8.98382 8.87496 9.90503 9.35948 10.6257 10.1709C11.2598 10.8952 11.5174 11.741 11.4236 12.6965Z"
        fill="#A9A9A9"
      />
    </svg>
  );
};

export default Alumni;