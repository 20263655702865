import React from "react";

const PollsAndSurveys = () => {
  return (
    <svg
      width="17"
      height="20"
      viewBox="0 0 17 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke="#ADADAD"
    >
      <path
        d="M11.8 2.80078H13.6C14.0774 2.80078 14.5352 2.99042 14.8728 3.32799C15.2104 3.66555 15.4 4.12339 15.4 4.60078V17.2008C15.4 17.6782 15.2104 18.136 14.8728 18.4736C14.5352 18.8111 14.0774 19.0008 13.6 19.0008H2.8C2.32261 19.0008 1.86477 18.8111 1.52721 18.4736C1.18964 18.136 1 17.6782 1 17.2008V4.60078C1 4.12339 1.18964 3.66555 1.52721 3.32799C1.86477 2.99042 2.32261 2.80078 2.8 2.80078H4.6"
        stroke="#ADADAD"
        stroke-width="1.22"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.9 1H5.49998C5.00292 1 4.59998 1.40294 4.59998 1.9V3.7C4.59998 4.19706 5.00292 4.6 5.49998 4.6H10.9C11.397 4.6 11.8 4.19706 11.8 3.7V1.9C11.8 1.40294 11.397 1 10.9 1Z"
        stroke="#ADADAD"
        stroke-width="1.22"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default PollsAndSurveys;