import React, { useState, useEffect, useContext } from "react";
import { Box, Grid, Hidden } from "@material-ui/core";
import SkillUp from "./SkillUp";
import StoryAndLiveFeed from "./StoryAndLiveFeed";
import BannerSlider from "./BannerSlider";
import NewsFeed from "./NewsFeed";
import Page from "src/component/Page";
import MyJobIndex from "src/views/pages/JOB/MyJobIndex";
import MoonLight from "src/views/pages/MyWork/MoonLight";
import { getDataHandlerAPI } from "src/apiConfig/service";
import AddMoonlightModal from "./AddMoonlightModal";
import MoonlightTabs from "../MyWork/MoonlightTabs";
import { AuthContext } from "src/context/Auth";
import News from "src/component/RecommendedPage/News";

export default function ({ homepage, MyJobs, tabView4 }) {
  const auth = useContext(AuthContext);
  const [moonIsLoading, setMoonIsLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [moonListPost, setMoonListPost] = useState([]);
  const [search, setSearch] = useState("");
  const [open, setOpen] = useState(false);

  const listMyMoonLight = async () => {
    try {
      const res = await getDataHandlerAPI(
        tabView4 === "Moonlight" ? "listAllUserMoonlight" : "listMoonlight",
        {
          limit: 10,
          page: page,
          search: search ? search : undefined,
        }
      );
      if (res) {
        setMoonIsLoading(false);
        setMoonListPost(res.docs);
        setPageCount(res.pages);
      } else {
        setMoonIsLoading(false);
        setMoonListPost([]);
      }
    } catch (error) {
      console.log("error", error);
      setMoonIsLoading(false);
      setMoonListPost([]);
    }
  };

  useEffect(() => {
    if (homepage) {
      auth.listPostHandlerBottom(1, []);
    }
    if (tabView4 === "AddMoonlight") {
      listMyMoonLight();
    }
    if (tabView4 === "Moonlight") {
      listMyMoonLight();
    }
  }, [page, homepage, tabView4, search]);

  return (
    <Page title="Dashboard">
      <Box style={{ padding: "0px 8px 0px 0px" }}>
        <Grid
          container
          spacing={1}
          style={{ position: "relative", paddingBottom: "50px" }}
        >
          <Grid item xs={12} md={9}>
            {homepage === "homepage" ? (
              <>
                <Box style={{ padding: "60px 2px 0px 15px" }}>
                  <Box className="scrollDiv">
                    <StoryAndLiveFeed
                      listPostHandler={() => {
                        auth.listPostHandlerBottom(1, []);
                      }}
                    />
                    <Box mt={2}>
                      <BannerSlider />
                    </Box>
                    <Box mt={3}>
                      <NewsFeed
                        listPostHandler={auth.listPostHandlerBottom}
                        listPost={auth.listPost1}
                        isLoading={auth.isLoadingNft}
                        setPage={auth.setPageNft}
                        page={auth.pageNft}
                        totalPages={auth.totalPages}
                        auth={auth}
                      />
                    </Box>
                  </Box>
                </Box>
              </>
            ) : MyJobs === "MyJobs" ? (
              <>
                <MyJobIndex tabView4={tabView4} tabView1={tabView4} />
              </>
            ) : tabView4 === "Moonlight" ? (
              <>
                <Box style={{ padding: "60px 2px 0px 15px" }}>
                  <MoonLight
                    moonListPost={moonListPost}
                    page={page}
                    setPage={setPage}
                    pageCount={pageCount}
                    setSearch={(itm) => setSearch(itm)}
                    search={search}
                    listMyMoonLight={listMyMoonLight}
                    moonIsLoading={moonIsLoading}
                  />
                </Box>
              </>
            ) : (
              <>
                {tabView4 === "AddMoonlight" && (
                  <>
                    <Box style={{ padding: "60px 2px 0px 15px" }}>
                      <MoonlightTabs
                        listMyMoonLight={listMyMoonLight}
                        moonListPost={moonListPost}
                        page={page}
                        setPage={setPage}
                        pageCount={pageCount}
                        tabView4={tabView4}
                        setOpen={setOpen}
                        moonIsLoading={moonIsLoading}
                        setSearch={(itm) => setSearch(itm)}
                        search={search}
                      />
                    </Box>

                    <AddMoonlightModal
                      open={open}
                      setOpen={() => setOpen(false)}
                      listMyMoonLight={listMyMoonLight}
                    />
                  </>
                )}
              </>
            )}
          </Grid>

          <Hidden smDown>
            <Grid item xs={12} md={3}>
              <Box className="scrollDiv">
                <Grid container direction={"column"} spacing={2}>
                  <Grid item xs={12}>
                    <Box style={{ paddingTop: "60px" }}>
                      <SkillUp />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Hidden>
          <Hidden mdUp>
            <News />
          </Hidden>
        </Grid>
      </Box>
    </Page>
  );
}
