import {
  Box,
  TableRow,
  makeStyles,
  TableBody,
  TableCell,
  TableContainer,
  Table,
  TableHead,
  IconButton,
} from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import { saveAs } from "file-saver";
import React, { useState } from "react";
import { BsCheckLg } from "react-icons/bs";
import { RxCross2 } from "react-icons/rx";
import { useHistory } from "react-router-dom";
import {
  getParticularObjectDataHanndler,
  putAPIHandler,
} from "src/apiConfig/service";
import DataNotFound from "./DataNotFound";
import { FiDownload } from "react-icons/fi";
import { AiFillEye, AiOutlineArrowLeft } from "react-icons/ai";
import TopTradingSkeleton from "src/Skeletons/TopTradingSkeleton";
import ConfirmationDialog from "src/component/ConfirmationDialog";

const useStyles = makeStyles((theme) => ({
  transactionBox: {
    position: "relative",
    zIndex: "999",
  },
  imgBox: {
    margin: "0px 4px",
    cursor: "pointer",
    maxWidth: "100%",
    width: "auto",
  },
  tableBox: {
    minWidth: "800px",
  },
  mainfilter: {
    "& .displayEnd": {
      marginTop: "30px",
    },
    "& .filterpaper": {
      padding: "30px",
    },
  },
}));

export default function MainTable1({ moonListPost, isLoading }) {
  const classes = useStyles();
  const [appliedUserList, setAppliedUserList] = useState([]);
  const [applyStatus, setApplyStatus] = useState("");
  const [data, setData] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [isLoading1, setIsLoading1] = useState(true);
  const [isLoadingApprove, setIsLoadingApprove] = useState(false);
  const [pageCount, setPageCount] = useState(1);
  const [page, setPage] = useState(1);
  const [jobId, setJobId] = useState("");
  const [open, setOpen] = useState(false);

  const handleDownload = (value) => {
    const url = value; // Replace with your PDF URL
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        saveAs(blob, "file.pdf"); // Specify the filename for the downloaded file
      })
      .catch((error) => {
        console.log("Error:", error);
      });
  };

  const applyMoonlightListHandler = async (value) => {
    try {
      const paramsData = {
        moonIds: value,
      };
      const response = await getParticularObjectDataHanndler(
        "myapplyMoonlightList",
        paramsData
      );
      if (response) {
        setIsLoading1(false);
        setAppliedUserList(response?.result.docs);
        setPageCount(response.pages);
      } else {
        setIsLoading1(false);
        setAppliedUserList([]);
      }
    } catch (error) {
      setIsLoading1(false);
      setAppliedUserList([]);
    }
  };
  const approveApiHandler = async () => {
    try {
      setIsLoadingApprove(true);
      const dataToSend = {
        moonlightId: data?._id,
        applystatus: applyStatus,
      };
      const response = await putAPIHandler("approveMoonlight", dataToSend);
      if (response?.responseCode === 200) {
        applyMoonlightListHandler(jobId);
        setOpenModal(false);
        setIsLoadingApprove(false);
        setOpen(true);
      } else {
        setIsLoadingApprove(false);
      }
    } catch (error) {
      console.log(error);
      setIsLoadingApprove(false);
    }
  };

  return (
    <Box className={classes.transactionBox}>
      {!open ? (
        <>
          <>
            <TableContainer>
              <Table className={classes.tableBox}>
                <TableHead>
                  <TableRow>
                    <TableCell>S No.</TableCell>
                    <TableCell>Job Name</TableCell>
                    <TableCell>Time</TableCell>
                    <TableCell>Price</TableCell>
                    <TableCell>Location</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {moonListPost &&
                    moonListPost?.map((value, i) => (
                      <TableRow>
                        <TableCell> {(page - 1) * 15 + i + 1}</TableCell>
                        <TableCell>{value?.name}</TableCell>
                        <TableCell>{value?.time}</TableCell>
                        <TableCell>{value?.price}</TableCell>
                        <TableCell>{value?.location}</TableCell>
                        <TableCell>
                          <IconButton
                            onClick={() => {
                              setJobId(value?._id);
                              setOpen(true);
                              applyMoonlightListHandler(value?._id);
                            }}
                          >
                            <AiFillEye />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </>
          {!isLoading && moonListPost && moonListPost.length === 0 && (
            <DataNotFound />
          )}
          <Box mt={3} mb={1} style={{ float: "right" }}>
            {!isLoading &&
              moonListPost &&
              moonListPost.length >= (page === 1 ? 20 : 0) && (
                <Pagination
                  variant="outlined"
                  shape="rounded"
                  count={pageCount}
                  page={page}
                  onChange={(e, v) => setPage(v)}
                />
              )}
          </Box>
        </>
      ) : (
        <UserDetails
          appliedUserList={appliedUserList}
          setApplyStatus={setApplyStatus}
          setOpenModal={setOpenModal}
          setData={setData}
          page={page}
          handleDownload={handleDownload}
          isLoading1={isLoading1}
          pageCount={pageCount}
          setPage={setPage}
          openModal={openModal}
          approveApiHandler={approveApiHandler}
          isLoadingApprove={isLoadingApprove}
          setOpen={setOpen}
          applyStatus={applyStatus}
        />
      )}
    </Box>
  );
}
const UserDetails = ({
  appliedUserList,
  setApplyStatus,
  setOpenModal,
  setData,
  page,
  pageCount,
  handleDownload,
  setPage,
  isLoading1,
  openModal,
  approveApiHandler,
  isLoadingApprove,
  setOpen,
  applyStatus,
}) => {
  return (
    <Box>
      <IconButton
        onClick={() => setOpen(false)}
        style={{
          background: "#4057A7",
          color: "#fff",
          padding: "8px",
          fontSize: "15px",
        }}
      >
        <AiOutlineArrowLeft />
      </IconButton>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {[
                "S No.",
                "User Name",
                "Job Name",
                "Status",
                "Apply Status",
                "Experience",
                "Action",
                "Download resume",
              ].map((item) => {
                return <TableCell>{item}</TableCell>;
              })}
            </TableRow>
          </TableHead>

          <TableBody>
            {appliedUserList?.map((value, i) => (
              <TableRow>
                <TableCell> {(page - 1) * 15 + i + 1}</TableCell>
                <TableCell>
                  {value?.userId?.firstName ? (
                    <>
                      {value?.userId?.firstName}&nbsp;{value.lastName}
                    </>
                  ) : (
                    "--"
                  )}
                </TableCell>
                <TableCell>
                  {value?.moonlightId.name ? (
                    <>{value?.moonlightId.name}</>
                  ) : (
                    "--"
                  )}
                </TableCell>

                <TableCell
                  style={{
                    color: value.status === "ACTIVE" ? "green" : "red",
                  }}
                >
                  {value.status === "ACTIVE"
                    ? "Active"
                    : value.status === "BLOCK"
                    ? "Blocked"
                    : "--"}
                </TableCell>
                <TableCell
                  style={{
                    color:
                      value.applystatus === "APPROVE"
                        ? "green"
                        : value.applystatus === "REJECT"
                        ? "red"
                        : "orange",
                  }}
                >
                  {value.applystatus === "APPROVE"
                    ? "Approved"
                    : value.applystatus === "REJECT"
                    ? "Rejected"
                    : value.applystatus === "PENDING"
                    ? "Pending"
                    : "--"}
                </TableCell>
                <TableCell>
                  {value.experience ? value.experience : "--"}
                </TableCell>

                <TableCell>
                  <Box display="flex" alignItems="center">
                    <IconButton
                      disabled={
                        value.applystatus === "APPROVE" ||
                        value.applystatus === "REJECTED"
                      }
                      style={{
                        background: "#008000a1",
                        color: "#fff",
                        padding: "6px",
                      }}
                      onClick={() => {
                        setOpenModal(true);
                        setApplyStatus("APPROVE");
                        setData(value);
                      }}
                    >
                      <BsCheckLg />
                    </IconButton>
                    <Box ml={1}>
                      <IconButton
                        disabled={
                          value.applystatus === "APPROVE" ||
                          value.applystatus === "REJECT"
                        }
                        style={{
                          background: "#ff0000a8",
                          color: "#fff",
                          padding: "6px",
                        }}
                        onClick={() => {
                          setOpenModal(true);
                          setApplyStatus("REJECT");
                          setData(value);
                        }}
                      >
                        <RxCross2 />
                      </IconButton>
                    </Box>
                  </Box>
                </TableCell>
                <TableCell style={{ textAlign: "center" }}>
                  <IconButton
                    style={{
                      padding: "6px",
                    }}
                    onClick={() => handleDownload(value?.resume)}
                  >
                    <FiDownload />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {isLoading1 && <TopTradingSkeleton />}
      {!isLoading1 && appliedUserList && appliedUserList.length === 0 && (
        <DataNotFound />
      )}
      <Box mt={3} mb={1} style={{ float: "right" }}>
        {!isLoading1 &&
          appliedUserList &&
          appliedUserList.length >= (page === 1 ? 20 : 0) && (
            <Pagination
              variant="outlined"
              shape="rounded"
              count={pageCount}
              page={page}
              onChange={(e, v) => setPage(v)}
            />
          )}
      </Box>
      <ConfirmationDialog
        open={openModal}
        handleClose={() => setOpenModal(false)}
        isLoading1={isLoadingApprove}
        title={applyStatus === "APPROVE" ? "Approve" : "Reject"}
        desc={`Are you sure want to ${
          applyStatus === "APPROVE" ? "approve" : "reject"
        }?`}
        confirmationHandler={() => approveApiHandler()}
      />
    </Box>
  );
};
