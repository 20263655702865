import React, { useState, useContext, useEffect } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import {
  AppBar,
  Toolbar,
  makeStyles,
  IconButton,
  Hidden,
  Typography,
  Box,
  TextField,
  SvgIcon,
  withStyles,
  MenuItem,
  Menu,
  Avatar,
  Button,
  Dialog,
  Divider,
  List,
  Badge,
  Tooltip,
  Popover,
} from "@material-ui/core";
import Logo from "src/component/Logo";
import {
  GiBlackBook,
  SlBookOpen,
  BiLogOut,
  IoChatboxEllipsesOutline,
  BiUser,
} from "react-icons/all";
import { FiBell, FiSearch, FiSettings } from "react-icons/fi";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Menu as MenuIcon } from "react-feather";
import { useHistory } from "react-router-dom";
import ConfirmationDialog from "src/component/ConfirmationDialog";
import SearchModal from "src/component/SearchModal";
import { AuthContext } from "../../../context/Auth";
import MetmaskDialog from "src/component/MetmaskDialog";
import { sortAddress } from "src/utils";
import Axios from "axios";
import apiConfigs from "src/apiConfig/ApiConfig";
import { toast } from "react-toastify";
import DataNotFound from "src/component/DataNotFound";
import DataLoading from "src/component/DataLoading";
import moment from "moment";
import CommentListSkeleton from "src/Skeletons/CommentListSkeleton";
import { HiOutlineDocumentText } from "react-icons/hi2";
import { getParticularObjectDataHanndler } from "src/apiConfig/service";

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);
const StyledMenu = withStyles({
  paper: {
    minWidth: "120px !important",
    boxShadow: "0px 0px 11px rgba(0, 0, 0, 0.16)",
    borderRadius: "10px",
    borderRadius: "5px",
    background: "#FDFDFD",
    backdropFilter: " blur(30px)",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const useStyles = makeStyles((theme) => ({
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "113px",
    [theme.breakpoints.down("xs")]: {
      paddingRight: "15px",
      display: "block",
      height: "45px",
      minHeight: "45px",
    },
    "& .logoHeader": {
      width: "85px",
      [theme.breakpoints.down("xs")]: {
        width: "55px",
      },
    },
  },
  toolbarbg: {
    background: "url('images/topbarbg.png')",
    backgroundColor: "#ffffff",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
  },
  logo: {
    marginRight: theme.spacing(2),
  },
  divider: {
    width: 1,
    height: 32,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  mainform: {
    "& .MuiOutlinedInput-root": {
      height: "40px",
      borderRadius: "25px",
      // backgroundColor: "#fff",
    },
    "& .MuiOutlinedInput-input": {
      padding: "0px 0px 0px 10px",
      broderRadius: "25px",
    },
    "& .searchBox": {
      "& .MuiFormControl-root": {
        borderRadius: "50px",
      },
    },
    "& .MuiInputBase-input": {
      fontSize: "14px",
    },
  },
  iconbtncss12: {
    "& h5": {
      color: "#fff",
    },
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  iconbtncss: {
    display: "flex",
    alignItems: "center",
    marginLeft: "15px",
    position: "relative",
    "& .iconenotification": {
      backgroundColor: "#EDEDED",
      padding: "12px",
      fontSize: "17px",
      [theme.breakpoints.down("xs")]: {
        padding: "6px",
        fontSize: "15px",
      },
      "& svg": {
        color: "#535353",
      },
    },
    "& .MuiBadge-badge": {
      padding: "4px 2px",
      height: "10px",
      minWidth: "10px",
      fontSize: "0.53rem",
    },
    "& .MuiBadge-anchorOriginTopRightRectangle": {
      transform: "scale(1) translate(17%, -31%)",
    },
  },
  typo: {
    display: "flex",
    justifyContent: "space-between",
    padding: " 12px 10px 14px",
    "& p": {
      fontWeight: "600",
      paddingLeft: "15px",
    },
  },
  notificationbox: {
    display: "flex",
    justifyContent: "space-between",
  },
  paper: {
    minWidth: "120px !important",
  },
  desktopDrawer: {
    position: "absolute",
    right: 0,
    top: 30,
    width: 349,
    minWidth: "120px !important",
    background: "#FFFFFF",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "5px",
    [theme.breakpoints.only("xs")]: {
      width: "243px",
    },
  },
  avatar: {
    cursor: "pointer",
    width: 40,
    height: 40,
    "@media (max-width: 767px)": {
      width: "37px",
      height: "37px",
    },
  },
  mainProfile: {
    "& .ButtonClass": {
      height: "40px",
      padding: "6px 28px",
      [theme.breakpoints.only("xs")]: {
        height: "40px",
        padding: "5px 18px",
        fontSize: "12px",
      },
    },
    " & .MainButton": {
      [theme.breakpoints.only("xs")]: {
        marginLeft: "66px",
      },
    },
  },
  UserNameClass: {
    display: "flex",
    alignItems: "center",

    padding: "28px 0px 18px 28px",
    [theme.breakpoints.only("xs")]: {
      padding: "20px 0px 15px 10px",
    },

    " & .UserProfile": {
      marginRight: "20px",
    },
    " & .AvatarProfile": {
      height: "60px",
      width: "60px",
    },
  },

  demo: {
    "& .MuiListItemIcon-root": {
      minWidth: "0px",
    },
    " & svg": {
      fontSize: "22px",
    },

    " & .ButtonBottom": {
      fontSize: "14px",
      fontWeight: "500",
    },
  },
  notificationBox: {
    "& .MuiPopover-paper": {
      width: "400px !important",
    },
  },
}));

const TopBar = ({ setMenuCollapse, className, onMobileNavOpen, ...rest }) => {
  const classes = useStyles();
  const history = useHistory();
  const auth = useContext(AuthContext);
  const [rightBar, setRightBar] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [openSearch, setOpenSearch] = useState(false);
  const [dissContWall, setDissContWall] = useState(false);
  const [notiLoading, setNotiLoading] = useState(false);
  const [notificationListData, setNotificationListData] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [notificationCount, setNotificationCount] = React.useState("");

  const readNotificationApi = async () => {
    try {
      const response = await Axios({
        method: "PUT",
        url: apiConfigs.notificationRead,
        headers: {
          token: window.sessionStorage.getItem("token"),
        },
      });
      if (response.data.responseCode === 200) {
        getNotificationList();
      } else {
        toast.error(response.data.responseMessage);
      }
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.responseMessage);
    }
  };

  const getNotificationList = async () => {
    try {
      setNotiLoading(true);
      const response = await getParticularObjectDataHanndler(
        "notificationList",
        {
          page: 1,
          limit: "15",
        }
      );
      if (response.responseCode === 200) {
        setNotificationListData(response.result.docs);
        setNotificationCount(response.result.count);
        setNotiLoading(false);
      } else {
        setNotiLoading(false);
        setNotificationListData([]);
      }
    } catch (error) {
      console.log(error);
      setNotiLoading(false);
      setNotificationListData([]);
    }
  };

  useEffect(() => {
    getNotificationList();
  }, []);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      {open1 && (
        <ConfirmationDialog
          open={open1}
          handleClose={() => setOpen1(false)}
          title={"Logout"}
          desc={"Are you sure you want to logout?"}
        />
      )}
      <PerfectScrollbar
        options={{ suppressScrollX: true }}
        className="walletbox"
      >
        <Box className={classes.mainProfile}>
          <Box className={classes.UserNameClass}>
            <Box className="UserProfile">
              <Avatar
                src={auth?.userData?.profilePic}
                className="AvatarProfile"
              />
            </Box>
            <Box>
              <Typography
                variant="h4"
                style={{ fontWeight: "700", width: "200px" }}
                className="textEllipsis"
              >
                {auth?.userData?.firstName} {auth?.userData?.lastName}
              </Typography>
              <Typography
                variant="body2"
                style={{ fontWeight: "500", width: "200px" }}
                className="textEllipsis"
              >
                {auth?.userData?.email}
              </Typography>
            </Box>
          </Box>

          <Box pl={2} pr={2}>
            <Divider />
          </Box>
        </Box>

        <Box mt={1}></Box>
        <Box className={classes.termsPrivay}>
          <div className={classes.demo}>
            <List style={{ paddingLeft: "16px", paddingBottom: "0px" }}>
              <Box>
                <IconButton>
                  <BiUser />
                </IconButton>
                <Button
                  className="ButtonBottom"
                  onClick={() => history.push("/self-about")}
                >
                  View Profile
                </Button>
              </Box>
              <Box>
                <IconButton>
                  <GiBlackBook />
                </IconButton>
                <Button
                  className="ButtonBottom"
                  onClick={() => history.push("/terms-conditions")}
                >
                  Terms & Conditions
                </Button>
              </Box>
              <Box>
                <IconButton>
                  <SlBookOpen />
                </IconButton>
                <Button
                  className="ButtonBottom"
                  onClick={() => history.push("/privacy-policy")}
                >
                  Privacy Policy
                </Button>
              </Box>
              <Box>
                <IconButton>
                  <HiOutlineDocumentText />
                </IconButton>
                <Button
                  className="ButtonBottom"
                  onClick={() =>
                    auth?.userData?.kycStatus === "APPROVE"
                      ? history.push("/all-pages")
                      : toast.warn(
                          "Please complete your KYC to access this feature."
                        )
                  }
                >
                  Business Pages
                </Button>
              </Box>
              <Box mb={2}>
                <IconButton>
                  <BiLogOut />
                </IconButton>
                <Button className="ButtonBottom" onClick={() => setOpen1(true)}>
                  Logout
                </Button>
              </Box>
            </List>
          </div>
        </Box>
      </PerfectScrollbar>
    </Box>
  );
  return (
    <AppBar
      elevation={0}
      className={clsx(classes.toolbarbg, className)}
      color="inherit"
      {...rest}
      style={{
        border: "none",
      }}
    >
      <Box>
        <Box>
          <Toolbar className={classes.toolbar}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              style={{ width: "100%" }}
            >
              <Box
                display="flex"
                justifyContent="flex-start"
                alignItems="center"
                style={{ marginRight: "20px" }}
              >
                <Hidden lgUp>
                  <IconButton
                    style={{ padding: "10px" }}
                    color="#fff"
                    onClick={() => {
                      onMobileNavOpen();
                      setMenuCollapse(false);
                    }}
                  >
                    <SvgIcon fontSize="small" style={{ fontSize: "25px" }}>
                      <MenuIcon style={{ color: "#fff" }} />
                    </SvgIcon>
                  </IconButton>
                </Hidden>
                <Logo className="logoHeader" />
              </Box>
              <Hidden xsDown>
                <Box style={{ width: " 700px", maxWidth: "100%" }}>
                  <Box className={classes.mainform}>
                    <Box className="searchBox">
                      <TextField
                        variant="outlined"
                        fullWidth
                        placeholder="Search"
                        type="search"
                        InputProps={{
                          endAdornment: (
                            <FiSearch
                              position="end"
                              style={{ fontSize: "14px", color: "#AFAFAF" }}
                            />
                          ),
                        }}
                        onClick={() => setOpenSearch(true)}
                      />
                    </Box>
                  </Box>
                </Box>
              </Hidden>
              {openSearch && (
                <SearchModal
                  openSearch={openSearch}
                  handleSearchClose={() => setOpenSearch(false)}
                />
              )}

              <Box className={classes.iconbtncss12}>
                <Box className={classes.iconbtncss}>
                  <Hidden smUp>
                    <Box>
                      <Tooltip title="Search" arrow>
                        <IconButton
                          className="iconenotification"
                          variant="contained"
                          onClick={() => setOpenSearch(true)}
                        >
                          <FiSearch />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </Hidden>
                  <Box pl={1}>
                    <Tooltip title="Chat" arrow>
                      <IconButton
                        className="iconenotification"
                        onClick={() =>
                          auth?.userData?.kycStatus === "APPROVE"
                            ? history.push("/chat")
                            : toast.warn(
                                "Please complete your KYC to access this feature."
                              )
                        }
                      >
                        <IoChatboxEllipsesOutline color="#535353" />
                      </IconButton>
                    </Tooltip>
                  </Box>
                  <Box pl={1}>
                    <Tooltip title="Notification" arrow>
                      <IconButton
                        className="iconenotification"
                        onClick={(event) => {
                          setAnchorEl(event.currentTarget);

                          readNotificationApi();
                        }}
                      >
                        <Badge
                          badgeContent={
                            notificationCount !== 0 && notificationCount
                          }
                          color={notificationCount !== 0 ? "error" : "none"}
                        >
                          <FiBell color="#535353" />
                        </Badge>

                        {/* <FiBell color="#535353" /> */}
                      </IconButton>
                    </Tooltip>

                    <Popover
                      id={id}
                      className={classes.notificationBox}
                      open={open}
                      anchorEl={anchorEl}
                      onClose={() => setAnchorEl(null)}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "center",
                      }}
                    >
                      <Box className={classes.typo}>
                        <Box>
                          <Typography
                            variant="h5"
                            style={{ color: "#323232", fontWeight: "700" }}
                          >
                            Notifications
                          </Typography>
                        </Box>
                        {notificationListData?.length > 7 && (
                          <Box>
                            <Typography
                              variant="h5"
                              color="primary"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                history.push("/allnotification");
                              }}
                            >
                              See All
                            </Typography>
                          </Box>
                        )}
                      </Box>

                      <Box style={{ maxHeight: "350px", overflow: "scroll" }}>
                        {notificationListData &&
                          notificationListData?.map((data, i) => {
                            return (
                              <StyledMenuItem key={i}>
                                <Box className={classes.notificationbox}>
                                  <Box display="flex" alignItems="center">
                                    <Box minWidth="50px">
                                      <IconButton className="iconenotification">
                                        <FiBell color="#535353" />
                                      </IconButton>
                                    </Box>
                                    <Box ml={1}>
                                      <Typography
                                        variant="body2"
                                        style={{
                                          textOverflow: "ellipsis",
                                          overflow: "hidden",
                                          whiteSpace: "break-spaces",
                                          fontSize: "12px",
                                          color: "#323232",
                                        }}
                                      >
                                        {data?.message}
                                      </Typography>

                                      <Typography
                                        variant="body1"
                                        style={{ color: "#323232" }}
                                      >
                                        {moment(data?.createdAt)
                                          .startOf("")
                                          .fromNow()}
                                      </Typography>
                                    </Box>
                                  </Box>
                                </Box>
                              </StyledMenuItem>
                            );
                          })}
                        {notiLoading &&
                          [1, 2, 3].map((itm) => {
                            return <CommentListSkeleton type="notification" />;
                          })}
                        {!notiLoading &&
                          notificationListData &&
                          notificationListData.length === 0 && <DataNotFound />}
                      </Box>
                    </Popover>
                  </Box>
                  <Box pl={1}>
                    <Tooltip title="Setting" arrow>
                      <IconButton
                        className="iconenotification"
                        onClick={() => {
                          setRightBar(!rightBar);
                        }}
                      >
                        <FiSettings />
                      </IconButton>
                    </Tooltip>
                  </Box>
                  <Box ml={1}>
                    {!auth.account ? (
                      <Tooltip title="Connect" arrow>
                        <IconButton
                          className="iconenotification"
                          onClick={() => auth.setOpenMetamask(true)}
                        >
                          <img
                            src="images/MetaMask.png"
                            style={{ width: "20px" }}
                          />{" "}
                        </IconButton>
                      </Tooltip>
                    ) : (
                      <Tooltip title="Disconnect" arrow>
                        <IconButton
                          className="iconenotification"
                          style={{ borderRadius: "40px" }}
                          onClick={() => setDissContWall(true)}
                        >
                          {sortAddress(auth.account)}
                        </IconButton>
                      </Tooltip>
                    )}
                  </Box>
                  <MetmaskDialog
                    setOpenMetamask={auth.setOpenMetamask}
                    openMetamask={auth.openMetamask}
                    auth={auth}
                  />
                </Box>
                <Box className="nameBox">
                  <Typography variant="h5">
                    {auth.userData.firstName ? auth.userData.firstName : ""}{" "}
                    {auth.userData.lastName ? auth.userData.lastName : ""}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Toolbar>
        </Box>

        <Dialog
          classes={{ paper: classes.desktopDrawer }}
          open={rightBar}
          onClose={() => {
            setRightBar(false);
          }}
        >
          {content}
        </Dialog>
      </Box>
      {dissContWall && (
        <ConfirmationDialog
          open={dissContWall}
          handleClose={() => setDissContWall(false)}
          title={"Disconnect"}
          confirmationHandler={() => {
            auth.disconnectWalletHandler();
            setDissContWall(false);
          }}
          desc={"Are you sure, you want to disconnect?"}
        />
      )}
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
};
TopBar.defaultProps = {
  onMobileNavOpen: () => {},
};

export default TopBar;
