import React from "react";

const AttachementIcon = () => {
  return (
    <svg
      width="21"
      height="19"
      viewBox="0 0 21 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.46665 17.3206L10.5005 18.326L19.2748 9.794C21.5751 7.5572 21.5751 3.91547 19.2748 1.67867C16.973 -0.559555 13.2308 -0.559555 10.929 1.67867L1.42362 10.9216C-0.474539 12.7674 -0.474539 15.7692 1.42362 17.615C2.37123 18.5364 3.61864 19 4.86604 19C6.11344 19 7.35938 18.5379 8.30846 17.615L17.0827 9.083C17.8066 8.37911 18.2043 7.44344 18.2043 6.44804C18.2043 5.45264 17.8066 4.51697 17.0812 3.81166C15.6335 2.40388 13.108 2.40388 11.6602 3.81166L3.61717 11.6326L4.65107 12.638L12.6941 4.81701C13.5876 3.94817 15.1538 3.94817 16.0473 4.81701C16.4948 5.25214 16.7419 5.83089 16.7419 6.44804C16.7419 7.06519 16.4948 7.64394 16.0473 8.07765L7.2731 16.6096C5.94527 17.9008 3.78535 17.9008 2.45751 16.6096C1.12968 15.3185 1.12968 13.2182 2.45751 11.927L11.9629 2.68402C13.6944 1.00037 16.5094 1.00037 18.2409 2.68402C19.9723 4.36766 19.9723 7.105 18.2409 8.78865L9.46665 17.3206Z"
        fill="#4057A7"
      />
    </svg>
  );
};

export default AttachementIcon;
