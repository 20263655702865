import React, { useState, useContext } from "react";
import {
  Box,
  Grid,
  Paper,
  TextField,
  Typography,
  Button,
} from "@material-ui/core";
import { CiStopwatch } from "react-icons/ci";
import { CiLocationOn } from "react-icons/ci";
import moment from "moment";
import { makeStyles } from "@material-ui/core";
import ApplyJobModal from "src/component/ApplyJobModal";
import { FiSearch } from "react-icons/fi";
import AddMoonlightModal from "../Dashboard/AddMoonlightModal";
import { deleteDataAPIHandler } from "src/apiConfig/service";
import { Pagination } from "@material-ui/lab";
import { AuthContext } from "src/context/Auth";
import DataNotFound from "src/component/DataNotFound";
import LoadHistory from "src/Skeletons/LoadHistory";
import ConfirmationDialog from "src/component/ConfirmationDialog";

const useStyles = makeStyles((theme) => ({
  followersBox: {
    "& .MuiOutlinedInput-input": {
      padding: "15px 15 px",
      broderRadius: "5px",
    },
    "& .searchBox": {
      "& .MuiFormControl-root": {
        borderRadius: "5px",
      },
      "& h6": {},
    },
  },
  btn: {
    marginTop: "10px",
    "& Button": {
      padding: "8px 30px",
    },
  },
  firstBox: {
    borderRight: "1px solid rgba(50, 50, 50, 0.8);",
    height: "100%",
    [theme.breakpoints.down("xs")]: {
      borderRight: "none",
    },

    "& svg": {
      padding: "0px 4px",
    },
    "& h6": {
      color: "rgba(50, 50, 50, 0.8)",
    },
  },
  moonjobBox: {
    textAlign: "center",
    borderRight: "1px solid rgba(50, 50, 50, 0.60)",

    [theme.breakpoints.down("sm")]: {
      borderRight: "none",
    },
  },
  typobox: {
    paddingLeft: "10px",

    [theme.breakpoints.down("sm")]: {
      paddingLeft: "0px",
    },
  },
  dateBox: {
    position: "absolute",
    bottom: "5px",
    right: "5px",
  },
}));

export default function MoonLight({
  moonListPost,
  type,
  listMyMoonLight,
  pageCount,
  page,
  setPage,
  tabView4,
  setOpen,
  moonIsLoading,
  setSearch,
  search,
  setIsLoading,
  isLoading,
}) {
  const classes = useStyles();
  const user = useContext(AuthContext);
  const userData = user?.userData;
  const [selectedData, setData] = useState({
    open: false,
    id: "",
  });
  const [modalsOpen, setModalsOpen] = useState({
    deleteModals: false,
    id: "",
  });
  const [jobDataID, setJobDataId] = useState({});
  const [openCreatePost, setOpenCreatePost] = useState(false);

  const deeleteHandler = async (id) => {
    const idSend = {
      _id: id,
    };
    try {
      setIsLoading(true);
      const res = await deleteDataAPIHandler("deleteMoonlight", idSend);
      if (res) {
        listMyMoonLight();
        setModalsOpen({ ...modalsOpen, deleteModals: false });
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };
  const pagecheck = page === 1 ? 10 : 0;
  return (
    <Paper className={`${classes.followersBox} paddingBox`} elevation={0}>
      <Box>
        <Grid container spacing={2}>
          <Box
            item
            className="scrollDiv"
            style={{ marginBottom: "55px", width: "100%" }}
          >
            <Box
              className="searchBox"
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <TextField
                variant="outlined"
                fullWidth
                placeholder="Search..."
                type="search"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <FiSearch
                      position="end"
                      style={{ fontSize: "14px", color: "#AFAFAF" }}
                    />
                  ),
                }}
              />
              &nbsp;&nbsp;
              {tabView4 === "AddMoonlight" && (
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "end",
                  }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    style={{ whiteSpace: "pre", padding: "26px" }}
                    onClick={() => setOpen(true)}
                  >
                    Add Moonlight
                  </Button>
                </Box>
              )}
            </Box>
            {moonIsLoading ? (
              <>
                {[1, 2, 3, 4].map((data, i) => {
                  return <LoadHistory />;
                })}
              </>
            ) : (
              <>
                {moonListPost && moonListPost.length === 0 && <DataNotFound />}
                {moonListPost &&
                  moonListPost.map((data, index) => {
                    return (
                      <>
                        <Box
                          style={{
                            background: "#F6F6F6",
                            overflow: "scroll",
                          }}
                          mt={2}
                          p={1.5}
                          mb={1}
                        >
                          <Grid container spacing={2}>
                            <Grid
                              item
                              xs={12}
                              sm={4}
                              md={4}
                              className={classes.moonjobBox}
                            >
                              <Box mb={1}>
                                <Typography
                                  variant="h4"
                                  style={{ wordBreak: "break-word" }}
                                >
                                  {data?.name}
                                </Typography>
                              </Box>
                              <Box className="displayFlexCenter">
                                <Box
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <CiStopwatch />

                                  <Typography variant="h6">
                                    {data?.time}
                                  </Typography>
                                </Box>
                                <Box
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    paddingLeft: "8px",
                                  }}
                                >
                                  <CiLocationOn />

                                  <Typography
                                    variant="h6"
                                    style={{ wordBreak: "break-word" }}
                                  >
                                    {data?.location}
                                  </Typography>
                                </Box>
                              </Box>
                              <Box mb={2}>
                                <Typography
                                  variant="h5"
                                  style={{
                                    color: "#4057A7",
                                    wordBreak: "break-word",
                                  }}
                                >
                                  ₹ {data?.price}
                                </Typography>
                              </Box>
                              <Box className={classes.btn}>
                                {type === "mypost" ? (
                                  <>
                                    <Button
                                      variant="contained"
                                      color="primary"
                                      onClick={() =>
                                        setData({
                                          open: true,
                                          id: data,
                                        })
                                      }
                                    >
                                      Update
                                    </Button>
                                    &nbsp;
                                    <Button
                                      variant="contained"
                                      color="primary"
                                      onClick={() =>
                                        setModalsOpen({
                                          deleteModals: true,
                                          id: data?._id,
                                        })
                                      }
                                    >
                                      Delete
                                    </Button>
                                  </>
                                ) : (
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    disabled={data?.is_apply}
                                    onClick={() => {
                                      setOpenCreatePost(true);
                                      setJobDataId(data);
                                    }}
                                  >
                                    {data?.is_apply ? "Applied" : "Apply Now"}
                                  </Button>
                                )}
                              </Box>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={8}
                              md={8}
                              style={{ position: "relative" }}
                            >
                              <Box className={classes.typobox}>
                                <Typography
                                  variant="h6"
                                  style={{
                                    color: "rgba(50, 50, 50, 0.8)",
                                    wordBreak: "break-word",
                                    margin: "0px",
                                  }}
                                >
                                  <div
                                    style={{ margin: "0px" }}
                                    dangerouslySetInnerHTML={{
                                      __html: data?.description,
                                    }}
                                  ></div>
                                </Typography>

                                <Typography
                                  style={{
                                    color: "rgba(50, 50, 50, 0.8)",
                                  }}
                                  className={classes.dateBox}
                                  variant="body1"
                                >
                                  {moment(data?.createdAt).format("L")}
                                </Typography>
                              </Box>
                            </Grid>
                          </Grid>
                        </Box>
                      </>
                    );
                  })}
              </>
            )}
            <Box mb={4}>
              {moonListPost && moonListPost?.length >= pagecheck && (
                <Pagination
                  variant="outlined"
                  shape="rounded"
                  count={pageCount}
                  page={page}
                  onChange={(e, v) => setPage(v)}
                />
              )}
            </Box>
          </Box>
        </Grid>
      </Box>

      <ApplyJobModal
        openCreatePost={openCreatePost}
        setOpenCreatePost={setOpenCreatePost}
        handleCloseCreatePost={() => setOpenCreatePost(false)}
        jobDataID={jobDataID}
        userData={userData}
        callBack={listMyMoonLight}
        type="moonlight"
      />
      {selectedData?.open && (
        <AddMoonlightModal
          selectedData={selectedData}
          listMyMoonLight={listMyMoonLight}
          open={selectedData?.open}
          setOpen={() =>
            setData({
              open: false,
            })
          }
        />
      )}

      {modalsOpen?.deleteModals && (
        <ConfirmationDialog
          open={modalsOpen?.deleteModals}
          handleClose={() =>
            setModalsOpen({ ...modalsOpen, deleteModals: false })
          }
          isLoading={isLoading}
          title="Delete"
          desc="Are you sure, you want to delete?"
          confirmationHandler={() => deeleteHandler(modalsOpen?.id)}
        />
      )}
    </Paper>
  );
}
