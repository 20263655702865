import React, { useState } from "react";
import {
  makeStyles,
  Dialog,
  DialogContent,
  IconButton,
  FormControl,
  Typography,
  TextField,
  Box,
  Divider,
  Button,
  Avatar,
  Grid,
  FormHelperText,
} from "@material-ui/core";
import { IoClose } from "react-icons/io5";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { values } from "lodash";
import SuccessfullModal from "./SuccessfullModal";
import AttachementIcon from "src/icons/AttachementIcon";
import { postDataIdinWithdata } from "src/apiConfig/service";
import ButtonCircularProgress from "./ButtonCircularProgress";
import { handleNegativeValue } from "src/utils";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  mainmodalBox: {
    "& .formControl": {
      width: "100%",
      backgroundColor: "transparent",
      border: "none",
      color: "#fff",
      "&:focus-visible": {
        outline: "none",
      },
    },
    "& h6": {
      fontWeight: "700",
    },
    "& p": {
      color: "rgba(0, 0, 0, 0.4)",
    },

    "& .MuiFormControl-root": {
      background: "#fff",
    },
    "& .addImagegIcon": {
      // cursor: "pointer",
      position: "relative",
      display: "flex",
      alignItems: "center",
      "& label": { cursor: "pointer" },
      "& input": {
        top: 0,
        left: 0,
        position: "absolute",
        width: "100%",
        height: "100%",
        opacity: 0,
      },
      "& svg": {
        color: "#ACACAC",
        fontSize: "22px",
        cursor: "pointer",
      },
    },
    "& .grid2": {
      textAlign: "end",
      [theme.breakpoints.down("xs")]: {
        textAlign: "start",
      },
    },
  },
  cancelBtn: {
    position: "absolute",
    top: 0,
    right: 0,
    "& svg": {
      color: "#fff",
      fontWeight: "700",
    },
  },

  root: {
    width: "100%",
    "& .displaySpaceBetween": {
      [theme.breakpoints.down("xs")]: {
        display: "block",
        "& p": {
          paddingBottom: "10px",
        },
      },
    },
  },

  avatarBox: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    textAlign: "center",
    alignItems: "center",
    padding: "10px 0px",
    "& .MuiAvatar-root": {
      height: "100px",
      width: "100px",
    },
  },
  radiogroup: {
    "& .MuiRadio-root": {
      color: "#4057A7",
    },
  },
  outlineborder1: {
    "& .react-tel-input": {
      "& .form-control": {
        borderRadius: "4px !important",
        // border:"none",
      },
    },
  },

  previewBox: {
    border: "1px solid rgba(64, 87, 167, 0.5)",
    borderRadius: "4px",
    "& .pdfBox": {
      padding: "10px",
      background: "rgba(64, 87, 167, 0.5)",
      "& h5": {
        color: "#fff",
        fontWeight: "400",
      },
    },
    "& .nameBox": {
      width: "100%",
      minWidth: "60px",
      wordBreak: "break-all",
      maxWidth: "67px",
      padding: "0px 8px",
    },
    "& .ViewBox": {
      paddingLeft: "10px",
      borderLeft: "1px solid rgba(50, 50, 50, 0.4)",
      "& p": {
        color: "#4057A7",
        textDecoration: "underline",
      },
    },
  },
}));

function HireModal({
  openCreatePost,
  handleCloseCreatePost,
  userData,
  jobDataID,
  callBack,
  type,
}) {
  const classes = useStyles();
  const history = useHistory();
  const [handleOpen, setHandleOpen] = useState(false);
  const [imageData, setImageData] = useState("");
  const [coverData, setCoverData] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [experiance, setExperiance] = useState("");
  const [question, setQuestion] = useState("No");

  const handleclose = () => {
    setHandleOpen(false);
  };
  const applyJobHandler = async () => {
    if ((imageData !== "" || coverData !== "") && experiance !== "") {
      setIsSubmit(false);
      try {
        setIsLoading(true);
        const formData = new FormData();
        formData.append(
          type === "moonlight" ? "moonlightId" : "jobId",
          jobDataID?._id
        );
        formData.append("resume", imageData ? imageData : undefined);
        formData.append("cover_Letter", coverData ? coverData : undefined);
        if (type !== "moonlight") {
          formData.append(
            type !== "moonlight" && "additionalQuestion",
            question === "No" ? false : true
          );
        }
        formData.append("experience", experiance);
        const response = await postDataIdinWithdata(
          type === "moonlight" ? "applyMoonlight" : "applyJob",
          formData
        );

        if (response?.responseCode === 200) {
          setIsLoading(false);
          handleCloseCreatePost();
          setImageData("");
          setExperiance("");
          setCoverData("");
          if (callBack) {
            callBack();
          }
        } else {
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
      }
    } else {
      setIsSubmit(true);
    }
  };

  return (
    <Dialog
      open={openCreatePost}
      onClose={() => {
        if (!isLoading) {
          handleCloseCreatePost();
        }
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="sm"
      fullWidth
      className={classes.root}
      handleDisabled
    >
      <Box className="bgcolorbox">
        <Box>
          <Typography variant="h5">
            Apply {jobDataID?.compnayName && `to ${jobDataID?.compnayName}`}
          </Typography>
        </Box>
      </Box>
      <DialogContent>
        <IconButton
          className={classes.cancelBtn}
          onClick={handleCloseCreatePost}
          disabled={isLoading}
        >
          <IoClose />
        </IconButton>

        <Box className={classes.mainmodalBox}>
          <Box>
            <Typography variant="h6">Contact Info</Typography>
          </Box>
          <Box className={classes.avatarBox}>
            <Box>
              <Avatar src={userData?.profilePic} />
            </Box>
            <Typography variant="h6">
              {userData?.firstName ? userData?.firstName : "N/A"}
            </Typography>
            <Typography variant="body2" style={{ marginBottom: "-4px" }}>
              {userData?.recentCompany}
            </Typography>
            <Typography variant="body1">
              {userData?.city}, {userData?.country}
            </Typography>
          </Box>
          <Box pb={1}>
            <FormControl fullWidth className={classes.commentControl}>
              <TextField
                fullWidth
                variant="outlined"
                placeholder="Email address *"
                name="email"
                value={userData?.email}
                disabled
              />
            </FormControl>
          </Box>
          {userData?.mobileNumber && (
            <Box pt={1} pb={1}>
              <FormControl
                fullWidth
                variant="filled"
                className={classes.outlineborder1}
              >
                <PhoneInput
                  type="number"
                  country={"in"}
                  name="mobileNumber"
                  value={userData?.mobileNumber}
                  placeholder="Enter phone number"
                  disabled
                />
              </FormControl>
            </Box>
          )}
          <Box my={1}>
            <Divider className={classes.dividerr} />
          </Box>

          <Box className={classes.inputBox}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Box>
                  <Typography variant="h6">Resume</Typography>
                  <Typography variant="body2">
                    Be sure to include an updated resume *
                  </Typography>
                  <Box py={1.5}>
                    <Box className="addImagegIcon">
                      {/* <input type="file" /> */}
                      <Box>
                        <Box className="addImagegIcon">
                          <IconButton
                            style={{
                              border: "1px dashed #4057A7",
                              padding: "10px 10px 6px 10px",
                            }}
                          >
                            <label htmlFor="raised-button-file">
                              <AttachementIcon
                                disabled={isLoading}
                                color="#4057A7 !important"
                                stroke="#4057A7 !important"
                              />
                            </label>
                          </IconButton>

                          <input
                            style={{ display: "none" }}
                            id="raised-button-file"
                            disabled={isLoading}
                            type="file"
                            accept=".doc, .docx, .pdf"
                            onChange={(e) => {
                              const maxSize = 2 * 1024 * 1024;
                              if (e.target.files[0].size <= maxSize) {
                                setImageData(e.target.files[0]);
                              }
                            }}
                          />
                        </Box>
                      </Box>
                      &nbsp;&nbsp;&nbsp;
                      <Typography variant="body2" style={{ color: "#4057A7" }}>
                        <label htmlFor="raised-button-file">
                          Upload Resume
                        </label>
                      </Typography>
                    </Box>
                    {isSubmit && imageData === "" && (
                      <FormHelperText error>
                        Please select a file.
                      </FormHelperText>
                    )}
                    {imageData && (
                      <span
                        style={{
                          fontSize: "12px",
                          marginTop: "5px",
                          wordBreak: "break-all",
                        }}
                      >
                        {imageData?.name}
                      </span>
                    )}
                  </Box>
                  <Typography variant="body2"> DOC, DOCX, PDF(2MB)</Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box className="coverBox">
                  <Typography variant="h6">Cover Letter</Typography>
                  <Typography variant="body2">
                    Be sure to include an updated cover letter *
                  </Typography>
                  <Box py={1.5}>
                    <Box className="addImagegIcon">
                      {/*   <input type="file" /> */}
                      <Box>
                        <Box className="addImagegIcon">
                          <IconButton
                            style={{
                              border: "1px dashed #4057A7",
                              padding: "10px 10px 6px 10px",
                            }}
                          >
                            {" "}
                            <label htmlFor="raised-button-file1">
                              <AttachementIcon
                                disabled={isLoading}
                                color="#4057A7 !important"
                                stroke="#4057A7 !important"
                              />
                            </label>
                          </IconButton>

                          <input
                            style={{ display: "none" }}
                            id="raised-button-file1"
                            disabled={isLoading}
                            type="file"
                            accept=".doc, .docx, .pdf"
                            onChange={(e) => {
                              const maxSize = 2 * 1024 * 1024;
                              if (e.target.files[0].size <= maxSize) {
                                setCoverData(e.target.files[0]);
                              }
                            }}
                          />
                        </Box>
                      </Box>
                      &nbsp;&nbsp;&nbsp;
                      <Typography variant="body2" style={{ color: "#4057A7" }}>
                        <label htmlFor="raised-button-file1">
                          Upload Cover Letter
                        </label>
                      </Typography>
                    </Box>
                    {isSubmit && coverData === "" && (
                      <FormHelperText error>
                        Please select a file.
                      </FormHelperText>
                    )}
                    {coverData && (
                      <span
                        style={{
                          fontSize: "12px",
                          marginTop: "5px",
                          wordBreak: "break-all",
                        }}
                      >
                        {coverData?.name}
                      </span>
                    )}
                  </Box>
                  <Typography variant="body2"> DOC, DOCX, PDF(2MB)</Typography>
                </Box>
              </Grid>
            </Grid>
            <Box my={1}>
              <Divider className={classes.dividerr} />
            </Box>
            {type !== "moonlight" && (
              <>
                <Box>
                  <Typography variant="h6">Additional Questions</Typography>
                </Box>
                <Box>
                  <Typography variant="body2">
                    Have you completed the following level of education:
                    Bachelor's Degree?*
                  </Typography>
                </Box>
                <FormControl
                  component="fieldset"
                  className={classes.radiogroup}
                >
                  <RadioGroup
                    aria-label="gender"
                    name="gender1"
                    value={question}
                    disabled={isLoading}
                    onChange={(e) => setQuestion(e.target.value)}
                  >
                    <FormControlLabel
                      value="Yes"
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="No"
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </>
            )}
            <Box pt={1} pb={2.5}>
              <FormControl fullWidth className={classes.commentControl}>
                <TextField
                  fullWidth
                  disabled={isLoading}
                  type="number"
                  variant="outlined"
                  value={experiance}
                  placeholder="How many years of work experience do you have?"
                  onChange={(e) => {
                    if (e.target.value.length <= 2) {
                      setExperiance(e.target.value);
                    }
                  }}
                  onKeyPress={(event) => {
                    if (
                      event?.key === "-" ||
                      event?.key === "+" ||
                      event?.key === "*" ||
                      event?.key === "/"
                    ) {
                      event.preventDefault();
                    }
                  }}
                  onKeyDown={(event) => handleNegativeValue(event)}
                />
                {isSubmit && experiance === "" && (
                  <FormHelperText error>
                    Please enter your experiance.
                  </FormHelperText>
                )}
              </FormControl>
            </Box>
            <Box className="displaySpaceBetween">
              <Typography variant="body2">
                Submitting this application won’t change your KashData profile.
              </Typography>

              <Button
                disabled={isLoading}
                variant="contained"
                color="primary"
                size="medium"
                onClick={() => {
                  applyJobHandler();
                }}
              >
                Submit {isLoading && <ButtonCircularProgress />}
              </Button>
            </Box>
            &nbsp;
          </Box>
        </Box>
      </DialogContent>
      <SuccessfullModal
        handleOpen={handleOpen}
        handleclose={handleclose}
        data="Job Applied Successfully"
      />
    </Dialog>
  );
}

export default HireModal;
