import GraphComponent from "src/component/GraphComponent";
import { Box, Grid } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { getParticularObjectDataHanndler } from "src/apiConfig/service";
import ContentGraphData from "src/component/ContentGraphData";
import { monthArray } from "src/utils";

const ContentGraph = () => {
  const [networkLikeData, setNetworkLikeData] = useState([]);
  const [networkCommentData, setNetworkCommentData] = useState([]);
  const [likeIsLoading, setLikeIsLoading] = useState(false);
  const [commentIsLoading, setCommentIsLoading] = useState(false);
  const [sortByLike, setSortByLike] = React.useState("monthly");
  const [sortByComment, setSortByComment] = React.useState("monthly");
  const [sortByTransaction, setSortByTransaction] = React.useState("monthly");
  const [transactionData, setTransactionData] = useState([]);
  const [transactionIsLoading, setTransactionIsLoading] = useState(false);

  const getNetworkLikeGraph = async () => {
    try {
      setLikeIsLoading(true);
      const response = await getParticularObjectDataHanndler(
        "getTotalLikesChart",
        { chartType: sortByLike }
      );
      if (response.responseCode === 200) {
        setLikeIsLoading(false);
        setNetworkLikeData(
          response.result.map((obj, index) => ({
            date: sortByLike === "yearly" ? monthArray[index] : obj.date,
            total: obj.total,
          }))
        );
      } else {
        setLikeIsLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLikeIsLoading(false);
    }
  };
  const getNetworkCommentGraph = async () => {
    try {
      setCommentIsLoading(true);
      const response = await getParticularObjectDataHanndler(
        "getTotalCommentsChart",
        { chartType: sortByComment }
      );
      if (response.responseCode === 200) {
        setCommentIsLoading(false);
        setNetworkCommentData(
          response.result.map((obj, index) => ({
            date: sortByComment === "yearly" ? monthArray[index] : obj.date,
            total: obj.total,
          }))
        );
      } else {
        setCommentIsLoading(false);
        setNetworkCommentData([]);
      }
    } catch (error) {
      console.log(error);
      setCommentIsLoading(false);
      setNetworkCommentData([]);
    }
  };
  const getTransactionCommentGraph = async () => {
    try {
      setTransactionIsLoading(true);
      const response = await getParticularObjectDataHanndler(
        "getTotalTransactionChart",
        { chartType: sortByTransaction }
      );
      if (response.responseCode === 200) {
        setTransactionIsLoading(false);
        setTransactionData(
          response.result.map((obj, index) => ({
            date: sortByTransaction === "yearly" ? monthArray[index] : obj.date,
            in: obj.in,
            out: obj.out,
          }))
        );
      } else {
        setTransactionIsLoading(false);
        setTransactionData([]);
      }
    } catch (error) {
      console.log(error);
      setTransactionIsLoading(false);
      setTransactionData([]);
    }
  };

  useEffect(() => {
    getNetworkLikeGraph();
  }, [sortByLike]);
  useEffect(() => {
    getNetworkCommentGraph();
  }, [sortByComment]);
  useEffect(() => {
    getTransactionCommentGraph();
  }, [sortByTransaction]);

  return (
    <>
      <Grid container spacing={1}>
        <Grid item lg={12} sm={12} xs={12}>
          <Box className="scrollDiv">
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} sm={6} lg={6}>
                <GraphComponent
                  sortBy={sortByLike}
                  setSortBy={setSortByLike}
                  networkData={networkLikeData}
                  isLoading={likeIsLoading}
                  GraphName="Likes Analytics"
                />
              </Grid>
              <Grid item xs={12} md={6} sm={6} lg={6}>
                <GraphComponent
                  sortBy={sortByComment}
                  setSortBy={setSortByComment}
                  networkData={networkCommentData}
                  isLoading={commentIsLoading}
                  GraphName="Comments Analytics"
                />
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <ContentGraphData
                  sortBy={sortByTransaction}
                  setSortBy={setSortByTransaction}
                  networkData={transactionData}
                  isLoading={transactionIsLoading}
                  GraphName="My Transactions Analytics"
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default ContentGraph;
