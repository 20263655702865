import LoadHistory from "src/Skeletons/LoadHistory";
import {
  Grid,
  makeStyles,
  Box,
  Typography,
  Paper,
  Divider,
  IconButton,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { FiEdit2 } from "react-icons/fi";
import { MdDelete } from "react-icons/md";
import Axios from "axios";
import apiConfigs from "src/apiConfig/ApiConfig";
import DataNotFound from "src/component/DataNotFound";
import { Pagination } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  avatarBox: {
    width: "fit-content",
    padding: "8px",
    background: "#4FA3A5",
    display: "flex",
    alignItems: "start",
    justifyContent: "center",
    borderRadius: "10px",
    width: "220px",
    "& img": {
      height: "150px",
      objectFit: "cover",
    },
  },
  followersBox: {
    padding: "15px",
    "& .mainBox": {
      [theme.breakpoints.down("xs")]: {
        display: "flex",
        justifyContent: "center",
      },
    },
    "& h6": {
      fontSize: "16px",
      fontWeight: "500",
    },
    "& .MuiDivider-root": {
      height: "2px",
    },
    "& .iconboxes": {
      border: " 2px solid #4057A7",
      width: "35px",
      height: "35px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: "50%",
      marginLeft: "10px",
      "& svg": {
        color: "#4057A7",
      },
    },
    "& h4": {
      fontSize: "16px",
      color: "#4057A7",
      fontWeight: "400",
    },
  },
  typoBox: {
    "& h5": {
      fontWeight: "500",
    },
    "& p": {
      fontWeight: "300",
      color: "#A0A0A0",
    },
  },
  mainhead: {
    margin: "20px",
    [theme.breakpoints.down("xs")]: {
      margin: "0px",
    },
    "& .descbox": {
      padding: "24px 0px 24px",
      [theme.breakpoints.down("xs")]: {
        padding: "8px 0px 8px",
      },
    },
  },
  centerbox: {
    display: "flex",
    justifyContent: "center",
    "@media(max-width:787px)": {
      justifyContent: "left",
    },
  },
  mainBoxdash: {
    display: "flex",
    "@media(max-width:687px)": {
      display: "block",
    },
  },
}));
const ProjectsData = [
  {
    logo: "images/purchased2.png",
    title: "Bulma CSS Framework",
    description:
      "Bulma is a mobile first CSS framework built on top of Flexbox. It's awesome and so easy to use.",
    address: "BULMA.IO",
    file: "images/video.png",
  },
  {
    logo: "images/purchased2.png",
    title: "Bulma CSS Framework",
    description:
      "Bulma is a mobile first CSS framework built on top of Flexbox. It's awesome and so easy to use.",
    address: "BULMA.IO",
    file: "images/video.png",
  },
];

function Purchased() {
  const classes = useStyles();
  const [purchasedCourseData, setPurchasedCourseData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);

  const myPurchasedCourseList = async () => {
    try {
      const response = await Axios({
        method: "POST",
        url: apiConfigs.purchases,
        headers: {
          token: window.sessionStorage.getItem("token"),
        },
        data: {
          page: page,
          limit: 5,
        },
      });
      if (response.data.responseCode === 200) {
        setPurchasedCourseData(response.data.result.docs);
        setTotalPage(response.data.result.pages);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        setPurchasedCourseData([]);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      setPurchasedCourseData([]);
    }
  };

  useEffect(() => {
    myPurchasedCourseList();
  }, []);

  return (
    <Box className="scrollDiv" style={{ padding: "60px 10px 0px 15px" }}>
      <Paper elevation={0} className={`${classes.followersBox} paddingBox`}>
        <Box>
          <Typography variant="h6">Purchased courses</Typography>
        </Box>
        <Box mt={1} mb={2}>
          <Divider />
        </Box>
        <Grid container spacing={3}>
          {purchasedCourseData &&
            purchasedCourseData?.map((data, i) => {
              return (
                <>
                  <Grid item xs={12} sm={12} md={9} lg={9}>
                    <Box className={classes.mainBoxdash}>
                      <Box className="mainBox">
                        <Box className={classes.avatarBox}>
                          <img src={data?.courseId?.logo} alt="" width="100%" />
                        </Box>
                      </Box>
                      <Box className={classes.mainhead}>
                        <Box className={classes.typoBox}>
                          <Box>
                            <Typography variant="h5">
                              {data?.courseId?.title}
                            </Typography>
                          </Box>
                          <Box
                            className="descbox"
                            style={{
                              width: "100%",
                              maxWidth: "300px",
                              wordBreak: "break-all",
                            }}
                          >
                            <Typography variant="body2">
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: data?.courseId?.description,
                                }}
                              ></div>
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={12} md={3} lg={3}>
                    {data?.courseId?.type === "video" ? (
                      <video
                        controls={true}
                        autoplay={false}
                        loop
                        muted
                        playsinline="true"
                        width="100%"
                        height="200px"
                      >
                        <source src={data?.courseId?.file} />
                      </video>
                    ) : (
                      <img
                        src={data?.courseId?.file}
                        width="100%"
                        height="200px"
                        style={{ objectFit: "cover" }}
                      />
                    )}
                  </Grid>
                </>
              );
            })}
        </Grid>
        {isLoading &&
          [1, 2, 3].map((tem) => {
            return (
              <Box width="100%">
                <LoadHistory />
              </Box>
            );
          })}
        {!isLoading &&
          purchasedCourseData &&
          purchasedCourseData?.length === 0 && <DataNotFound />}
        {!isLoading &&
          purchasedCourseData &&
          purchasedCourseData?.length >= (page === 1 ? 5 : 0) && (
            <Pagination
              variant="outlined"
              shape="rounded"
              count={totalPage}
              page={page}
              onChange={(e, v) => setPage(v)}
            />
          )}
      </Paper>
    </Box>
  );
}

export default Purchased;
