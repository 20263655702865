import React, { createContext, useState, useEffect } from "react";
import axios from "axios";
import apiConfigs from "src/apiConfig/ApiConfig";
import { calculateTimeLeft } from "src/component/timer";
import {
  getDataHandlerAPI,
  getParticularObjectDataHanndler,
} from "src/apiConfig/service";
import { injected } from "src/connectors";
import { useWeb3React } from "@web3-react/core";
import { toast } from "react-toastify";
import { getContract, getWeb3Obj, swichNetworkHandler } from "src/utils";
import { ACTIVE_NETWORK, RPC_URL, toeknAddress } from "src/constants";
import {
  geocodeByAddress,
  geocodeByPlaceId,
  getLatLng,
} from "react-places-autocomplete";
import web3 from "web3";
import TokenABI from "src/ABI/TokenABI.json";
import { useHistory } from "react-router-dom";

export const AuthContext = createContext();

const setSession = (accessToken) => {
  if (accessToken) {
    sessionStorage.setItem("token", accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    sessionStorage.removeItem("token");
    delete axios.defaults.headers.common.Authorization;
  }
};

function checkLogin() {
  const accessToken = window.sessionStorage.getItem("token");
  return accessToken ? true : false;
}
export default function AuthProvider(props) {
  const { activate, account, chainId, deactivate, library } = useWeb3React();
  const history = useHistory();
  const [isLogin, setIsLogin] = useState(checkLogin());
  const [userData, setUserData] = useState({});
  const [endTime, setEndtime] = useState();
  const [timeLeft, setTimeLeft] = useState();
  const [selfTabShow, setSelfTabShow] = useState("self-home");
  const [isLoading, setIsLoading] = useState(true);
  const [tsLoadingTrending, setIsLoadingTrending] = useState(true);
  const [createPost, setcreatePost] = useState(true);
  const [listPost, setListPost] = useState([]);
  const [skills, setSkills] = useState([]);
  const [skillsData, setSkillsData] = useState([]);
  const [photoLoading, setPhotoLoading] = useState(true);
  const [metaMaskLoading, setMetaMaskLoading] = useState(false);
  const [photoLibraryData, setPhotoLibraryData] = useState([]);
  const [listEventPost, setListEventPost] = useState([]);
  const [openMetamask, setOpenMetamask] = React.useState(false);
  const [notiLoading, setNotiLoading] = React.useState(false);
  const [address, setAddress] = React.useState("");
  const [yourWalletBalance, setYourWalletBalance] = useState("");
  const [tokenBalance, settokenBalance] = useState("");
  const [page, setPage] = useState(1);
  const [pageNft, setPageNft] = useState(1);
  const [listPost1, setListPost1] = useState([]);
  const [isLoadingNft, setIsLoadingNft] = useState(false);
  const [totalPages, setTotalPages] = useState({
    totalPage: "",
    currentPage: "",
  });

  const [notiTotalPages, setNotiTotalPages] = useState({
    totalPage: 1,
    currentPage: 1,
  });
  const [latLong, setLatLong] = React.useState({
    lat: "",
    long: "",
  });

  const walletConnect = async () => {
    try {
      setMetaMaskLoading(true);
      activate(injected, undefined, true)
        .then(() => {
          window.sessionStorage.setItem("ConnectWallet", true);
          setMetaMaskLoading(false);
          setOpenMetamask(false);
        })
        .catch((error) => {
          if (error) {
            toast.warn(error.message);
            console.log("ERROR", error);
            setMetaMaskLoading(false);
            setOpenMetamask(false);
            const errorMSG = error.message; //+ ' Please install Metamask';
            activate(injected);
          }
        });
    } catch (error) {
      console.log("ERROR", error);
      setMetaMaskLoading(false);
    }
  };

  const connnectWalletApi = async (account) => {
    try {
      const response = await getParticularObjectDataHanndler("connect", {
        address: account,
      });
      if (response.responseCode === 200) {
        getUserProfileDatahandler(window.sessionStorage.getItem("token"));
      } else {
        toast.error(response.responseMessage);
      }
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.responseMessage);
    }
  };

  const getOurWalletBlc = async () => {
    const web3 = getWeb3Obj(RPC_URL);
    const balance = await web3.eth.getBalance(account);
    const balanceImETH = web3.utils.fromWei(balance);
    setYourWalletBalance(parseFloat(balanceImETH).toFixed(2));
  };

  const getTokenWalletBalance = async () => {
    const contractObjToken = getContract(
      toeknAddress,
      TokenABI,
      library,
      account
    );
    const getTokenBalance = await contractObjToken.balanceOf(account);
    const toStngBl = getTokenBalance.toString();
    const balancetoken = web3.utils.fromWei(toStngBl);
    settokenBalance(parseFloat(balancetoken).toFixed(3));
  };

  useEffect(() => {
    if (window.sessionStorage.getItem("ConnectWallet")) {
      walletConnect();
    }
  }, [window.sessionStorage.getItem("ConnectWallet")]);

  const disconnectWalletHandler = async () => {
    try {
      deactivate();
      toast.success("You have been disconnected successfully!");
      window.sessionStorage.removeItem("ConnectWallet");
    } catch (error) {
      console.log(error);
    }
  };

  const getUserProfileDatahandler = async (token, isWellcome) => {
    try {
      const res = await axios({
        method: "GET",
        url: apiConfigs.viewMyProfile,
        headers: {
          token: token,
        },
      });
      if (res.data.responseCode === 200) {
        setUserData(res?.data?.result);
        if (isWellcome) {
          toast.success(`Welcome ${res.data.result.firstName}!`);
        }
        listPostHandler();
        listPostHandlerTrending();
      }
    } catch (error) {
      if (error.response) {
        if (error.response.data.responseCode == 404) {
          window.sessionStorage.removeItem("token");
        }
      }
    }
  };

  const listPostHandlerTrending = async () => {
    try {
      const res = await getDataHandlerAPI("trending");
      if (res) {
        setListPost(
          res &&
            res?.docs?.filter((item) => {
              return item?.postType === "MEDIA" || item?.postType === "NFT";
            })
        );
        setIsLoadingTrending(false);
      } else {
        setIsLoadingTrending(false);
      }
    } catch (error) {
      console.log("error", error);
      setIsLoadingTrending(false);
    }
  };

  const listPostHandler = async () => {
    try {
      const res = await getDataHandlerAPI("postList");
      if (res) {
        setListEventPost(
          res.filter((item) => {
            return item?.postType === "EVENT";
          })
        );

        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      console.log("error", error);
      setIsLoading(false);
    }
  };
  const listPostHandlerBottom = async (pageNft, oldData) => {
    try {
      setIsLoadingNft(true);
      const res = await getDataHandlerAPI("postList", {
        limit: "11",
        page: pageNft !== undefined ? pageNft : 1,
      });
      if (res) {
        setIsLoadingNft(false);
        setTotalPages({
          totalPage: res.pages,
          currentPage: res.page,
        });
        setListPost1([...oldData, ...res.docs]);
      } else {
        setIsLoadingNft(false);
      }
    } catch (error) {
      console.log("error", error);
      setIsLoadingNft(false);
    }
  };

  const getPhotoLibraryHandle = async (find) => {
    try {
      const response = await getParticularObjectDataHanndler(`listMedia`, {
        type: find,
      });
      if (response.responseCode === 200) {
        setPhotoLoading(false);
        setPhotoLibraryData(response.result.docs);
      } else {
        setPhotoLoading(false);
        setPhotoLibraryData([]);
      }
    } catch (error) {
      setPhotoLoading(false);
      setPhotoLibraryData([]);
    }
  };

  const getSkills = async () => {
    try {
      const response = await getParticularObjectDataHanndler("listSkill", {});

      if (response.responseCode === 200) {
        setSkills(response.result.docs);
        let data = [];
        for (let i = 0; i < response?.result?.docs?.length; i++) {
          data.push(response.result.docs[i].skillName.toUpperCase());
        }
        setSkillsData(data);
      } else {
        setSkills([]);
        setSkillsData([]);
      }
    } catch (error) {
      setSkills([]);
      setSkillsData([]);
    }
  };

  const handleAddressChange = (add) => {
    setAddress(add);
  };

  const handleSelectLocation = (address) => {
    setAddress(address);
    geocodeByAddress(address)
      .then((results) => {
        if (results?.length > 1) {
          window.localStorage.setItem(
            "postLocation",
            results[1]?.formatted_address
          );
        } else {
          window.localStorage.setItem(
            "postLocation",
            results[0]?.formatted_address
          );
        }
        return getLatLng(results[0]);
      })
      .then((latLng) => {
        setLatLong({
          lat: latLng.lat,
          long: latLng.lng,
        });
      })
      .catch((error) => console.error("Error", error));
  };

  useEffect(() => {
    if (endTime) {
      const timer = setTimeout(() => {
        setTimeLeft(calculateTimeLeft(endTime));
        const timeLefts = calculateTimeLeft(endTime);
        localStorage.setItem("otpTimer", JSON.stringify(timeLefts));
      }, 1000);
      return () => clearTimeout(timer);
    }
  });
  useEffect(() => {
    if (window.sessionStorage.getItem("token")) {
      getUserProfileDatahandler(window.sessionStorage.getItem("token"));
      listPostHandler();
      getPhotoLibraryHandle("PHOTO");
      getSkills();
    }
  }, [sessionStorage.getItem("token")]);

  useEffect(() => {
    if (account && chainId) {
      getOurWalletBlc();
      getTokenWalletBalance();
      connnectWalletApi(account);
      if (chainId !== ACTIVE_NETWORK) {
        if (window.ethereum) {
          swichNetworkHandler();
        }
      }
    }
  }, [chainId, account]); // eslint-disable-line

  let data = {
    userLoggedIn: isLogin,
    userData,
    createPost,
    setcreatePost,
    timeLeft,
    setTimeLeft,
    endTime,
    skills,
    skillsData,
    setEndtime,
    listPost,
    isLoading,
    tsLoadingTrending,
    photoLoading,
    photoLibraryData,
    listEventPost,
    account,
    library,
    latLong,
    address,
    setLatLong,
    setAddress,
    notiLoading,
    notiTotalPages,
    yourWalletBalance,
    tokenBalance,
    listPost1,
    isLoadingNft,
    pageNft,
    setPageNft,
    totalPages,
    listPostHandlerBottom: listPostHandlerBottom,
    listPostHandlerTrending: listPostHandlerTrending,
    handleSelectLocation: handleSelectLocation,
    handleAddressChange: handleAddressChange,
    metaMaskLoading,
    openMetamask,
    setOpenMetamask,
    walletConnect: walletConnect,
    setIsLogin: (data) => setIsLogin(data),
    checkLogin: (data) => checkLogin(data),
    listPostHandler: listPostHandler,
    disconnectWalletHandler: disconnectWalletHandler,
    getPhotoLibraryHandle: (data) => getPhotoLibraryHandle(data),
    getUserProfileDatahandler: (data) => getUserProfileDatahandler(data),
    userLogIn: (type, data, isWellcome) => {
      setSession(data);
      getUserProfileDatahandler(data, isWellcome);
      setIsLogin(type);
    },
    selfTabShow,
    setSelfTabShow: (tabView) => setSelfTabShow(tabView),
  };

  return (
    <AuthContext.Provider value={data}>{props.children}</AuthContext.Provider>
  );
}
