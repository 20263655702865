import React, { useEffect, useContext, useState } from "react";
import { useLocation, matchPath, useHistory } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import PropTypes from "prop-types";
import {
  Box,
  Drawer,
  Hidden,
  List,
  ListSubheader,
  makeStyles,
} from "@material-ui/core";
import NewApp from "./NewApp";
import NavItem from "./NavItem";
import { AuthContext } from "src/context/Auth";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { MdLogout } from "react-icons/md";

const sections = [
  {
    items: [
      {
        title: "Dashboard",
        icon: "/images/das1.png",
        href: "/dashboard",
      },

      {
        title: "Logout",
        icon: MdLogout,
        href: "/",
      },
    ],
  },
];

const sectionsBelow = [
  {
    items: [
      {
        // title: "Logout",
        icon: ExitToAppIcon,
        href: "/terms-and-condition",
      },
      // {
      //   title: "Privacy Policy",
      //   //icon: PieChartIcon,
      //   href: "/privacy-policy",
      // },
    ],
  },
];

function renderNavItems({ items, pathname, depth = 0 }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, pathname, depth }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({ acc, pathname, item, depth }) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false,
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items,
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles((theme) => ({
  mobileDrawer: {
    width: 220,
    backgroundColor: "#0c0e35",
    // backgroundColor: theme.palette.background.default,
  },
  desktopDrawer: {
    width: 220,
    top: 70,
    height: "100%",
    backgroundColor: "#0c0e35",
  },
  avatar: {
    cursor: "pointer",
    width: 64,
    height: 64,
  },
  socialIcon: {
    cursor: "pointer",
    marginRight: 5,
  },
  ButtonBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: "10px",
  },
  logoicon: {
    display: "flex",
    marginTop: "16px",
    alignItems: "center",
    marginLeft: "30px",
  },
  logoutbutton: {
    // justifyContent: "space-between",
    paddingLeft: 10,
    borderRadius: 10,
    width: "100px",
    textAlign: "center",
    color: "#b61733",
    border: "1px solid #b61733",

    fontWeight: "bold",
  },
}));

const NavBar = ({
  onMobileClose,
  openMobile,
  setMenuCollapse,
  menuCollapse,
}) => {
  const [openLogout, setOpenLogout] = useState(false);
  const auth = useContext(AuthContext);

  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Box>
          {sections.map((section, i) => (
            <List
              key={`menu${i}`}
              subheader={
                <ListSubheader disableGutters disableSticky>
                  {section.subheader}
                </ListSubheader>
              }
            >
              {renderNavItems({
                items: section.items,
                pathname: location.pathname,
              })}
            </List>
          ))}
          {sectionsBelow.map((section, i) => (
            <List
              key={`menu${i}`}
              subheader={
                <ListSubheader disableGutters disableSticky>
                  {section.subheader}
                </ListSubheader>
              }
            ></List>
          ))}
        </Box>
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          <NewApp
            menuCollapse={menuCollapse}
            setMenuCollapse={setMenuCollapse}
          />
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <NewApp menuCollapse={menuCollapse} setMenuCollapse={setMenuCollapse} />
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default NavBar;
