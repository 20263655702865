import React, { useState } from "react";
import { NavLink as RouterLink } from "react-router-dom";
import { useHistory } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import { Button, Collapse, ListItem, makeStyles } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ConfirmationDialog from "src/component/ConfirmationDialog";

const useStyles = makeStyles((theme) => ({
  item: {
    display: "block",
    paddingTop: 0,
    paddingBottom: 0,
  },
  itemLeaf: {
    display: "flex",
    marginTop: "20px",
    paddingLeft: "8px",
    paddingRight: "8px",
    paddingTop: "0px",
    paddingBottom: "0px",

    "& .MuiButton-label": {
      width: "100%",
      display: "flex",
      alignItems: "inherit",
      justifyContent: "center",
      flexDirection: "column",
      alignContent: "center",
    },
    "& .MuiSvgIcon-root": {
      marginBottom: "8px",
    },
  },
  button: {
    color: "#707070",
    padding: "10px 0",
    justifyContent: "flex-start",
    textTransform: "none",
    letterSpacing: 0,
    width: "100%",
  },
  buttonLeaf: {
    color: "#778096",
    width: "100%",
    padding: "10px 8px",
    margin: "0 13px",
    textAlign: "center",
    borderRadius: "8px",
    letterSpacing: "0",
    textTransform: "uppercase",
    justifyContent: "flex-start",
    fontSize: "12px",
    fontWeight: "400",
    "&:hover": {
      color: "#fff",
      borderRadius: "8px",
      background: "rgba(204, 35, 75, 0.1)",
      // boxShadow: "0px 4px 4px rgb(0 0 0 / 25%), inset 0px 0px 8px #b61733, inset 0px 0px 35px #b51632",
      boxShadow:
        "0px 18px 40px -14px rgb(222 13 50 / 90%), inset 0px 0px 35px #b51632, inset 0px 0px 8px #b61733",
    },
    "&.depth-0": {
      "& $title": {
        fontWeight: theme.typography.fontWeightMedium,
        whiteSpace: "pre",
        marginTop: "10px",
      },
    },
  },
  icon: {
    display: "flex",
    alignItems: "center",
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(1),
  },
  // title: {
  //   marginRight: "auto",
  // },
  active: {
    color: "#fff",
    borderRadius: "8px",
    background: "rgba(204, 35, 75, 0.1)",
    // boxShadow: "0px 4px 4px rgb(0 0 0 / 25%), inset 0px 0px 8px #b61733, inset 0px 0px 35px #b51632",
    boxShadow:
      "0px 18px 40px -14px rgb(222 13 50 / 90%), inset 0px 0px 35px #b51632, inset 0px 0px 8px #b61733",
    "& $title": {
      fontWeight: theme.typography.fontWeightMedium,
    },
    "& $icon": {
      color: "00e0b0",
    },
  },
}));

const NavItem = ({
  children,
  className,
  depth,
  href,
  icon: Icon,
  info: Info,
  open: openProp,
  title,
  ...rest
}) => {
  const classes = useStyles();
  const history = useHistory();
  const [open, setOpen] = useState(openProp);
  const [openLogout, setOpenLogout] = useState(false);
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };
  const logoutHandler = () => {
    history.push("/");
    window.localStorage.removeItem("token");
  };
  let paddingLeft = 8;

  if (depth > 0) {
    paddingLeft = 32 + 8 * depth;
  }

  const style = { paddingLeft };

  if (children) {
    return (
      <ListItem
        className={clsx(classes.item, className)}
        disableGutters
        key={title}
        {...rest}
      >
        <Button
          className={classes.button}
          onClick={handleToggle}
          style={{ color: "#ccc" }}
        >
          {Icon && (
            <Icon
              className={classes.icon}
              size="20"
              style={{ marginBottom: "8px" }}
            />
          )}
          <span className={classes.title} style={{ marginTop: "8px" }}>
            {title} 
          </span>
          {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </Button>
        <Collapse in={open}>{children}</Collapse>
      </ListItem>
    );
  }

  return (
    <>
      <ListItem
        align="center"
        className={clsx(classes.itemLeaf, className)}
        disableGutters
        key={title}
        {...rest}
      >
        {title === "Logout" ? (
          <Button
            activeClassName={classes.active}
            className={clsx(classes.buttonLeaf, `depth-${depth}`)}
            style={style}
            onClick={() => setOpenLogout(true)}
          >
            {Icon && <Icon size="20" style={{ marginBottom: "8px" }} />}
            <span className={classes.title}>{title}</span>
            {Info && <Info />}
          </Button>
        ) : (
          <Button
            activeClassName={classes.active}
            className={clsx(classes.buttonLeaf, `depth-${depth}`)}
            component={RouterLink}
            exact
            style={style}
            to={href}
          >
            {Icon && <Icon size="20" style={{ marginBottom: "8px" }} />}
            <span className={classes.title}>{title}</span>
            {Info && <Info />}
          </Button>
        )}
      </ListItem>
      <ConfirmationDialog
        title="LOGOUT"
        desc="Are you sure you want to logout?"
        open={openLogout}
        handleClose={() => setOpenLogout(false)}
        logoutHandler={logoutHandler}
      />
    </>
  );
};

NavItem.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  depth: PropTypes.number.isRequired,
  href: PropTypes.string,
  icon: PropTypes.elementType,
  info: PropTypes.elementType,
  open: PropTypes.bool,
  title: PropTypes.string.isRequired,
};

NavItem.defaultProps = {
  open: false,
};

export default NavItem;
