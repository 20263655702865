import React, { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
// import { Document as DocxDocument, Page as DocxPage } from "react-docx";
import { Button, makeStyles, Typography, Box } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  mainBox: {
    "& button": {
      padding: "0px",
      borderRadius: "5px",
    },
  },
}));

const ViewPdfAdDocx = ({ pdfUrl }) => {
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  const classes = useStyles();
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  /*To Prevent right click on screen*/
  document.addEventListener("contextmenu", (event) => {
    event.preventDefault();
  });

  /*When document gets loaded successfully*/
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  return (
    <div className={classes.mainBox}>
      <Document file={pdfUrl} onLoadSuccess={onDocumentLoadSuccess}>
        <Page pageNumber={pageNumber} />
      </Document>
      <Box my={2}>
        <Typography variant="body2">
          Page {pageNumber || (numPages ? 1 : "--")} of {numPages || "--"}
        </Typography>
        <Button
          variant="contained"
          color="primary"
          disabled={pageNumber <= 1}
          onClick={() => changePage(-1)}
          className="Pre"
        >
          Previous
        </Button>
        &nbsp;
        <Button
          variant="contained"
          color="primary"
          disabled={pageNumber >= numPages}
          onClick={() => changePage(1)}
        >
          Next
        </Button>
      </Box>
    </div>
  );
};

export default ViewPdfAdDocx;
