import React, { useContext } from "react";
import {
  makeStyles,
  Paper,
  Box,
  Typography,
  TextField,
  Button,
  Divider,
  Grid,
  FormControl,
  Avatar,
  Select,
  MenuItem,
} from "@material-ui/core";
import { CiImageOn } from "react-icons/ci";
import PollModal from "src/component/PollModal";
import EventModal from "src/component/EventModal";
import CreatePostModal from "src/component/CreatePostModal";
import Addlocation from "src/component/Addlocation";
import TagFriend from "src/component/TagFriend";
import { BsCalendar4Event } from "react-icons/bs";
import PollsAndSurveys from "src/icons/PollsAndSurveys";
import { AuthContext } from "../../../context/Auth";
import { toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "15px 25px 7px",
    [theme.breakpoints.down("xs")]: {
      padding: "10px",
    },
    "& .base": {
      "& button": {
        fontSize: "11px",
        color: "#ADADAD",
        [theme.breakpoints.down("xs")]: {
          fontSize: "10px",
        },
        "& svg": {
          color: "#ADADAD",
          marginRight: "10px",
          fontSize: "25px",
          [theme.breakpoints.down("xs")]: {
            fontSize: "20px",
          },
        },
      },
    },
    "& .avtarimage": {
      "& .MuiAvatar-root": {
        width: "48px",
        height: "48px",
      },
    },
  },

  buttonpost: {
    // backgroundColor: "#0F2FFF",
    padding: "4px 28px",
    fontWeight: "300",
  },
  commentControl: {
    "& .MuiInputBase-root": {
      boxShadow: "none !important",
      marginLeft: "7px",
    },

    "& .allIconInput": {
      "@media(max-width:408px)": {
        padding: "4px",
        "& svg": {
          fontSize: "17px",
        },
      },
    },
    "& .MuiFormControl-root": {
      backgroundColor: "#fff",
    },
  },
  leftborder: {
    borderRight: "1px solid  #F1F1F1",
  },
}));

function StoryAndLiveFeed({ listPostHandler }) {
  const classes = useStyles();
  const auth = useContext(AuthContext);
  const [openCreatePost, setOpenCreatePost] = React.useState(false);
  const [openCreatePoll, setOpenCreatePoll] = React.useState(false);
  const [openCreateEvent, setOpenCreateEvent] = React.useState(false);
  const [sortBy, setSortBy] = React.useState("Top");
  const [openModal, setOpenModal] = React.useState(false);
  const [friendModal, setFriendModal] = React.useState(false);

  return (
    <>
      <Paper className={classes.root} elevation={0}>
        <Box display="flex" alignItems="center" className="avtarimage">
          <Avatar src={auth?.userData?.profilePic} />
          <FormControl fullWidth className={classes.commentControl}>
            <TextField
              className="commentField"
              fullWidth
              variant="outlined"
              placeholder="Start a Post."
              onClick={() =>
                auth?.userData?.kycStatus === "APPROVE"
                  ? setOpenCreatePost(true)
                  : toast.warn(
                      "Please complete your KYC to access this feature."
                    )
              }
            />
          </FormControl>
        </Box>
        <Box mt={2} mb={1}>
          <Divider />
        </Box>
        <Box>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              lg={4}
              align="left"
              className={classes.leftborder}
            >
              <Box className="base">
                <Button
                  onClick={() =>
                    auth?.userData?.kycStatus === "APPROVE"
                      ? setOpenCreatePost(true)
                      : toast.warn(
                          "Please complete your KYC to access this feature."
                        )
                  }
                >
                  <CiImageOn /> <label>Photo/Video</label>
                </Button>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              lg={4}
              align="left"
              className={classes.leftborder}
            >
              <Box className="base">
                <Button
                  onClick={() =>
                    auth?.userData?.kycStatus === "APPROVE"
                      ? setOpenCreateEvent(true)
                      : toast.warn(
                          "Please complete your KYC to access this feature."
                        )
                  }
                >
                  <BsCalendar4Event style={{ fontSize: "18px" }} />{" "}
                  <label>Event</label>
                </Button>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4} align="left">
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Box className="base">
                  <Button
                    onClick={() =>
                      auth?.userData?.kycStatus === "APPROVE"
                        ? setOpenCreatePoll(true)
                        : toast.warn(
                            "Please complete your KYC to access this feature."
                          )
                    }
                  >
                    <PollsAndSurveys style={{ fontSize: "22px" }} />{" "}
                    <label>Polls & Surveys</label>
                  </Button>
                </Box>
                <Button
                  className={classes.buttonpost}
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={() =>
                    auth?.userData?.kycStatus === "APPROVE"
                      ? setOpenCreatePost(true)
                      : toast.warn(
                          "Please complete your KYC to access this feature."
                        )
                  }
                >
                  Post
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <CreatePostModal
          openCreatePost={openCreatePost}
          handleCloseCreatePost={() => setOpenCreatePost(false)}
          handleClickOpenModal={() => setOpenModal(true)}
          handleClickFriendModal={() => setFriendModal(true)}
          listPostHandler={listPostHandler}
        />
        <PollModal
          openCreatePoll={openCreatePoll}
          handleCloseCreatePoll={() => setOpenCreatePoll(false)}
          listPostHandler={listPostHandler}
        />
        <EventModal
          openCreateEvent={openCreateEvent}
          handleCloseCreateEvent={() => setOpenCreateEvent(false)}
          listPostHandler={listPostHandler}
        />
        <Addlocation
          handleClickCloseModal={() => setOpenModal(false)}
          openModal={openModal}
        />
        <TagFriend
          handleClickCloseFriendModal={() => setFriendModal(false)}
          friendModal={friendModal}
        />
      </Paper>
    </>
  );
}

export default StoryAndLiveFeed;
