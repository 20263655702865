import React, { lazy } from "react";
import { Redirect } from "react-router-dom";
import DashboardLayout from "src/layouts/DashboardLayout";

import HomeLayout from "src/layouts/HomeLayout";
import KYCLayout from "src/layouts/KYCLayout";
import LoginLayout from "src/layouts/LoginLayout";
export const routes = [
  {
    exact: true,
    path: "/",
    layout: LoginLayout,
    component: lazy(() => import("src/component/LoginTabs")),
  },

  {
    exact: true,
    path: "/testing",
    layout: LoginLayout,
    component: lazy(() => import("src/views/pages/MySelf/Testing")),
  },

  {
    exact: true,
    path: "/reset-otp",
    layout: LoginLayout,
    component: lazy(() => import("src/views/auth/ResetOtp")),
  },
  {
    exact: true,
    path: "/reset-otp-join",
    layout: LoginLayout,
    component: lazy(() => import("src/views/JoinNow/ResetOtp")),
  },

  {
    exact: true,
    guard: true,
    path: "/home",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/index")),
  },
  {
    exact: true,
    guard: true,
    path: "/post-detail",
    layout: DashboardLayout,
    component: lazy(() => import("src/component/ViewLiveFeedModal1")),
  },

  {
    exact: true,
    path: "/joinlogin",
    layout: LoginLayout,
    component: lazy(() => import("src/views/JoinNow/Login")),
  },

  {
    exact: true,
    path: "/reset-password",
    layout: LoginLayout,
    component: lazy(() => import("src/views/auth/reset-password/index")),
  },
  {
    exact: true,
    path: "/signup",
    layout: LoginLayout,
    component: lazy(() => import("src/views/JoinNow/signup/signup")),
  },
  {
    exact: true,
    path: "/forget-password",
    layout: LoginLayout,
    component: lazy(() => import("src/views/auth/forget-password/index")),
  },
  {
    exact: true,
    path: "/change-password",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/auth/ChangePassword")),
  },

  {
    exact: true,
    path: "/view-profile",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/component/Activities")),
  },
  {
    exact: true,
    path: "/other-profile",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/OtherProfile/OtherProfile")),
  },
  {
    exact: true,
    path: "/business-page",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/OtherProfile/OtherProfile")),
  },
  {
    exact: true,
    path: "/no-result",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/OtherProfile/NoResultFound")),
  },
  {
    exact: true,
    path: "/menu-popper",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/OtherProfile/MenuPopper")),
  },
  {
    exact: true,
    path: "/job",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/JOB/Index")),
  },
  {
    exact: true,
    path: "/job-description",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/JOB/Jobdescription")),
  },
  {
    exact: true,
    path: "/allnotification",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Notification/MainIndex")),
  },

  {
    exact: true,
    path: "/manage-account",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/CoreNetwork/Index")),
  },
  {
    exact: true,
    path: "/chat",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Chat/ChatHistory")),
  },
  {
    exact: true,
    path: "/projects",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/MyLiberary/Projects")),
  },
  {
    exact: true,
    path: "/purchased",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/MyLiberary/Purchased")),
  },
  {
    exact: true,
    path: "/moonlight",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/MyWork/MoonLight")),
  },
  {
    exact: true,
    path: "/mywork",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Work/Work")),
  },
  // {
  //   exact: true,
  //   path: "/network",
  //   guard: true,
  //   layout: DashboardLayout,
  //   component: lazy(() => import("src/views/pages/MyNetwork/Network")),
  // },
  {
    exact: true,
    path: "/mycontent",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Content/Index")),
  },

  {
    exact: true,
    path: "/projects",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/MyLiberary/Projects")),
  },
  {
    exact: true,
    path: "/purchased",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/MyLiberary/Purchased")),
  },

  {
    exact: true,
    path: "/network",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/MyNetwork/ParentNetWorkCom")),
  },

  {
    exact: true,
    path: "/aboutself",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Static/AboutUs")),
  },
  {
    exact: true,
    path: "/contact-us",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Static/ContactUs")),
  },
  {
    exact: true,
    path: "/terms-conditions",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Static/TermsAndCondition")),
  },
  {
    exact: true,
    path: "/privacy-policy",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Static/TermsAndCondition")),
  },
  {
    exact: true,
    path: "/terms-condition",
    layout: LoginLayout,
    component: lazy(() => import("src/views/pages/Static/TermsAndCondition")),
  },
  {
    exact: true,
    path: "/privacy",
    layout: LoginLayout,
    component: lazy(() => import("src/views/pages/Static/TermsAndCondition")),
  },
  {
    exact: true,
    path: "/cookie-policy",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Static/TermsAndCondition")),
  },

  {
    exact: true,
    path: "/registration",
    guard: true,
    layout: KYCLayout,
    component: lazy(() => import("src/views/pages/KYC/Registration")),
  },

  {
    exact: true,
    path: "/asset",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Asset/Bought")),
  },

  {
    exact: true,
    path: "/asset-details",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/AssetDetails/AssetDetails")),
  },
  {
    exact: true,
    path: "/kyc",
    layout: KYCLayout,
    component: lazy(() => import("src/views/pages/KYC/KycSteps")),
  },
  {
    exact: true,
    path: "/wallet",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Wallet/index")),
  },
  {
    exact: true,
    path: "/library",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/MyLiberary/Photos")),
  },
  {
    exact: true,
    path: "/self-about",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/MySelf/About")),
  },
  {
    exact: true,
    path: "/viewwork",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Work/ViewWork")),
  },
  {
    exact: true,
    path: "/business-page",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/BussinessPage/PageProfile")),
  },
  {
    exact: true,
    path: "/business-jobs",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/BussinessPage/BusinessJobs")),
  },
  {
    exact: true,
    path: "/business-courses",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/BussinessPage/BusinessCourses")
    ),
  },
  {
    exact: true,
    path: "/business-about",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/BussinessPage/BusinessAbout")
    ),
  },

  {
    exact: true,
    path: "/all-pages",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/BussinessPage/AllPages")),
  },
  {
    exact: true,
    path: "/404",
    component: lazy(() => import("src/views/errors/NotFound")),
  },
  {
    component: () => <Redirect to="/404" />,
  },
];
