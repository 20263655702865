import {
  Dialog,
  DialogContent,
  Box,
  Typography,
  IconButton,
  TextField,
  Button,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
  iconbtn: {
    position: "absolute",
    top: "0",
    right: "0",
  },
  mainbox: {
    "& .typo": {
      paddingBottom: "14px",
      textAlign: "center",
      "& h5": {
        fontWeight: "700",
      },
    },
    "& .minbox": {
      overflow: "scroll",
      padding: "17px 25px",
      background: "#FFFFFF",
      border: "1px solid #DFDFDF",
      borderRadius: "7px",
    },
    "& .imgbox": {
      paddingTop: "20px",
      display: "flex",
      alignItems: "center",
    },
    "& .typo2": {
      paddingLeft: "22px",
    },
  },
  btn2: {
    padding: "9px 20px",
  },
  btn3: {
    padding: "9px 50px",
    fontSize: "18px",
    [theme.breakpoints.down("xs")]: {
      padding: "9px 20px",
      fontSize: "13px",
    },
  },
}));

export default function RewardModal({ handleClickCloseModal, openModal }) {
  const classes = useStyles();
  return (
    <Dialog
      open={openModal}
      onClose={handleClickCloseModal}
      maxWidth="sm"
      fullWidth
    >
      <Box className={classes.iconbtn}>
        <IconButton onClick={handleClickCloseModal}>
          <CloseIcon style={{ color: "#000" }} />
        </IconButton>
      </Box>
      <DialogContent>
        <Box className={classes.mainbox}>
          <Box className="typo">
            <Typography variant="h5">Add Location</Typography>
          </Box>
          <Box className="minbox">
            <Box>
              <TextField
                fullWidth
                variant="outlined"
                placeholder="Search Location"
              />
            </Box>
            <Box className="imgbox">
              <img src="images/User2.png" alt="" />
              <Box className="typo2">
                <Typography variant="body2" style={{ fontWeight: "600" }}>
                  New Delhi
                </Typography>
                <Typography variant="body2" style={{ color: "#B6B6B6" }}>
                  Delhi
                </Typography>
              </Box>
            </Box>
            <Box className="imgbox">
              <img src="images/User2.png" alt="" />
              <Box className="typo2">
                <Typography variant="body2" style={{ fontWeight: "600" }}>
                  Statue Of Liberty
                </Typography>
                <Typography variant="body2" style={{ color: "#B6B6B6" }}>
                  New York
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box align="center">
            <Box mt={1.5}>
              <Typography
                variant="body2"
                style={{ color: " rgba(0, 0, 0, 0.3)" }}
              >
                or
              </Typography>
            </Box>
            <Box mt={1.5}>
              <Button
                variant="outlined"
                color="secondary"
                className={classes.btn2}
              >
                Turn On Location Services
              </Button>
            </Box>
            <Box mt={1.5}>
              <Button
                variant="contained"
                color="secondary"
                className={classes.btn3}
                onClick={handleClickCloseModal}
              >
                Done
              </Button>
            </Box>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
