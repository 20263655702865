import React from "react";
import {
  Box,
  FormControl,
  Typography,
  TextField,
  InputAdornment,
  IconButton,
} from "@material-ui/core";

const SeeMoreComponent = ({
  showLimit,
  setsliceCount,
  arrayData,
  seeMoreHandle,
  sliceCount,
}) => {
  return (
    <Box display="flex" mt={1} style={{ cursor: "pointer" }}>
      {showLimit < sliceCount && (
        <Typography
          variant="body1"
          style={{ color: "#fff" }}
          onClick={() => {
            seeMoreHandle(arrayData);
            setsliceCount(sliceCount - showLimit);
          }}
        >
          Show Less &nbsp;
        </Typography>
      )}
      {arrayData?.length > sliceCount && (
        <Typography
          variant="body1"
          style={{ color: "#fff" }}
          onClick={() => {
            seeMoreHandle(arrayData);
            setsliceCount(sliceCount + showLimit);
          }}
        >
          Show More
        </Typography>
      )}
    </Box>
  );
};

export default SeeMoreComponent;
