import React from "react";
import { IconButton, Dialog, DialogActions } from "@material-ui/core";
import { IoClose } from "react-icons/io5";
import ViewPdfAdDocx from "./ViewPdfAdDocx";

const ViewImageModal = ({ viewModal, handleClose, imageData, type }) => {
  return (
    <Dialog
      open={viewModal}
      onClose={() => handleClose()}
      fullWidth
      maxWidth="sm"
    >
      <DialogActions style={{ display: "block", padding: "0px" }}>
        <IconButton
          onClick={() => handleClose()}
          style={{
            position: "absolute",
            top: "5px",
            right: "5px",
            background: "#4057A7",
            zIndex: "1",
            padding: "3px",
          }}
        >
          <IoClose style={{ color: "#fff" }} />
        </IconButton>
        <figure style={{ margin: "5px 5px 2px" }}>
          {type ? (
            <ViewPdfAdDocx pdfUrl={imageData} />
          ) : (
            <img
              style={{ height: "410px", width: "100%" }}
              src={imageData ? imageData : "images/contactsteps.png"}
            />
          )}
        </figure>
      </DialogActions>
    </Dialog>
  );
};

export default ViewImageModal;
