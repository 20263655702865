import React, { useState } from "react";
import { makeStyles, Typography, Box, Avatar } from "@material-ui/core";
import { tokenName } from "src/constants";

const useStyles = makeStyles((theme) => ({
  mapingbox: {
    display: "flex",
    justifyContent: "space-between",
    padding: "10px 0px",
    cursor: "pointer",
    "& .ImageBox": {
      display: "flex",
      alignItems: "center",
      margionRight: "10px",
      "& .MuiAvatar-root": {
        width: "50px !important",
        height: "50px !important",
      },
      "& h6": {
        fontSize: "14px",
        fontWeight: "400",
        color: "#fff",
      },
      "& p": {
        color: "#fff",
      },
    },
    "& .TextBox": {
      marginLeft: "10px",
    },
    "& .iconBox": {
      "& svg": {
        fontSize: "17px",
        color: "#fff",
      },
    },
  },
}));

const SkillUpCourse = ({ data, type, setSkillModal, setCourseData }) => {
  const classes = useStyles();
  function textDescription(itemdata) {
    const textData = (
      <div
        dangerouslySetInnerHTML={{
          __html: itemdata?.description,
        }}
      ></div>
    );
    return textData;
  }
  return (
    <div>
      <Box
        className={classes.mapingbox}
        onClick={() => {
          setSkillModal(true);
          setCourseData(data);
        }}
      >
        <Box className="ImageBox">
          <Avatar src={data?.logo} alt="Image" />

          <Box className="TextBox">
            <Typography
              variant="h6"
              style={type === "search" ? { color: "rgb(50, 50, 50)" } : {}}
            >
              {data?.title}
            </Typography>
            <Box my={0.6}>
              <Typography variant="body1">
                <div
                  dangerouslySetInnerHTML={{
                    __html: data?.description?.slice(0, 40),
                  }}
                ></div>
                {/* {textDescription(data)}              
              {textDescription(data)?.length >= 40 && "..."} */}
              </Typography>
            </Box>
            <Typography
              variant="body2"
              style={type === "search" ? { color: "#BFBFBF" } : {}}
            >
              {data?.coursePrice} {tokenName}
            </Typography>
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default SkillUpCourse;
