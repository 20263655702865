import {
  Box,
  Dialog,
  DialogContent,
  Typography,
  makeStyles,
  Grid,
  Button,
  Divider,
  IconButton,
  DialogActions,
} from "@material-ui/core";
import React, { useState } from "react";
import { IoClose } from "react-icons/io5";

const useStyles = makeStyles((theme) => ({
  maindialouge: {
    cursor: "pointer",
    width: "100%",
    "& .MuiDialogContent-root": {
      padding: "0px 0px",
    },
  },
  imgVedioBox: {
    width: "100%",
    height: "305px",
    position: "relative",
    marginTop: "20px",
    "& img": {
      color: "transparent",
      width: "100%",
      height: "100%",
      objectFit: "contain",
      textAlign: "center",
      background: "#f0f8ff",
    },
  },
  cancelBtn1: {
    background: "#000000",
    top: "6px",
    right: "10px",
    position: "absolute",
    "&:hover": {
      backgroundColor: "#4057a7 !important",
    },
  },
  selectBox: {
    minHeight: "300px",
    maxHeight: "300px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& h6": {
      color: "rgba(46, 106, 195, 1)",
    },
  },
  headerBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  cancelBtn: {
    position: "absolute",
    top: 0,
    right: 0,
    "& svg": {
      color: "#fff",
      fontWeight: "700",
    },
  },
  imgBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& img": {
      width: "200px",
    },
  },
}));

export const getBase64 = (file, cb) => {
  let reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = function () {
    cb(reader.result);
  };
  reader.onerror = function (err) {
    console.log("Error: ", err);
  };
};
function ImageAndVideoModal({
  openImageAndVideo,
  handleCloseImageAndVideo,
  userData,
  type,
  setOpenModalOpen,
  profileImage642,
  setProfileImage642,
}) {
  const classes = useStyles();
  const hiddenFileInput = React.useRef(null);
  const [profileImage641, setProfileImage641] = useState(
    userData?.profilePic ? userData?.profilePic : ""
  );

  const isDocx = profileImage642?.type?.includes("application/pdf");

  return (
    <Dialog
      open={openImageAndVideo}
      onClose={() => setOpenModalOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="sm"
      fullWidth
      className={classes.maindialouge}
    >
      <Box
        className="bgcolorbox"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box>
          <Typography variant="h5">
            {type === "image"
              ? "Edit your Photo"
              : type === "docx"
              ? "Select your Docx"
              : "Select your Video"}
          </Typography>
        </Box>
        <Box>
          <IconButton
            className={classes.cancelBtn}
            /* onClick={() =>
              handleCloseImageAndVideo(profileImage641, profileImage642)
            } */
            onClick={() => setOpenModalOpen(false)}
          >
            <IoClose />
          </IconButton>
        </Box>
      </Box>
      <DialogContent>
        {profileImage641 === "" ? (
          <>
            {" "}
            <Box style={{ paddingTop: "5px" }} className={classes.selectBox}>
              <Typography
                variant="subtitle2"
                onClick={() => {
                  hiddenFileInput.current.click();
                }}
              >
                Select {type} to share
              </Typography>
            </Box>
          </>
        ) : (
          <Box className={classes.imgVedioBox}>
            {type === "image" ? (
              <img
                className={classes.selectBox}
                src={profileImage641 ? profileImage641 : "images/camera1.png"}
                alt="loading"
                width="100%"
              />
            ) : type === "docx" ? (
              <>
                {isDocx ? (
                  <Box className={classes.imgBox}>
                    <img src="images/pdf.png" alt="" />
                  </Box>
                ) : (
                  <Box className={classes.imgBox}>
                    <img src="images/docs.png" alt="" />
                  </Box>
                )}
                <Box
                  m={1}
                  style={{ wordBreak: "break-all", textAlign: "center" }}
                >
                  <Typography variant="body2">
                    {profileImage642.name}
                  </Typography>
                </Box>
              </>
            ) : (
              <video
                className={classes.selectBox}
                controls="false"
                autoplay="true"
                loop
                muted
                playsinline="true"
                width="100%"
              >
                <source
                  src={profileImage641 ? profileImage641 : ""}
                  type="video/mp4"
                  accept="video/*"
                />
              </video>
            )}

            <IconButton
              size="small"
              className={classes.cancelBtn1}
              onClick={() => {
                setProfileImage641(
                  userData?.profilePic ? userData?.profilePic : ""
                );
                setProfileImage642(
                  userData?.profilePic ? userData?.profilePic : ""
                );
              }}
            >
              <IoClose color="#ffffff" />
            </IconButton>
          </Box>
        )}
        <input
          style={{ display: "none" }}
          id="image-button-file1"
          ref={hiddenFileInput}
          type="file"
          accept={
            type === "image"
              ? "image/*"
              : type === "docx"
              ? ".doc, .docx, .pdf"
              : "video/*"
          }
          onChange={(e) => {
            setProfileImage641(URL.createObjectURL(e.target.files[0]));
            setProfileImage642(e.target.files[0]);
          }}
        />

        <Box mt={1} mb={2}>
          <Divider />
        </Box>
      </DialogContent>
      <DialogActions>
        <Box>
          <Button
            variant="outlined"
            color="secondary"
            size="small"
            onClick={() => setOpenModalOpen(false)}
            style={{ marginRight: "5px", padding: "19px 24px" }}
          >
            Back
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="small"
            style={{ padding: "20px 26px" }}
            onClick={() => {
              handleCloseImageAndVideo(profileImage641, profileImage642);
              setProfileImage641(type);
              setOpenModalOpen(false);
              setProfileImage641("");
            }}
          >
            Done
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
}

export default ImageAndVideoModal;
