import React, { useState, useRef, useEffect } from "react";
import {
  makeStyles,
  Dialog,
  DialogContent,
  IconButton,
  FormControl,
  Typography,
  TextField,
  Box,
  Button,
  Divider,
  InputBase,
  FormHelperText,
} from "@material-ui/core";
import JoditEditor from "jodit-react";
import { IoClose } from "react-icons/io5";
import Chip from "@material-ui/core/Chip";
import MultiSelect from "./MulitiSelect";
import { postDataIdinWithdata, putAPIHandler } from "src/apiConfig/service";
import ButtonCircularProgress from "./ButtonCircularProgress";
import { useContext } from "react";
import { AuthContext } from "src/context/Auth";

const useStyles = makeStyles((theme) => ({
  mainmodalBox: {
    "& .formControl": {
      width: "100%",
      backgroundColor: "transparent",
      border: "none",
      color: "#fff",
      "&:focus-visible": {
        outline: "none",
      },
    },
  },
  cancelBtn: {
    position: "absolute",
    top: 0,
    right: 0,
    "& svg": {
      color: "#fff",
      fontWeight: "700",
    },
  },
  BottomButton: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingTop: "12px",
    " & h6": {
      cursor: "pointer",
    },
  },
  root: {
    width: "100%",
  },
  mainRoot: {
    [theme.breakpoints.only("xs")]: {
      padding: "0px",
    },
  },

  ButtoBox: {
    [theme.breakpoints.only("xs")]: {
      height: "40px",
      padding: "0px 15px",
      fontSize: "14px",
    },
  },
  searchInput: {
    "& .MuiInputBase-inputTypeSearch": {
      padding: "10px 0px",
      borderBottom: "1px solid #949494",
      borderRadius: "0px",
      background: "#fff",
    },
  },
  chip1: {
    background: "rgb(44, 60, 116)",
    color: "#fff",
    boxShadow:
      "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
    " & .MuiChip-deleteIcon": {
      color: "#fff",
    },
  },
  ButtonBotom: {
    [theme.breakpoints.only("xs")]: {
      display: "flex",
    },
  },
}));

function JobDescriptionModal({
  openCreatePost1,
  handleCloseCreatePost1,
  setSelectedOptions,
  selectedOptions,
  data,
  authformData,
  imageData,
  _onInputChange,
  formData,
  description,
  setDescription,
  handleCloseCreatePost,
  pageData,
  pageJOBHAndler,
  type,
  jobId,
}) {
  const classes = useStyles();
  const auth = useContext(AuthContext);
  const [newButton1, setNewButton1] = useState([]);
  const [checkdata1, setCheckdata1] = useState(false);
  const [block, setBlcok] = useState(false);
  const [searchInput1, setSearchInput1] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const editor = useRef(null);

  const onKeyDown1 = (event) => {
    if (event.key === "Enter") {
      setCheckdata1(false);
      setSearchInput1("");
      if (searchInput1 !== "") {
        setNewButton1([...newButton1, searchInput1]);
      } else {
        setCheckdata1(false);
      }
    }
  };

  const handleDelete1 = (data1, key1) => () => {
    const values = [...newButton1];
    for (var i = 0; i < values.length; i++) {
      if (i == key1) {
        var values1 = values.splice(key1, 1);
      }
    }
    setNewButton1(values);
  };

  const jobPostHandler = async () => {
    const skillSName =
      selectedOptions &&
      selectedOptions?.map((item) => {
        return item?.label;
      });

    const fromData = new FormData();
    fromData.append("jobTitle", formData?.jobTitle);
    fromData.append("jobExperiences", formData?.exprience);
    fromData.append("categoryId", formData?.category);
    fromData.append("workspaceType", formData?.workplace);
    fromData.append("jobType", formData?.jobType);
    fromData.append("jobLocation", authformData?.address);
    fromData.append("lat", authformData?.latLong?.lat);
    fromData.append("long", authformData?.latLong?.long);
    fromData.append("compnayName", formData?.company);
    fromData.append("description", description);
    if (type !== "edit") {
      if (pageData?.type === "page") {
        fromData.append("pageId", pageData?.id);
      }
    }

    fromData.append("skills", JSON.stringify(skillSName));
    fromData.append("companyLogo", imageData);
    if (selectedOptions?.length > 0 || description !== "") {
      try {
        setIsLoading(true);
        const checkType =
          type === "edit"
            ? await putAPIHandler("updateJobInfo", fromData, {
                _id: jobId?._id,
              })
            : await postDataIdinWithdata("jobPost", fromData);

        if (checkType) {
          setBlcok(true);
          handleCloseCreatePost1();
          auth.setcreatePost(!auth.createPost);
          handleCloseCreatePost();
          setIsLoading(false);
          pageJOBHAndler();
        } else {
          setIsLoading(false);
        }
      } catch (error) {
        console.log("error", error);
        setIsLoading(false);
      }
    } else {
      setIsSubmit(true);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setBlcok(false);
    }, 2000);
  }, [block]);

  return (
    <>
      <Dialog
        open={openCreatePost1}
        onClose={handleCloseCreatePost1}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="sm"
        fullWidth
        className={classes.root}
      >
        <Box className="bgcolorbox">
          <Box>
            <Typography variant="h5">Tell us about the role</Typography>
          </Box>
        </Box>
        <DialogContent>
          <IconButton
            className={classes.cancelBtn}
            onClick={handleCloseCreatePost1}
          >
            <IoClose />
          </IconButton>
          <Box className={classes.mainRoot}>
            <Box className={classes.mainmodalBox}>
              <Box pb={1}>
                <Typography variant="h5"> Description *</Typography>
              </Box>
              <JoditEditor
                ref={editor}
                value={description}
                variant="outlined"
                name="description"
                fullWidth
                size="small"
                tabIndex={1}
                onChange={(e) => setDescription(e)}
                disabled={isLoading}
              />
              {isSubmit && description === "" && (
                <FormHelperText error>
                  Please enter your description
                </FormHelperText>
              )}
              <Box mt={2}>
                <Box pb={1}>
                  <Typography variant="h5"> Skills*</Typography>
                </Box>
                <Box>
                  <Typography variant="h6">
                    {" "}
                    Add skill keywords to make your job more visible to the
                    right candidates.
                  </Typography>
                </Box>
                <Box pt={3} pb={3} style={{ maxWidth: "250px" }}>
                  <Box pb={1}>
                    {checkdata1 === true ? (
                      <>
                        {" "}
                        <InputBase
                          type="search"
                          className={classes.searchInput}
                          placeholder="Add  Hobbies & Fun"
                          onKeyDown={onKeyDown1}
                          onChange={_onInputChange}
                          disabled={isLoading}
                        />
                      </>
                    ) : (
                      <>
                        <MultiSelect
                          data={data}
                          setSelectedOptions={setSelectedOptions}
                          selectedOptions={selectedOptions}
                        />

                        {isSubmit && selectedOptions.length === 0 && (
                          <FormHelperText error>
                            Please select skills
                          </FormHelperText>
                        )}
                      </>
                    )}
                  </Box>
                  {newButton1?.map((data1, key1) => {
                    return (
                      <>
                        <Box key1={key1} pl={1} pb={1}>
                          <Chip
                            onDelete={handleDelete1(data1, key1)}
                            label={data1}
                            className={classes.chip1}
                          />
                        </Box>
                      </>
                    );
                  })}
                </Box>
              </Box>
              <Box mt={1}>
                <Divider style={{ color: "#E3E3E3" }} />
              </Box>
              <Box pt={1} pb={1}>
                <Box pb={1}>
                  <Typography variant="h5"> Applicant Collection</Typography>
                </Box>
                <FormControl fullWidth className={classes.commentControl}>
                  <TextField
                    fullWidth
                    name="applicant"
                    value={formData?.applicant}
                    variant="outlined"
                    placeholder="Email address "
                    onChange={_onInputChange}
                    disabled={isLoading}
                  />
                  {isSubmit && formData?.applicant == "" && (
                    <FormHelperText error>Please select skills</FormHelperText>
                  )}
                </FormControl>
              </Box>
              <Box className={classes.BottomButton}>
                <Box align="end" className={classes.ButtonBotom}>
                  <Button
                    variant="contained"
                    color="secondary"
                    size="small"
                    style={{ padding: "19px 24px" }}
                    className={classes.ButtoBox}
                    onClick={handleCloseCreatePost1}
                  >
                    Back
                  </Button>
                  &nbsp;&nbsp;
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    style={{ padding: "20px 26px" }}
                    disabled={isLoading}
                    className={classes.ButtoBox}
                    onClick={() => {
                      jobPostHandler();
                    }}
                  >
                    Post {isLoading && <ButtonCircularProgress />}
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
      <Dialog
        fullWidth="xs"
        maxWidth="xs"
        open={block}
        keepMounted
        onClose={() => setBlcok(false)}
        className={classes.DialogClass}
      >
        <Box display="flex" justifyContent="end">
          <IconButton
            className={classes.cancelBtn}
            onClick={() => setBlcok(false)}
          >
            <IoClose />
          </IconButton>
        </Box>
        <DialogContent>
          <Box mt={3} mb={3}>
            <Box align="center" mt={2}>
              <img src="images/sucess.png" alt="dumy" />
            </Box>

            <Box mt={3} mb={2} align="center">
              <Typography
                variant="h4"
                style={{ color: "#52A500", maxWidth: "200px" }}
              >
                Job Posted Successfully
              </Typography>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default JobDescriptionModal;
