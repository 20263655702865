import {
  postDataIdinQueryWithdata,
  postDataIdinWithdata,
} from "src/apiConfig/service";
import {
  Box,
  TextField,
  Typography,
  InputAdornment,
  IconButton,
  FormControl,
  Avatar,
} from "@material-ui/core";
import moment from "moment";
import React, { useState, useEffect } from "react";
import { FaRegSmileBeam } from "react-icons/fa";
import { BiLike } from "react-icons/bi";
import { Send } from "@material-ui/icons";
import CommentReplyList from "./CommentReplyList";
import Picker from "emoji-picker-react";

export default function CommentList({
  classes,
  comment,
  commentListData,
  viewData,
  listCommentHandler,
}) {
  const [commnetId, setCommentId] = useState("");
  const [replyComment, setReplyComment] = useState("");
  const [error, setError] = useState("");
  const [seeMore, setSeeMore] = useState(false);
  const [commentField, setCommentField] = useState(false);
  const replyoncomment = (commentdata) => {
    setCommentId(commentdata?._id);
    setCommentField(true);
  };
  const [showPicker, setShowPicker] = useState(false);

  const onEmojiClick = (e, emojiObject) => {
    const sym = e.unified.split("_");
    const codeArray = [];
    sym.forEach((el) => codeArray.push("0x" + el));
    let emoji = String.fromCodePoint(...codeArray);
    setReplyComment(replyComment + emoji);
    setShowPicker(false);
  };

  const addCommentHandler = async () => {
    if (replyComment !== "") {
      try {
        const res = await postDataIdinQueryWithdata(
          "addComment",
          { postId: viewData?._id, commentId: commnetId },
          { comments: replyComment }
        );
        if (res) {
          listCommentHandler();
          setReplyComment("");
          setCommentField(false);
        }
      } catch (error) {}
    } else {
      setError("Please enter comment");
    }
  };
  const likeOnComment = async (commentdata) => {
    try {
      const res = await postDataIdinWithdata("addLikeOnComent", {
        postId: viewData?._id,
        commentId: commentdata?._id,
      });
      if (res) {
        listCommentHandler();
        setReplyComment("");
        setCommentField(false);
      }
    } catch (error) {}
  };

  return (
    <>
      <Box className="commetBox">
        <Box display="flex" alignItems="flex-start" mb={1}>
          <Avatar
            src={comment?.userId?.profilePic && comment?.userId?.profilePic}
          />
          <Box display="block">
            <Typography
              style={{ wordBreak: "break-word", marginLeft: "5px" }}
              variant="h6"
            >
              {comment?.userId?.firstName
                ? comment?.userId?.firstName
                : "Anonymous"}
            </Typography>
            <Typography
              variant="body1"
              style={{
                color: "rgba(255, 255, 255, 0.7)",
                wordBreak: "break-word",
                marginLeft: "5px",
              }}
            >
              {comment?.comments?.comment}
            </Typography>
          </Box>
        </Box>

        {seeMore && (
          <CommentReplyList
            type="comment"
            comment={comment}
            setSeeMore={(item) => setSeeMore(item)}
          />
        )}
      </Box>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box display="flex" alignItems="center" className={classes.replyflex}>
          <Typography variant="body1" onClick={() => likeOnComment(comment)}>
            {comment?.isLike ? (
              <span style={{ color: "#4057a7" }}>Like</span>
            ) : (
              <span>Like</span>
            )}{" "}
          </Typography>

          <Typography variant="body1" style={{ marginLeft: "10px" }}>
            <BiLike style={{ color: "#4057a7" }} /> {comment?.no_of_like}
          </Typography>
          <Typography
            onClick={() => {
              setSeeMore(true);
              replyoncomment(comment);
            }}
            variant="body1"
            style={{ marginLeft: "10px" }}
          >
            Reply{" "}
            {comment?.comments?.replies?.length > 0 &&
              comment?.comments?.replies?.length}
          </Typography>
        </Box>
        <Typography variant="body1">
          {" "}
          {moment(comment?.createdAt).local().fromNow()}
        </Typography>
      </Box>
      {commentField && (
        <Box className={classes.mainTextFl} mb={1} mt={0.6}>
          {showPicker && (
            <Box className={classes.emojiBox}>
              <Picker onEmojiClick={onEmojiClick} onClose height="350px" />
            </Box>
          )}
          <FormControl fullWidth className={classes.commentControl}>
            <TextField
              type="text"
              variant="outlined"
              placeholder="Add a comment..."
              fullWidth
              value={replyComment}
              onChange={(e) => setReplyComment(e.target.value)}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  addCommentHandler();
                }
              }}
              size="lg"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton>
                      <FaRegSmileBeam
                        onClick={() => setShowPicker((val) => !val)}
                      />
                    </IconButton>
                    <IconButton>
                      <Send onClick={() => addCommentHandler()} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>
        </Box>
      )}
    </>
  );
}
