import { Box, Typography, makeStyles, Button } from "@material-ui/core";
import React from "react";
import moment from "moment";
import { useState } from "react";
import { putAPIHandler } from "src/apiConfig/service";

const useStyles = makeStyles((theme) => ({
  todoBox: {
    background: "#F4F4F4",
    padding: "20px",
    borderRadius: "10px",
  },
}));

export default function ToDolist({ data, toDoListHandler }) {
  const classes = useStyles();
  const [isReadMore, setIsReadMore] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const handleClseToDo = async () => {
    try {
      setIsLoading(true);
      const response = await putAPIHandler(
        "closeToDo",
        {},
        {
          id: data?._id,
        }
      );
      console.log("lasjdflkasdf", response);
      if (response?.responseCode === 200) {
        toDoListHandler();
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  return (
    <Box>
      <Box className={classes.todoBox}>
        <Box className="displaySpacebetween" mb={1.5}>
          <Box mb={1} className="displaySpaceBetween">
            <Typography variant="h6" style={{ wordBreak: "break-all" }}>
              {data?.topic}
            </Typography>
            <Button
              disabled={isLoading}
              variant="contained"
              color="secondary"
              onClick={() => handleClseToDo(data?._id)}
            >
              {isLoading ? "..." : "Close"}
            </Button>
          </Box>

          <Typography variant="body1">
            {moment(data?.date).format("ll")} to{" "}
            {moment(data?.time).format("ll")}
          </Typography>
        </Box>

        <Typography variant="body2" style={{ wordBreak: "break-all" }}>
          {isReadMore ? data?.description.slice(0, 80) : data?.description}
          <span
            onClick={() => setIsReadMore(!isReadMore)}
            style={{
              color: "#29B6ED",
              cursor: "pointer",
              paddingLeft: "3px",
            }}
          >
            {data?.description?.length >= 80 && (
              <>{isReadMore ? "... more" : " See less"}</>
            )}
          </span>
        </Typography>
      </Box>
    </Box>
  );
}
