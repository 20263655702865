import React, { useState } from "react";
import {
  makeStyles,
  Dialog,
  DialogContent,
  IconButton,
  FormControl,
  Typography,
  Box,
  Divider,
  Button,
  Menu,
  TextField,
  FormHelperText,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { IoClose } from "react-icons/io5";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import "react-phone-input-2/lib/style.css";
import ButtonCircularProgress from "./ButtonCircularProgress";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: " ",
    "& .btnBox": {
      display: "flex",
      justifyContent: "center",
    },
  },
  mainmodalBox: {
    paddingTop: "30px",
    paddingBottom: "15px",
    "& .formControl": {
      width: "100%",
      backgroundColor: "transparent",
      border: "none",
      color: "#fff",
      "&:focus-visible": {
        outline: "none",
      },
    },
    "& h6": {
      fontWeight: "700",
    },

    "& h5": {
      color: "rgba(50, 50, 50, 0.6)",
    },
    "& button": {
      height: "47px",
    },
  },
  cancelBtn: {
    position: "absolute",
    top: 0,
    right: 0,
    "& svg": {
      color: "#fff",
      fontWeight: "700",
    },
  },

  bgcolorbox: {
    background: " #4057A7",
    padding: "14px",
    "& h6": {
      fontSize: "16px",
      fontWeight: 700,
      textAlign: "left",
      color: "#ffffff",
    },
  },

  selectformControl: {
    "& .MuiSelect-select": {
      background: "#fff",
    },
  },
  RadioGroup: {
    background: "#fff",
    width: "100%",
    "& .radioBox": {
      display: "flex",
      alignItems: "center",
    },
  },
}));
const ReportData = [
  {
    description: "Harmful or  disrespectful content",
  },
  {
    description: "Child sex / Pornographic content",
  },
  {
    description: "Fake News",
  },
  {
    description: "Abusive content",
  },
  {
    description: "Anti harmony content",
  },
];
function PollModal({
  openCreateReport,
  handleCloseCreateReport,
  setCategorySelect,
  categorySelect,
  reportHandler,
  id,
  isLoading,
  setReportMsg,
  reportMsg,
  isSubmit,
}) {
  const classes = useStyles();
  const [isReportOpen, setIsReportOpen] = useState(false);
  const handleCheck = (event) => {
    setCategorySelect(event.target.value);
    setIsReportOpen("");
  };

  return (
    <Dialog
      open={openCreateReport}
      onClose={() => {
        if (!isLoading) {
          handleCloseCreateReport();
        }
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="xs"
      fullWidth
      className={classes.root}
    >
      <Box align="center" className={classes.bgcolorbox}>
        <Box>
          <Typography variant="h6">Report</Typography>
        </Box>
      </Box>
      <DialogContent>
        <IconButton
          className={classes.cancelBtn}
          disabled={isLoading}
          onClick={handleCloseCreateReport}
        >
          <IoClose />
        </IconButton>

        <Box className={classes.mainmodalBox}>
          <Box>
            <Typography variant="subtitle2">
              Why are you reporting this post
            </Typography>
          </Box>
          <Box my={1}>
            <Divider className={classes.dividerr} />
          </Box>
          <Box>
            <FormControl component="fieldset" className={classes.RadioGroup}>
              {ReportData.map((data, index) => {
                return (
                  <>
                    <Box className="radioBox">
                      <FormControlLabel
                        //   value="female"
                        control={
                          <Radio
                            checked={categorySelect === data?.description}
                            onChange={handleCheck}
                            disabled={isLoading}
                          />
                        }
                        value={data?.description}
                      />
                      <Typography variant="h5">{data.description}</Typography>
                    </Box>
                    <Box my={1}>
                      <Divider className={classes.dividerr} />
                    </Box>
                  </>
                );
              })}
              {isSubmit && categorySelect === "" && reportMsg === "" && (
                <FormHelperText error>Please select atleast one</FormHelperText>
              )}
              <Box className="radioBox">
                <FormControlLabel
                  //   value="female"
                  control={
                    <Radio
                      checked={isReportOpen}
                      onChange={(e) => {
                        setCategorySelect("");
                        setIsReportOpen(e.target.checked);
                      }}
                      disabled={isLoading}
                    />
                  }
                />
                <Typography variant="h5">Other</Typography>
              </Box>
              {isReportOpen && (
                <TextField
                  placeholder="enter your views"
                  variant="outlined"
                  fullWidth
                  value={reportMsg}
                  onChange={(e) => setReportMsg(e.target.value)}
                />
              )}
              {isReportOpen &&
                isSubmit &&
                reportMsg === "" &&
                categorySelect === "" && (
                  <FormHelperText error>Please enter your views</FormHelperText>
                )}
            </FormControl>
          </Box>
          <Box className="btnBox">
            <Box className="grid2" pt={3}>
              <Button
                variant="outlined"
                color="secondary"
                size="large"
                disabled={isLoading}
                onClick={handleCloseCreateReport}
              >
                Cancel
              </Button>
            </Box>
            &nbsp;&nbsp;
            <Box pt={3}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                disabled={isLoading}
                onClick={() => reportHandler(id?.id)}
              >
                Report {isLoading && <ButtonCircularProgress />}
              </Button>
            </Box>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default PollModal;
