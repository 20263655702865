import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  makeStyles,
  Grid,
  Box,
  Dialog,
  DialogContent,
  IconButton,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  mainbox: {
    maxWidth: "100%",
    width: "auto",
    marginTop: "35px",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    cursor: "pointer",
  },
  root: {
    // background: "url('images/loginback.png')",
    backgroundColor: "#ffffff",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    // minHeight: "calc(100vh - 4px)",
  },
  mainbox2: {
    maxWidth: "200px",
    width: "100%",
    margin: "0 auto",
    marginTop: "40%",
  },
  mainClass: {
    // backgroundColor: "#4057A7",
    background: "url('images/backkycimage.png')",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    textAlign: "center",
    "& h1": {
      color: "#000000",
      marginTop: "-27px",
    },
    "& h2": {
      fontWeight: "400",
      fontSize: "20px",
      lineHeight: "24px",
      letterSpacing: "0.005em",
      color: "#000000",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  content: {
    // height: "calc(100vh - 165px)",
    // overflowY: "scroll",
    // overflowX: "hidden",
    // marginBottom: "30px",
  },
  iconbtn: {
    display: "flex",
    justifyContent: "flex-end",
 
  },
  iconbtndio: {
    "& .MuiDialogContent-root": {
      padding: "0px 0px !important",
    },
  },
}));

const KYCLayout = ({ children }) => {
  const classes = useStyles();
  const history = useHistory();
  const [open, setOpen] = useState(false);
  useEffect(() => {
    setOpen(true);
  }, []);

  return (
    <Box className={classes.iconbtndio}>
      <Dialog
        open={open}
        // onClick={() => setOpen(false)}
        maxWidth="lg"
        fullWidth
      >
        <Box className={classes.iconbtn}>
          <IconButton
            style={{ position: "absolute", zIndex: "1", background: " #fff" }}
            onClick={() => history.push("/home")}
          >
            <CloseIcon style={{ color: "#4057A7" }} />
          </IconButton>
        </Box>
        <DialogContent style={{ padding: "0px" }}>
          <Box className={classes.root}>
            <Box className={classes.mainbox1}>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={3}
                  lg={3}
                  className={classes.mainClass}
                >
                  <Box>
                    <img
                      onClick={() => history.push("/home")}
                      src="images/owneddata.png"
                      alt="dummy"
                      className={classes.mainbox}
                    />
                  </Box>
                  <Box>
                    <img
                      src="images/kycimg.png"
                      alt="dummy"
                      className={classes.mainbox2}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={9} lg={9}>
                  <Box className={classes.content}>{children}</Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

KYCLayout.propTypes = {
  children: PropTypes.node,
};

export default KYCLayout;
