import {
  Avatar,
  Box,
  Grid,
  IconButton,
  Typography,
  makeStyles,
} from "@material-ui/core";
import React, { useContext, useState } from "react";
import { BsFillCameraVideoFill } from "react-icons/bs";
import moment from "moment";
import DataNotFound from "src/component/DataNotFound";
import { deleteDataAPIHandler } from "src/apiConfig/service";
import { AuthContext } from "src/context/Auth";
import EventModal from "src/component/EventModal";
import { FiEdit2 } from "react-icons/fi";
import { MdDelete } from "react-icons/md";
import ConfirmationDialog from "src/component/ConfirmationDialog";
import { HiOutlineExternalLink } from "react-icons/hi";

const style = makeStyles((theme) => ({
  mapingbox1: {
    padding: "0px 15px 15px",
    "& figure": {
      width: "100%",
      maxWidth: "147px",
      borderRadius: "4px",
      overflow: "hidden",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      margin: "0px",
      "& img": {
        minHeight: "100%",
        width: "100%",
      },
      "@media(max-width:590px)": {
        maxWidth: "100%",
      },
    },
    "& h5": {
      fontWeight: "600",
    },
    "& h6": {
      fontWeight: "300",
      margin: "4px 0px",
      wordBreak: "break-all",
    },
    "& .textBox": {
      marginLeft: "8px",
      "@media(max-width:590px)": {
        marginLeft: "0px",
        marginTop: "10px",
      },
      "& svg": {
        marginRight: "5px",
        color: "rgb(64, 87, 167)",
      },
    },
    "& h4": {
      fontWeight: "600",
    },
    "& .fleximages": {
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
      "@media(max-width:590px)": {
        display: "block",
      },
    },
  },
  avatarBox: {
    width: "140px",
    height: "140px",
    borderRadius: "10px",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      height: "150px",
    },
  },
  iconflex: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    background: "#fff",
    padding: "10px",
    borderRadius: "10px",
    transition: "0.5s",

    "&:hover": {
      transform: "scale(1.02)",
      boxShadow:
        "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px",
    },
    "@media(max-width:687px)": {
      display: "block",
    },
    "& h4": {
      fontSize: "16px",
      color: "#4057A7",
      fontWeight: "400",
    },
    "& .MuiIconButton-root": {
      "&:hover": {
        background: "none",
      },
    },
    "& .iconboxes": {
      border: " 2px solid #4057A7",
      width: "35px",
      height: "35px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: "50%",
      "& svg": {
        color: "#4057A7",
      },
    },
    "& .figureflex": {
      display: "flex",
      alignItems: "center",
      "@media(max-width:619px)": {
        display: "block",
      },
    },
  },
}));

const Event = () => {
  const classes = style();
  const auth = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const [openCreateEvent, setOpenCreateEvent] = React.useState(false);
  const [eventId, setEventId] = React.useState(false);

  const [modalsOpen, setModalsOpen] = useState({
    deleteModals: false,
    id: "",
  });

  const deeleteHandler = async (id) => {
    const idSend = {
      _id: id,
    };
    try {
      setIsLoading(true);
      const res = await deleteDataAPIHandler("deletePost", idSend);
      if (res) {
        auth.listPostHandler();
        setModalsOpen({});
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };
  function funtionFilter(value) {
    return value.filter((itm) => itm?.userId?._id === auth?.userData?._id);
  }

  return (
    <Box className="scrollDiv">
      <Box className={classes.root}>
        <Box className={classes.mapingbox1}>
          <Box mb={2}>
            <Typography variant="h4">Events</Typography>
          </Box>
          <Grid container spacing={1}>
            {auth.listEventPost &&
              funtionFilter(auth.listEventPost)?.map((data, index) => {
                return (
                  <>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <Box className={classes.iconflex}>
                        <Box
                          className="fleximages"
                          onClick={() =>
                            window.open(data?.eventPost?.eventLink)
                          }
                        >
                          <Avatar
                            src={data?.eventPost?.image}
                            className={classes.avatarBox}
                          />
                          <Box className="textBox">
                            <Box display="flex" alignItems="center">
                              <Typography variant="h5">
                                {data?.eventPost?.eventName}
                              </Typography>
                              <IconButton
                                onClick={() =>
                                  window.open(data?.eventPost?.eventLink)
                                }
                              >
                                <HiOutlineExternalLink />
                              </IconButton>
                            </Box>

                            <Typography variant="h6">
                              {moment(data?.eventPost?.startDate).format(
                                "llll"
                              )}
                            </Typography>
                            <Box display="flex" alignItems="center">
                              <BsFillCameraVideoFill />
                              <Typography variant="h6">
                                {data?.userId?.firstName ? (
                                  <>
                                    {data?.userId?.firstName}{" "}
                                    {data?.userId?.lastName}
                                  </>
                                ) : (
                                  "..."
                                )}
                              </Typography>
                            </Box>
                            <Typography variant="h6">
                              {data?.eventPost?.description}
                            </Typography>
                          </Box>
                        </Box>
                        <Box
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <IconButton
                            style={{ padding: "6px" }}
                            onClick={() => {
                              setOpenCreateEvent(true);
                              setEventId(data);
                            }}
                          >
                            <FiEdit2
                              color="#4057A7"
                              style={{ fontSize: "15px" }}
                            />
                            &nbsp;
                            <Typography variant="h4">Edit</Typography>
                          </IconButton>

                          <Box className="iconboxes">
                            <IconButton
                              onClick={(event) => {
                                event.stopPropagation();
                                setModalsOpen({
                                  ...modalsOpen,
                                  deleteModals: true,
                                  id: data?._id,
                                });
                              }}
                            >
                              <MdDelete />
                            </IconButton>
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                  </>
                );
              })}
            {!auth.isLoading &&
              auth.listEventPost &&
              funtionFilter(auth.listEventPost).length === 0 && (
                <DataNotFound />
              )}
          </Grid>
        </Box>
      </Box>
      {modalsOpen?.deleteModals && (
        <ConfirmationDialog
          open={modalsOpen?.deleteModals}
          handleClose={() =>
            setModalsOpen({ ...modalsOpen, deleteModals: false })
          }
          isLoading={isLoading}
          title="Delete"
          desc="Are you sure, you want to delete?"
          confirmationHandler={() => deeleteHandler(modalsOpen?.id)}
        />
      )}
      {openCreateEvent && (
        <EventModal
          openCreateEvent={openCreateEvent}
          handleCloseCreateEvent={() => setOpenCreateEvent(false)}
          listPostHandler={() => auth.listPostHandler()}
          type="eventUpdate"
          eventId={eventId}
        />
      )}
    </Box>
  );
};
export default Event;
