import React from "react";
import {
  makeStyles,
  Typography,
  Paper,
  Box,
  Divider,
  Grid,
  Button,
  Avatar,
} from "@material-ui/core";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  jobsbox: {
    "& .doted": {
      marginBottom: "10px",
      "& h5": {
        color: "#323232",
      },
    },
    "& .MuiDivider-root": {
      backgroundColor: "rgba(231, 231, 231, 0.2)",
    },
  },
  mapingbox1: {
    "& .ImageBox": {
      display: "flex",
      cursor: "pointer",
      justifyContent: "space-between",
      "& .MuiAvatar-root": {
        width: "50px !important",
        height: "50px !important",
      },
      "& h6": {
        fontSize: "14px",
        fontWeight: "400",
        color: "#323232",
      },
      "& p": {
        color: "#ABABAB",
      },
      "& button": {
        background: " #E4E4E4",
        borderRadius: "30px",
        fontSize: "7px",
        fontWeight: "700",
        minWidth: "26px",
        minHeight: "12px",
        marginLeft: "10px",
        padding: "3px 7px",
      },
    },
    "& .TextBox": {
      marginLeft: "10px",
    },
    "& .subtextbox": {
      "& h5": {
        color: "#323232",
        margin: "5px 0px",
      },
      "& p": {
        color: "#323232",

        "& span": {
          fontWeight: "700",
          color: "#70CDDD",
        },
      },
      "& h6": {
        color: "#909090",
        fontSize: "10px",
        fontWeight: "400",
      },
    },
    "& .iconBox": {
      "& svg": {
        fontSize: "17px",
        color: "#4057A7",
      },
    },
  },
  root: {
    padding: "15px",
    [theme.breakpoints.down("xs")]: {
      padding: "10px",
    },
  },
}));

function Trending({ skillUpAdCourseData, history, LoadHistory, isLoading }) {
  const classes = useStyles();

  return (
    <Paper className={classes.root} elevation={0}>
      <Box className={classes.jobsbox}>
        <Box className="doted">
          <Typography variant="h5">Trending</Typography>
        </Box>
        <Grid container spacing={1} direction={"column"}>
          {skillUpAdCourseData &&
            skillUpAdCourseData?.trending?.slice(0, 4)?.map((data, index) => {
              return (
                <Grid item xs={12} key={index}>
                  <Box
                    className={classes.mapingbox1}
                    onClick={() =>
                      history.push({
                        pathname: "/job-description",
                        state: data,
                        search: data?._id,
                      })
                    }
                  >
                    <Box className="ImageBox">
                      <Box style={{ display: "flex" }}>
                        <Avatar src={data?.companyLogo} alt="" />

                        <Box className="TextBox">
                          <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                          >
                            <Typography
                              variant="h6"
                              style={{
                                whiteSpace: "nowrap",
                                width: "100px",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {data?.jobTitle}
                            </Typography>
                          </Box>
                          <Typography variant="body1">
                            {data?.compnayName}
                          </Typography>
                          <Typography variant="body2">
                            {data?.jobLocation}
                          </Typography>
                        </Box>
                      </Box>
                      <Box>
                        <Button>Job</Button>
                      </Box>
                    </Box>
                    <Box mt={2} mb={1}>
                      <Divider />
                    </Box>
                    <Box className="subtextbox">
                      <Typography variant="body1">
                        {moment(data?.updatedAt).format("ll")}
                      </Typography>
                      <Typography variant="h6">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: `${data?.description?.slice(0, 40)}${
                              data?.description?.length > 40 ? "..." : ""
                            }`,
                          }}
                        ></div>
                      </Typography>
                    </Box>
                  </Box>
                  <Box mt={2} mb={1}>
                    <Divider />
                  </Box>
                </Grid>
              );
            })}
          {isLoading &&
            [1].map((data, i) => {
              return <LoadHistory />;
            })}
        </Grid>
        {skillUpAdCourseData?.trending &&
          skillUpAdCourseData?.trending?.length >= 5 && (
            <Box display="flex" alignItems="center" justifyContent="center">
              <Typography
                variant="body1"
                style={{ color: "#323232", cursor: "pointer" }}
                onClick={() => history.push("/job")}
              >
                Show More
              </Typography>
            </Box>
          )}
      </Box>
    </Paper>
  );
}

export default Trending;
