import {
  Dialog,
  DialogContent,
  IconButton,
  Typography,
  Box,
  Button,
  makeStyles,
} from "@material-ui/core";
import React, { useState } from "react";
import { IoClose } from "react-icons/io5";
import CoreNetworkIcon from "src/icons/CoreNetworkIcon";
import Corporate from "src/icons/Corporate";
import Besties from "src/icons/Besties";
import Alumni from "src/icons/Alumni";
import Classmate from "src/icons/Classmate";
import Following from "src/icons/Following";
import { postDataIdinWithdata } from "src/apiConfig/service";
import ButtonCircularProgress from "./ButtonCircularProgress";
import UserRequestLIst from "./UserRequestLIst";

const useStyles = makeStyles((theme) => ({
  cancelBtn: {
    position: "absolute",
    top: 0,
    right: 0,
    "& svg": {
      color: "#fff",
      fontWeight: "700",
    },
  },
}));

const manageData = [
  {
    id: "0",
    coretext: "Core Network",
    type: "core_network",
    icon: <CoreNetworkIcon />,
  },
  {
    id: "1",
    coretext: " Corporate Colleague",
    type: "colleagues",
    icon: <Corporate />,
  },
  {
    id: "2",
    coretext: "Besties",
    type: "besties",
    icon: <Besties />,
  },
  {
    id: "3",
    coretext: "Alumni",
    type: "allumni",
    icon: <Alumni />,
  },
  {
    id: "4",
    coretext: "Classmate",
    type: "classmate",
    icon: <Classmate />,
  },
  {
    id: "5",
    coretext: "Following",
    type: "default",
    icon: <Following />,
  },
];

export default function RequestModal({
  handleCloseModal,
  followModal,
  userData,
  globalSearch,
  getOtherUserProfile,
}) {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);

  const requestApiHandler = async (type) => {
    try {
      setIsLoading(true);
      const dataToSend = {
        to_id: userData?._id,
        type: type,
      };
      const res = await postDataIdinWithdata("request", dataToSend);
      if (res.responseCode === 200) {
        globalSearch();
        setIsLoading(false);
        handleCloseModal();
        getOtherUserProfile(userData?._id);
      } else {
        setIsLoading(false);
        handleCloseModal();
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  return (
    <Dialog
      open={followModal}
      onClose={handleCloseModal}
      fullWidth
      maxWidth="xs"
    >
      <Box className="bgcolorbox">
        <Box>
          <Typography variant="h5">Request</Typography>
        </Box>
      </Box>
      <DialogContent>
        <IconButton className={classes.cancelBtn} onClick={handleCloseModal}>
          <IoClose />
        </IconButton>
        <Box mb={3}>
          {manageData &&
            manageData?.map((data, index) => {
              return (
                <UserRequestLIst
                  data={data}
                  index={index}
                  classes={classes}
                  requestApiHandler={requestApiHandler}
                  isLoading={isLoading}
                />
              );
            })}
        </Box>
      </DialogContent>
    </Dialog>
  );
}
