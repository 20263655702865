import React from "react";
import {
  FacebookShareButton,
  TelegramShareButton,
  EmailShareButton,
  TwitterShareButton,
} from "react-share";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Typography,
  Dialog,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import List from "@material-ui/core/List";
import { FaFacebookF } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaTelegramPlane } from "react-icons/fa";
import { makeStyles } from "@material-ui/styles";
import { MdEmail } from "react-icons/md";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
  ShareSocialMedia: {
    "& .socialBox": {
      "& .iconShareButton": {
        background: "rgba(64, 87, 167, 0.05)",
      },

      "& p": {
        marginTop: "7px",
        fontSize: "14px",
        fontWeight: "700",
      },
      "&:hover": {
        background: "rgba(255, 255, 255, 0.1)",
        "& .iconShareButton": {
          // background: "#5D5D5D",

          background: theme.palette.background.blur,
        },
      },
    },
  },
}));
export default function ShareSocialMedia({
  url,
  setOpenShare,
  openShare,
  text,
}) {
  const classes = useStyles();
  return (
    <Dialog
      open={openShare}
      onClose={() => setOpenShare(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="xs"
      fullWidth
    >
      <DialogActions
        style={{
          background: "#4057A7",
          padding: "0px",
          justifyContent: "space-between",
        }}
      >
        <Typography
          variant="h6"
          style={{ color: "rgb(255, 255, 255)", marginLeft: "23px" }}
        >
          Share
        </Typography>
        <IconButton
          onClick={() => setOpenShare(false)}
          className={classes.customizedButton}
        >
          <CloseIcon style={{ color: "#fff" }} />
        </IconButton>
      </DialogActions>
      <DialogContent>
        <Box className={classes.sharemodal} mb={2} align="center" mt={3}>
          <Box className={classes.ShareSocialMedia}>
            <Grid container spacing={2} justifyContent="center">
              <Grid item xs={3}>
                <Box className="socialBox">
                  <FacebookShareButton
                    url={url}
                    title={text !== undefined ? text : `Check latest post`}
                    hashtag=""
                  >
                    <IconButton className="iconShareButton">
                      {" "}
                      <FaFacebookF
                        style={{
                          fontSize: "23px",
                          color: "rgba(50, 50, 50, 0.75)",
                        }}
                      />
                    </IconButton>
                    <br />
                    <Typography variant="body2" color="primary">
                      Facebook
                    </Typography>
                  </FacebookShareButton>
                </Box>
              </Grid>

              <Grid item xs={3}>
                <Box className="socialBox">
                  <TelegramShareButton
                    url={url}
                    title={text !== undefined ? text : `Check latest post`}
                    hashtag=""
                  >
                    <IconButton className="iconShareButton">
                      <FaTelegramPlane
                        style={{
                          fontSize: "23px",
                          color: "rgba(50, 50, 50, 0.75)",
                        }}
                      />
                    </IconButton>
                    <br />
                    <Typography variant="body2" color="primary">
                      {" "}
                      Telegram
                    </Typography>
                  </TelegramShareButton>
                </Box>
              </Grid>
              <Grid item xs={3}>
                <Box className="socialBox">
                  <TwitterShareButton
                    url={url}
                    title={text !== undefined ? text : `Check latest post`}
                    hashtag=""
                  >
                    <IconButton className="iconShareButton">
                      {" "}
                      <FaTwitter
                        style={{
                          fontSize: "23px",
                          color: "rgba(50, 50, 50, 0.75)",
                        }}
                      />
                    </IconButton>
                    <br />
                    <Typography variant="body2" color="primary">
                      {" "}
                      Twitter
                    </Typography>
                  </TwitterShareButton>{" "}
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
