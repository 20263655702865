export default {
  h1: {
    fontWeight: 700,
    fontSize: 38,
    fontFamily: "'Mulish', sans-serif",
    "@media(max-width:767px)": {
      fontSize: "30px !important",
      lineHeight: "40px",
    },
  },
  h2: {
    fontWeight: 700,
    fontSize: 30,
    fontFamily: "'Mulish', sans-serif",
    "@media(max-width:767px)": {
      fontSize: "23px !important",
      lineHeight: "40px",
    },
  },
  h3: {
    fontWeight: 700,
    fontSize: 24,
    fontFamily: "'Mulish', sans-serif",
    "@media (max-width:767px)": {
      fontSize: "20 !important",
    },
  },
  h4: {
    fontWeight: 700,
    fontSize: 20,
    fontFamily: "'Mulish', sans-serif",
    "@media (max-width:767px)": {
      fontSize: "18px !important",
    },
  },
  h5: {
    fontWeight: 600,
    fontSize: 16,
    fontFamily: "'Mulish', sans-serif",
    "@media (max-width:767px)": {
      fontSize: "14px !important",
    },
  },
  h6: {
    fontWeight: 600,
    fontSize: 14,
    fontFamily: "'Mulish', sans-serif",
    "@media (max-width:767px)": {
      fontSize: "13px !important",
    },
  },
  body2: {
    fontWeight: 400,
    fontSize: 12,
    fontFamily: "'Mulish', sans-serif",
  },
  body1: {
    fontWeight: 400,
    fontSize: 10,
    fontFamily: "'Mulish', sans-serif",
  },

  overline: {
    fontWeight: 500,
  },
  button: {
    textTransform: "capitalize",
    fontFamily: "'Mulish', sans-serif",
  },
  subtitle2: {
    fontWeight: 700,
    fontSize: 18,
    fontFamily: "'Mulish', sans-serif",
  },
  subtitle3: {
    fontWeight: 700,
    fontSize: 12,
    fontFamily: "'Mulish', sans-serif",
  },
};
