import React, { useContext } from "react";
import {
  Typography,
  Dialog,
  DialogContent,
  Button,
  Box,
  IconButton,
  makeStyles,
} from "@material-ui/core";
import { AuthContext } from "src/context/Auth";
import { IoClose } from "react-icons/io5";
import ButtonCircularProgress from "src/component/ButtonCircularProgress";
import { toast } from "react-toastify";
import { handleSubmitBlcDataFun } from "src/blockchainfunction";

const useStyles = makeStyles((theme) => ({
  cancelBtn: {
    position: "absolute",
    top: 0,
    right: 0,
    "& svg": {
      color: "#fff",
      fontWeight: "700",
    },
  },
  maindialouge: {
    width: "100%",
  },
}));
const ConfirmationBlockChain = ({
  handleUploadApi,
  dataSend,
  openModal,
  handleCloseModal,
  setIsLoading,
  isLoading,
}) => {
  const auth = useContext(AuthContext);
  const classes = useStyles();

  const handleSubmitDataFun = async () => {
    try {
      setIsLoading(true);
      const response = await handleSubmitBlcDataFun(dataSend, auth);
      if (response) {
        handleUploadApi();
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  return (
    <Dialog
      open={openModal}
      onClose={() => !isLoading && handleCloseModal()}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="xs"
      className={classes.maindialouge}
    >
      <Box className="bgcolorbox">
        <Box>
          <Typography variant="h5">Send to blockchain</Typography>
        </Box>
      </Box>
      <DialogContent>
        <IconButton
          className={classes.cancelBtn}
          disabled={isLoading}
          onClick={handleCloseModal}
        >
          <IoClose />
        </IconButton>

        <Typography
          variant="h5"
          style={{
            color: "rgba(50, 50, 50, 0.8)",
            fontSize: "16px",
            margin: "20px 0px",
            textAlign: "center",
            fontWeight: "700",
          }}
        >
          Are you sure, you want to send data on blockchain?
        </Typography>
        <Box mb={2} align="center">
          <Button
            variant="contained"
            color="secondary"
            disabled={isLoading}
            onClick={handleCloseModal}
            size="medium"
            style={{ padding: "7px 50px" }}
          >
            No
          </Button>
          <Button
            style={{ marginLeft: "13px", padding: "7px 50px" }}
            variant="contained"
            color="primary"
            size="medium"
            onClick={() =>
              auth?.userData?.kycStatus === "APPROVE"
                ? !auth?.account
                  ? auth.setOpenMetamask(true)
                  : handleSubmitDataFun()
                : toast.warn("Please complete your KYC to access this feature.")
            }
            disabled={isLoading}
          >
            {auth?.account ? "Yes" : "Connect wallet"}{" "}
            {isLoading && <ButtonCircularProgress />}
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ConfirmationBlockChain;
