import { Typography, Box, Button } from "@material-ui/core";
import React from "react";

export default function UserRequestLIst({
  data,
  index,
  requestApiHandler,
  isLoading,
}) {
  return (
    <Box
      mt={3}
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      key={index}
    >
      <Box display="flex" alignItems="center">
        <Box
          style={{
            height: "20px",
            width: "20px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginLeft: "5px",
          }}
          key={index}
        >
          {data.icon}
        </Box>
        <Box ml={2}>
          <Typography variant="h6">{data.coretext}</Typography>
        </Box>
      </Box>
      {console.log("kjsdfhkdjsf", data?.id)}
      {console.log("kjsdfasdfahkdjsf", index)}
      <Button
        variant="contained"
        size="small"
        color="primary"
        className="buttonno"
        onClick={() => {
          requestApiHandler(data?.type);
        }}
        disabled={isLoading}
        key={index}
      >
        Request
      </Button>
    </Box>
  );
}
