import React, { useEffect, useState } from "react";
import {
  makeStyles,
  Dialog,
  DialogContent,
  IconButton,
  FormControl,
  Typography,
  TextField,
  Box,
  Button,
  Grid,
  Avatar,
} from "@material-ui/core";
import { AiOutlineCamera } from "react-icons/ai";
import { IoClose } from "react-icons/io5";
import FormHelperText from "@material-ui/core/FormHelperText";
import "react-phone-input-2/lib/style.css";
import { dataPostHandler, putAPIHandler } from "src/apiConfig/service";
import ButtonCircularProgress from "./ButtonCircularProgress";
import { validUrl } from "src/utils";
import { KeyboardDateTimePicker } from "@material-ui/pickers";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  cancelBtn1: {
    background: "#000000",
    top: "6px",
    right: "10px",
    position: "absolute",
    "&:hover": {
      backgroundColor: "#4057a7 !important",
    },
  },
  mainmodalBox: {
    "& .formControl": {
      width: "100%",
      backgroundColor: "transparent",
      border: "none",
      color: "#fff",
      "&:focus-visible": {
        outline: "none",
      },
    },
    "& .helpText": {
      textAlign: "end",
      marginTop: "0px",
    },
    "& h6": {
      fontWeight: "700",
    },
    "& p": {
      color: "rgba(0, 0, 0, 0.4)",
      background: "#fff",
    },

    "& button": {
      height: "42px",
    },
  },
  cancelBtn: {
    position: "absolute",
    top: "5px",
    right: 0,
    "& svg": {
      color: "#fff",
      fontWeight: "700",
    },
    "& .MuiIconButton-root:hover": {
      backgroundColor: "#4057a7!important",
    },
  },
  commentControl: {
    "& .MuiInputBase-input": {
      background: "#fff",
    },
    "& .MuiInput-root": {
      backgroundColor: "#fff",
    },
  },

  coverPic: {
    background: "#f4f4f4",
    paddingBottom: "180px",
    "& .MuiAvatar-root": {
      width: "100%",
      height: "180px",
      borderRadius: "0",
    },
    "& .profile1": {
      position: "absolute",
      width: "100%",
      zIndex: 111,
    },
  },
  inputBox: {
    textAlign: "center",
    paddingTop: "24px",
    paddingBottom: "10px",
  },
  dateControl: {
    background: "#fff",
    "& .MuiInputBase-input": {
      backgroundColor: "#fff !important",
    },
    "& .MuiInput-root": {
      backgroundColor: "#fff !important",
    },
  },
}));

export const getBase64 = (file, cb) => {
  let reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = function () {
    cb(reader.result);
  };
  reader.onerror = function (err) {
    // console.log("Error: ", err);
  };
};

function PollModal({
  openCreateEvent,
  handleCloseCreateEvent,
  userData,
  listPostHandler,
  type,
  eventId,
}) {
  const classes = useStyles();
  const [petImage, setPetImage] = useState("");
  const [imageAndVideo1, setImageAndVideo1] = useState("");
  const [profileImage64, setProfileImage64] = useState(
    userData?.profilePic ? userData?.profilePic : ""
  );
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [createEvent, setCreateEvent] = useState({
    eventName: "",
    startDate: null,
    endDate: null,
    eventLink: "",
    description: "",
    speakers: "",
  });
  useEffect(() => {
    if (eventId) {
      setProfileImage64(eventId?.eventPost?.image);
      setImageAndVideo1(eventId?.eventPost?.image);
      setCreateEvent({
        eventName: eventId?.eventPost?.eventName,
        startDate: eventId?.eventPost?.startDate,
        endDate: eventId?.eventPost?.endDate,
        eventLink: eventId?.eventPost?.eventLink,
        description: eventId?.eventPost?.description,
        speakers: eventId?.eventPost?.speaker,
      });
    }
  }, [eventId]);

  const createEventHandler = async () => {
    const formData = new FormData();
    formData.append("eventName", createEvent.eventName);
    formData.append("eventImage", imageAndVideo1);
    formData.append("postType", "EVENT");
    formData.append("startDate", createEvent.startDate);
    formData.append("endDate", createEvent.endDate);
    formData.append("eventLink", createEvent.eventLink);
    formData.append("speaker", createEvent.speakers);
    formData.append("description", createEvent.description);
    if (
      createEvent.eventName !== "" &&
      createEvent.startDate !== null &&
      createEvent.endDate !== null &&
      createEvent.eventLink !== "" &&
      createEvent.speakers !== "" &&
      createEvent.description !== "" &&
      imageAndVideo1 !== "" &&
      validUrl(createEvent.eventLink)
    ) {
      try {
        setIsLoading(true);
        const CheckApi =
          type === "eventUpdate"
            ? putAPIHandler("editPost", formData, { id: eventId?._id })
            : dataPostHandler("addPost", formData);
        const res = await CheckApi;
        if (res) {
          setIsLoading(false);
          setImageAndVideo1("");
          listPostHandler();
          setCreateEvent({
            eventName: "",
            startDate: null,
            endDate: null,
            eventLink: "",
            description: "",
            speakers: "",
          });
          setPetImage("");
          setProfileImage64("");
          handleCloseCreateEvent();
        } else {
          setIsLoading(false);
        }
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    } else {
      setIsSubmit(true);
    }
  };
  return (
    <Dialog
      open={openCreateEvent}
      onClose={() => {
        if (!isLoading) {
          handleCloseCreateEvent();
          setPetImage("");
          setCreateEvent({
            eventName: "",
            startDate: null,
            endDate: null,
            eventLink: "",
            description: "",
            speakers: "",
          });
          setProfileImage64("");
          setImageAndVideo1("");
        }
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="xs"
      fullWidth
      className={classes.root}
    >
      <Box className="bgcolorbox">
        <Box>
          <Typography variant="h5">
            {type === "eventUpdate" ? " Update an event" : "Create an event"}
          </Typography>
        </Box>
      </Box>
      <Box className={classes.coverPic}>
        <Box style={{ position: "relative" }}>
          <>
            <input
              style={{ display: "none" }}
              type="file"
              disabled={isLoading}
              id="raised-button-filee"
              accept="image/*"
              onChange={(e) => {
                setPetImage(URL.createObjectURL(e.target.files[0]));
                setImageAndVideo1(e.target.files[0]);
                getBase64(e.target.files[0], (result) => {
                  setProfileImage64(result);
                });
              }}
            />
            <Box
              style={{
                position: "absolute",
                width: "100%",
                height: "100%",
                top: "0px",
              }}
            >
              {profileImage64 === "" ? (
                <>
                  <label htmlFor="raised-button-filee">
                    <Box
                      style={{
                        cursor: "pointer",
                        textAlign: "center",
                        padding: "44px 0px 34px 0px",
                      }}
                    >
                      <AiOutlineCamera
                        style={{ color: "#000", fontSize: "60px" }}
                      />
                      <Typography
                        variant="h5"
                        style={{ color: "rgba(50, 50, 50, 0.8)" }}
                      >
                        Upload cover image
                      </Typography>
                      <Typography
                        variant="h5"
                        style={{
                          color: "rgba(50, 50, 50, 0.6)",
                          fontWeight: "400",
                        }}
                      >
                        Size recommendation 1200x400px
                      </Typography>
                    </Box>
                  </label>
                </>
              ) : (
                <>
                  <Box className="profile1">
                    <Avatar src={profileImage64 ? profileImage64 : <></>} />
                    <IconButton
                      disabled={isLoading}
                      size="small"
                      className={classes.cancelBtn1}
                      onClick={() => {
                        setPetImage("");
                        setProfileImage64("");
                        setImageAndVideo1("");
                      }}
                    >
                      <IoClose color="#ffffff" />
                    </IconButton>
                  </Box>
                </>
              )}
            </Box>
          </>
        </Box>
      </Box>
      {isSubmit && imageAndVideo1 === "" && (
        <FormHelperText style={{ marginLeft: "23px" }} error>
          Please select a image file.
        </FormHelperText>
      )}
      <DialogContent>
        <IconButton
          className={classes.cancelBtn}
          disabled={isLoading}
          onClick={() => {
            handleCloseCreateEvent();
            setCreateEvent({
              eventName: "",
              startDate: null,
              endDate: null,
              eventLink: "",
              description: "",
              speakers: "",
            });
            setPetImage("");
            setProfileImage64("");
            setImageAndVideo1("");
          }}
        >
          <IoClose />
        </IconButton>

        <Box className={classes.mainmodalBox}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <Box>
                <Typography variant="body2">Event name</Typography>
              </Box>
              <Box>
                <FormControl fullWidth className={classes.commentControl}>
                  <TextField
                    fullWidth
                    disabled={isLoading}
                    placeholder="E.g., 3D World"
                    value={createEvent.eventName}
                    onChange={(e) => {
                      if (e.target.value.length <= 256) {
                        setCreateEvent({
                          ...createEvent,
                          ["eventName"]: e.target.value,
                        });
                      }
                    }}
                    error={isSubmit && createEvent.eventName === ""}
                    helperText={
                      isSubmit &&
                      createEvent.eventName === "" &&
                      "Please enter event name."
                    }
                  />
                </FormControl>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box>
                <Typography variant="body2">Start date</Typography>
              </Box>
              <FormControl className={classes.dateControl} fullWidth>
                <KeyboardDateTimePicker
                  format="DD/MM/YYYY h:mm"
                  placeholder="DD/MM/YYYY H:MM"
                  disablePast
                  InputProps={{ readOnly: true }}
                  fullWidth
                  disabled={isLoading}
                  value={createEvent.startDate}
                  onChange={(date) => {
                    setCreateEvent({
                      ...createEvent,
                      ["startDate"]: new Date(date),
                    });
                  }}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  error={isSubmit && createEvent.startDate === null}
                  helperText={
                    isSubmit &&
                    createEvent.startDate === null &&
                    "Please select start date."
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl className={classes.dateControl} fullWidth>
                <Box>
                  <Typography variant="body2">End date</Typography>
                </Box>
                <KeyboardDateTimePicker
                  format="DD/MM/YYYY h:mm"
                  placeholder="DD/MM/YYYY H:MM"
                  fullWidth
                  value={createEvent?.endDate}
                  InputProps={{ readOnly: true }}
                  onChange={(date) =>
                    setCreateEvent({
                      ...createEvent,
                      ["endDate"]: new Date(date),
                    })
                  }
                  disabled={!createEvent?.startDate || isLoading}
                  minDate={createEvent?.startDate}
                  minTime={createEvent?.startDate}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  error={isSubmit && createEvent.endDate === null}
                  helperText={
                    isSubmit &&
                    createEvent.endDate === null &&
                    "Please select end date."
                  }
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={12}>
              <Box>
                <Typography variant="body2">External event link</Typography>
              </Box>
              <Box>
                <FormControl fullWidth className={classes.commentControl}>
                  <TextField
                    fullWidth
                    disabled={isLoading}
                    placeholder="E.g., http//:"
                    value={createEvent.eventLink}
                    onChange={(e) => {
                      setCreateEvent({
                        ...createEvent,
                        ["eventLink"]: e.target.value,
                      });
                    }}
                    error={
                      (isSubmit && createEvent.eventLink === "") ||
                      (isSubmit &&
                        createEvent.eventLink !== "" &&
                        !validUrl(createEvent.eventLink))
                    }
                    helperText={
                      (isSubmit &&
                        createEvent.eventLink === "" &&
                        "Please enter external event link.") ||
                      (isSubmit &&
                        createEvent.eventLink !== "" &&
                        !validUrl(createEvent.eventLink) &&
                        "Please enter valid event link.")
                    }
                    inputProps={{ maxLength: 100 }}
                  />
                  <FormHelperText className="helpText">
                    {createEvent.eventLink.length}/100
                  </FormHelperText>
                </FormControl>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Box>
                <Typography variant="body2">Description</Typography>
              </Box>
              <Box>
                <FormControl fullWidth className={classes.commentControl}>
                  <TextField
                    fullWidth
                    disabled={isLoading}
                    placeholder="E.g., topics,schedule,etc."
                    value={createEvent.description}
                    onChange={(e) => {
                      if (e.target.value.length <= 256) {
                        setCreateEvent({
                          ...createEvent,
                          ["description"]: e.target.value,
                        });
                      }
                    }}
                    error={isSubmit && createEvent.description === ""}
                    helperText={
                      isSubmit &&
                      createEvent.description === "" &&
                      "Please enter the description."
                    }
                  />
                  <FormHelperText className="helpText">
                    {createEvent.description.length}/256
                  </FormHelperText>
                </FormControl>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Box>
                <Typography variant="body2">Speakers</Typography>
              </Box>
              <Box>
                <FormControl fullWidth className={classes.commentControl}>
                  <TextField
                    fullWidth
                    disabled={isLoading}
                    value={createEvent.speakers}
                    onChange={(e) => {
                      if (e.target.value.length <= 50) {
                        setCreateEvent({
                          ...createEvent,
                          ["speakers"]: e.target.value,
                        });
                      }
                    }}
                    error={isSubmit && createEvent.speakers === ""}
                    helperText={
                      isSubmit &&
                      createEvent.speakers === "" &&
                      "Please enter speaker name."
                    }
                    inputProps={{ maxLength: 50 }}
                  />
                </FormControl>
              </Box>
            </Grid>
          </Grid>

          <Box className={classes.inputBox}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              disabled={isLoading}
              onClick={createEventHandler}
            >
              {type === "eventUpdate" ? "Update" : "Create"}
              {isLoading && <ButtonCircularProgress />}
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default PollModal;
