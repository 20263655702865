import { Box, Avatar, IconButton, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { AvatarGroup } from "@material-ui/lab";
import { AiFillHeart, AiOutlineHeart } from "react-icons/ai";
import { RiSendPlaneLine } from "react-icons/ri";
import { FaRegComment } from "react-icons/fa";
import {
  patchAPIHandlerPathId,
  postDataIdinQuery,
} from "src/apiConfig/service";
import { toast } from "react-toastify";
import ShareSocialMedia from "../ShareSocialMedia";
export default function UserListFeed({
  classes,
  viewData,
  listPostHandler,
  auth,
  type,
}) {
  const [openShare, setOpenShare] = React.useState(false);
  const [isLoadingLike, setIsLoadingLike] = useState(false);

  const addLikeHandler = async (id) => {
    try {
      setIsLoadingLike(true);
      const res = await postDataIdinQuery("addLike", {
        postId: id,
      });
      if (res?.responseCode === 200) {
        if (viewData?.postType === "NFT") {
          await patchAPIHandlerPathId("addToFav", id);
        }
        listPostHandler();
        setIsLoadingLike(false);
      } else {
        setIsLoadingLike(false);
      }
    } catch (error) {
      setIsLoadingLike(false);
    }
  };

  const handleClickOpenShare = () => {
    setOpenShare(true);
  };

  const handleCloseShare = () => {
    setOpenShare(false);
  };
  return (
    <>
      <Box mb={1} className="shareBox">
        <Box display="flex" alignItems="center">
          {viewData?.likeHistory && viewData?.likeHistory.length > 0 && (
            <>
              <Box className="avatarGroup">
                <AvatarGroup>
                  {viewData?.likeHistory &&
                    viewData?.likeHistory.slice(0, 3).map((userdata, i) => {
                      return (
                        <Avatar
                          src={
                            userdata?.userId?.profilePic &&
                            userdata?.userId?.profilePic
                          }
                          className="avatar"
                          style={
                            i === 0
                              ? { zIndex: 0 }
                              : i === 1
                              ? { zIndex: 1 }
                              : { zIndex: 3 }
                          }
                        />
                      );
                    })}
                  {viewData?.likeHistory?.length < 0 && (
                    <Avatar
                      src={
                        viewData?.userId?.profilePic &&
                        viewData?.userId?.profilePic
                      }
                      className="avatar"
                    />
                  )}
                </AvatarGroup>
              </Box>
              <Box ml={1}>
                <Typography variant="body1">
                  {viewData?.likeHistory?.length > 3 ? (
                    <>
                      {viewData?.likeHistory[0]?.userId?.firstName}
                      {viewData?.likeHistory?.length > 3 &&
                        viewData?.likeHistory?.length - 3}{" "}
                      more liked this
                    </>
                  ) : (
                    <>
                      {viewData?.likeHistory &&
                        viewData?.likeHistory.slice(0, 3).map((username) => {
                          return (
                            <>
                              {username?.userId?.firstName
                                ? username?.userId?.firstName
                                : "Anonymous"}{" "}
                            </>
                          );
                        })}{" "}
                      liked this
                    </>
                  )}
                </Typography>
              </Box>
            </>
          )}
        </Box>

        <Box display="flex" alignItems="center">
          {type !== "event" && (
            <Box display="flex" alignItems="center">
              <IconButton
                disabled={isLoadingLike}
                onClick={() =>
                  auth?.userData?.kycStatus === "APPROVE"
                    ? addLikeHandler(viewData?._id)
                    : toast.warn(
                        "Please complete your KYC to access this feature."
                      )
                }
                style={{ padding: "5px" }}
              >
                {isLoadingLike ? (
                  "..."
                ) : (
                  <>
                    {viewData?.islike ? (
                      <AiFillHeart
                        style={viewData?.islike ? { color: "red" } : {}}
                      />
                    ) : (
                      <AiOutlineHeart
                        style={
                          viewData?.islike === true ? { color: "red" } : {}
                        }
                      />
                    )}
                  </>
                )}
              </IconButton>
              <Typography variant="h6" style={{ fontSize: "12px" }}>
                {viewData?.likes_no}
              </Typography>
            </Box>
          )}

          <Box
            display="flex"
            alignItems="center"
            style={{ margin: "0px 20px" }}
          >
            <IconButton style={{ padding: "5px" }}>
              <FaRegComment color="#FFFFFF" />
            </IconButton>
            <Typography variant="h6" style={{ fontSize: "12px" }}>
              {viewData?.comments?.length}
            </Typography>
          </Box>
          <Box>
            <IconButton
              style={{ padding: "5px" }}
              onClick={() =>
                auth?.userData?.kycStatus === "APPROVE"
                  ? handleClickOpenShare()
                  : toast.warn(
                      "Please complete your KYC to access this feature."
                    )
              }
            >
              <RiSendPlaneLine color="#FFFFFF" />
            </IconButton>
          </Box>
        </Box>
        {openShare && (
          <ShareSocialMedia
            openShare={openShare}
            setOpenShare={setOpenShare}
            url={`${window.location.origin}/post-detail?${viewData?._id}`}
          />
        )}
      </Box>
    </>
  );
}
