import React, { useState } from "react";
import {
  makeStyles,
  Dialog,
  DialogContent,
  IconButton,
  FormControl,
  Typography,
  TextField,
  Box,
  Button,
  Avatar,
  FormHelperText,
  InputAdornment,
} from "@material-ui/core";
import { toeknAddress, tokenName } from "src/constants";
import { IoClose } from "react-icons/io5";
import SuccessfullModal from "./SuccessfullModal";
import "react-phone-input-2/lib/style.css";
import { handleNegativeValue } from "src/utils";
import TokenABI from "src/ABI/TokenABI.json";
import ButtonCircularProgress from "src/component/ButtonCircularProgress";
import web3 from "web3";
import { getContract } from "src/utils";
import { toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& .MuiDialog-paperWidthXs": {
      width: "100%",
      maxWidth: "398px",
    },
  },
  mainmodalBox: {
    "& h5": {
      fontWeight: "700",
      color: "#4057A7",
    },
    "& .typoBox1": {
      paddingTop: "10px",
      paddingBottom: "16px",
      display: "flex",
      justifyContent: "center",
      "& p": {
        width: "100%",
        maxWidth: "93%",
      },
    },
    "& p": {
      color: "rgba(50, 50, 50, 0.8)",
    },
  },
  commentControl: {
    "& .MuiInputBase-input": {
      background: "#fff",
    },
    "& .MuiInput-root": {
      backgroundColor: "#fff",
    },
  },
  cancelBtn: {
    position: "absolute",
    top: 0,
    right: 0,
    "& svg": {
      color: "#fff",
      fontWeight: "700",
    },
  },

  videoBox: {
    display: "flex",
    justifyContent: "center",
    "& .MuiAvatar-root": {
      width: "160px",
      height: "120px",
      borderRadius: "5px",
    },
  },
  threeBox: {
    textAlign: "center",
    marginTop: "8px",
  },
}));

function PollModal({ handleOpenGift, handleCloseGift, data, auth }) {
  const classes = useStyles();
  const [handleOpen, setHandleOpen] = React.useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [tokenPrice, setTokenPrice] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const transferTokenFun = async () => {
    try {
      setIsLoading(true);
      const contractObjToken = getContract(
        toeknAddress,
        TokenABI,
        auth?.library,
        auth?.account
      );
      const toSrngpr = tokenPrice.toString();
      const transferToken = await contractObjToken.transfer(
        data?.userId?.walletAddress,
        web3.utils.toWei(toSrngpr, "ether")
      );
      await transferToken.wait();
      setIsLoading(false);
      toast.success("Token transfer successfully");
      setHandleOpen(true);
      handleCloseGift(false);
    } catch (error) {
      console.log(error);
      toast.error(error.message);
      setIsLoading(false);
    }
  };

  return (
    <>
      <Dialog
        open={handleOpenGift}
        onClose={!isLoading && handleCloseGift}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="xs"
        fullWidth
        className={classes.root}
      >
        <Box className="bgcolorbox">
          <Box>
            <Typography variant="h5">Gift</Typography>
          </Box>
        </Box>
        <DialogContent>
          <IconButton
            className={classes.cancelBtn}
            onClick={!isLoading && handleCloseGift}
          >
            <IoClose />
          </IconButton>

          <Box className={classes.mainmodalBox}>
            <Box className={classes.videoBox} align="center">
              {data?.mediaType?.split("/")[0] === "image" ? (
                <Avatar
                  src={
                    data?.mediaFile ? data?.mediaFile : "/images/Buyvideo.png"
                  }
                />
              ) : (
                <video
                  controls={false}
                  autoplay={false}
                  loop
                  muted
                  playsinline="true"
                  width="160px"
                  height="120px"
                >
                  <source
                    src={data && data?.mediaFile}
                    type="video/mp4"
                    accept="video/*"
                  />
                </video>
              )}
            </Box>

            <Box className="typoBox1" mt={1}>
              <Typography variant="body2">{data?.tokenName}</Typography>
            </Box>

            <Box className="eventBox">
              <Box>
                <Typography variant="body2">Token Send</Typography>
              </Box>
              <Box>
                <FormControl fullWidth className={classes.commentControl}>
                  <TextField
                    fullWidth
                    placeholder="Enter token amount"
                    type="number"
                    value={tokenPrice}
                    disabled={isLoading}
                    onKeyPress={(event) => {
                      if (
                        event?.key === "-" ||
                        event?.key === "+" ||
                        event?.key === "*" ||
                        event?.key === "/"
                      ) {
                        event.preventDefault();
                      }
                    }}
                    onChange={(e) => setTokenPrice(e.target.value)}
                    onKeyDown={(event) => handleNegativeValue(event)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {tokenName}
                        </InputAdornment>
                      ),
                    }}
                  />
                </FormControl>
              </Box>
            </Box>
            <Box className="eventBox" pt={2}>
              <Box>
                <Typography variant="body2">Send To</Typography>
              </Box>
              <Box>
                <FormControl fullWidth className={classes.commentControl}>
                  <TextField
                    fullWidth
                    disabled
                    placeholder={data?.userId?.walletAddress}
                  />
                </FormControl>
              </Box>
            </Box>
            <Box className={classes.threeBox}>
              <Box py={2}>
                <FormHelperText error className="displayFlexCenter">
                  {isSubmit && tokenPrice === "" && "Please enter token."}
                  {isSubmit &&
                    tokenPrice !== "" &&
                    tokenPrice <= 0 &&
                    "Please enter valid token."}
                  {isSubmit &&
                    Number(auth?.tokenBalance) <= Number(tokenPrice) &&
                    "Your balance is low."}
                </FormHelperText>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={isLoading}
                  onClick={() =>
                    auth?.account
                      ? Number(auth?.tokenBalance) >= Number(tokenPrice) &&
                        tokenPrice !== "" &&
                        tokenPrice >= 0
                        ? (() => {
                            if (data?.userId?.walletAddress !== auth?.account) {
                              setIsSubmit(false);
                              transferTokenFun();
                            } else {
                              toast.warn(
                                "Owners cannot transfer own your address."
                              );
                            }
                          })()
                        : setIsSubmit(true)
                      : auth.setOpenMetamask(true)
                  }
                >
                  {auth?.account ? (
                    <>Gift Now {isLoading && <ButtonCircularProgress />}</>
                  ) : (
                    "Connect Wallet"
                  )}
                </Button>
              </Box>

              <Box pb={3.5}>
                {auth?.account && auth?.tokenBalance && (
                  <Typography variant="body1">
                    Available Balance {auth?.tokenBalance} {tokenName}
                  </Typography>
                )}
              </Box>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
      {handleOpen && (
        <SuccessfullModal
          handleOpen={handleOpen}
          handleclose={() => setHandleOpen(false)}
          data={`Gift ${tokenPrice} ${tokenName} Tokens Successfully`}
        />
      )}
    </>
  );
}

export default PollModal;
