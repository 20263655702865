import { Button, Box } from "@material-ui/core";
import React from "react";

export default function PoolCreate({
  addVoteHandler,
  pollDetails,
  data,
  isLoadingVote,
}) {
  return (
    <div>
      <Box>
        {pollDetails &&
          pollDetails.map((item, i) => {
            return (
              <div mt={0} key={i}>
                <VoteButton
                  item={item}
                  data={data}
                  isLoadingVote={isLoadingVote}
                  addVoteHandler={addVoteHandler}
                  index={i}
                />
              </div>
            );
          })}
      </Box>
    </div>
  );
}
const VoteButton = ({ item, data, isLoadingVote, addVoteHandler }) => {
  return (
    <Button
      onClick={() => addVoteHandler(item?._id, data?._id)}
      variant="contained"
      color="primary"
      size="large"
      className="pullButton"
      disabled={isLoadingVote}
      fullWidth
    >
      {item?.option}
    </Button>
  );
};
