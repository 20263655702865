import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  makeStyles,
  Divider,
  Paper,
  Grid,
  Typography,
} from "@material-ui/core";
import SkillsCard from "./SkillsCard";
import { getDataHandlerAPI } from "src/apiConfig/service";
import DataNotFound from "src/component/DataNotFound";
import JobFilter from "src/component/JobFilter";
import { AuthContext } from "src/context/Auth";
import LoadHistory from "src/Skeletons/LoadHistory";
import { Pagination } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  mainform: {
    "& .MuiOutlinedInput-root": {
      height: "40px",
      borderRadius: "25px",
      backgroundColor: "#f1f1f1",
      paddingLeft: "13px",
    },
    "& .MuiOutlinedInput-input": {
      padding: "0px 0px 0px 10px",
      broderRadius: "25px",
    },
    "& .searchBox": {
      "& .MuiFormControl-root": {
        borderRadius: "50px",
      },
    },
    "& svg": {
      position: "absolute",
      right: "25px",
      fontSize: "17px",
      color: "#AFAFAF",
      cursor: "pointer",
    },
  },
  jobsbox: {
    "& h5": {
      fontSize: "18px",
      paddingBottom: "5px",
    },
  },
  mainBox: {
    border: "1px solid #C8C8C8",
    borderRadius: "5px",
    paddingLeft: "20px",
    "& h6": {
      color: "#7A7A7A",
      fontWeight: "300",
    },
  },
}));

const Job = ({ tabView1 }) => {
  const classes = useStyles();
  const auth = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(true);
  const [jobsPostlistData, setJobsPostlistData] = useState([]);
  const [jobFilter, setJobFilter] = useState({
    search: "",
    exprience: "",
  });
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [filterSta, setFilterSta] = useState(false);

  const getJobPostLIst = async () => {
    try {
      const searchData =
        jobFilter?.search !== "" ? { search: jobFilter?.search } : {};
      const paramsData = {
        ...searchData,
        exprience: jobFilter?.exprience ? jobFilter?.exprience : null,
        lat: auth.address !== "" ? auth?.latLong?.lat : "",
        long: auth.address !== "" ? auth?.latLong?.long : "",
        page: page,
        limit: "15",
      };
      const response = await getDataHandlerAPI("jobList", paramsData);
      if (response) {
        setIsLoading(false);
        setJobsPostlistData(response.docs);
        setPageCount(response.pages);
        setFilterSta(false);
      } else {
        setIsLoading(false);
        setJobsPostlistData([]);
        setFilterSta(false);
      }
    } catch (error) {
      setIsLoading(false);
      setJobsPostlistData([]);
      setFilterSta(false);
    }
  };

  useEffect(() => {
    getJobPostLIst();
  }, [tabView1, auth.createPost, page, filterSta]);

  return (
    <div>
      <Box className="scrollDiv">
        <Box style={{ padding: "60px 10px 0px 15px" }}>
          <Paper className={`${classes.followersBox} paddingBox`} elevation={0}>
            <JobFilter
              jobFilter={jobFilter}
              setJobFilter={setJobFilter}
              setFilterSta={setFilterSta}
              auth={auth}
            />
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Paper
                  className={`${classes.mainBox} paddingBox`}
                  elevation={0}
                >
                  <Box className={classes.jobsbox}>
                    <Typography variant="h5">Recommended for you</Typography>
                    <Typography variant="h6">
                      Based on your profile and search history
                    </Typography>
                  </Box>
                  <Box mt={2}>
                    <Divider className={classes.dividerr} />
                  </Box>
                  {isLoading ? (
                    <>
                      {[1].map((data, i) => {
                        return <LoadHistory />;
                      })}
                    </>
                  ) : (
                    <>
                      {jobsPostlistData &&
                        jobsPostlistData.map((data, index) => {
                          if (index != 0) {
                            return;
                          }
                          return (
                            <SkillsCard
                              data={data}
                              index={index}
                              type="recommend"
                              callBack={() => getJobPostLIst()}
                            />
                          );
                        })}

                      {jobsPostlistData && jobsPostlistData.length === 0 && (
                        <DataNotFound />
                      )}
                    </>
                  )}
                </Paper>
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={6}>
                {isLoading ? (
                  <>
                    {[1, 2, 3].map((data, i) => {
                      return <LoadHistory />;
                    })}
                  </>
                ) : (
                  <>
                    {jobsPostlistData &&
                      jobsPostlistData?.filter((_, index) => index % 2 == 0)
                        .length != 0 && (
                        <Paper
                          className={`${classes.mainBox} paddingBox`}
                          elevation={0}
                        >
                          <Box className={classes.jobsbox}>
                            <Typography variant="h5">
                              More jobs for you
                            </Typography>
                            <Typography variant="h6">
                              Based on your profile and search history
                            </Typography>
                          </Box>
                          {jobsPostlistData &&
                            jobsPostlistData
                              ?.filter((_, index) => index % 2 == 0)
                              ?.map((data, index) => {
                                return (
                                  <SkillsCard
                                    data={data}
                                    index={index}
                                    callBack={() => getJobPostLIst()}
                                  />
                                );
                              })}
                        </Paper>
                      )}
                  </>
                )}
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={6}>
                {isLoading ? (
                  <>
                    {[1, 2, 3].map((data, i) => {
                      return <LoadHistory />;
                    })}
                  </>
                ) : (
                  <>
                    {jobsPostlistData &&
                      jobsPostlistData?.filter((_, index) => index % 2 !== 0)
                        .length != 0 && (
                        <Paper
                          className={`${classes.mainBox} paddingBox`}
                          elevation={0}
                        >
                          {jobsPostlistData &&
                            jobsPostlistData
                              ?.filter((_, index) => index % 2 !== 0)
                              ?.map((data, index) => {
                                return (
                                  <SkillsCard
                                    data={data}
                                    index={index}
                                    callBack={() => getJobPostLIst()}
                                  />
                                );
                              })}
                        </Paper>
                      )}
                  </>
                )}
              </Grid>
              {!isLoading &&
                jobsPostlistData &&
                jobsPostlistData.length >= (page === 1 ? 15 : 0) && (
                  <Pagination
                    variant="outlined"
                    shape="rounded"
                    count={pageCount}
                    page={page}
                    onChange={(e, v) => setPage(v)}
                  />
                )}
            </Grid>
          </Paper>
        </Box>
      </Box>
    </div>
  );
};

export default Job;
