import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  makeStyles,
  Typography,
  IconButton,
  Dialog,
  DialogContent,
  FormControl,
  TextField,
  InputAdornment,
  Grid,
  Divider,
  Avatar,
  Button,
} from "@material-ui/core";
import "react-datepicker/dist/react-datepicker.css";
import { IoSearchOutline } from "react-icons/io5";
import useDebounce from "src/customHook/Debounce";
import { getParticularObjectDataHanndler } from "src/apiConfig/service";
import CommentListSkeleton from "src/Skeletons/CommentListSkeleton";
import DataNotFound from "src/component/DataNotFound";
import RequestModal from "./RequestModal";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  DialogClass: {
    " & .MuiDialog-paperFullWidth": {
      borderRadius: "30px",
    },
  },
  commentControl: {
    "& .MuiInputBase-input": {
      background: "#fff",
    },
    "& .MuiInput-root": {
      backgroundColor: "#fff",
    },
  },
  recentSearchBox: {
    display: "flex",
    alignItems: "center",
  },
  mainBox: {
    "& h6, svg": {
      color: "#A9A9A9",
      fontWeight: "300",
    },
    "& .textClx": {
      width: "360px",
      "@media(max-width:636px)": {
        width: "200px",
      },
      "@media(max-width:420px)": {
        width: "150px",
      },
    },
  },
  mapingbox1: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& .ImageBox": {
      display: "flex",
      alignItems: "center",
      "& .MuiAvatar-root": {
        width: "50px",
        height: "50px",
      },
    },
    "& .TextBox": {
      marginLeft: "10px",
    },
    "& p": {
      color: "#BFBFBF",
    },
  },
  cancelBtn: {
    position: "absolute",
    top: 0,
    right: 0,
    "& svg": {
      color: "#fff",
      fontWeight: "700",
    },
  },
}));

function SearchModal({ openSearch, handleSearchClose }) {
  const classes = useStyles();
  const [search, setSearch] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const deb = useDebounce(search, 1000);
  const [userListData, setUserListData] = useState([]);

  const globalSearch = async () => {
    try {
      const response = await getParticularObjectDataHanndler("search", {
        search: search ? search : "",
      });
      if (response.responseCode === 200) {
        setIsLoading(false);
        setUserListData(response.result);
      } else {
        setIsLoading(false);
        setUserListData([]);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      setUserListData([]);
    }
  };

  useEffect(() => {
    globalSearch();
  }, [deb]);

  return (
    <Box>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={openSearch}
        keepMounted
        onClose={handleSearchClose}
        className={classes.DialogClass}
      >
        <DialogContent>
          <Box className={classes.mainBox}>
            <FormControl fullWidth className={classes.commentControl}>
              <TextField
                placeholder="Search"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      <IconButton edge="start">
                        <IoSearchOutline />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
            <Box
              style={{
                maxHeight: "600px",
                overflow: "auto",
                marginTop: "10px",
              }}
            >
              {userListData &&
                userListData?.users?.map((data, index) => {
                  return (
                    <>
                      <Grid item xs={12} key={index}>
                        <UsersList
                          data={data}
                          index={index}
                          classes={classes}
                          handleSearchClose={handleSearchClose}
                          globalSearch={globalSearch}
                        />
                        <Box>
                          <Divider />
                        </Box>
                      </Grid>
                    </>
                  );
                })}

              {isLoading &&
                [1, 2, 3].map((tem) => {
                  return (
                    <Box width="100%">
                      <CommentListSkeleton />
                    </Box>
                  );
                })}
              {!isLoading && userListData && userListData.length === 0 && (
                <DataNotFound />
              )}
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
}

export default SearchModal;

const UsersList = ({ classes, data, globalSearch, handleSearchClose }) => {
  const [followModal, setFollowModal] = useState(false);
  const [userData, setUserData] = useState(false);
  const history = useHistory();
  return (
    <Box>
      <Box className={classes.mapingbox1} py={1.2}>
        <Box className="ImageBox">
          <Avatar
            src={data?.profilePic}
            alt={data?.firstName}
            style={{ cursor: "pointer" }}
            onClick={() => {
              history.push({
                pathname: "/other-profile",
                search: data?._id,
                state: {
                  id: data?._id,
                },
              });
              handleSearchClose();
            }}
          />
          <Box
            className="TextBox"
            style={{ cursor: "pointer" }}
            onClick={() => {
              history.push({
                pathname: "/other-profile",
                search: data?._id,

                state: {
                  id: data?._id,
                },
              });
              handleSearchClose();
            }}
          >
            <Typography
              variant="body2"
              style={{ fontWeight: "700", color: "#323232" }}
              className="textEllipsis textClx"
            >
              {data?.firstName}&nbsp;{data?.lastName}
            </Typography>
            <Typography
              variant="body1"
              style={{ fontSize: "12px", color: "#929292" }}
              className="textEllipsis textClx"
            >
              {data?.email}
            </Typography>
          </Box>
        </Box>
        <Button
          variant="contained"
          size="small"
          color="primary"
          onClick={() => {
            setFollowModal(true);
            setUserData(data);
          }}
          disabled={data?.isRequested || data?.isFollowed}
          style={{ padding: "0 15px" }}
        >
          {data?.isRequested
            ? "Requested"
            : data?.isFollowed
            ? "Followed"
            : "Follow"}
        </Button>
      </Box>
      <RequestModal
        handleCloseModal={() => setFollowModal(false)}
        followModal={followModal}
        userData={userData}
        globalSearch={globalSearch}
      />
    </Box>
  );
};
