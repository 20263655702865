import React from 'react'

const MyNetwork = ({ className }) => {
  return (
    <svg
      width="20"
      height="16"
      viewBox="0 0 20 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_876_991)">
        <path
          d="M20 15.4378C19.9859 15.4563 19.9677 15.473 19.9586 15.4934C19.7073 16.0508 19.0814 16.1694 18.626 15.7428C18.4166 15.547 18.216 15.3426 18.0077 15.1458C17.7999 14.9497 17.5874 14.7584 17.3736 14.562C15.3711 15.6461 13.525 14.7409 12.7093 13.6643C11.6947 12.3258 11.8775 10.483 13.0679 9.34339C14.2362 8.22492 16.1009 8.05798 17.4559 8.91235C18.8012 9.76049 19.6166 11.6743 18.4817 13.5347C18.8273 13.8469 19.1953 14.1629 19.5414 14.4995C19.7148 14.6682 19.8484 14.8747 20 15.0641C20 15.1887 20 15.3132 20 15.4378ZM17.9817 11.689C17.9528 10.3597 16.8556 9.34364 15.4829 9.37454C14.1542 9.40444 13.0591 10.4736 13.0929 11.7077C13.1294 13.0399 14.238 14.0846 15.5787 14.0503C16.9301 14.0156 18.0093 12.9552 17.9817 11.689Z"
          fill="white"
        />
        <path
          d="M8.39933 2.6397C10.4885 2.6397 12.5779 2.64244 14.667 2.63795C15.2819 2.63671 15.8155 2.80713 16.252 3.23419C16.3489 3.32887 16.3733 3.42156 16.3522 3.55062C16.1926 4.52309 15.6444 5.27555 14.8879 5.90293C13.7649 6.83429 12.4349 7.35827 10.9999 7.65801C9.7262 7.92386 8.43969 7.98516 7.14042 7.85933C5.82162 7.73151 4.55907 7.42281 3.37726 6.84301C2.37279 6.35018 1.49385 5.71432 0.90242 4.76777C0.656056 4.37335 0.496675 3.94853 0.441204 3.49232C0.432349 3.41932 0.471153 3.3199 0.524019 3.26434C0.953984 2.81212 1.50114 2.63621 2.13163 2.6377C4.22078 2.64294 6.31018 2.6397 8.39933 2.6397Z"
          fill="white"
        />
        <path
          d="M11.5927 12.4172C11.5169 12.4172 11.4534 12.4172 11.3896 12.4172C8.29128 12.4172 5.19298 12.4062 2.09468 12.4234C1.08839 12.4289 0.283675 11.8065 0.0620519 10.9893C0.0263734 10.8582 0.00319544 10.7197 0.00293501 10.5846C-0.000710965 8.72265 7.03153e-05 6.86069 7.03153e-05 4.99872C7.03153e-05 4.96957 0.00319544 4.94042 0.00736227 4.86816C0.0818444 4.99249 0.132888 5.08219 0.188099 5.1694C0.827186 6.17799 1.74207 6.89856 2.8119 7.45294C4.02002 8.07908 5.31747 8.43687 6.67585 8.60381C6.70788 8.60779 6.74018 8.61327 6.77143 8.621C6.78992 8.62548 6.80685 8.63545 6.83055 8.64542C6.84279 8.90379 6.84669 9.16441 6.86961 9.42354C6.92612 10.0639 7.4975 10.5849 8.17227 10.621C8.49858 10.6384 8.82437 10.6459 9.12725 10.5017C9.6843 10.2365 9.93874 9.79005 9.95775 9.20851C9.96374 9.02239 9.95879 8.83602 9.95879 8.64093C10.339 8.5769 10.7101 8.52457 11.0768 8.45057C12.7438 8.11446 14.2751 7.50277 15.5564 6.39725C16.0088 6.00707 16.3885 5.55858 16.6843 5.04731C16.7114 5.00047 16.74 4.95462 16.7901 4.8719C16.7901 5.95524 16.7901 6.99573 16.7901 8.04743C15.3561 7.67868 14.042 7.87925 12.9149 8.82232C11.7888 9.76439 11.3865 10.976 11.5927 12.4172Z"
          fill="white"
        />
        <path
          d="M4.96704 2.14212C5.02616 1.20952 5.84989 0.305573 6.82597 0.0815785C6.98926 0.0442046 7.15801 0.0105682 7.32469 0.00882404C8.04008 0.00085095 8.75573 -0.00587635 9.47086 0.00807656C10.6485 0.0309992 11.7376 1.01294 11.8275 2.14038C11.4978 2.14038 11.1691 2.14387 10.841 2.13515C10.8074 2.13415 10.7613 2.06439 10.7454 2.01879C10.5204 1.3802 9.99901 1.01269 9.29534 1.0087C8.69687 1.00521 8.09867 1.00521 7.50021 1.0087C6.7981 1.01294 6.28011 1.3792 6.04807 2.01879C6.02984 2.06887 5.96109 2.13565 5.91369 2.13714C5.603 2.14736 5.29179 2.14212 4.96704 2.14212Z"
          fill="white"
        />
        <path
          d="M8.41538 8.79483C8.61044 8.79508 8.80602 8.78785 9.0003 8.80031C9.04588 8.8033 9.11906 8.86908 9.12401 8.91168C9.13885 9.0405 9.13729 9.1733 9.12192 9.30237C9.0826 9.63126 8.85629 9.8241 8.50992 9.83706C8.45158 9.8393 8.39299 9.8393 8.33439 9.83806C7.91563 9.83033 7.68645 9.61631 7.66874 9.2169C7.64999 8.79483 7.64999 8.79483 8.08386 8.79483C8.19428 8.79458 8.3047 8.79458 8.41538 8.79483Z"
          fill="white"
        />
        <path
          d="M14.5515 13.0167C14.1374 13.142 13.9658 12.8198 13.8004 12.5007C13.3741 11.6779 13.7017 10.6055 14.5145 10.1463C15.4218 9.63382 16.5427 9.85084 17.1154 10.6417C17.2706 10.856 17.1782 11.0628 17.1159 11.2611C16.7672 12.3751 15.8547 13.0097 14.5515 13.0167Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_876_991">
          <rect width="20" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default MyNetwork
