import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  makeStyles,
  withStyles,
  Box,
  Grid,
  Paper,
  IconButton,
  Container,
  Dialog,
  DialogContent,
} from "@material-ui/core";
import clsx from "clsx";
import CloseIcon from "@material-ui/icons/Close";
import { Link as RouterComponent, useHistory } from "react-router-dom";
import { GrClose } from "react-icons/gr";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Check from "@material-ui/icons/Check";
import StepConnector from "@material-ui/core/StepConnector";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import StepContent from "./StepContent";
import StepContent2 from "./StepContent2";
import StepContent3 from "./StepContent3";

const useQontoStepIconStyles = makeStyles({
  root: {
    color: "#eaeaf0",
    display: "flex",
    height: 22,
    alignItems: "center",
  },
  active: {
    color: "#784af4",
  },
  circle: {
    width: 8,
    height: 8,
    borderRadius: "50%",
    backgroundColor: "currentColor",
  },
  completed: {
    color: "#784af4",
    zIndex: 1,
    fontSize: 18,
  },
});

function QontoStepIcon(props) {
  const classes = useQontoStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {completed ? (
        <Check className={classes.completed} />
      ) : (
        <div className={classes.circle} />
      )}
    </div>
  );
}

QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   */
  active: PropTypes.bool,
  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed: PropTypes.bool,
};
const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 60,
  },
  active: {
    "& $line": {
      backgroundImage:
        "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
    },
  },
  completed: {
    "& $line": {
      backgroundImage:
        "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
    },
  },
  line: {
    marginTop: " 1% !important",
    borderStyle: "'dashed'",
    overflow: "hidden",
    border: "1px dashed #cccccc",
  },
})(StepConnector);
const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: "#ccc",
    borderRadius: "10px",
    height: "83px",
    width: "87px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: "999",
  },
  active: {
    background: "#4057A7",
    borderRadius: "10px",
    height: "83px",
    width: "87px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: "999",
  },
  completed: {
    backgroundColor: "#4057A7",
  },
});

function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  const icons = {
    1: <img src="/images/steper1.png" alt="" width="40px" />,
    2: <img src="/images/steper2.png" alt="" width="40px" />,
    3: <img src="/images/steper3.png" alt="" width="40px" />,
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
}

ColorlibStepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
  icon: PropTypes.node,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",

    "& h6": {
      color: "#4057A7",
      width: "100%",
      maxWidth: "574px",
      textAlign: "center",
      fontSize: "18px",
      fontWeight: 500,
      paddingBottom: "8px",
      textTransform: "capitalize",
    },
    "& .MuiStepper-root": {
      "@media(max-width:459px)": {
        display: "block",
      },
    },
    "& .MuiStepConnector-alternativeLabel": {
      "@media(max-width:459px)": {
        position: "initial",
      },
    },
    "& .MuiButton-contained.Mui-disabled": {
      boxShadow: " none",
      backgroundColor: "#f50057",
    },
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    color: theme.palette.primary.main,
    "& .MuiPaper-elevation1": {
      boxShadow: "none",
    },
  },
  contactbox: {
    padding: "30px 30px 70px",
    borderRadius: "5px",
    [theme.breakpoints.down("xs")]: {
      padding: "30px 0px 10px",
    },
    "& h4": {
      fontWeight: "300",
      color: theme.palette.primary.main,
      marginBottom: "30px",
      marginTop: "20px",
    },
  },
  buttonboxes: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    maxWidth: "790px",
    margin: "0 auto",
    marginBottom: "16px",
    [theme.breakpoints.down("xs")]: {
      display: "block",
      width: "100%",
      maxWidth: "100%",
    },
    "& button": {
      fontSize: "18px",
      fontWeight: "500",
      margin: "0px 10px",
      marginBottom: "10px",
      [theme.breakpoints.down("xs")]: {
        marginBottom: "10px",
        margin: "0px",
      },
    },
  },
}));

export default function KycSteps({ setkycModal, kycModal, setOpenKycModal }) {
  const classes = useStyles();
  const history = useHistory();
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();
  const [block, setBlcok] = useState(false);
  const [basicDetails, setBasicDetails] = useState({});
  const [iIdDetails, setIdDetails] = useState({});

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  function getSteps() {
    return ["", "", ""];
  }

  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <StepContent
            setBasicDetails={setBasicDetails}
            basicDetails={basicDetails}
            activeStep={activeStep}
            handleBack={handleBack}
            steps={steps}
            setBlcok={setBlcok}
            handleNext={handleNext}
          />
        );
      case 1:
        return (
          <StepContent2
            setIdDetails={setIdDetails}
            iIdDetails={iIdDetails}
            activeStep={activeStep}
            handleBack={handleBack}
            steps={steps}
            setBlcok={setBlcok}
            handleNext={handleNext}
          />
        );
      case 2:
        return (
          <StepContent3
            activeStep={activeStep}
            handleBack={handleBack}
            steps={steps}
            setBlcok={setBlcok}
            handleNext={handleNext}
            basicDetails={basicDetails}
            iIdDetails={iIdDetails}
            setkycModal={setkycModal}
          />
        );
      default:
        return "Unknown step";
    }
  }

  return (
    <div className={classes.root}>
      <Dialog
        open={kycModal}
        onClose={() => setkycModal(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogContent>
          <Box style={{ position: "absolute", top: "0", right: "0" }}>
            <IconButton onClick={() => setkycModal(false)}>
              <CloseIcon style={{ color: "#000" }} />
            </IconButton>
          </Box>
          <Box>
            <Grid container>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Stepper
                  alternativeLabel
                  activeStep={activeStep}
                  connector={<ColorlibConnector />}
                  orientation="horizontal"
                >
                  {steps.map((label, index) => (
                    <Step key={label}>
                      <Typography
                        variant="h6"
                        style={{ color: "#4057A7", textAlign: "center" }}
                      >
                        Step - {index + 1}
                      </Typography>
                      <StepLabel
                        StepIconComponent={ColorlibStepIcon}
                      ></StepLabel>
                    </Step>
                  ))}
                </Stepper>

                <div>
                  {activeStep === steps.length ? (
                    <div>
                      <Box
                        component={Paper}
                        textAlign="center"
                        className={classes.contactbox}
                        elevation={0}
                      >
                        <Typography variant="h4">
                          Your document has been submitted. kindly wait for
                          review
                        </Typography>
                        <Button
                          onClick={() => setActiveStep(0)}
                          className={classes.button}
                          variant="contained"
                          color="secondary"
                          size="large"
                        >
                          Reset
                        </Button>
                      </Box>
                    </div>
                  ) : (
                    <div>
                      <Container>
                        <Box className={classes.instructions}>
                          {getStepContent(activeStep)}
                        </Box>
                      </Container>

                      <Dialog
                        fullWidth="xs"
                        maxWidth="xs"
                        open={block}
                        keepMounted
                        onClose={() => {
                          setkycModal(false);
                          setOpenKycModal(false);
                          setBlcok(false);
                        }}
                        className={classes.DialogClass}
                      >
                        <Box display="flex" justifyContent="end">
                          <IconButton>
                            <GrClose
                              onClick={() => {
                                setkycModal(false);
                                setOpenKycModal(false);
                                setBlcok(false);
                              }}
                            />
                          </IconButton>
                        </Box>
                        <DialogContent>
                          <Box align="center">
                            <img src="images/sucess.png" alt="dumy" />
                          </Box>

                          <Box mt={3} align="center">
                            <Typography
                              variant="h4"
                              style={{ color: "#52A500", maxWidth: "200px" }}
                            >
                              KYC Submitted Successfully
                            </Typography>
                          </Box>
                          <Box mt={3} mb={2} align="center">
                            <Button
                              variant="contained"
                              color="primary"
                              size="large"
                              onClick={() => {
                                setkycModal(false);
                                setOpenKycModal(false);
                                setBlcok(false);
                              }}
                            >
                              Go To KashData
                            </Button>
                          </Box>
                        </DialogContent>
                      </Dialog>
                    </div>
                  )}
                </div>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}
