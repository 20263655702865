import React from 'react'

const Myself = ({ className }) => {
  return (
    <svg
      width="20"
      height="15"
      viewBox="0 0 20 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_876_969)">
        <path
          d="M7.49361 14.9993C5.44163 14.9993 3.38933 14.9996 1.33735 14.999C0.88735 14.999 0.489711 14.871 0.215203 14.4908C0.00194454 14.1954 -0.0288384 13.852 0.0203508 13.5036C0.209808 12.1632 0.908295 11.1252 1.9565 10.3079C2.89268 9.57801 3.97611 9.1813 5.13063 8.96054C6.34386 8.72836 7.56724 8.69691 8.79316 8.80777C10.0058 8.91735 11.1787 9.19051 12.2539 9.78828C13.4868 10.4734 14.3937 11.4434 14.8345 12.8089C14.944 13.1478 15.0132 13.4947 14.9913 13.853C14.9507 14.5159 14.4912 14.9647 13.8241 14.9952C13.6867 15.0015 13.5493 14.9993 13.4115 14.9993C11.4389 14.9993 9.46626 14.9993 7.49361 14.9993Z"
          fill="white"
        />
        <path
          d="M11.246 3.75597C11.2391 5.84787 9.56154 7.50716 7.46196 7.49858C5.41601 7.49032 3.74167 5.78656 3.75151 3.72261C3.76135 1.66407 5.44647 -0.00475425 7.51083 1.01764e-05C9.58788 0.0047746 11.253 1.67899 11.246 3.75597Z"
          fill="white"
        />
        <path
          d="M16.8891 1.24456C17.7085 1.24456 18.5279 1.24328 19.347 1.24519C19.645 1.24583 19.8789 1.4129 19.9649 1.67653C20.049 1.93381 19.9747 2.21745 19.7443 2.36388C19.6225 2.44138 19.4606 2.49252 19.3165 2.49347C17.6885 2.50236 16.0605 2.49919 14.4322 2.49919C14.1558 2.49919 13.9365 2.39532 13.8124 2.13931C13.7007 1.90872 13.7321 1.68542 13.8876 1.48468C14.0196 1.31412 14.2024 1.24456 14.4151 1.24456C15.2399 1.24456 16.0646 1.24456 16.8891 1.24456Z"
          fill="white"
        />
        <path
          d="M16.8656 6.25103C16.0567 6.25103 15.2481 6.25198 14.4392 6.2504C14.106 6.24976 13.8635 6.08491 13.7797 5.80953C13.6547 5.39852 13.9479 5.00625 14.3928 4.99608C14.6464 4.99036 14.9003 4.99481 15.1542 4.99481C16.5391 4.99481 17.924 4.99418 19.3092 4.99513C19.7021 4.99545 19.982 5.24034 19.9972 5.59195C20.0137 5.96866 19.7329 6.24817 19.3238 6.24976C18.5044 6.25326 17.685 6.25103 16.8656 6.25103Z"
          fill="white"
        />
        <path
          d="M18.1256 10.0044C17.7134 10.0044 17.3012 10.0085 16.8892 10.0031C16.5801 9.999 16.3482 9.81636 16.2698 9.53113C16.1993 9.27449 16.3098 8.97941 16.5481 8.84855C16.6503 8.79233 16.7769 8.75421 16.8927 8.75326C17.7118 8.74532 18.5309 8.74564 19.35 8.75072C19.6803 8.75262 19.9221 8.95432 19.9878 9.25988C20.044 9.52065 19.9037 9.81763 19.6508 9.93103C19.5483 9.97708 19.4277 9.99932 19.3147 10.0019C18.9184 10.0095 18.522 10.0044 18.1256 10.0044Z"
          fill="white"
        />
        <path
          d="M18.1142 13.755C17.702 13.755 17.2901 13.7588 16.8778 13.7537C16.5595 13.7496 16.299 13.5231 16.2558 13.2261C16.212 12.9237 16.3834 12.6353 16.6706 12.54C16.7391 12.5172 16.8153 12.5073 16.888 12.5073C17.7121 12.5051 18.5363 12.5032 19.3608 12.5073C19.6819 12.5089 19.9066 12.6922 19.9815 12.9923C20.0469 13.2544 19.9193 13.5507 19.668 13.6727C19.5674 13.7216 19.4468 13.7489 19.3344 13.7515C18.9282 13.7607 18.5211 13.7553 18.1142 13.755Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_876_969">
          <rect width="20" height="15" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default Myself
