import React, { useState, useEffect } from "react";
import {
  Box,
  TextField,
  Grid,
  FormControl,
  Select,
  MenuItem,
  Typography,
  Button,
  FormHelperText,
  makeStyles,
  IconButton,
} from "@material-ui/core";
import Dropzone from "react-dropzone-uploader";
import { BsUpload } from "react-icons/bs";
import { Form, Formik } from "formik";
import * as yep from "yup";
import { MdDelete } from "react-icons/md";

const useStyles = makeStyles((theme) => ({
  field: {
    "& input": {
      fontSize: "13px",
      fontWeight: "400",
    },
    marginTop: "0px",
    marginBottom: "0px",
  },
  formControl: {
    width: "100%",
  },
  contactbox: {
    margin: "5px 30px 5px",
    borderRadius: "5px",
    [theme.breakpoints.down("xs")]: {
      padding: "30px 0px 10px",
    },
    "& h5": {
      fontWeight: "400",
      color: "rgba(0, 0, 0, 0.4)",
    },
  },
  dropzonebox: {
    "& .dzu-dropzone": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      backgroundColor: "#f1f1f1",
      width: "100%",
      minHeight: "191px",
      overflow: "auto",
      margin: "0 auto",
      position: "relative",
      boxSizing: "border-box",
      transition: "all .15s linear",
      borderRadius: "10px",
      background: theme.palette.background.darkgrey,
      border: "none",
      "& .dzu-previewImage": {
        maxHeight: "150px !important",
        maxWidth: "100% !important",
      },
      "& .dzu-previewStatusContainer": {
        position: "absolute !important",
        top: "7px !important",
        right: "8px !important",
      },
      "& .dzu-previewContainer": {
        padding: "20px 20px !important",
        justifyContent: "center",
        minHeight: "auto",
        minHeight: "186px",
      },
    },
    "& .uploadbox": {
      "& h6": {
        marginBottom: "0px",
      },
    },
    "& h6": {
      fontWeight: "300",
      color: theme.palette.background.greyWhite,
    },
  },
  buttonboxes: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    maxWidth: "790px",
    margin: "0 auto",
    marginBottom: "16px",
    [theme.breakpoints.down("xs")]: {
      display: "block",
      width: "100%",
      maxWidth: "100%",
    },
    "& button": {
      fontSize: "18px",
      fontWeight: "500",
      margin: "0px 10px",
      marginBottom: "10px",
      [theme.breakpoints.down("xs")]: {
        marginBottom: "10px",
        margin: "0px",
      },
    },
  },
  previewImageBox: {
    width: "auto",
    height: "244px",
    display: "flex",
    padding: "10px",
    borderRadius: "5px",
    justifyContent: "center",
    backgroundColor: "#f1f1f1",
    position: "relative",
    "& img": {
      color: "transparent",
      width: "100%",
      height: "100%",
      objectFit: "contain",
      textAlign: "center",
      borderRadius: "5px",
    },
    "& .MuiIconButton-root": {
      position: "absolute",
      top: "0",
      right: "0",
      color: "red",
    },
  },
}));

export default function StepContent2({
  setIdDetails,
  activeStep,
  handleBack,
  steps,
  setBlcok,
  handleNext,
  iIdDetails,
}) {
  const classes = useStyles();
  const [forntImage, setForntImage] = useState(
    iIdDetails?.forntImage ? iIdDetails?.forntImage : ""
  );
  const Meta = (iIdDetails) => {
    setIdDetails({
      setIdDetails,
    });
  };
  const [backImage, setBackImage] = useState(
    iIdDetails?.backImage ? iIdDetails?.backImage : ""
  );
  const [isSubmit, setIsSubmit] = useState(false);
  const [frontImage, setFrontImage] = useState(iIdDetails?.forntImage);
  const [backImage1, setBackImage1] = useState(iIdDetails?.backImage);
  const [selectDocument, setSelectDocument] = useState("");
  useEffect(() => {
    setSelectDocument(iIdDetails?.documentType);
  }, [iIdDetails]);

  const initialFormValues = {
    documentType: iIdDetails?.documentType ? iIdDetails?.documentType : "",
    ducumentNumber: iIdDetails?.ducumentNumber
      ? iIdDetails?.ducumentNumber
      : "",
    FrontImage: iIdDetails?.forntImage ? iIdDetails?.forntImage : "",
    BackImage: iIdDetails?.backImage ? iIdDetails?.backImage : "",
  };
  const formValidationSchema = yep.object().shape({
    documentType: yep.string().required("Document type is required."),
    ducumentNumber: yep
      .string()
      .transform((value) => value.toUpperCase())
      .required("Document number is required.")
      .matches(
        selectDocument === "Pan Card"
          ? /^([A-Z]{5})(\d{4})([A-Z]{1})$/
          : selectDocument === "Aadhaar Card"
          ? /^\d{12}$/
          : selectDocument === "Driving License" && /^[A-Z0-9]{1,20}$/,
        "Please enter valid number."
      ),
  });

  return (
    <>
      <Box className={classes.contactbox}>
        <Box mb={4}>
          <Typography variant="h5">
            In this section, you will be required to upload a copy of your
            government-issued ID card, such as a pan card, aadhaar card or
            driving license. The document must be valid and show your full name,
            photo, and date of birth.
          </Typography>
        </Box>
        <Formik
          initialValues={initialFormValues}
          validationSchema={formValidationSchema}
          onSubmit={(values) => {
            setIsSubmit(true);
            if ((forntImage !== "", backImage !== "")) {
              setIsSubmit(false);
              const dataSend = {
                documentType: values?.documentType,
                ducumentNumber: values?.ducumentNumber.toUpperCase(),
                forntImage1: values.FrontImage,
                backImage1: values.BackImage,
                forntImage: forntImage,
                backImage: backImage,
              };
              setIdDetails(dataSend);
              if (activeStep === steps?.length - 1) {
                setBlcok(true);
              } else {
                handleNext();
              }
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values,
            setFieldValue,
          }) => (
            <Form>
              <Grid container spacing={2}>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Box>
                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                    >
                      <Select
                        displayEmpty
                        inputProps={{ "aria-label": "Without label" }}
                        fullWidth
                        name="documentType"
                        value={values?.documentType}
                        error={Boolean(
                          touched.documentType && errors.documentType
                        )}
                        onChange={(e) => {
                          setSelectDocument(e.target.value);
                          handleChange(e);
                        }}
                        onBlur={handleBlur}
                      >
                        <MenuItem value="">Select Document Type</MenuItem>
                        <MenuItem value="Pan Card">Pan Card</MenuItem>
                        <MenuItem value="Aadhaar Card">Aadhaar Card</MenuItem>
                        <MenuItem value="Driving License">
                          Driving license
                        </MenuItem>
                      </Select>
                    </FormControl>
                    <FormHelperText error>
                      {touched.documentType && errors.documentType}
                    </FormHelperText>
                  </Box>
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Box className={classes.box}>
                    <TextField
                      type="text"
                      placeholder="Enter Document Number"
                      variant="outlined"
                      fullWidth
                      inputProps={{ maxLength: 32 }}
                      className={classes.field}
                      name="ducumentNumber"
                      value={values.ducumentNumber.toUpperCase()}
                      error={Boolean(
                        touched.ducumentNumber && errors.ducumentNumber
                      )}
                      disabled={!values?.documentType}
                      onBlur={handleBlur}
                      onChange={(e) => {
                        console.log("sdkfjs", e.target.value);
                        handleChange(e);
                      }}
                      onKeyPress={(event) => {
                        if (
                          event?.key === "-" ||
                          event?.key === "+" ||
                          event?.key === "*" ||
                          event?.key === "/"
                        ) {
                          event.preventDefault();
                        }
                      }}
                    />
                  </Box>
                  <FormHelperText error>
                    {touched.ducumentNumber && errors.ducumentNumber}
                  </FormHelperText>
                </Grid>
                <Grid
                  item
                  lg={6}
                  md={6}
                  sm={12}
                  xs={12}
                  className={classes.dropzonebox}
                >
                  {frontImage ? (
                    <Box className={classes.previewImageBox}>
                      <img src={iIdDetails?.forntImage} />
                      <IconButton onClick={() => setFrontImage("")}>
                        <MdDelete />
                      </IconButton>
                    </Box>
                  ) : (
                    <Dropzone
                      maxFiles={1}
                      accept="image/*"
                      onChangeStatus={({ meta, file }, status) => {
                        setFieldValue("FrontImage", file);
                        setForntImage(
                          status === "removed" ? "" : URL.createObjectURL(file)
                        );
                      }}
                      inputContent={(files, extra) =>
                        extra.files ? (
                          "Image, Video files only"
                        ) : (
                          <Box textAlign="center" className="uploadbox">
                            <BsUpload style={{ color: "rgb(153 153 153)" }} />
                            <Typography
                              variant="h6"
                              style={{ color: "rgb(153 153 153)" }}
                            >
                              Drag and Drop here or{" "}
                              <span style={{ color: "#4057A7" }}>
                                Browse Files
                              </span>
                            </Typography>
                          </Box>
                        )
                      }
                      styles={{
                        dropzoneReject: {
                          borderColor: "red",
                          backgroundColor: "#DAA",
                        },
                        inputLabel: (files, extra) =>
                          extra.files ? { color: "red" } : {},
                      }}
                    />
                  )}
                  <FormHelperText error>
                    {isSubmit &&
                      forntImage === "" &&
                      "Please select front image."}
                  </FormHelperText>
                  <Box textAlign="center" pt={1}>
                    <Typography style={{ color: "#000" }} variant="h6">
                      Front side
                    </Typography>
                  </Box>
                </Grid>
                <Grid
                  item
                  lg={6}
                  md={6}
                  sm={12}
                  xs={12}
                  className={classes.dropzonebox}
                >
                  {backImage1 ? (
                    <Box className={classes.previewImageBox}>
                      <img src={iIdDetails?.backImage} />
                      <IconButton onClick={() => setBackImage1("")}>
                        <MdDelete />
                      </IconButton>
                    </Box>
                  ) : (
                    <Dropzone
                      maxFiles={1}
                      accept="image/*"
                      initialValues={iIdDetails?.forntImage}
                      value={iIdDetails?.forntImage}
                      onChangeStatus={({ meta, file }, status) => {
                        setFieldValue("BackImage", file);
                        setBackImage(
                          status === "removed" ? "" : URL.createObjectURL(file)
                        );
                      }}
                      inputContent={(files, extra) =>
                        extra.files ? (
                          "Image, Video files only"
                        ) : (
                          <Box textAlign="center" className="uploadbox">
                            <BsUpload style={{ color: "rgb(153 153 153)" }} />
                            <Typography
                              variant="h6"
                              style={{ color: "rgb(153 153 153)" }}
                            >
                              Drag and Drop here or{" "}
                              <span style={{ color: "#4057A7" }}>
                                Browse Files
                              </span>
                            </Typography>
                          </Box>
                        )
                      }
                      styles={{
                        dropzoneReject: {
                          borderColor: "red",
                          backgroundColor: "#DAA",
                        },
                        inputLabel: (files, extra) =>
                          extra.files ? { color: "red" } : {},
                      }}
                    />
                  )}
                  <FormHelperText error>
                    {isSubmit &&
                      backImage === "" &&
                      "Please select back image."}
                  </FormHelperText>
                  <Box textAlign="center" pt={1}>
                    <Typography style={{ color: "#000" }} variant="h6">
                      Back side
                    </Typography>
                  </Box>
                </Grid>
              </Grid>

              <Box className={classes.instructions}>
                <Box py={4} textAlign="center" className={classes.buttonboxes}>
                  <Button
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    className={classes.button}
                    variant="contained"
                    color="secondary"
                    size="large"
                    fullWidth
                    style={{ backgroundColor: "#828282" }}
                  >
                    Previous
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    fullWidth
                    className={classes.button}
                    type="submit"
                  >
                    {activeStep === steps.length - 1 ? "Submit" : "Next"}
                  </Button>
                </Box>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </>
  );
}
