import {
  Grid,
  makeStyles,
  Box,
  Typography,
  Paper,
  Button,
  Avatar,
  IconButton,
  Dialog,
  DialogContent,
  TextField,
  TextareaAutosize,
  FormHelperText,
} from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { FiEdit2 } from "react-icons/fi";
import { MdDelete, MdModeEditOutline } from "react-icons/md";
import { AiOutlinePlus } from "react-icons/ai";
import { IoClose } from "react-icons/io5";
import {
  getDataHandlerAPI,
  putAPIHandler,
  uploadFiles,
  deleteDataAPIHandler,
} from "src/apiConfig/service";
import AttachementIcon from "src/icons/AttachementIcon";
import ButtonCircularProgress from "src/component/ButtonCircularProgress";
import { dataPostHandler } from "src/apiConfig/service";
import { toast } from "react-toastify";
import DataNotFound from "src/component/DataNotFound";
import LoadHistory from "src/Skeletons/LoadHistory";
import ConfirmationDialog from "src/component/ConfirmationDialog";
import { AuthContext } from "src/context/Auth";
import { Pagination } from "@material-ui/lab";
import MultiSelect from "src/component/MulitiSelect";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
    background: "#f0f0f0",
    padding: "18px 12px",
    borderRadius: "4px",
    alignItems: "flex-start",
    transition: "0.5s",
    "&:hover": {
      transform: "scale(1.01)",
    },
    "@media(max-width:531px)": {
      display: "block",
    },
    "& .iconboxes": {
      border: " 2px solid #4057A7",
      width: "20px",
      height: "20px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: "50%",
      marginLeft: "10px",
      "& svg": {
        color: "#4057A7",
      },
    },
    "& h4": {
      fontSize: "16px",
      color: "#4057A7",
      fontWeight: "400",
    },
  },
  mainContent: {
    display: "flex",
    alignItems: "flex-start",
    "& .MuiAvatar-root": {
      width: "50px",
      height: "50px",
      borderRadius: "50%",
      marginRight: "10px",
    },
    "& h6": {
      fontWeight: "300",
    },
    "& p": {
      color: "#6D6D6D",
      fontWeight: "300",
      wordBreak: "break-all",
    },
  },
  btn: {
    "& Button": {
      fontSize: "12px",
      fontWeight: "300",
      whiteSpace: "pre",
    },
    "@media(max-width:531px)": {
      marginTop: "4px",
    },
  },
  BoxImg: {
    paddingTop: "6px",
    paddingBottom: "40px",
    display: "flex",
    alignItems: "center",
    "& .circle": {
      borderRadius: "50%",
      border: "1px dashed #4057A7",
    },
    "& p": {
      color: "#4057A7",
      paddingLeft: "8px",
    },
  },
  mainDivPoeject: {
    padding: "60px 21px 0px 21px",
  },
  centerbox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    "@media(max-width:687px)": {
      justifyContent: "left",
    },
  },
  editboxes: {
    display: "flex",
    alignContent: "center",
    justifyContent: "start",
    marginTop: "10px",
    "@media(max-width:687px)": {
      display: "flex",
      alignItems: "center",
    },
  },
  cancelBtn: {
    position: "absolute",
    top: 0,
    right: 0,
    "& svg": {
      color: "#fff",
      fontWeight: "700",
    },
  },
  attachment: {
    display: "flex",
    alignItems: "center",
    marginLeft: "13px",
    paddingTop: "8px",
    "& .MuiIconButton-root": {
      border: "1px dashed",
      height: "45px",
      width: "45px",
      color: "rgba(64, 87, 167, 1)",
    },
    "& label": {
      cursor: "pointer",
    },
    "& .MuiAvatar-root": {
      width: "45px",
      height: "45px",
    },
  },
  skillCls: {
    "& .css-13cymwt-control": {
      backgroundColor: "#f1f1f1 !important",
      borderRadius: "5px",
      height: "52px",
      borderStyle: "none",
      borderColor: "#f1f1f1 !important",
    },
    "& .css-t3ipsp-control": {
      backgroundColor: "#f1f1f1 !important",
      borderRadius: "5px",
      height: "52px",
      borderStyle: "none",
      borderColor: "#f1f1f1 !important",
      boxShadow: "none !important",
      "&:hover": {
        borderColor: "#f1f1f1 !important",
        height: "52px",
      },
    },
  },
}));
function Projects() {
  const classes = useStyles();
  const auth = useContext(AuthContext);
  const UserId = auth?.userData?._id;
  const [addOpen, setAddOpen] = useState(false);
  const [readmore, setReadmore] = useState({
    length: "180",
    showTab: "",
  });
  const [profileImage641, setProfileImage641] = useState("");
  const [projectList, setProjectList] = useState([]);

  const [projectUpdateData, setProjectUpdateData] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const [modal, setModal] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading1, setIsLoading1] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [title, SetTitle] = useState("");
  const [description, setDescription] = useState("");
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [totalCount, setTotalCount] = useState("");
  const [selectedOptions, setSelectedOptions] = useState([]);

  useEffect(() => {
    if (projectUpdateData) {
      SetTitle(projectUpdateData?.headLine);
      setDescription(projectUpdateData?.description);
      setSelectedOptions(
        projectUpdateData &&
          projectUpdateData?.skillId?.map((itm) => ({
            label: itm?.skillName,
            value: itm?._id,
          }))
      );
    }
  }, [projectUpdateData]);

  const addProjectApiHandler = async () => {
    setIsSubmit(true);
    if (
      title !== "" &&
      description !== "" &&
      profileImage641 !== "" &&
      selectedOptions?.length !== 0
    ) {
      try {
        setIsLoading1(true);
        const result = await uploadFiles(profileImage641);
        const dataToSend = {
          headLine: title,
          description: description,
          file: result[0].url,
          skillId:
            selectedOptions && selectedOptions.map((item, i) => item.value),
        };
        const response = await dataPostHandler("addProject", dataToSend);
        if (response.data.responseCode === 200) {
          setIsLoading1(false);
          setAddOpen(false);
          setIsSubmit(false);
          addProjectHandler();
        } else {
          setIsLoading1(false);
        }
      } catch (error) {
        console.log(error);
        toast.error(error?.response?.data?.responseMessage);
        setIsLoading1(false);
      }
    }
  };

  const addProjectHandler = async () => {
    try {
      setIsLoading(true);
      const res = await getDataHandlerAPI("projectList", {
        page: page,
        limit: 15,
      });

      if (res) {
        setIsLoading(false);
        setProjectList(res?.docs);
        setTotalPage(res?.pages);
        setTotalCount(res?.total);
      } else {
        setIsLoading(false);
        setProjectList([]);
      }
    } catch (error) {
      setIsLoading(false);
      setProjectList([]);
    }
  };
  useEffect(() => {
    addProjectHandler();
  }, [page]);
  const updateProjectApiHandler = async () => {
    if (
      title !== "" &&
      description !== "" &&
      description?.length <= 2000 &&
      profileImage641 !== "" &&
      selectedOptions?.length !== 0
    ) {
      try {
        setIsLoading1(true);
        const result = await uploadFiles(profileImage641);
        const id = {
          _id: projectUpdateData?._id,
        };
        const dataToSend = {
          headLine: title,
          description: description,
          file: result[0].url,
          skillId:
            selectedOptions && selectedOptions.map((item, i) => item.value),
        };
        const response = await putAPIHandler("updateproject", dataToSend, id);
        if (response?.responseCode === 200) {
          addProjectHandler();
          setIsLoading1(false);
          setAddOpen(false);
        } else {
          setIsLoading1(false);
        }
      } catch (error) {
        setIsLoading1(false);
      }
    }
  };
  const deeleteHandler = async (id) => {
    const idSend = {
      _id: projectUpdateData?._id,
    };
    try {
      setIsLoading(true);
      const res = await deleteDataAPIHandler("Deleteproject", idSend);
      if (res) {
        addProjectHandler();
        setDeleteModal(false);
        setIsLoading(false);
        addProjectHandler();
      }
    } catch (error) {
      setIsLoading(false);
    }
  };
  return (
    <Box className="scrollDiv">
      <Box className={classes.mainDivPoeject}>
        <Paper className={`${classes.followersBox} paddingBox`} elevation={0}>
          <Box
            className={classes.BoxImg}
            onClick={() => {
              setAddOpen(true);
              setModal("add");
              setDescription("");
              SetTitle("");
              setProfileImage641("");
            }}
          >
            <Box className="circle">
              <IconButton style={{ padding: "5px" }}>
                <AiOutlinePlus style={{ color: "#4057A7", fontSize: "24px" }} />
              </IconButton>
            </Box>
            <Typography variant="body2">Add Project</Typography>
          </Box>
          <Grid container spacing={1}>
            {isLoading ? (
              [1, 2, 3].map((tem) => {
                return (
                  <Box width="100%">
                    <LoadHistory />
                  </Box>
                );
              })
            ) : (
              <>
                {projectList &&
                  projectList
                    ?.filter((data) => {
                      return UserId === data?.userId;
                    })
                    .map((data, i) => {
                      return (
                        <Grid item xs={12} sm={12}>
                          <Box className={classes.root}>
                            <Box className={classes.mainContent}>
                              <Box>
                                <Avatar src={data.file} />
                              </Box>
                              <Box>
                                <Typography variant="h5">
                                  {data?.headLine}
                                </Typography>

                                <Typography variant="body2">
                                  {data?.description.slice(
                                    0,
                                    data.headLine === readmore.showTab
                                      ? readmore.length
                                      : "180"
                                  )}
                                  {data.headLine === readmore.showTab &&
                                  data?.description.length >= readmore.length
                                    ? ""
                                    : "..."}
                                </Typography>
                              </Box>
                            </Box>
                            <Box className={classes.btn} ml={2}>
                              {data?.description.length >= 180 && (
                                <Button
                                  variant="contained"
                                  color="primary"
                                  fullWidth
                                  onClick={() =>
                                    data.headLine === readmore.showTab
                                      ? setReadmore({
                                          length: "60",
                                          showTab: "",
                                        })
                                      : setReadmore({
                                          length: "2000",
                                          showTab: data.headLine,
                                        })
                                  }
                                >
                                  {data.headLine === readmore.showTab
                                    ? "Read Less"
                                    : "Read More"}
                                </Button>
                              )}

                              <Box className={classes.editboxes}>
                                <Box className="iconboxes">
                                  <IconButton
                                    style={{ padding: "6px" }}
                                    onClick={() => {
                                      setAddOpen(true);
                                      setModal("update");
                                      setProjectUpdateData(data);
                                    }}
                                  >
                                    <MdModeEditOutline />
                                  </IconButton>
                                </Box>

                                <Box className={classes.centerbox}>
                                  <Box className="iconboxes">
                                    <IconButton
                                      onClick={() => {
                                        setDeleteModal(true);
                                        setProjectUpdateData(data);
                                      }}
                                    >
                                      <MdDelete />
                                    </IconButton>
                                  </Box>
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                        </Grid>
                      );
                    })}
              </>
            )}

            {!isLoading && projectList && projectList.length === 0 && (
              <DataNotFound />
            )}
            {!isLoading && totalCount && totalCount > (page === 1 ? 15 : 0) && (
              <Pagination
                variant="outlined"
                shape="rounded"
                count={totalPage}
                page={page}
                onChange={(e, v) => setPage(v)}
              />
            )}
          </Grid>
        </Paper>
      </Box>
      <Dialog
        open={addOpen}
        onClose={() => setAddOpen(false)}
        disabled={isLoading1}
        fullWidth
        maxWidth="sm"
      >
        <Box className="bgcolorbox">
          <Box>
            <Typography variant="h5">
              {modal === "update" ? "Update Project" : "Add Project"}
            </Typography>
          </Box>
        </Box>
        <DialogContent>
          <IconButton
            className={classes.cancelBtn}
            onClick={() => setAddOpen(false)}
            disabled={isLoading1}
          >
            <IoClose />
          </IconButton>
          <Box my={1}>
            <Typography variant="body2">Title</Typography>
          </Box>
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Topic"
            type="text"
            value={title}
            disabled={isLoading1}
            onChange={(e) => SetTitle(e.target.value)}
            error={isSubmit && title === ""}
            inputProps={{ maxLength: 250 }}
          />

          <FormHelperText error>
            {isSubmit && title === "" && "Please enter title."}
          </FormHelperText>
          <Box my={1}>
            <Typography variant="body2">Select Skills:</Typography>
          </Box>
          <Box className={classes.skillCls}>
            <MultiSelect
              data={auth.skills}
              setSelectedOptions={setSelectedOptions}
              selectedOptions={selectedOptions}
              isLoading={isLoading1}
            />
          </Box>

          {isSubmit && selectedOptions?.length === 0 && (
            <FormHelperText error>Please select skills.</FormHelperText>
          )}
          <Box my={1}>
            <Typography variant="body2">Description :</Typography>
          </Box>
          <TextField
            rows={5}
            variant="outlined"
            placeholder="Write something....."
            type="text"
            fullWidth
            multiline
            disabled={isLoading1}
            inputProps={{ maxLength: 2000 }}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            error={isSubmit && description === ""}
          />
          <FormHelperText
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography color="error">
              {isSubmit && description === "" && "Please enter description."}
              {isSubmit &&
                description?.length >= 2000 &&
                "You can not enter more than 2000 characters."}
            </Typography>
            <Typography className="helpText">
              {description?.length} /2000
            </Typography>
          </FormHelperText>

          <Box my={1}>
            <Typography fullWidth variant="body2">
              Upload Image :
            </Typography>
          </Box>
          <Box className={classes.attachment}>
            <IconButton>
              <label htmlFor="contained-button-profile">
                {profileImage641 !== "" ? (
                  <Avatar src={URL.createObjectURL(profileImage641)} />
                ) : (
                  <AttachementIcon />
                )}
              </label>
            </IconButton>
            <Box ml={1}>
              {profileImage641 === "" ? (
                ""
              ) : (
                <IconButton
                  style={{ border: "none" }}
                  onClick={() => setProfileImage641("")}
                >
                  <MdDelete />
                </IconButton>
              )}
            </Box>
          </Box>
          <FormHelperText error>
            {isSubmit && profileImage641 === "" && "Please upload image."}
          </FormHelperText>
          <input
            style={{ display: "none" }}
            id="contained-button-profile"
            type="file"
            accept="image/*"
            onChange={(e) => {
              setProfileImage641(e.target.files[0]);
            }}
            disabled={isLoading}
          />
          <Box mt={4} mb={2} align="center">
            <Button
              variant="contained"
              color="secondary"
              onClick={() => setAddOpen(false)}
              style={{ padding: "7px 50px" }}
              disabled={isLoading1}
            >
              Cancel
            </Button>
            {modal === "update" ? (
              <Button
                style={{ marginLeft: "13px", padding: "7px 50px" }}
                variant="contained"
                color="primary"
                disabled={isLoading1}
                onClick={() => updateProjectApiHandler()}
              >
                Update {isLoading1 && <ButtonCircularProgress />}
              </Button>
            ) : (
              <Button
                style={{ marginLeft: "13px", padding: "7px 50px" }}
                variant="contained"
                color="primary"
                disabled={isLoading1}
                onClick={() => addProjectApiHandler()}
              >
                Add {isLoading1 && <ButtonCircularProgress />}
              </Button>
            )}
          </Box>
        </DialogContent>
      </Dialog>

      <ConfirmationDialog
        open={deleteModal}
        handleClose={() => setDeleteModal(false)}
        isLoading={isLoading}
        title="Delete"
        desc="Are you sure, you want to delete this project?"
        confirmationHandler={() => deeleteHandler()}
      />
    </Box>
  );
}

export default Projects;
