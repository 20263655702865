import { Avatar, Box, Typography } from "@material-ui/core";
import React, { useState, useContext, useRef, useEffect } from "react";
import { SpeedDial, SpeedDialAction, SpeedDialIcon } from "@material-ui/lab";
import { BiDotsHorizontalRounded } from "react-icons/bi";
import { ImGift } from "react-icons/im";
import { HiOutlineShoppingBag } from "react-icons/hi";
import { AiOutlineHeart } from "react-icons/ai";
import { MdFavorite } from "react-icons/md";
import { AiOutlineDelete } from "react-icons/ai";
import Report from "src/component/Report";
import moment from "moment";
import {
  checkMediaDoc,
  checkText,
  mediaTypeImg,
  mediaTypeVideo,
  viewImage,
  viewVideo,
} from "src/utils";
import BuyModal from "src/component/BuyModal";
import GiftModal from "src/component/GiftModal";
import ViewLiveFeedModal from "src/component/ViewLiveFeedModal";
import {
  deleteDataAPIHandler,
  postDataIdinQuery,
  postDataIdinWithdata,
  patchAPIHandlerPathId,
} from "src/apiConfig/service";
import { AuthContext } from "src/context/Auth";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { RiCloseFill } from "react-icons/ri";
import { GoReport } from "react-icons/go";
import ConfirmationDialog from "src/component/ConfirmationDialog";
import { concat } from "lodash";

export default function CommonNews({
  classes,
  data,
  index,
  listPostHandler,
  pagetype,
}) {
  const auth = useContext(AuthContext);
  const history = useHistory();
  const [open, setOpen] = useState({ firstDial: false, secondDial: false });
  const [openFeed, setOpenFeed] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [categorySelect, setCategorySelect] = useState("");
  const [reportMsg, setReportMsg] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);
  const [isLoadingLike, setIsLoadingLike] = useState(false);
  const videoRef = useRef(null);
  const [postId1, setPostId] = useState({
    id: "",
  });

  const actions = [
    { icon: <GoReport />, name: "Report" },
    { icon: <ImGift />, name: "Gift" },
    {
      icon: <HiOutlineShoppingBag />,
      name: "Buy",
    },
    {
      icon: data?.islike ? (
        <MdFavorite style={data?.islike === true ? { color: "red" } : {}} />
      ) : (
        <AiOutlineHeart style={data?.islike === true ? { color: "red" } : {}} />
      ),
      name: "Like",
    },
    { icon: <AiOutlineDelete />, name: "Delete" },
  ];
  const handleOpenFeed = () => {
    setOpenFeed(true);
  };
  const handleCloseFeed = () => {
    setOpenFeed(false);
  };
  const [modalsOpen, setModalsOpen] = useState({
    liveFeeds: false,
    giftModals: false,
    buyModals: false,
    reportModals: false,
    deleteModals: false,
    id: "",
  });
  const [modalsOpenView, setModalsOpenView] = useState({
    liveFeeds: false,
    giftModals: false,
    buyModals: false,
    reportModals: false,
    deleteModals: false,
    id: "",
  });
  const handlerModalsOpen = (actionName, id) => {
    setOpen(false);
    switch (actionName) {
      case "Report":
        setModalsOpen({ ...modalsOpen, reportModals: true, id: id });
        break;
      case "Gift":
        setModalsOpen({ ...modalsOpen, giftModals: true });
        break;
      case "Buy":
        setModalsOpen({ ...modalsOpen, buyModals: true });
        break;
      case "Delete":
        setModalsOpen({ ...modalsOpen, deleteModals: true, id: id });
        break;
      default:
        break;
    }
  };
  const handleOpen = (open) => {
    switch (open) {
      case 1:
        setOpen({ ...open, firstDial: true });
        break;
      case 2:
        setOpen({ ...open, secondDial: true });
        break;
      case 3:
        setOpen({ ...open, thirdDial: true });
        break;
      case 4:
        setOpen({ ...open, fourthDial: true });
        break;
      case 5:
        setOpen({ ...open, fifthDial: true });
        break;
      case 6:
        setOpen({ ...open, sixthDial: true });
        break;
      default:
        break;
    }
  };
  const handleClose = () => {
    setOpen({ firstDial: false, secondDial: false });
  };

  const addLikeHandler = async (id) => {
    try {
      setIsLoadingLike(true);
      const res = await postDataIdinQuery("addLike", {
        postId: id,
      });
      if (res?.responseCode === 200) {
        if (data?.postType === "NFT") {
          await patchAPIHandlerPathId("addToFav", id);
        }
        listPostHandler();
        auth.listPostHandler();
        auth.listPostHandlerTrending();
        setIsLoadingLike(false);
      } else {
        setIsLoadingLike(false);
      }
    } catch (error) {
      setIsLoadingLike(false);
    }
  };

  const deeleteHandler = async (id) => {
    const idSend = {
      _id: id,
    };
    try {
      setIsLoading(true);
      const res = await deleteDataAPIHandler("deletePost", idSend);
      if (res) {
        listPostHandler();
        auth.listPostHandler();
        auth.listPostHandlerTrending();
        handleCloseFeed();
        setModalsOpen({ ...modalsOpen, deleteModals: false });
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };
  const reportHandler = async (id) => {
    const idSend = {
      postId: id,
      description: [{ reason: categorySelect ? categorySelect : reportMsg }],
    };
    if (categorySelect !== "" || reportMsg !== "") {
      try {
        setIsLoading(true);
        const res = await postDataIdinWithdata("reportOnPost", idSend);
        if (res) {
          listPostHandler();
          auth.listPostHandler();
          auth.listPostHandlerTrending();
          setModalsOpen({});
          setIsLoading(false);
          setCategorySelect("");
          setReportMsg("");
        } else {
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
      }
    } else {
      setIsSubmit(true);
    }
  };

  useEffect(() => {
    // Check if videoRef exists before setting properties
    if (videoRef.current && openFeed) {
      videoRef.current.muted = true; // Set muted based on openFeed value
      videoRef.current.pause(); // Pause the video when openFeed is true
    }
  }, [openFeed]);
  return (
    <>
      <Box
        className="postimg1"
        style={
          (data && checkMediaDoc(data)) || checkText(data) || mediaTypeImg(data)
            ? {
                backgroundImage:
                  "url(" +
                  `${
                    data && checkMediaDoc(data)
                      ? data?.mediaType === "application/pdf"
                        ? "images/pdf.png"
                        : "images/docs.png"
                      : checkText(data)
                      ? "images/logo.png"
                      : viewImage(data)
                  }` +
                  ")",
                cursor: "pointer",
              }
            : { cursor: "pointer", display: "flex", alignItems: "center" }
        }
        onClick={() => {
          handleOpenFeed();
          setPostId({ id: data?._id });
        }}
      >
        {data && !mediaTypeImg(data) && mediaTypeVideo(data) && (
          <div className="videos videoWrapper">
            <video
              ref={videoRef}
              controls={openFeed ? false : true}
              autoplay={openFeed ? false : true}
              loop
              muted
              playsInline={true}
            >
              <source
                src={data ? data && viewVideo(data) : ""}
                type="video/mp4"
                accept="video/*"
              />
            </video>
          </div>
        )}

        <Box className="activ">
          <Box
            display="flex"
            alignItems="center"
            className="databanner"
            onClick={(event) => {
              event.stopPropagation();
              if (data?.userId?._id !== auth?.userData?._id) {
                history.push({
                  pathname: "/other-profile",
                  search: data?.userId?._id,
                  state: {
                    id: data?.userId?._id,
                  },
                });
              }
            }}
          >
            <Avatar
              src={data?.userId?.profilePic && data?.userId?.profilePic}
            />
            <Box>
              <Typography variant="h6" className="Word-BrPre">
                {data?.userId?.firstName
                  ? data?.userId?.firstName
                  : "Anonymous"}
              </Typography>
              <Typography variant="body1" style={{ margin: "0px 8px" }}>
                {" "}
                {moment(data?.createdAt).local().fromNow()}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box className={classes.subtext}>
          <Typography className="Word-BrPre" variant="h5">
            {data?.postType === "NFT" ? (
              <>
                {data?.title.slice(0, 50)}
                {data?.title.length >= 50 && "..."}
              </>
            ) : (
              <>
                {data?.mediaPost?.title.slice(0, 50)}
                {data?.mediaPost?.title.length >= 50 && "..."}
              </>
            )}
          </Typography>
        </Box>

        <Box className={classes.iconboxes}>
          <SpeedDial
            ariaLabel="SpeedDial openIcon example"
            icon={
              <SpeedDialIcon
                openIcon={<RiCloseFill />}
                icon={<BiDotsHorizontalRounded />}
                onClick={(event) => event.stopPropagation()}
              />
            }
            className={classes.speedDial}
            onClose={handleClose}
            onOpen={() => handleOpen(1)}
            open={open.firstDial}
            onClick={(event) => event.stopPropagation()}
          >
            {actions
              .filter((itm) =>
                pagetype === "page"
                  ? itm.name === "Delete"
                  : itm.name === "Buy" || itm.name === "Gift"
                  ? data?.postType === "NFT" &&
                    data.userId?._id !== auth?.userData?._id
                  : itm.name === "Report"
                  ? auth?.userData?._id !== data?.userId?._id
                  : itm.name === "Delete"
                  ? auth?.userData?._id === data?.userId?._id
                  : itm
              )
              .map((action) => {
                return (
                  <SpeedDialAction
                    className={classes.actionspeed}
                    style={{ zIndex: "999" }}
                    key={action.name}
                    icon={
                      isLoadingLike && action.name === "Like"
                        ? "..."
                        : action.icon
                    }
                    disabled={isLoadingLike}
                    tooltipTitle={action.name === "Like" ? "" : action.name}
                    onClick={(event) => {
                      event.stopPropagation();
                      action.name !== "Delete"
                        ? auth?.userData?.kycStatus === "APPROVE"
                          ? action.name === "Like"
                            ? addLikeHandler(data?._id)
                            : handlerModalsOpen(action.name, data?._id)
                          : toast.warn(
                              "Please complete your KYC to access this feature."
                            )
                        : handlerModalsOpen(action.name, data?._id);
                    }}
                  />
                );
              })}
          </SpeedDial>
        </Box>
      </Box>
      {modalsOpen.reportModals && (
        <Report
          openCreateReport={modalsOpen.reportModals}
          handleCloseCreateReport={() => {
            setModalsOpen({ ...modalsOpen, reportModals: false });
          }}
          setCategorySelect={(item) => setCategorySelect(item)}
          categorySelect={categorySelect}
          reportHandler={(id) => reportHandler(id)}
          id={modalsOpen}
          isLoading={isLoading}
          reportMsg={reportMsg}
          setReportMsg={(item) => setReportMsg(item)}
          isSubmit={isSubmit}
        />
      )}
      {modalsOpen.buyModals && (
        <BuyModal
          handleOpenBuy={modalsOpen.buyModals}
          handleCloseBuy={() => {
            setModalsOpen({ ...modalsOpen, buyModals: false });
          }}
          data={data}
          listPostHandler={() => {
            auth.listPostHandlerBottom(1, []);
            auth.listPostHandler();
            auth.listPostHandlerTrending();
          }}
          auth={auth}
        />
      )}
      {modalsOpen.giftModals && (
        <GiftModal
          handleOpenGift={modalsOpen.giftModals}
          handleCloseGift={() => {
            setModalsOpen({ ...modalsOpen, giftModals: false });
          }}
          data={data}
          auth={auth}
        />
      )}
      {modalsOpen.deleteModals && (
        <ConfirmationDialog
          open={modalsOpen?.deleteModals}
          handleClose={() =>
            setModalsOpen({ ...modalsOpen, deleteModals: false })
          }
          isLoading={isLoading}
          title="Delete"
          desc="Are you sure, you want to delete?"
          confirmationHandler={() => deeleteHandler(modalsOpen?.id)}
        />
      )}
      <ViewLiveFeedModal
        openFeed={openFeed}
        handleCloseFeed={handleCloseFeed}
        id={postId1}
        listPostHandler={listPostHandler}
        modalsOpenView={modalsOpenView}
        setModalsOpenView={setModalsOpenView}
      />
    </>
  );
}
