import React from "react";
import PropTypes from "prop-types";
import { makeStyles, Grid, Box } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  wrapper: {
    flex: "1 1 auto",
    overflow: "hidden",
    backgroundColor: "#FFFFFF",
    position: "relative",
    zIndex: "9",
  },
  mainbox: {
    maxWidth: "85%",
    width: "auto",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    borderRadius: "15px",
  },
  logoBox: {
    position: "absolute",
    top: "46px",
    left: "47px",
    cursor: "pointer",
  },
  root: {
    backgroundColor: "#fffff",

    "& .shape": {
      position: "absolute",
      top: "50px",
      left: "-30px",
      [theme.breakpoints.down("xs")]: {
        position: "absolute",
        top: "-50px",
        left: "-30px",
      },
    },
  },

  imgBox: {
    backgroundImage: "url('images/loginbackground.png')",
    // height: "90vh",
    // minHeight: "770px",
    // height: "100%",
    position: "relative",
    height: "calc(100vh - 40px)",
    margin: "20px",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    borderRadius: "20px",

    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  mainClass: {
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}));

const LoginLayout = ({ children }) => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const data = window?.location?.pathname;
  return (
    <Box>
      <Box className={classes.root}>
        <div className={classes.wrapper}>
          <Box className={classes.main}>
            {data === "/verify-kyc" ? (
              <Box className={classes.content}>{children}</Box>
            ) : (
              <>
                <Box className={classes.mainbox1}>
                  {window.location.pathname === "/term-conditions" ||
                  window.location.pathname === "/policy-privacy" ? (
                    <>
                      <Box className={classes.content}>{children}</Box>
                    </>
                  ) : (
                    <>
                      <Grid container alignItems="center">
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={6}
                          lg={7}
                          className={classes.mainClass}
                        >
                          <Box className={classes.imgBox}>
                            {data === "/forget-password" ? (
                              <>
                                <img
                                  src="images/forgotpass.png"
                                  alt="dummy"
                                  className={classes.mainbox}
                                />
                                <img
                                  src="images/mainLogo.png"
                                  alt="dummy"
                                  className={classes.logoBox}
                                  onClick={() => history.push("/")}
                                />
                              </>
                            ) : data === "/forget-otp" ? (
                              <>
                                <img
                                  src="images/login2.png"
                                  alt="dummy"
                                  className={classes.mainbox}
                                />
                                <img
                                  src="images/mainLogo.png"
                                  alt="dummy"
                                  className={classes.logoBox}
                                  onClick={() => history.push("/")}
                                />
                              </>
                            ) : data === "/reset-otp" ? (
                              <>
                                <img
                                  src="images/verify.png"
                                  alt="dummy"
                                  className={classes.mainbox}
                                />
                                <img
                                  src="images/mainLogo.png"
                                  alt="dummy"
                                  className={classes.logoBox}
                                  onClick={() => history.push("/")}
                                />
                              </>
                            ) : data === "/" ||
                              data === "/privacy" ||
                              data === "/terms-condition" ? (
                              <>
                                <img
                                  src="images/joinbanner.png"
                                  alt="dummy"
                                  className={classes.mainbox}
                                />
                                <img
                                  src="images/mainLogo.png"
                                  alt="dummy"
                                  className={classes.logoBox}
                                  onClick={() => history.push("/")}
                                />
                              </>
                            ) : data === "/signup" ? (
                              <>
                                <img
                                  src="images/verifys.png"
                                  alt="dummy"
                                  className={classes.mainbox}
                                />
                                <img
                                  src="images/mainLogo.png"
                                  alt="dummy"
                                  className={classes.logoBox}
                                  onClick={() => history.push("/")}
                                />
                              </>
                            ) : data === "/reset-password" ? (
                              <>
                                <img
                                  src="images/resetpass.png"
                                  alt="dummy"
                                  className={classes.mainbox}
                                />
                                <img
                                  src="images/mainLogo.png"
                                  alt="dummy"
                                  className={classes.logoBox}
                                  onClick={() => history.push("/")}
                                />
                              </>
                            ) : data === "/reset-otp-join" ? (
                              <>
                                <img
                                  src="images/verify.png"
                                  alt="dummy"
                                  className={classes.mainbox}
                                />
                                <img
                                  src="images/mainLogo.png"
                                  alt="dummy"
                                  className={classes.logoBox}
                                  onClick={() => history.push("/")}
                                />
                              </>
                            ) : (
                              <></>
                            )}
                          </Box>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={6}
                          lg={5}
                          className={classes.left}
                        >
                          <Box className={classes.content}>{children}</Box>
                        </Grid>
                      </Grid>
                    </>
                  )}
                </Box>
              </>
            )}
          </Box>
        </div>
      </Box>
    </Box>
  );
};

LoginLayout.propTypes = {
  children: PropTypes.node,
};

export default LoginLayout;
