import { Box, Paper, Typography } from "@material-ui/core";
import React from "react";
import ContentGraphs from "./ContentGraph";
import Page from "src/component/Page";

function Index() {
  return (
    <Page title="My Analytics">
      <Box className="scrollDiv">
        <Box mb={1}>
          <Paper elevation={0}>
            <Box p={2} display="flex">
              <Typography variant="h4">My Content Analytics</Typography>
            </Box>
          </Paper>
        </Box>
        <Box mt={2} display="flex">
          <ContentGraphs />
        </Box>
      </Box>
    </Page>
  );
}

export default Index;
