import React from "react";

const Arena = () => {
  return (
    <svg
      width="20"
      height="14"
      viewBox="0 0 20 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.9635 0H6.03645C2.70247 0 0 2.70247 0 6.03653V7.21356C0 10.5476 2.70247 13.25 6.03645 13.25H13.9635C17.2976 13.25 20 10.5476 20 7.21356V6.03653C20 2.70247 17.2976 0 13.9635 0ZM13.2786 12.097H6.72265C3.70503 12.097 1.24998 9.64263 1.24998 6.62496C1.24998 3.60742 3.70503 1.15234 6.72265 1.15234H13.2786C16.2962 1.15234 18.7507 3.60742 18.7507 6.62496C18.7507 9.64263 16.2962 12.097 13.2786 12.097Z"
        fill="white"
      />
      <path
        d="M13.279 3.81836H6.72304C5.17549 3.81836 3.91699 5.07681 3.91699 6.62432C3.91699 8.17118 5.17549 9.42968 6.72304 9.42968H13.279C14.8259 9.42968 16.0844 8.17123 16.0844 6.62432C16.0844 5.07681 14.8259 3.81836 13.279 3.81836Z"
        fill="white"
      />
      <path
        d="M13.2786 2.15234H6.72265C4.25652 2.15234 2.25 4.1589 2.25 6.62499C2.25 9.09113 4.25652 11.0971 6.72265 11.0971H13.2786C15.7448 11.0971 17.7507 9.09117 17.7507 6.62499C17.7507 4.1589 15.7448 2.15234 13.2786 2.15234ZM13.2786 10.4303H6.72265C4.62434 10.4303 2.91667 8.72335 2.91667 6.62495C2.91667 4.52668 4.62434 2.81898 6.72265 2.81898H13.2786C15.3769 2.81898 17.084 4.52668 17.084 6.62495C17.084 8.72335 15.377 10.4303 13.2786 10.4303Z"
        fill="white"
      />
    </svg>
  );
};

export default Arena;
