import {
  Box,
  Button,
  FormHelperText,
  Typography,
  FormControl,
  TextField,
  IconButton,
  InputAdornment,
} from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import React from "react";

export default function PollOption({
  classes,
  i,
  item,
  setTranspotation,
  handleChange,
  transpotation,
  removeFormFields,
  isLoading,
}) {
  const showError = (value, error) => (
    console.log("value---", value, error), value.trim().length === 0 && error
  );

  return (
    <Box pb={1}>
      <Typography>Option{i + 1}</Typography>
      <FormControl fullWidth className={classes.commentControl}>
        <TextField
          {...(showError(item?.option, item?.error) && {
            ...{
              error: item?.error,
              helperText: "Enter the value.",
            },
          })}
          disabled={isLoading}
          fullWidth
          style={{ backgroundColor: "#fff" }}
          name="option"
          placeholder="E.g., Public transportation"
          value={item?.option}
          onChange={(e) => {
            handleChange(i, e);
          }}
          inputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {transpotation.length < 3 ? null : (
                  <IconButton
                    onClick={() => removeFormFields(i)}
                    className="delete"
                  >
                    <Delete style={{ color: "red" }} />
                  </IconButton>
                )}
              </InputAdornment>
            ),
            maxLength: 30,
          }}
        />

        <FormHelperText className="helpText">
          {item?.option.length}/30
        </FormHelperText>
      </FormControl>
    </Box>
  );
}
