import { Avatar, Box, Typography, IconButton } from "@material-ui/core";
import React, { useState, useContext } from "react";
import { BsThreeDots } from "react-icons/bs";
import moment from "moment";
import ListItemText from "@material-ui/core/ListItemText";
import { withStyles } from "@material-ui/core/styles";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Report from "src/component/Report";
import { postDataIdinWithdata } from "src/apiConfig/service";
import { useHistory } from "react-router-dom";
import { AuthContext } from "src/context/Auth";
import { toast } from "react-toastify";
import {
  checkMediaDoc,
  checkText,
  mediaTypeImg,
  viewImage,
  viewVideo,
} from "src/utils";
import ShareSocialMedia from "../ShareSocialMedia";
import { HiOutlineExternalLink, HiOutlineShoppingBag } from "react-icons/hi";
import BuyModal from "src/component/BuyModal";
import GiftModal from "src/component/GiftModal";

const StyledMenuItem = withStyles((theme) => ({
  root: {},
}))(MenuItem);
const StyledMenu = withStyles({
  paper: {
    minWidth: "85px !important",
    boxShadow: "0px 0px 11px rgb(0 0 0 / 16%)",
    borderRadius: "15px",
    backgroundColor: "#FFFFFF !important",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));
export default function UserDataFeed({
  classes,
  viewData,
  handleCloseFeed,
  type,
  modalsOpenView,
  setModalsOpenView,
}) {
  const history = useHistory();
  const auth = useContext(AuthContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [categorySelect, setCategorySelect] = useState("");
  const [reportMsg, setReportMsg] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const [openShare, setOpenShare] = React.useState(false);

  const handleClickOpenShare = () => {
    if (auth?.userData?.kycStatus === "APPROVE") {
      setOpenShare(true);
      setAnchorEl(false);
    } else {
      toast.warn("Please complete your KYC to access this feature.");
    }
  };

  const handlerModalsOpen = (actionName, id) => {
    switch (actionName) {
      case "Report":
        setModalsOpenView({ ...modalsOpenView, reportModals: true, id: id });
        break;
      case "Gift":
        setModalsOpenView({ ...modalsOpenView, giftModals: true, id: id });
        break;
      case "Buy":
        setModalsOpenView({ ...modalsOpenView, buyModals: true, id: id });
        break;
      case "Delete":
        setModalsOpenView({ ...modalsOpenView, deleteModals: true, id: id });
        break;
      default:
        break;
    }
  };

  const reportHandler = async (id) => {
    const idSend = {
      postId: id,
      description: [{ reason: categorySelect ? categorySelect : reportMsg }],
    };
    if (categorySelect !== "" || reportMsg !== "") {
      try {
        setIsLoading(true);
        const res = await postDataIdinWithdata("reportOnPost", idSend);
        if (res) {
          handleCloseFeed();
          history.push("/home");
          setModalsOpenView({});
          setIsLoading(false);
          setCategorySelect("");
          setReportMsg("");
        } else {
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
      }
    } else {
      setIsSubmit(true);
    }
  };

  return (
    <Box className={classes.mainUserBox}>
      <Box>
        {(viewData && type === "event") ||
        checkMediaDoc(viewData) ||
        checkText(viewData) ||
        mediaTypeImg(viewData) ? (
          <img
            src={
              type === "event"
                ? viewData?.eventPost?.image
                : viewData && checkMediaDoc(viewData)
                ? viewData?.mediaType === "application/pdf"
                  ? "images/pdf.png"
                  : "images/docs.png"
                : checkText(viewData)
                ? "images/logo.png"
                : viewImage(viewData)
            }
            alt="bannerImage1"
            className="bannerImg"
          />
        ) : (
          <video
            controls="false"
            autoplay="true"
            loop
            muted
            playsinline="true"
            width="100%"
            className="bannerImg"
          >
            <source
              src={viewData ? viewVideo(viewData) : ""}
              type="video/mp4"
              accept="video/*"
            />
          </video>
        )}
        <Box className={classes.nameIconBox}>
          <Box
            display="flex"
            alignItems="center"
            className="databanner"
            style={{ cursor: "pointer" }}
            onClick={() =>
              viewData?.userId?._id !== auth?.userData?._id &&
              history.push({
                pathname: "/other-profile",
                search: viewData?.userId?._id,
              })
            }
          >
            <Avatar
              src={viewData?.userId?.profilePic && viewData?.userId?.profilePic}
            />
            <Box textAlign="left" m={"0px 8px"}>
              <Typography variant="h5">
                {viewData?.userId?.firstName
                  ? viewData?.userId?.firstName
                  : "Anonymous"}
              </Typography>
              <Typography variant="body1">
                {" "}
                {moment(viewData?.createdAt).local().fromNow()}
              </Typography>
            </Box>
          </Box>

          <Box className="iconBox">
            <Box></Box>
            <Box>
              {type !== "event" && (
                <IconButton onClick={handleClick}>
                  <BsThreeDots />
                </IconButton>
              )}
              <StyledMenu
                id="customized-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <StyledMenuItem onClick={handleClickOpenShare}>
                  <ListItemText primary="Share" />
                </StyledMenuItem>

                {viewData?.userId?._id !== auth.userData?._id && (
                  <>
                    <StyledMenuItem
                      onClick={(event) => {
                        event.stopPropagation();
                        auth?.userData?.kycStatus === "APPROVE"
                          ? (() => {
                              handlerModalsOpen("Report", viewData?._id);
                              setAnchorEl(null);
                            })()
                          : toast.warn(
                              "Please complete your KYC to access this feature."
                            );
                      }}
                    >
                      <ListItemText primary="Report" />
                    </StyledMenuItem>
                    {viewData?.postType === "NFT" && (
                      <>
                        <StyledMenuItem
                          onClick={(event) => {
                            event.stopPropagation();
                            auth?.userData?.kycStatus === "APPROVE"
                              ? (() => {
                                  handlerModalsOpen("Gift", viewData?._id);
                                  setAnchorEl(null);
                                })()
                              : toast.warn(
                                  "Please complete your KYC to access this feature."
                                );
                          }}
                        >
                          <ListItemText primary="Gift" />
                        </StyledMenuItem>

                        <StyledMenuItem
                          onClick={(event) => {
                            event.stopPropagation();
                            auth?.userData?.kycStatus === "APPROVE"
                              ? (() => {
                                  handlerModalsOpen("Buy", viewData?._id);
                                  setAnchorEl(null);
                                })()
                              : toast.warn(
                                  "Please complete your KYC to access this feature."
                                );
                          }}
                        >
                          <ListItemText primary="Buy" />
                        </StyledMenuItem>
                      </>
                    )}
                  </>
                )}
              </StyledMenu>
            </Box>
          </Box>
        </Box>
        <Box p="4px" display="flex" alignItems="center">
          <Typography
            variant={type === "event" ? "h4" : "h6"}
            style={{
              fontWeight: "300",
              wordBreak: "break-word",
              cursor: "pointer",
              color: "#fff",
            }}
          >
            {type === "event"
              ? viewData?.eventPost?.speaker
              : viewData?.postType === "NFT"
              ? viewData?.title
              : viewData &&
                viewData?.mediaPost?.title &&
                viewData?.mediaPost?.title}
          </Typography>
          {type === "event" && (
            <IconButton
              onClick={() =>
                type === "event" && window.open(viewData?.eventPost?.eventLink)
              }
            >
              <HiOutlineExternalLink />
            </IconButton>
          )}
        </Box>
        {type === "event" && (
          <Box p="4px">
            <Typography
              variant="h6"
              style={{ fontWeight: "300", wordBreak: "break-word" }}
            >
              {viewData?.eventPost?.description}
            </Typography>
          </Box>
        )}

        {openShare && (
          <ShareSocialMedia
            openShare={openShare}
            setOpenShare={setOpenShare}
            url={`${window.location.origin}/post-detail?${viewData?._id}`}
          />
        )}
        {modalsOpenView.reportModals && (
          <Report
            openCreateReport={modalsOpenView.reportModals}
            handleCloseCreateReport={() => {
              setModalsOpenView({ ...modalsOpenView, reportModals: false });
            }}
            setCategorySelect={(item) => setCategorySelect(item)}
            categorySelect={categorySelect}
            reportHandler={(id) => reportHandler(id)}
            id={modalsOpenView}
            isLoading={isLoading}
            reportMsg={reportMsg}
            setReportMsg={(item) => setReportMsg(item)}
            isSubmit={isSubmit}
          />
        )}
        {modalsOpenView?.buyModals && (
          <BuyModal
            handleOpenBuy={modalsOpenView?.buyModals}
            handleCloseBuy={() => {
              setModalsOpenView({ ...modalsOpenView, buyModals: false });
            }}
            auth={auth}
            data={viewData}
            listPostHandler={() => {
              auth.listPostHandler();
              auth.listPostHandlerTrending();
              auth.listPostHandlerBottom(1, []);
            }}
          />
        )}
        {modalsOpenView?.giftModals && (
          <GiftModal
            handleOpenGift={modalsOpenView?.giftModals}
            handleCloseGift={() => {
              setModalsOpenView({ ...modalsOpenView, giftModals: false });
            }}
            data={viewData}
            auth={auth}
          />
        )}
      </Box>
    </Box>
  );
}
