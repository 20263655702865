import { Contract } from "@ethersproject/contracts";
import { RPC_URL } from "src/constants";
import * as XLSX from "xlsx";
import Web3 from "web3";
import { ACTIVE_NETWORK, NetworkDetails } from "src/constants";
import { toast } from "react-toastify";
import { chatApiMainUrl } from "src/apiConfig/ApiConfig";

export function sortAddress(add) {
  const sortAdd = `${add?.slice(0, 6)}...${add?.slice(add.length - 4)}`;
  return sortAdd;
}
export function sortAddress2(add) {
  const sortAdd = `${add?.slice(0, 15)}...${add?.slice(add.length - 2)}`;
  return sortAdd;
}

export function getSigner(library, account) {
  return library.getSigner(account).connectUnchecked();
}

export function getProviderOrSigner(library, account) {
  return account ? getSigner(library, account) : library;
}

export function getContract(address, ABI, library, account) {
  return new Contract(address, ABI, getProviderOrSigner(library, account));
}

export const getWeb3Obj = (RPC_URL) => {
  const httpProvider = new Web3.providers.HttpProvider(RPC_URL);
  const web3 = new Web3(httpProvider);
  return web3;
};

export const getWeb3ContractObject = (abi, contractAddress, RPC_URL) => {
  const web3 = getWeb3Obj(RPC_URL);
  const contract = new web3.eth.Contract(abi, contractAddress);
  return contract;
};
export const getBalanceOf = async (abi, address, account) => {
  try {
    const contract = await getWeb3ContractObject(abi, address);
    const balanceOf = await contract.methods.balanceOf(account).call();
    return balanceOf.toString();
  } catch (error) {
    return 0;
  }
};
export const calculateTimeLeft = (endDate) => {
  if (endDate) {
    let difference = endDate * 1000 - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }
    return timeLeft;
  } else {
    return false;
  }
};
export const downloadExcel = (dataToPrint, sheetName) => {
  const workSheet = XLSX.utils.json_to_sheet(dataToPrint);
  const workBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workBook, workSheet, sheetName);
  let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
  XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
  XLSX.writeFile(workBook, `${sheetName}.xlsx`);
};

export function validUrl(value) {
  const re =
    /((http|https):\/\/.)(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;
  return re.test(value);
}
export const validateAccountAddress = async (account) => {
  const RPC_URL = "https://kovan.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161";
  const httpProvider = new Web3.providers.HttpProvider(RPC_URL);
  const web3 = new Web3(httpProvider);
  try {
    const accountCheck = web3.utils.toChecksumAddress(account);
    if (accountCheck !== "") {
      return true;
    }
  } catch (error) {
    console.log(error);
    return false;
  }
};
export const getBase64 = (file, cb) => {
  let reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = function () {
    cb(reader.result);
  };
  reader.onerror = function (err) {
    console.log("Error: ", err);
  };
};
export const resizeBase64Img = async (base64, newWidth, newHeight) => {
  return new Promise((resolve, reject) => {
    var canvas = document.createElement("canvas");
    canvas.style.width = newWidth.toString() + "px";
    canvas.style.height = newHeight.toString() + "px";
    let context = canvas.getContext("2d");
    let img = document.createElement("img");
    img.src = base64;
    img.onload = function () {
      context.scale(newWidth / img.width, newHeight / img.height);
      context.drawImage(img, 0, 0);
      resolve(canvas.toDataURL());
    };
  });
};

export const resizeBase64Img2 = (base64, newWidth, newHeight) => {
  var canvas = base64;
  var ctx = canvas.getContext("2d");
  var maxW = 324;
  var maxH = 324;
  var img = new Image();
  img.onload = function () {
    var iw = img.width;
    var ih = img.height;
    var scale = Math.min(maxW / iw, maxH / ih);
    var iwScaled = iw * scale;
    var ihScaled = ih * scale;
    canvas.width = iwScaled;
    canvas.height = ihScaled;
    ctx.drawImage(img, 0, 0, iwScaled, ihScaled);
    let output = canvas.toDataURL("image/jpeg", 0.5);
  };
  img.src = base64;
};
export const swichNetworkHandler = async () => {
  try {
    await window.ethereum.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: "0x" + ACTIVE_NETWORK.toString(16) }],
    });
  } catch (error) {
    if (error.code === 4902) {
      addNetworkHandler();
    }
  }
};
const addNetworkHandler = async () => {
  try {
    await window.ethereum.request({
      method: "wallet_addEthereumChain",
      params: NetworkDetails,
    });
  } catch (error) {
    console.log("ERROR", error);
  }
};
export const checkLocationEnabled = async () => {
  if (!navigator.geolocation) {
    alert("Geolocation is not supported.");
  } else {
    navigator.geolocation.getCurrentPosition(
      () => {
        console.log("Location is enabled.");
      }
      // () => {
      //   toast.warn("Please enable location services to continue.");
      // }
    );
  }
};
export const handleNegativeValue = (event) => {
  const {
    key,
    target: { value, selectionStart },
  } = event;
  const newValue =
    value.slice(0, selectionStart) + value.slice(selectionStart + 1);
  const parsedValue = parseFloat(newValue);
  if (
    ["ArrowUp", "ArrowDown", "-"].includes(key) &&
    (isNaN(parsedValue) || parsedValue < 0)
  ) {
    event.preventDefault();
  }
};

export const mediaTypeImg = (value) => {
  return value?.postType === "NFT"
    ? value?.mediaType?.split("/")[0] === "image"
    : value?.mediaPost?.media[0]?.mediaType === "image";
};

export const viewImage = (value) => {
  return value?.postType === "NFT"
    ? value?.mediaFile
    : value?.mediaPost?.media[0]?.mediaUrl;
};

export const mediaTypeVideo = (value) => {
  return value?.postType === "NFT"
    ? value?.mediaType?.split("/")[0] === "video"
    : value?.mediaPost?.media[0]?.mediaType === "video";
};

export const viewVideo = (value) => {
  return value?.postType === "NFT"
    ? value?.mediaFile
    : value?.mediaPost?.media[0]?.mediaUrl;
};

export const checkText = (value) => {
  return value?.mediaPost?.media?.length === 0 && value?.postType === "MEDIA";
};

export const checkMediaDoc = (value) => {
  return (
    value?.postType === "NFT" && !mediaTypeImg(value) && !mediaTypeVideo(value)
  );
};

export const paramsVal = chatApiMainUrl
  ? {
      secure: true,
      reconnect: true,
      rejectUnauthorized: false,
      transports: ["websocket"],
    }
  : {
      secure: true,
      reconnect: true,
      rejectUnauthorized: false,
    };

export const monthArray = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
