import React from 'react'

const MyNetwork = ({ className }) => {
  return (
    <svg
      width="20"
      height="19"
      viewBox="0 0 20 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_10_7115)">
        <path
          d="M19.9996 5.34835C19.9071 5.57772 19.8138 5.82319 19.576 5.93546C19.3727 6.03164 19.1382 6.06745 18.9153 6.12137C18.8482 6.13747 18.7737 6.12379 18.6704 6.12379C18.6704 6.25578 18.6704 6.37811 18.6704 6.50044C18.6704 6.78333 18.672 7.06582 18.67 7.34871C18.6679 7.60867 18.5467 7.72536 18.2742 7.72577C17.4286 7.72738 16.583 7.72738 15.7374 7.72577C15.4533 7.72536 15.3329 7.61309 15.3283 7.33865C15.322 6.94912 15.3225 6.56 15.3195 6.17047C15.3195 6.16524 15.3137 6.1596 15.2916 6.12017C15.1983 6.12017 15.0838 6.12741 14.9705 6.11896C14.4452 6.07952 14.0536 5.76565 14.0165 5.26707C13.9853 4.84615 13.9849 4.4035 14.0853 3.99707C14.2615 3.28401 14.7759 2.83855 15.5082 2.63654C15.5574 2.62286 15.6066 2.61078 15.6757 2.59268C15.3679 2.15808 15.2521 1.69853 15.392 1.19431C15.4933 0.82893 15.7036 0.535576 16.0181 0.311436C16.6346 -0.127991 17.499 -0.0994205 18.0755 0.378235C18.7429 0.931946 18.8337 1.67841 18.34 2.59026C18.3704 2.60193 18.3992 2.61722 18.43 2.62487C19.2677 2.82849 19.7759 3.34075 19.9663 4.152C19.9717 4.17494 19.9888 4.19506 20.0009 4.21679C19.9996 4.59424 19.9996 4.9713 19.9996 5.34835Z"
          fill="white"
        />
        <path
          d="M20.0005 16.6266C19.8597 16.9916 19.6339 17.2785 19.2169 17.3602C19.0478 17.3932 18.8699 17.3831 18.6712 17.394C18.6712 17.7711 18.6712 18.1457 18.6712 18.5203C18.6712 18.905 18.575 18.9988 18.178 18.9988C17.3782 18.9988 16.578 18.9992 15.7782 18.9988C15.4508 18.9984 15.3304 18.8845 15.3283 18.5686C15.3262 18.1807 15.3279 17.7924 15.3279 17.3819C15.2125 17.3819 15.1171 17.3831 15.0213 17.3819C14.4852 17.3751 14.0678 17.0576 14.0182 16.5433C13.9807 16.1538 13.9786 15.7454 14.0586 15.3643C14.2169 14.6057 14.7251 14.1233 15.4978 13.9076C15.5528 13.8923 15.6074 13.8762 15.6628 13.8601C15.1692 12.8975 15.2754 12.1326 15.9711 11.6098C16.6051 11.1334 17.4819 11.1535 18.0918 11.6581C18.7466 12.2006 18.8349 12.9881 18.3609 13.8126C18.6596 13.9651 18.9803 14.089 19.2527 14.2778C19.6397 14.5462 19.8647 14.9365 19.9617 15.3904C19.9692 15.4262 19.9876 15.46 20.0005 15.4951C20.0005 15.8721 20.0005 16.2496 20.0005 16.6266Z"
          fill="white"
        />
        <path
          d="M10.0172 5.15436C12.5982 5.16764 14.6893 7.19375 14.6747 9.66774C14.6602 12.1655 12.5565 14.1839 9.9805 14.1719C7.41698 14.1598 5.30792 12.1043 5.33125 9.64078C5.35541 7.14345 7.44613 5.14148 10.0172 5.15436ZM6.18562 8.50358C5.80863 9.86774 6.03649 11.0947 6.97042 12.1615C7.75356 13.056 8.77372 13.4962 10.0042 13.5147C10.0042 13.3067 10.0113 13.1248 10.0022 12.9437C9.99383 12.782 10.0609 12.6693 10.1921 12.5763C10.4545 12.3912 10.7128 12.2001 10.9728 12.0114C11.2419 11.8162 11.2506 11.8118 11.0927 11.5321C10.9836 11.3389 10.9911 11.1735 11.114 10.988C11.3485 10.6339 11.5918 10.2814 11.7821 9.90476C11.9796 9.51402 12.2887 9.31121 12.6957 9.15628C13.1035 9.00096 13.478 8.90277 13.8745 9.14622C13.8891 9.15508 13.912 9.15186 13.9312 9.15468C13.8999 8.22069 13.183 7.01307 12.1279 6.39377C11.0906 5.78533 9.99466 5.6461 8.81121 5.97808C8.93493 6.22033 9.03115 6.44085 9.15737 6.64366C9.2336 6.76599 9.33899 6.88752 9.46188 6.96398C9.88802 7.22836 10.3254 7.47705 10.7632 7.72332C10.9328 7.81869 11.0152 7.94504 11.0127 8.13538C11.0073 8.51244 11.019 8.88989 11.0065 9.26654C11.0032 9.37238 10.9636 9.49229 10.9007 9.57881C10.3821 10.2939 9.8547 11.0029 9.32775 11.7124C9.14821 11.9542 8.98158 11.978 8.73623 11.8013C8.35007 11.5236 7.96934 11.2391 7.57818 10.9683C7.41697 10.8569 7.33866 10.7196 7.30992 10.5333C7.23452 10.0432 7.14954 9.55467 7.05956 9.06655C7.04665 8.99613 7.00375 8.90438 6.94543 8.87259C6.70924 8.74503 6.46096 8.63557 6.18562 8.50358Z"
          fill="white"
        />
        <path
          d="M1.33728 6.13675C1.16524 6.12588 1.01569 6.12387 0.867814 6.10616C0.382519 6.04741 0.0109449 5.65185 0.0105284 5.18224C0.0101118 4.84382 -0.0132157 4.49775 0.0484356 4.16818C0.200897 3.35572 0.722434 2.84949 1.54556 2.63179C1.5768 2.62374 1.60805 2.61449 1.63804 2.60362C1.64929 2.5996 1.65845 2.58914 1.66803 2.58149C1.03777 1.67608 1.40768 0.802856 1.9363 0.374293C2.54906 -0.121873 3.44509 -0.126702 4.07326 0.371476C4.59605 0.786357 4.97595 1.66079 4.33861 2.59437C4.41109 2.61288 4.47774 2.62938 4.54439 2.64748C5.40376 2.88128 5.98778 3.61849 6.01236 4.50338C6.01778 4.70418 6.01611 4.90579 6.01319 5.10659C6.00486 5.68806 5.61163 6.08645 5.00969 6.12145C4.90764 6.12749 4.80516 6.12226 4.68061 6.12226C4.68061 6.48966 4.68061 6.83371 4.68061 7.17737C4.68061 7.25262 4.68519 7.32867 4.67686 7.40352C4.65437 7.61156 4.5344 7.72384 4.31945 7.72464C3.44134 7.72746 2.56281 7.72746 1.68469 7.72424C1.46558 7.72343 1.34061 7.59547 1.33853 7.37696C1.33478 6.97013 1.33728 6.56249 1.33728 6.13675Z"
          fill="white"
        />
        <path
          d="M1.67085 13.8571C1.16972 12.9537 1.26428 12.181 1.94286 11.6362C2.54688 11.1513 3.43915 11.144 4.04984 11.6189C4.73925 12.1557 4.84214 12.9263 4.36142 13.8269C4.62677 13.9496 4.91295 14.0438 5.15456 14.2015C5.70151 14.5581 5.97435 15.082 6.00685 15.7142C6.02143 16.0015 6.02559 16.2948 5.98435 16.5785C5.91354 17.0638 5.51489 17.3737 5.00835 17.3821C4.90587 17.3838 4.8034 17.3825 4.68051 17.3825C4.68051 17.7733 4.68093 18.1423 4.68051 18.5117C4.68009 18.9057 4.58179 18.9998 4.17272 18.9998C3.3725 18.9998 2.57229 19.0006 1.77249 18.9994C1.45507 18.999 1.33801 18.8851 1.3376 18.5785C1.33676 18.1894 1.3376 17.8002 1.3376 17.3821C1.21346 17.3821 1.09891 17.3842 0.984768 17.3817C0.438238 17.3701 0.0120942 16.9568 0.00959481 16.4296C0.00792856 16.1222 -0.0208143 15.8083 0.0300064 15.5077C0.170805 14.6731 0.679427 14.1343 1.5263 13.9021C1.57004 13.8901 1.61378 13.8792 1.65752 13.8675C1.6621 13.8655 1.66626 13.8603 1.67085 13.8571Z"
          fill="white"
        />
        <path
          d="M6.49077 3.08143C6.38413 2.88224 6.28916 2.70478 6.18335 2.5072C7.21851 1.98608 8.30948 1.69434 9.4592 1.62593C10.9871 1.53498 12.441 1.81747 13.8235 2.50559C13.7219 2.69673 13.6252 2.87902 13.5182 3.07982C12.411 2.53657 11.2425 2.25932 10.0116 2.25811C8.77895 2.2569 7.60883 2.53134 6.49077 3.08143Z"
          fill="white"
        />
        <path
          d="M13.0747 16.4477C13.1651 16.6481 13.2479 16.8308 13.3433 17.0416C11.1131 17.9329 8.90278 17.9317 6.66626 17.0404C6.7604 16.8328 6.84497 16.6457 6.93453 16.4473C8.9811 17.2593 11.0202 17.2625 13.0747 16.4477Z"
          fill="white"
        />
        <path
          d="M2.44968 10.895C2.22015 10.9324 2.01229 10.9662 1.78776 11.0029C1.63613 10.1039 1.63571 9.22262 1.78734 8.31641C2.00895 8.35182 2.2164 8.38441 2.44468 8.42103C2.31763 9.24476 2.30763 10.0556 2.44968 10.895Z"
          fill="white"
        />
        <path
          d="M17.5618 8.42178C17.7922 8.38556 17.9996 8.35297 18.2204 8.31836C18.3708 9.22297 18.3716 10.1038 18.2191 11.002C17.995 10.9646 17.7872 10.9296 17.5605 10.8913C17.6959 10.064 17.6922 9.24872 17.5618 8.42178Z"
          fill="white"
        />
        <path
          d="M16.678 4.83398C16.9071 4.83398 17.112 4.83398 17.3286 4.83398C17.3286 5.04605 17.3286 5.24887 17.3286 5.46455C17.1104 5.46455 16.9008 5.46455 16.678 5.46455C16.678 5.25329 16.678 5.05088 16.678 4.83398Z"
          fill="white"
        />
        <path
          d="M16.6816 16.1055C16.8999 16.1055 17.1049 16.1055 17.3256 16.1055C17.3256 16.3091 17.3256 16.5111 17.3256 16.7268C17.1182 16.7268 16.9087 16.7268 16.6816 16.7268C16.6816 16.5276 16.6816 16.326 16.6816 16.1055Z"
          fill="white"
        />
        <path
          d="M3.33244 4.83789C3.33244 5.05479 3.33244 5.25277 3.33244 5.46444C3.1125 5.46444 2.90255 5.46444 2.67969 5.46444C2.67969 5.25639 2.67969 5.05358 2.67969 4.83789C2.89422 4.83789 3.10375 4.83789 3.33244 4.83789Z"
          fill="white"
        />
        <path
          d="M3.32744 16.7331C3.10333 16.7331 2.89797 16.7331 2.67969 16.7331C2.67969 16.5251 2.67969 16.3231 2.67969 16.1094C2.89297 16.1094 3.10292 16.1094 3.32744 16.1094C3.32744 16.3138 3.32744 16.5154 3.32744 16.7331Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_10_7115">
          <rect width="20" height="19" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default MyNetwork
