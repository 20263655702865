import React from "react";
import { Box, Grid } from "@material-ui/core";
import Page from "src/component/Page";
import MyJob from "./MyJobs";

const Index = ({ tabView1 }) => {
  return (
    <Page title="Job">
      <Grid container spacing={1}>
        <Grid item xs={12} md={12} lg={12}>
          <Box className="scrollDiv">
            <MyJob tabView1={tabView1} />
          </Box>
        </Grid>
      </Grid>
    </Page>
  );
};

export default Index;
