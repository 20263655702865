import React, { useState, useContext, useEffect } from "react";
import {
  makeStyles,
  Box,
  Grid,
  IconButton,
  Typography,
} from "@material-ui/core";
import PhotosCards from "src/component/PhotosCards";
import { AiOutlinePlus } from "react-icons/ai";
import { MdDelete } from "react-icons/md";
import { HiUpload } from "react-icons/hi";
import { dataPostHandler } from "../../../apiConfig/service/index";
import { toast } from "react-toastify";
import DataNotFound from "src/component/DataNotFound";
import PhotoSkeleton from "src/Skeletons/PhotoSkeleton";
import { AuthContext } from "src/context/Auth";
import ConfirmationBlockChain from "src/component/ConfirmationBlockChain";

const useStyles = makeStyles((theme) => ({
  GridBox: {
    position: "relative",
    "& .DeleteICon": {
      position: "absolute",
      zIndex: "100",
      top: "2px",
      right: "1px",
      "& svg": { background: "rgb(255, 255, 255)" },
    },
    "& .DeleteICon1": {
      position: "absolute",
      zIndex: "100",
      top: "2px",
      right: "41px",
      "& svg": { background: "rgb(255, 255, 255)" },
    },
    " & .design": {
      background: "rgba(128, 128, 128, 0.4)",
      borderRadius: "5px",
      minHeight: "222px",
    },
    width: "20%",
    [theme.breakpoints.down("sm")]: {
      width: "33%",
    },
    [theme.breakpoints.down("xs")]: {
      width: "50%",
    },
    "& .iconstyle": {
      width: "28px",
      border: "1px dashed #4057A7",
      height: "28px",
      display: "flex",
      alignItems: "center",
      borderRadius: "50%",
      justifyContent: "center",
      color: "#4057A7",
      padding: "2px",
      "& svg": {
        color: "#4057A7",
      },
    },
  },

  PhotosCard: {
    " & svg": {},
  },
  BoxImg: {
    background: "rgba(128, 128, 128, 0.4)",
    border: "1px dashed #939393",
    backdropFilter: "blur(30px)",
    display: "flex",
    borderRadius: "5px",
    maxHeight: "225px",
    flexDirection: "column",
    minHeight: "225px",
    textAlign: "center",
    cursor: "pointer",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "5px",

    "& h6": {
      color: "#fff",
    },
    " & svg": {
      color: "rgb(153 153 153)",
      cursor: "pointer",
    },
    " & span": {
      color: "#4057A7",
      fontSize: "12px",
    },
  },

  imagescontrol: {
    "& figure": {
      width: "100%",
      borderRadius: "5px",
      overflow: "hidden",
      transform: "scale(1)",
      margin: "5px 0px",
      maxHeight: "227px",
      minHeight: "227px",
      display: "flex",
      justifyContent: "center",
      border: "1px solid #E2E2E2",
      flexDirection: "column",
      alignItems: "center",
      "& img": {
        width: "78px",
        minHeight: "84px",
      },
    },
    "& .loaderBox": {
      position: "absolute",
      background: "#0000009e",
      width: "100%",
      borderRadius: "5px",
      height: "100%",
      top: "0",
      right: "0",
      zIndex: "999",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  },

  photsDiv: {
    paddingTop: "50px",
  },
}));

function FollowersPage() {
  const classes = useStyles();
  const auth = useContext(AuthContext);
  const [profileImage, setProfileImage] = useState("");
  const isDocx = profileImage?.type?.includes("application/pdf");
  const [isLoading, setIsLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const handleUploadPhoto = async () => {
    try {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("image", profileImage);
      formData.append("type", "DOCUMENT");
      const response = await dataPostHandler("addMedia", formData);
      if (response.data.responseCode === 200) {
        auth.getPhotoLibraryHandle("DOCUMENT");
        setIsLoading(false);
        setOpenModal(false);
        setProfileImage("");
      } else {
        setIsLoading(false);
        toast.error(response.data.responseMessage);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (auth?.photoLibraryData.length > 0)
      auth.getPhotoLibraryHandle("DOCUMENT");
  }, []);

  useEffect(() => {
    if (profileImage !== "") {
      setOpenModal(true);
    }
  }, [profileImage]);
  return (
    <Box className="scrollDiv">
      <Box className={classes.photsDiv}>
        <Box className="paddingBox">
          <Box className={classes.PhotosCard}>
            <Grid container spacing={2}>
              <Box className={classes.GridBox}>
                <Box className={classes.Cards}>
                  <figure
                    className="figure"
                    style={{
                      margin: "0px",
                      marginRight: "6px",
                      marginLeft: "6px",
                    }}
                  >
                    {profileImage === "" ? (
                      <>
                        {" "}
                        <Box my={2} className={classes.BoxImg}>
                          <label htmlFor="raised-button-file1">
                            <AiOutlinePlus
                              style={{
                                width: "33px",
                                border: "1px dashed #4057A7",
                                height: "33px",
                                display: "flex",
                                alignItems: "center",
                                borderRadius: "50%",
                                justifyContent: "center",
                                color: "#4057A7",
                                marginBottom: "10px",
                              }}
                            />
                          </label>
                          <span>Add Documents</span>
                        </Box>
                      </>
                    ) : (
                      <>
                        <Box className={classes.imagescontrol}>
                          <figure>
                            {isDocx ? (
                              <img src="images/pdf.png" alt="" />
                            ) : (
                              <img src="images/docs.png" alt="" />
                            )}
                            <Box m={1} style={{ wordBreak: "break-all" }}>
                              <Typography variant="body2">
                                {profileImage.name}
                              </Typography>
                            </Box>
                            {isLoading ? (
                              <Box className="loaderBox">
                                <img
                                  src="images/photoloading.gif"
                                  width="100%"
                                />
                              </Box>
                            ) : (
                              ""
                            )}
                          </figure>
                        </Box>
                      </>
                    )}
                  </figure>
                  {profileImage !== "" && (
                    <>
                      {!isLoading && (
                        <IconButton
                          className="DeleteICon"
                          onClick={() => setProfileImage("")}
                          disabled={isLoading}
                        >
                          <MdDelete className="iconstyle" />
                        </IconButton>
                      )}
                      <IconButton
                        className="DeleteICon1"
                        onClick={handleUploadPhoto}
                        disabled={isLoading}
                      >
                        {isLoading ? "..." : <HiUpload className="iconstyle" />}
                      </IconButton>
                    </>
                  )}
                </Box>
                <>
                  <input
                    style={{ display: "none" }}
                    id="raised-button-file1"
                    type="file"
                    accept=".doc, .docx, .pdf"
                    onChange={(e) => {
                      // const maxSize = 2  1024  1024;
                      // if (e.target.files[0].size <= maxSize) {
                      setProfileImage(e.target.files[0]);
                      // }
                    }}
                    disabled={isLoading}
                  />
                </>
              </Box>

              {auth?.photoLoading ? (
                <>
                  {[1, 2, 4].map((item, i) => {
                    return <PhotoSkeleton type="phototab" />;
                  })}
                </>
              ) : (
                <>
                  {auth?.photoLibraryData &&
                    auth?.photoLibraryData.map((data, index) => {
                      if (data.type !== "DOCUMENT") {
                        return;
                      }
                      return (
                        <Box className={classes.GridBox}>
                          <PhotosCards
                            data={data}
                            index={index}
                            getPhotoLibraryHandle={auth.getPhotoLibraryHandle}
                            type="ebook"
                            profileImage={profileImage}
                          />
                        </Box>
                      );
                    })}
                </>
              )}

              {!auth.photoLoading &&
                auth?.photoLibraryData &&
                auth?.photoLibraryData.length === 0 && <DataNotFound />}
            </Grid>
          </Box>
        </Box>
      </Box>
      <ConfirmationBlockChain
        handleUploadApi={handleUploadPhoto}
        dataSend={profileImage}
        openModal={openModal}
        setIsLoading={setIsLoading}
        isLoading={isLoading}
        handleCloseModal={() => setOpenModal(false)}
      />
    </Box>
  );
}

export default FollowersPage;
