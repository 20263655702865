import React, { useContext, useState, useRef, useEffect } from "react";
import {
  Box,
  Typography,
  makeStyles,
  Avatar,
  IconButton,
} from "@material-ui/core";
import { MdFavorite } from "react-icons/md";
import { AiOutlineHeart } from "react-icons/ai";
import { ImGift } from "react-icons/im";
import { HiOutlineShoppingBag } from "react-icons/hi";
import ViewLiveFeedModal from "src/component/ViewLiveFeedModal";
import BuyModal from "src/component/BuyModal";
import GiftModal from "src/component/GiftModal";
import Report from "src/component/Report";
import { AiOutlineDelete } from "react-icons/ai";
import moment from "moment";
import {
  deleteDataAPIHandler,
  postDataIdinQuery,
  postDataIdinWithdata,
  patchAPIHandlerPathId,
} from "src/apiConfig/service";
import { AuthContext } from "src/context/Auth";
import {
  checkMediaDoc,
  checkText,
  mediaTypeImg,
  mediaTypeVideo,
  viewImage,
  viewVideo,
} from "src/utils";
import { useHistory } from "react-router-dom";
import { GoReport } from "react-icons/go";
import { toast } from "react-toastify";
import ConfirmationDialog from "src/component/ConfirmationDialog";

const useStyles = makeStyles((theme) => ({
  bannerbox: {
    backgroundPosition: "center center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    minHeight: "338px",
    maxHeight: "338px",
    overflow: "hidden",
    position: "relative",
    borderRadius: "5px",
    cursor: "pointer",
    background:
      "linear-gradient(180deg, #D9D9D9 -2.78%, rgba(0, 0, 0, 0) -2.78%, #000000 100%)",

    "& .databanner": {
      overflow: "hidden",
      position: "absolute",
      top: "15px",
      left: "20px",
      zIndex: "1",
      "& h6": {
        color: "#fff",
        margin: "0px 8px",
      },
      "& p": {
        color: "#fff",
        // marginLeft: "5px",
      },
    },
    "& .subtext": {
      position: "absolute",
      bottom: "15px",
      left: "20px",
      "& h5": {
        color: "#fff",
        width: "100%",
        maxWidth: "341px",
      },
    },
  },

  iconboxes: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    position: "absolute",
    zIndex: 1,
    bottom: "0px",
    right: "22px",

    "& .icon1": {
      backgroundColor: "#FFFFFF",
      width: "40px",
      height: "40px",
      borderRadius: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginBottom: "14px",
      "& svg": {
        color: "#4057A7",
      },
    },
  },
}));
function BannerMain({
  data,
  listPostHandler,
  modalsOpen,
  setModalsOpen,
  modalsOpenView,
  setModalsOpenView,
}) {
  const auth = useContext(AuthContext);
  const userData = auth?.userData;
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingLike, setIsLoadingLike] = useState(false);
  const [categorySelect, setCategorySelect] = useState("");
  const [reportMsg, setReportMsg] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);
  const classes = useStyles();
  const videoRef = useRef(null);

  const addLikeHandler = async (id) => {
    try {
      setIsLoadingLike(true);
      const res = await postDataIdinQuery("addLike", {
        postId: id,
      });
      if (res?.responseCode === 200) {
        if (data?.postType === "NFT") {
          await patchAPIHandlerPathId("addToFav", id);
        }
        listPostHandler();
        setIsLoadingLike(false);
      } else {
        setIsLoadingLike(false);
      }
    } catch (error) {
      setIsLoadingLike(false);
    }
  };
  const deeleteHandler = async (id) => {
    const idSend = {
      _id: id,
    };
    try {
      setIsLoading(true);
      const res = await deleteDataAPIHandler("deletePost", idSend);
      if (res) {
        listPostHandler();
        setModalsOpen({});
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };
  const reportHandler = async (id) => {
    const idSend = {
      postId: id,
      description: [{ reason: categorySelect ? categorySelect : reportMsg }],
    };
    if (categorySelect !== "" || reportMsg !== "") {
      try {
        setIsLoading(true);
        const res = await postDataIdinWithdata("reportOnPost", idSend);
        if (res) {
          listPostHandler();
          setModalsOpen({});
          setIsLoading(false);
          setCategorySelect("");
          setReportMsg("");
        } else {
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
      }
    } else {
      setIsSubmit(true);
    }
  };

  useEffect(() => {
    // Check if videoRef exists before setting properties

    if (videoRef.current && modalsOpen?.liveFeeds) {
      videoRef.current.muted = true; // Set muted based on openFeed value
      videoRef.current.pause(); // Pause the video when openFeed is true
    }
  }, [modalsOpen?.liveFeeds]);
  return (
    <>
      <Box
        className={classes.bannerbox}
        onClick={() => {
          setModalsOpen({ ...modalsOpen, liveFeeds: true, id: data?._id });
        }}
        style={
          (data && checkMediaDoc(data)) || checkText(data) || mediaTypeImg(data)
            ? {
                backgroundImage:
                  "url(" +
                  `${
                    data && checkMediaDoc(data)
                      ? data?.mediaType === "application/pdf"
                        ? "images/pdf.png"
                        : "images/docs.png"
                      : checkText(data)
                      ? "images/logo.png"
                      : viewImage(data)
                  }` +
                  ")",
              }
            : {
                display: "flex",
                alignItems: "center",
              }
        }
      >
        {data && !mediaTypeImg(data) && mediaTypeVideo(data) && (
          <video
            ref={videoRef}
            controls={modalsOpen?.liveFeeds ? false : true}
            autoplay={modalsOpen?.liveFeeds ? false : true}
            loop
            muted
            playsInline={true}
            width="100%"
          >
            <source
              src={data ? data && viewVideo(data) : ""}
              type="video/mp4"
              accept="video/*"
            />
          </video>
        )}
        <Box
          display="flex"
          alignItems="center"
          className="databanner"
          onClick={() =>
            data?.userId?._id !== auth?.userData?._id &&
            history.push({
              pathname: "/other-profile",
              search: data?.userId?._id,
              state: {
                id: data?.userId?._id,
              },
            })
          }
        >
          <Avatar src={data?.userId?.profilePic && data?.userId?.profilePic} />
          <Box>
            <Typography variant="h6">
              {data?.userId?.firstName ? data?.userId?.firstName : "Anonymous"}
            </Typography>
            <Typography variant="body1" style={{ margin: "0px 8px" }}>
              {moment(data?.createdAt).local().fromNow()}
            </Typography>
          </Box>
        </Box>
        <Box className={classes.iconboxes}>
          <Box className="icon1">
            <IconButton
              disabled={isLoadingLike}
              onClick={(event) => {
                event.stopPropagation();
                auth?.userData?.kycStatus === "APPROVE"
                  ? addLikeHandler(data?._id)
                  : toast.warn(
                      "Please complete your KYC to access this feature."
                    );
              }}
            >
              {isLoadingLike ? (
                "..."
              ) : (
                <>
                  {data?.islike ? (
                    <MdFavorite
                      style={data?.islike === true ? { color: "red" } : {}}
                    />
                  ) : (
                    <AiOutlineHeart
                      style={data?.islike === true ? { color: "red" } : {}}
                    />
                  )}
                </>
              )}
            </IconButton>
          </Box>
          {userData?._id !== data?.userId?._id && (
            <>
              {data?.postType === "NFT" && (
                <>
                  <Box className="icon1">
                    <IconButton
                      onClick={(event) => {
                        event.stopPropagation();
                        auth?.userData?.kycStatus === "APPROVE"
                          ? setModalsOpen({
                              ...modalsOpen,
                              giftModals: true,
                              id: data?._id,
                            })
                          : toast.warn(
                              "Please complete your KYC to access this feature."
                            );
                      }}
                    >
                      <ImGift />
                    </IconButton>
                  </Box>

                  <Box className="icon1">
                    <IconButton
                      onClick={(event) => {
                        event.stopPropagation();
                        auth?.userData?.kycStatus === "APPROVE"
                          ? setModalsOpen({
                              ...modalsOpen,
                              buyModals: true,
                              id: data?._id,
                            })
                          : toast.warn(
                              "Please complete your KYC to access this feature."
                            );
                      }}
                    >
                      <HiOutlineShoppingBag />
                    </IconButton>
                  </Box>
                </>
              )}
              <Box className="icon1">
                <IconButton
                  onClick={(event) => {
                    event.stopPropagation();
                    auth?.userData?.kycStatus === "APPROVE"
                      ? setModalsOpen({
                          ...modalsOpen,
                          reportModals: true,
                          id: data?._id,
                        })
                      : toast.warn(
                          "Please complete your KYC to access this feature."
                        );
                  }}
                >
                  <GoReport />
                </IconButton>
              </Box>
            </>
          )}
          {userData?._id === data?.userId?._id && (
            <Box className="icon1">
              <IconButton
                onClick={(event) => {
                  event.stopPropagation();
                  setModalsOpen({
                    ...modalsOpen,
                    deleteModals: true,
                    id: data?._id,
                  });
                }}
              >
                <AiOutlineDelete />
              </IconButton>
            </Box>
          )}
        </Box>
        <Box className="subtext">
          <Typography style={{ wordBreak: "break-word" }} variant="h5">
            {data?.postType === "NFT" ? (
              <>
                {data?.title.slice(0, 50)}
                {data?.title.length >= 50 && "..."}
              </>
            ) : (
              <>
                {data?.mediaPost?.title.slice(0, 50)}
                {data?.mediaPost?.title.length >= 50 && "..."}
              </>
            )}
          </Typography>
        </Box>
      </Box>

      {modalsOpen?.reportModals && (
        <Report
          openCreateReport={modalsOpen?.reportModals}
          handleCloseCreateReport={() => {
            setModalsOpen({ ...modalsOpen, reportModals: false });
          }}
          setCategorySelect={(item) => setCategorySelect(item)}
          categorySelect={categorySelect}
          reportHandler={(id) => reportHandler(id)}
          id={modalsOpen}
          isLoading={isLoading}
          reportMsg={reportMsg}
          setReportMsg={(item) => setReportMsg(item)}
          isSubmit={isSubmit}
        />
      )}
      {modalsOpen?.buyModals && (
        <BuyModal
          handleOpenBuy={modalsOpen?.buyModals}
          handleCloseBuy={() => {
            setModalsOpen({ ...modalsOpen, buyModals: false });
          }}
          auth={auth}
          data={data}
          listPostHandler={listPostHandler}
        />
      )}
      {modalsOpen?.giftModals && (
        <GiftModal
          handleOpenGift={modalsOpen?.giftModals}
          handleCloseGift={() => {
            setModalsOpen({ ...modalsOpen, giftModals: false });
          }}
          data={data}
          auth={auth}
        />
      )}
      {modalsOpen?.liveFeeds && (
        <ViewLiveFeedModal
          openFeed={modalsOpen?.liveFeeds}
          id={modalsOpen}
          handleCloseFeed={() => {
            setModalsOpen({ ...modalsOpen, liveFeeds: false });
          }}
          modalsOpenView={modalsOpenView}
          setModalsOpenView={setModalsOpenView}
        />
      )}
      {modalsOpen?.deleteModals && (
        <ConfirmationDialog
          open={modalsOpen?.deleteModals}
          handleClose={() =>
            setModalsOpen({ ...modalsOpen, deleteModals: false })
          }
          isLoading={isLoading}
          title="Delete"
          desc="Are you sure, you want to delete?"
          confirmationHandler={() => deeleteHandler(modalsOpen?.id)}
        />
      )}
    </>
  );
}

export default BannerMain;
