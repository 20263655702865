import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Paper } from "@material-ui/core";
import CardHeader from "@material-ui/core/CardHeader";
import Skeleton from "@material-ui/lab/Skeleton";
const useStyles = makeStyles((theme) => ({
  PostBox: {
    marginTop: "15px",
    "& .MuiCardHeader-root": {
      padding: "0 0 8px 0",
    },
    "& .MuiCardContent-root": {
      padding: "16px 16px 16px 0",
    },
  },
  eventBox1: {
    "& .MuiCardHeader-root": {
      padding: "0 0 8px 0",
      display: "flex",
      alignItems: "flex-start",
    },
    "& .MuiCardContent-root": {
      padding: "16px 16px 16px 0",
    },
    "& .MuiCardHeader-content": {
      marginTop: "10px",
    },
  },
  eventBox: {
    width: "140px",
    height: "140px",
    borderRadius: "10px",
  },
  circleBox: {
    width: "40px",
    height: "40px",
  },
}));

export default function CommentListSkeleton({ type }) {
  const classes = useStyles();
  return (
    <div>
      <Box className={type === "event" ? classes.eventBox1 : classes.PostBox}>
        <CardHeader
          avatar={
            <Skeleton
              animation="wave"
              variant="circle"
              className={
                type === "event" ? classes.eventBox : classes.circleBox
              }
            />
          }
          title={
            <Skeleton
              animation="wave"
              height={20}
              width={type === "notification" ? "250px" : "60%"}
              style={{ marginBottom: 6 }}
            />
          }
          subheader={<Skeleton animation="wave" height={15} width="30%" />}
        />
      </Box>
    </div>
  );
}
