import React, { useState } from "react";
import {
  makeStyles,
  Dialog,
  DialogContent,
  IconButton,
  FormControl,
  Typography,
  TextField,
  Box,
  MenuItem,
  Button,
  Select,
  Grid,
} from "@material-ui/core";
import { IoClose } from "react-icons/io5";
import FormHelperText from "@material-ui/core/FormHelperText";
import "react-phone-input-2/lib/style.css";
import { dataPostHandler } from "src/apiConfig/service";
import PollOption from "./polloption/PollOption";
import ButtonCircularProgress from "./ButtonCircularProgress";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  mainmodalBox: {
    "& .formControl": {
      width: "100%",
      backgroundColor: "transparent",
      border: "none",
      color: "#fff",
      "&:focus-visible": {
        outline: "none",
      },
    },
    "& .helpText": {
      textAlign: "end",
      marginTop: "0px",
    },
    "& h6": {
      fontWeight: "700",
    },
    "& p": {
      color: "rgba(0, 0, 0, 0.4)",
      background: "#fff",
    },
    "& h5": {
      color: "rgba(50, 50, 50, 0.6)",
    },
  },
  cancelBtn: {
    position: "absolute",
    top: 0,
    right: 0,
    "& svg": {
      color: "#fff",
      fontWeight: "700",
    },
  },

  commentControl: {
    "& .MuiInputBase-input": {
      background: "#fff",
    },
  },
  mainmodalBoxx: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    height: "auto",
  },
  selectformControl: {
    "& .MuiSelect-select": {
      background: "#fff",
    },
  },
  flexboxbtn: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    flexWrap: "wrap",
    "& .MuiChip-root": {
      background: "#4057a7",
      color: " #fff",
      fontSize: "14px",
      margin: "6px 6px",
    },
    "& .MuiChip-deleteIcon": {
      color: "#fff",
    },
  },
  inputBox: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    marginBottom: "20px",
  },
  buttonBox: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: "5px",
  },
}));

function PollModal({ openCreatePoll, handleCloseCreatePoll, listPostHandler }) {
  const classes = useStyles();
  const [question, setQuestion] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [transpotation, setTranspotation] = useState([
    {
      option: "",
      error: false,
    },
    {
      option: "",
      error: false,
    },
  ]);
  const [age, setAge] = React.useState(1);
  const [isValid, setIsValid] = useState([
    {
      state: "",
    },
  ]);

  const addFormFields = () => {
    setTranspotation([...transpotation, { option: "" }]);
  };
  const removeFormFields = (i) => {
    let newFormValues = [...transpotation];
    newFormValues.splice(i, 1);
    setTranspotation(newFormValues);
  };
  const handleChange = async (i, e) => {
    let newFormValues = [...transpotation];

    newFormValues[i][e.target.name] = e.target.value;
    setTranspotation(newFormValues);
    if (e?.target?.name === "option") {
      let newValidationValues = [...isValid];
    }
  };
  const addPoolHandler = async () => {
    var firstDay = new Date();
    var nextWeek = new Date(firstDay.getTime() + age * 24 * 60 * 60 * 1000);
    const optionsList =
      transpotation &&
      transpotation.map((optionData, i) => {
        return optionData?.option;
      });
    const formData = new FormData();
    formData.append("question", question);
    formData.append("postType", "POLL");
    formData.append("options", [optionsList]);
    formData.append("pollDuration", nextWeek);
    const emptyOptions = transpotation.every((obj) => {
      return obj.option.length > 0;
    });
    if (!emptyOptions) {
      setTranspotation(
        [...transpotation].map((object) => {
          if (object.option === "") {
            setIsSubmit(true);
            return {
              ...object,
              error: true,
            };
          } else return object;
        })
      );
    } else {
      if (transpotation) {
        if (question !== "") {
          try {
            setIsLoading(true);
            const res = await dataPostHandler("addPost", formData);
            if (res.data.responseCode === 200) {
              listPostHandler();
              setIsLoading(false);
              setQuestion("");
              setTranspotation([
                {
                  option: "",
                  error: false,
                },
                {
                  option: "",
                  error: false,
                },
              ]);
              handleCloseCreatePoll();
            } else {
              setIsLoading(false);
            }
          } catch (error) {
            console.log(error);
            setIsLoading(false);
          }
        } else {
          setIsSubmit(true);
        }
      }
    }
  };
  return (
    <Dialog
      open={openCreatePoll}
      onClose={!isLoading && handleCloseCreatePoll}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="sm"
      fullWidth
      className={classes.root}
    >
      <Box className="bgcolorbox">
        <Box>
          <Typography variant="h5">Create a poll</Typography>
        </Box>
      </Box>
      <DialogContent>
        <IconButton
          disabled={isLoading}
          className={classes.cancelBtn}
          onClick={handleCloseCreatePoll}
        >
          <IoClose />
        </IconButton>

        <Box className={classes.mainmodalBox}>
          <Box className={classes.mainmodalBoxx}>
            <Box pt={2}>
              <Box>
                <Typography variant="body1">Your question*</Typography>
              </Box>
              <Box pb={1}>
                <FormControl fullWidth className={classes.commentControl}>
                  <TextField
                    fullWidth
                    value={question}
                    disabled={isLoading}
                    placeholder="E.g., How do you commute to work?"
                    onChange={(e) => {
                      if (e.target.value.length <= 256) {
                        setQuestion(e.target.value);
                      }
                    }}
                  />
                  {isSubmit && question === "" && (
                    <FormHelperText error>
                      Please enter your question.
                    </FormHelperText>
                  )}
                </FormControl>
              </Box>
            </Box>

            <Box pb={2} pt={1}>
              {transpotation &&
                transpotation.map((item, i) => {
                  return (
                    <PollOption
                      item={item}
                      classes={classes}
                      i={i}
                      setTranspotation={setTranspotation}
                      handleChange={(a, b) => handleChange(a, b)}
                      transpotation={transpotation}
                      removeFormFields={(index) => removeFormFields(index)}
                      isLoading={isLoading}
                    />
                  );
                })}
              <Box pt={1} pb={2} className={classes.buttonBox}>
                {transpotation.length < 4 && (
                  <Box className={classes.flexboxbtn}>
                    <Button
                      disabled={isLoading}
                      onClick={() => {
                        if (transpotation.length - 1) {
                          if (transpotation.length < 4) {
                            addFormFields();
                          }
                        } else {
                          removeFormFields();
                        }
                      }}
                      variant="contained"
                      size="small"
                      style={{
                        marginRight: "6px",
                        fontWight: "300",
                        color: "#fff",

                        backgroundColor: "#4057a7",
                      }}
                    >
                      +&nbsp; Add Option &nbsp;
                    </Button>
                  </Box>
                )}
                {transpotation.length > 2 && (
                  <Button
                    disabled={isLoading}
                    onClick={() => removeFormFields()}
                    variant="contained"
                    size="small"
                    style={{
                      marginRight: "6px",
                      fontWight: "300",
                      color: "#fff",

                      backgroundColor: "#4057a7",
                    }}
                  >
                    remove
                  </Button>
                )}
              </Box>
            </Box>
          </Box>

          <Box pt={2.8} pb={5}>
            <Box pb={1}>
              <Typography variant="h5">Poll Duration</Typography>
            </Box>

            <FormControl className={classes.selectformControl} fullWidth>
              <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                value={age}
                onChange={(e) => setAge(e.target.value)}
                disabled={isLoading}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                }}
              >
                <MenuItem value={1}>1 day</MenuItem>
                <MenuItem value={2}>2 day </MenuItem>
                <MenuItem value={3}>3 day</MenuItem>
                <MenuItem value={4}>4 day</MenuItem>
              </Select>
            </FormControl>
          </Box>

          <Box className={classes.inputBox}>
            <Button
              variant="outlined"
              color="secondary"
              size="small"
              style={{ padding: "20px 30px" }}
              onClick={handleCloseCreatePoll}
              disabled={isLoading}
            >
              Back
            </Button>
            <Box ml={2}>
              <Button
                onClick={() => addPoolHandler()}
                variant="contained"
                color="primary"
                size="small"
                style={{ padding: "20px 30px" }}
                disabled={isLoading}
              >
                Done {isLoading && <ButtonCircularProgress />}
              </Button>
            </Box>
            &nbsp;
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default PollModal;
