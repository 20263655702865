import { Box, Typography } from "@material-ui/core";
import { CheckCircle, Done } from "@material-ui/icons";
import React from "react";

export default function PoolProgressBar({ pollDetails, data }) {
  const calculatePercent = (votes, total, a) => {
    let totalVotes = 0;
    for (let i = 0; i < a.length; i++) {
      totalVotes += a[i].vote;
    }
    if (votes !== 0 && total[0]?.vote !== 0) {
      return `${parseInt((votes / totalVotes) * 100)}%`;
    }
    return "0%";
  };
  return (
    <div>
      <Box>
        <Box>
          {pollDetails &&
            pollDetails.map((item, i) => {
              return (
                <>
                  {i == 0 && (
                    <Box className="CustomProgressPoll">
                      <Typography variant="body2" color="primary">
                        {item?.option}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="primary"
                        style={{ color: "#62aacd", zIndex: 1 }}
                      >
                        {calculatePercent(
                          item?.vote,
                          data?.pollPost?.options.filter(
                            (count) => count?.vote > 0
                          ),
                          data?.pollPost?.options
                        )}
                      </Typography>
                      <Box
                        className="CustomProgressPollInner grey"
                        style={{
                          width: calculatePercent(
                            item?.vote,
                            data?.pollPost?.options.filter(
                              (count) => count?.vote > 0
                            ),
                            data?.pollPost?.options
                          ),
                        }}
                      ></Box>
                    </Box>
                  )}

                  {i == 1 && (
                    <Box className="CustomProgressPoll">
                      <Typography variant="body2" color="primary">
                        {item?.option}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="primary"
                        style={{ color: "#62aacd" }}
                      >
                        {calculatePercent(
                          item?.vote,
                          data?.pollPost?.options.filter(
                            (count) => count?.vote > 0
                          ),
                          data?.pollPost?.options
                        )}
                      </Typography>
                      <Box
                        className="CustomProgressPollInner blue"
                        style={{
                          width: calculatePercent(
                            item?.vote,
                            data?.pollPost?.options.filter(
                              (count) => count?.vote > 0
                            ),
                            data?.pollPost?.options
                          ),
                        }}
                      ></Box>
                    </Box>
                  )}

                  {i == 2 && (
                    <Box className="CustomProgressPoll">
                      <Typography variant="body2" color="primary">
                        {item?.option}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="primary"
                        style={{ color: "#62aacd" }}
                      >
                        {calculatePercent(
                          item?.vote,
                          data?.pollPost?.options.filter(
                            (count) => count?.vote > 0
                          ),
                          data?.pollPost?.options
                        )}
                      </Typography>
                      <Box
                        className="CustomProgressPollInner green"
                        style={{
                          width: calculatePercent(
                            item?.vote,
                            data?.pollPost?.options.filter(
                              (count) => count?.vote > 0
                            ),
                            data?.pollPost?.options
                          ),
                        }}
                      ></Box>
                    </Box>
                  )}
                  {i == 3 && (
                    <Box className="CustomProgressPoll">
                      <Typography variant="body2" color="primary">
                        {item?.option}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="primary"
                        style={{ color: "#62aacd" }}
                      >
                        {calculatePercent(
                          item?.vote,
                          data?.pollPost?.options.filter(
                            (count) => count?.vote > 0
                          ),
                          data?.pollPost?.options
                        )}
                      </Typography>
                      <Box
                        className="CustomProgressPollInner grey"
                        style={{
                          width: calculatePercent(
                            item?.vote,
                            data?.pollPost?.options.filter(
                              (count) => count?.vote > 0
                            ),
                            data?.pollPost?.options
                          ),
                        }}
                      ></Box>
                    </Box>
                  )}
                </>
              );
            })}
        </Box>
      </Box>
    </div>
  );
}
