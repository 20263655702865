import React, { useState, useEffect } from "react";
import {
  Box,
  makeStyles,
  Divider,
  Paper,
  Grid,
  Typography,
  TableContainer,
  IconButton,
  FormControl,
  Select,
  MenuItem,
  Button,
} from "@material-ui/core";
import PostedJobs from "src/icons/PostedJobs";
import AppliedJobs from "src/icons/AppliedJobs";
import SavedJobs from "src/icons/SavedJobs";
import MoonLight from "src/views/pages/MyWork/MoonLight";
import MainTable1 from "src/component/MainTable1";
import MyJobTable from "./MyJobTable";

const useStyles = makeStyles((theme) => ({
  mainform: {
    "& .MuiOutlinedInput-root": {
      height: "40px",
      borderRadius: "25px",
      backgroundColor: "#f1f1f1",
      paddingLeft: "13px",
    },
    "& .MuiOutlinedInput-input": {
      padding: "0px 0px 0px 10px",
      broderRadius: "25px",
    },
    "& .searchBox": {
      "& .MuiFormControl-root": {
        borderRadius: "50px",
      },
    },
  },
  root1: {
    "& .activeTav": {
      background: "#4057A7",
    },
    "& .InactiveTav": {
      background: "#4057A7",
      boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.1)",
    },
    "& .buttonbox": {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      cursor: "pointer",
      whiteSpace: "pre",

      [theme.breakpoints.only("xs")]: {
        justifyContent: "start",
      },
      "& .active": {
        background: "transparent",
        color: "#4057A7",
        padding: "23px 10px",
        boxShadow: "none",
        fontSize: "16px",
        borderBottom: "2px solid",
        borderImageBottom: "linear-gradient(90deg, #4C74B4 0%, #67B7D3 100%)",
        "&:hover": {
          boxShadow: "none",
        },
        [theme.breakpoints.only("xs")]: {
          fontSize: "14px",
        },
      },
      "& .inactive": {
        background: "transparent",
        color: "grey",
        padding: "23px 10px",
        boxShadow: "none",
        fontSize: "16px",
        borderBottom: "2px solid transparent",
        "&:hover": {
          boxShadow: "none",
        },
        [theme.breakpoints.only("xs ")]: {
          fontSize: "14px",
        },
      },
    },
  },
  DateBox: {
    "& .MuiOutlinedInput-root": {
      height: "40px",
      borderRadius: "25px",
      backgroundColor: "#f1f1f1",
      paddingLeft: "13px",
    },
    "& .MuiOutlinedInput-input": {
      padding: "0px 0px 0px 10px",
      broderRadius: "25px",
    },
    "& .MuiFormControl-root": {
      borderRadius: "50px",
    },
  },
  DateBox1: {
    "& .MuiOutlinedInput-root": {
      height: "40px",
      borderRadius: "25px",
      backgroundColor: "#4057A7",
      paddingLeft: "13px",
    },
    "& .MuiOutlinedInput-input": {
      padding: "0px 0px 0px 10px",
      broderRadius: "25px",
    },
    "& .MuiFormControl-root": {
      borderRadius: "50px",
    },
    "& .MuiInputBase-input": {
      backgroundColor: "#4057A7",
      color: "#fff",
    },
    "& .MuiSelect-iconOutlined": {
      color: "#fff",
    },
  },
}));
const MoonlightTabs = ({
  listMyMoonLight,
  moonListPost,
  page,
  setPage,
  pageCount,
  tabView4,
  setOpen,
  moonIsLoading,
  setSearch,
  search,
}) => {
  const classes = useStyles();
  const [tabView, setTabView] = useState("moonjobs");
  const [isLoading, setIsLoading] = useState(false);

  return (
    <div>
      <Box>
        <Box style={{ padding: "10px 2px 0px 15px" }}>
          <Paper
            className={`${classes.followersBox} paddingBox`}
            elevation={0}
            style={{
              overflowY: "scroll",
              height: "calc(100vh - 220px)",
            }}
          >
            <TableContainer>
              <Box className={classes.root1}>
                <Grid container>
                  <Grid item md={11} xs={12}>
                    <Box className="buttonbox">
                      <Box
                        onClick={() => setTabView("moonjobs")}
                        className={
                          tabView === "moonjobs" ? "active" : "inactive"
                        }
                      >
                        <IconButton
                          className={
                            tabView === "moonjobs" ? "activeTav" : "InactiveTav"
                          }
                        >
                          <PostedJobs
                            className={
                              tabView === "moonjobs"
                                ? "activeboxTabs"
                                : "inActiveboxTabs"
                            }
                          />
                        </IconButton>
                        &nbsp;&nbsp;&nbsp; Posted Moonlight
                      </Box>
                      <Box
                        onClick={() => setTabView("appliedmoon")}
                        className={
                          tabView === "appliedmoon" ? "active" : "inactive"
                        }
                      >
                        <IconButton
                          className={
                            tabView === "appliedmoon"
                              ? "activeTav"
                              : "InactiveTav"
                          }
                        >
                          <AppliedJobs
                            className={
                              tabView === "appliedmoon"
                                ? "activeboxTabs"
                                : "inActiveboxTabs"
                            }
                          />
                        </IconButton>
                        &nbsp;&nbsp;&nbsp; Applicants
                      </Box>
                      <Box
                        onClick={() => setTabView("usermoon")}
                        className={
                          tabView === "usermoon" ? "active" : "inactive"
                        }
                      >
                        <IconButton
                          className={
                            tabView === "usermoon" ? "activeTav" : "InactiveTav"
                          }
                        >
                          <SavedJobs
                            className={
                              tabView === "usermoon"
                                ? "activeboxTabs"
                                : "inActiveboxTabs"
                            }
                          />
                        </IconButton>
                        &nbsp;&nbsp;&nbsp; My applied jobs
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
                <Box>
                  <Divider style={{ bakground: "rgba(0, 0, 0, 0.4)" }} />
                </Box>
              </Box>
            </TableContainer>

            <Box>
              <Box mt={2}>
                {tabView === "moonjobs" && (
                  <MoonLight
                    listMyMoonLight={listMyMoonLight}
                    moonListPost={moonListPost}
                    type="mypost"
                    page={page}
                    setPage={setPage}
                    pageCount={pageCount}
                    tabView4={tabView4}
                    setOpen={setOpen}
                    moonIsLoading={moonIsLoading}
                    setSearch={setSearch}
                    search={search}
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                  />
                )}
                {tabView === "appliedmoon" && (
                  <MainTable1
                    moonListPost={moonListPost}
                    isLoading={isLoading}
                  />
                )}
                {tabView === "usermoon" && <MyJobTable />}
              </Box>
            </Box>
          </Paper>
        </Box>
      </Box>
    </div>
  );
};

export default MoonlightTabs;
