import React from "react";

import Select from "react-select";

export default function MultiSelect({
  data,
  setSelectedOptions,
  selectedOptions,
  isLoading,
}) {
  function handleSelect(item) {
    setSelectedOptions(item);
  }

  const colourStyles = {
    option: (styles, { isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isDisabled
          ? undefined
          : isSelected
          ? "#000"
          : isFocused
          ? "#000"
          : undefined,
        ":active": {
          ...styles[":active"],
          backgroundColor: !isDisabled
            ? isSelected
              ? "#000"
              : "#000"
            : undefined,
        },
      };
    },
  };

  return (
    <div className="app">
      <div className="dropdown-container">
        <Select
          options={
            data &&
            data?.map((item) => {
              return {
                label: item?.skillName,
                value: item?._id,
              };
            })
          }
          disabled={isLoading}
          placeholder="Please choose a skills"
          value={selectedOptions}
          onChange={handleSelect}
          isSearchable={true}
          styles={colourStyles}
          isMulti
        />
      </div>
    </div>
  );
}
