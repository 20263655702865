import axios from "axios";
import apiConfigs from "../ApiConfig";
import { toast } from "react-toastify";

export const uploadFiles = async (dataToSend) => {
  let formdata = new FormData();
  formdata.append("file", dataToSend);
  try {
    const res = await axios({
      method: "POST",
      url: apiConfigs.uploadFiles,
      data: formdata,
    });
    if (res.data.responseCode === 200) {
      return res.data.result;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
  }
};
export const uploadFiles1 = async (dataToSend) => {
  let formdata = new FormData();
  formdata.append("file", dataToSend);
  try {
    const res = await axios({
      method: "POST",
      url: apiConfigs.uploadFile,
      data: formdata,
    });
    if (res.data.responseCode === 200) {
      return res.data.result;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
  }
};
export const dataPostHandler = async (endPoint, dataToSend) => {
  try {
    const res = await axios({
      method: "POST",
      url: apiConfigs[endPoint],
      headers: {
        token: window.sessionStorage.getItem("token"),
      },
      data: dataToSend,
    });
    if (res.data.responseCode === 200) {
      toast.success(res.data.responseMessage);
      return res;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
    toast.error(error?.response?.data?.responseMessage);
    if (error.response.status === 440) {
      window.location.href = "/";
    }
  }
};

export const getDataHandlerAPI = async (endPoint, dataSend) => {
  try {
    const res = await axios({
      method: "GET",
      url: apiConfigs[endPoint],
      headers: {
        token: window.sessionStorage.getItem("token"),
      },
      params: dataSend,
    });
    if (res.data.responseCode === 200) {
      return res.data.result;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
    if (error.response.status === 440) {
      window.location.href = "/";
    }
    return false;
  }
};

export const patchAPIHandler = async (endPoint, dataToSend) => {
  try {
    const res = await axios({
      method: "PATCH",
      url: apiConfigs[endPoint],
      headers: {
        token: window.sessionStorage.getItem("token"),
      },
      data: dataToSend,
    });
    if (res.data.responseCode === 200) {
      return res;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
    if (error.response.status === 440) {
      window.location.href = "/";
    }
    toast.error(error?.response?.data?.responseMessage);
    return false;
  }
};

export const patchAPIHandlerPathId = async (endPoint, _id) => {
  try {
    const res = await axios({
      method: "PATCH",
      url: `${apiConfigs[endPoint]}/${_id}`,
      headers: {
        token: window.sessionStorage.getItem("token"),
      },
    });
    if (res.data.responseCode === 200) {
      return res;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
    if (error.response.status === 440) {
      window.location.href = "/";
    }
    toast.error(error?.response?.data?.responseMessage);
    return false;
  }
};

export const putAPIHandler = async (endPoint, dataToSend, id) => {
  try {
    const res = await axios({
      method: "PUT",
      url: apiConfigs[endPoint],
      headers: {
        token: window.sessionStorage.getItem("token"),
      },
      params: id,
      data: dataToSend,
    });
    if (res.data.responseCode === 200) {
      toast.success(res.data.responseMessage);
      return res.data;
    } else {
      toast.success(res.responseMessage);
    }
  } catch (error) {
    if (error.response.responseCode === 440) {
      window.location.href = "/";
    }
    toast.error(error?.response?.data?.responseMessage);
    return false;
  }
};

export const getParticularObjectDataHanndler = async (endPoint, _id) => {
  try {
    const res = await axios({
      method: "GET",
      url: `${apiConfigs[endPoint]}`,
      headers: {
        token: window.sessionStorage.getItem("token"),
      },
      params: _id,
    });
    if (res) {
      return res.data;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
    if (error.response.status === 440) {
      window.location.href = "/";
    }
  }
};

export const deleteDataAPIHandler = async (endPoint, _id) => {
  try {
    const res = await axios({
      method: "DELETE",
      url: apiConfigs[endPoint],
      headers: {
        token: window.sessionStorage.getItem("token"),
      },
      params: _id,
    });
    if (res.data.responseCode === 200) {
      toast.success(res.data.responseMessage);
      return res.data;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
    toast.error(error?.response?.data?.responseMessage);
    if (error.response.status === 440) {
      window.location.href = "/";
    }
    return false;
  }
};

export const postAPIHandlerIdInPath = async (endPoint, _id) => {
  try {
    const res = await axios({
      method: "POST",
      url: `${apiConfigs[endPoint]}/${_id}`,
      headers: {
        token: window.sessionStorage.getItem("token"),
      },
    });
    if (res.data.responseCode === 200) {
      return res;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
    if (error.response.status === 440) {
      window.location.href = "/";
    }
    toast.error(error?.response?.data?.responseMessage);
    return false;
  }
};

export const getDataWithoutToken = async (endPoint) => {
  try {
    const res = await axios({
      method: "GET",
      url: apiConfigs[endPoint],
    });
    if (res.data.responseCode === 200) {
      return res.data.result;
    } else {
      return false;
    }
  } catch (error) {
    console.log("error");
  }
};

export const postDataIdinQuery = async (endPoint, id) => {
  try {
    const res = await axios({
      method: endPoint === "savedJob" ? "POST" : "GET",
      url: apiConfigs[endPoint],
      headers: {
        token: window.sessionStorage.getItem("token"),
      },
      params: id,
    });
    if (res.data.responseCode === 200) {
      toast.success(res.data.responseMessage);
      return res.data;
    } else {
      return false;
    }
  } catch (error) {
    console.log("error");
  }
};
export const postDataIdinQueryWithdata = async (endPoint, id, dataSend) => {
  try {
    const res = await axios({
      method: "POST",
      url: apiConfigs[endPoint],
      headers: {
        token: window.sessionStorage.getItem("token"),
      },
      params: id,
      data: dataSend,
    });
    if (res.data.responseCode === 200) {
      toast.success(res.data.responseMessage);
      return res.data;
    } else {
      return false;
    }
  } catch (error) {
    console.log("error");
  }
};
export const postDataIdinWithdata = async (endPoint, dataSend) => {
  try {
    const res = await axios({
      method: "POST",
      url: apiConfigs[endPoint],
      headers: {
        token: window.sessionStorage.getItem("token"),
      },

      data: dataSend,
    });
    if (res.data.responseCode === 200) {
      toast.success(res.data.responseMessage);
      return res.data;
    } else {
      toast.error(res.data.responseMessage);
      return false;
    }
  } catch (error) {
    if (error.response) {
      toast.error(error.response.data.responseMessage);
    }
    console.log("error");
  }
};
export const handleUploadFiles = async (filedata) => {
  var data = new FormData();
  data.append("image", filedata);
  try {
    const response = await axios({
      method: "POST",
      url: apiConfigs.uploadImage,
      headers: {
        authorization: window.localStorage.getItem("token"),
      },
      data: data,
    });
    if (response.data.data) {
      const splitImg = response.data.data?.Key; //main network
      return splitImg;
    } else {
      toast.error(response.data.message);
    }
  } catch (err) {
    console.error(err.response);
  }
};
