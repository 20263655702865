import { postDataIdinWithdata, putAPIHandler } from "src/apiConfig/service";
import {
  Box,
  Dialog,
  FormControl,
  IconButton,
  TextField,
  Typography,
  makeStyles,
  DialogContent,
  Button,
  FormHelperText,
  InputAdornment,
} from "@material-ui/core";
import React, { useState, useEffect, useContext, useRef } from "react";
import { IoClose } from "react-icons/io5";
import ButtonCircularProgress from "src/component/ButtonCircularProgress";
import { AuthContext } from "src/context/Auth";
import PlacesAutocomplete from "react-places-autocomplete";
import { checkLocationEnabled, handleNegativeValue } from "src/utils";
import JoditEditor from "jodit-react";
import { depositCoinName } from "src/constants";
const useStyles = makeStyles((theme) => ({
  mainmodalBox: {
    paddingTop: "10px",
    paddingBottom: "15px",
    "& .formControl": {
      width: "100%",
      backgroundColor: "transparent",
      border: "none",
      color: "#fff",
      "&:focus-visible": {
        outline: "none",
      },
    },

    "& .addImagegIcon": {
      cursor: "pointer",
      position: "relative",
      "& input": {
        top: 0,
        left: 0,
        position: "absolute",
        width: "100%",
        height: "100%",
        opacity: 0,
      },
      "& svg": {
        color: "#ACACAC",
        fontSize: "22px",
      },
    },
  },
  cancelBtn: {
    position: "absolute",
    top: 0,
    right: 0,
    zIndex: 1,
    "& svg": {
      color: "#fff",
      fontWeight: "700",
    },
  },

  root: {
    width: "100%",
  },
  DialogMain: {
    display: "flex",
    alignItems: "center",

    "& svg": {
      color: "#4057A7",
    },
  },

  ButtonBox: {
    padding: "10px 30px",
    [theme.breakpoints.only("xs")]: {
      height: "45px",
      padding: "0px 22px",
      fontSize: "16px",
      marginTop: "20px",
    },
  },
  jobsuggest: {
    background: "#fff",
    position: "absolute",
    padding: "8px",
    borderRadius: "10px",
    width: "100%",
    zIndex: "999",
  },
}));
export default function AddMoonlightModal({
  setOpen,
  open,
  selectedData,
  listMyMoonLight,
}) {
  const classes = useStyles();
  const [isSubmit, setIsSubmit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const auth = useContext(AuthContext);
  const editor = useRef(null);
  const [description, setDescription] = useState("");

  const [data, setData] = useState({
    name: "",
    time: "",
    location: "",
    price: "",
  });
  useEffect(() => {
    if (selectedData) {
      setDescription(
        selectedData?.id?.description ? selectedData?.id?.description : ""
      );
      setData({
        name: selectedData?.id?.name ? selectedData?.id?.name : "",
        time: selectedData?.id?.time ? selectedData?.id?.time : "",
        location: selectedData?.id?.location ? selectedData?.id?.location : "",
        price: selectedData?.id?.price ? selectedData?.id?.price : "",
      });

      auth.setAddress(
        selectedData?.id?.location ? selectedData?.id?.location : ""
      );
      auth.setLatLong({
        lat: selectedData?.id?.joblocation?.coordinates[0]
          ? selectedData?.id?.joblocation?.coordinates[0]
          : "",
        long: selectedData?.id?.joblocation?.coordinates[1]
          ? selectedData?.id?.joblocation?.coordinates[1]
          : "",
      });
    }
  }, [selectedData]);
  const _onInputChange = (e) => {
    const name = e.target.name;
    const value = e.target.name == "description" ? e : e.target.value;
    const temp = { ...data, [name]: value };

    setData(temp);
  };

  const jobPostHandler = async () => {
    const data1 = {
      name: data?.name,
      time: data?.time,

      description: description,
      price: data?.price,
      location: auth?.address,
      lat: auth?.latLong?.lat,
      long: auth?.latLong?.long,
    };
    if (
      data?.name !== "" &&
      data?.price !== "" &&
      data?.time !== "" &&
      auth?.address !== "" &&
      description !== "" &&
      description?.length <= 600
    ) {
      try {
        setIsLoading(true);
        const res = await postDataIdinWithdata("addMoonlight", data1);
        if (res?.responseCode === 200) {
          setIsLoading(false);
          listMyMoonLight();
          setDescription("");
          setData({
            name: "",
            time: "",
            location: "",
            price: "",
          });
          auth.setAddress("");
          setOpen();
        } else {
          setIsLoading(false);
        }
      } catch (error) {
        console.log("error", error);
        setIsLoading(false);
      }
    } else {
      setIsSubmit(true);
    }
  };
  const jobPostHandler1 = async () => {
    const data1 = {
      name: data?.name,
      time: data?.time,
      location: auth?.address,
      lat: auth?.latLong?.lat,
      long: auth?.latLong?.long,
      description: description,
      price: data?.price,
    };
    if (
      data?.name !== "" &&
      data?.price !== "" &&
      data?.time !== "" &&
      data?.location !== "" &&
      description !== ""
    ) {
      try {
        setIsLoading(true);
        const res = await putAPIHandler("updateMoonlight", data1, {
          _id: selectedData?.id?._id,
        });
        if (res?.responseCode === 200) {
          setIsLoading(false);
          listMyMoonLight();
          setData({
            name: "",
            time: "",
            location: "",
            description: "",
            price: "",
          });
          setDescription("");
          auth.setAddress("");

          setOpen();
        } else {
          setIsLoading(false);
        }
      } catch (error) {
        console.log("error", error);
        setIsLoading(false);
      }
    } else {
      setIsSubmit(true);
    }
  };
  useEffect(() => {
    checkLocationEnabled();
  }, []);
  return (
    <Dialog
      open={open}
      onClose={() => !isLoading && setOpen()}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="sm"
      fullWidth
      className={classes.root}
    >
      <Box className="bgcolorbox">
        <Box>
          <Typography variant="h5">
            {selectedData ? "Edit" : "Add"} Moonlight Post
          </Typography>
        </Box>
      </Box>
      <DialogContent>
        <IconButton
          className={classes.cancelBtn}
          onClick={() => !isLoading && setOpen()}
        >
          <IoClose />
        </IconButton>
        <Box style={{}}>
          <Box className={classes.mainmodalBox}>
            <Box pt={1} pb={1}>
              <FormControl fullWidth className={classes.commentControl}>
                <TextField
                  fullWidth
                  name="name"
                  value={data?.name}
                  disabled={isLoading}
                  variant="outlined"
                  placeholder="Enter job title"
                  onChange={(e) => {
                    if (e.target.value.length <= 61) {
                      _onInputChange(e);
                    }
                  }}
                />
              </FormControl>
              {isSubmit && data?.name === "" && (
                <FormHelperText error>Please enter job title.</FormHelperText>
              )}
              {data.name.length > "60" && (
                <FormHelperText error>
                  Please enter job title less than or equal to 60 charcters.
                </FormHelperText>
              )}
            </Box>
            <Box pt={1} pb={1}>
              <FormControl fullWidth className={classes.commentControl}>
                <TextField
                  fullWidth
                  disabled={isLoading}
                  value={data?.time}
                  type="number"
                  onInput={(e) => {
                    e.target.value = Math.max(0, parseInt(e.target.value))
                      .toString()
                      .slice(0, 10);
                  }}
                  onKeyPress={(event) => {
                    if (
                      event?.key === "-" ||
                      event?.key === "+" ||
                      event?.key === "*" ||
                      event?.key === "/"
                    ) {
                      event.preventDefault();
                    }
                  }}
                  onKeyDown={(event) => handleNegativeValue(event)}
                  name="time"
                  variant="outlined"
                  placeholder="Moonlight duration"
                  onChange={_onInputChange}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">Hours</InputAdornment>
                    ),
                  }}
                />
              </FormControl>
              {isSubmit && data?.time === "" && (
                <FormHelperText error>Please enter time.</FormHelperText>
              )}
            </Box>
            <Box pt={1} pb={1}>
              <FormControl
                inputVariant="outlined"
                className={classes.formControl}
                fullWidth
              >
                <PlacesAutocomplete
                  className={classes.autocompleteClass}
                  value={auth.address}
                  name="jobLocation"
                  onChange={auth.handleAddressChange}
                  onSelect={auth.handleSelectLocation}
                  disabled={isLoading}
                >
                  {({
                    getInputProps,
                    suggestions,
                    getSuggestionItemProps,
                    loading,
                  }) => (
                    <Box className="minbox">
                      <Box>
                        <TextField
                          fullWidth
                          variant="outlined"
                          placeholder="Search location"
                          {...getInputProps({
                            placeholder: "Job location",
                            className: "location-search-input",
                          })}
                          inputProps={{ "aria-label": "search" }}
                        />
                      </Box>
                      {auth.address !== "" && (
                        <Box className={classes.jobsuggest}>
                          {loading && (
                            <div style={{ marginTop: "5px" }}>Loading...</div>
                          )}
                          {suggestions.map((suggestion) => {
                            const className = suggestion.active
                              ? "suggestion-item--active"
                              : "suggestion-item";

                            const style =
                              suggestion.active === true
                                ? {
                                    backgroundColor: "#fff",
                                    cursor: "pointer",
                                    color: "#404040",
                                    borderBottom: "1px solid #e7e7e7",
                                    marginTop: "9px",
                                    fontSize: "14px",
                                    fontWeight: "400",
                                    padding: "4px 30px 8px 0px",
                                  }
                                : {
                                    backgroundColor: "#ffffff",
                                    cursor: "pointer",
                                    color: "#404040",
                                    borderBottom: "1px solid #e7e7e7",
                                    marginTop: "9px",
                                    fontSize: "14px",
                                    fontWeight: "400",
                                    padding: "4px 30px 8px 0px",
                                  };
                            return (
                              <div
                                {...getSuggestionItemProps(suggestion, {
                                  className,
                                  style,
                                })}
                              >
                                <Box className="imgbox">
                                  <Box className="typo2">
                                    <Typography
                                      variant="body2"
                                      style={{ fontWeight: "600" }}
                                    >
                                      {suggestion.description}
                                    </Typography>
                                  </Box>
                                </Box>
                              </div>
                            );
                          })}
                        </Box>
                      )}
                    </Box>
                  )}
                </PlacesAutocomplete>
              </FormControl>
              {isSubmit && auth.address === "" && (
                <FormHelperText error>
                  Please enter job location.
                </FormHelperText>
              )}
            </Box>

            <Box pt={1} pb={1}>
              <FormControl
                inputVariant="outlined"
                className={classes.formControl}
                fullWidth
              >
                <TextField
                  fullWidth
                  type="number"
                  name="price"
                  value={data?.price}
                  disabled={isLoading}
                  variant="outlined"
                  placeholder="Enter price"
                  onChange={_onInputChange}
                  onInput={(e) => {
                    e.target.value = Math.max(0, parseInt(e.target.value))
                      .toString()
                      .slice(0, 10);
                  }}
                  onKeyPress={(event) => {
                    if (
                      event?.key === "-" ||
                      event?.key === "+" ||
                      event?.key === "*" ||
                      event?.key === "/"
                    ) {
                      event.preventDefault();
                    }
                  }}
                  onKeyDown={(event) => handleNegativeValue(event)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {depositCoinName}
                      </InputAdornment>
                    ),
                  }}
                />
              </FormControl>
              {isSubmit && data?.price === "" && (
                <FormHelperText error>Please enter price.</FormHelperText>
              )}
            </Box>
            <Box pt={1} pb={1}>
              <FormControl fullWidth className={classes.commentControl}>
                <JoditEditor
                  ref={editor}
                  value={description}
                  variant="outlined"
                  name="description"
                  fullWidth
                  size="small"
                  tabIndex={1}
                  onChange={(value) => {
                    setDescription(value);
                  }}
                  disabled={isLoading}
                />
              </FormControl>
              <Box className="displaySpaceBetween">
                <FormHelperText error>
                  {isSubmit &&
                    description === "" &&
                    "Please enter description."}
                  {isSubmit &&
                    description?.length >= 600 &&
                    "Text must be at most 600 characters."}
                </FormHelperText>
                <Box alignItems="end">{description?.length}/600</Box>
              </Box>
            </Box>
            <Box>
              <Box align="center" mt={2}>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={
                    data?.time.length > "60" || data?.time <= "0" || isLoading
                  }
                  size="medium"
                  className={classes.ButtonBox}
                  onClick={() => {
                    if (selectedData?.id?._id) {
                      jobPostHandler1();
                    } else {
                      jobPostHandler();
                    }
                  }}
                >
                  Submit {isLoading && <ButtonCircularProgress />}
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
