import React, { useState, useEffect } from "react";
import { Box, Grid, Paper, Typography } from "@material-ui/core";

import { makeStyles } from "@material-ui/core";
import WorkCards from "./WorkCards";
import { getDataHandlerAPI } from "src/apiConfig/service";
import DataNotFound from "src/component/DataNotFound";
import LoadHistory from "src/Skeletons/LoadHistory";
import Page from "src/component/Page";
import { Pagination } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({}));

function Index() {
  const classes = useStyles();
  const [myWorkList, setMyWorkList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);

  const listMyWorkHandler = async () => {
    try {
      setIsLoading(true);
      const res = await getDataHandlerAPI("myworkMoonlightList", {
        page: page,
        limit: 15,
      });

      if (res) {
        setIsLoading(false);
        setMyWorkList(res?.docs);
        setPageCount(res?.pages);
      } else {
        setIsLoading(false);
        setMyWorkList([]);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    listMyWorkHandler();
  }, []);

  return (
    <Page title="My works">
      <Box mt={-1}>
        <Box className="scrollDiv">
          <Box className="searchBox">
            <Box mt={1} mb={3}>
              <Paper elevation={0}>
                <Box p={2}>
                  <Typography variant="h4">My Works</Typography>
                </Box>
              </Paper>
            </Box>
          </Box>
          <Box>
            {isLoading ? (
              <Grid container spacing={2}>
                {[1, 2, 3, 4].map((data, i) => (
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <LoadHistory type="work" />
                  </Grid>
                ))}
              </Grid>
            ) : (
              <Box elevation={0}>
                <Box mt={-2}>
                  <Grid container spacing={2}>
                    {myWorkList &&
                      myWorkList?.map((data, index) => {
                        return (
                          <>
                            <Grid item xs={12} sm={6} md={4} lg={3}>
                              <Box className={classes.mainBox} elevation={0}>
                                <WorkCards data={data} index={index} />
                              </Box>
                            </Grid>
                          </>
                        );
                      })}
                    {!isLoading && myWorkList && myWorkList.length === 0 && (
                      <DataNotFound />
                    )}
                    {!isLoading &&
                      myWorkList &&
                      myWorkList.length >= (page === 1 ? 15 : 0) && (
                        <Pagination
                          variant="outlined"
                          shape="rounded"
                          count={pageCount}
                          page={page}
                          onChange={(e, v) => setPage(v)}
                        />
                      )}
                  </Grid>
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Page>
  );
}

export default Index;
