import { Box } from "@material-ui/core";
import React from "react";

export default function DataLoading() {
  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <img
        src="images/loader/loader2.gif"
        style={{
          width: "100%",
          height: "100%",
          maxHeight: "100px",
          borderRadius: "10px",
          objectFit: "cover",
        }}
      />
    </Box>
  );
}
