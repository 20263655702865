import React, { useState } from "react";
import {
  makeStyles,
  Typography,
  Paper,
  Box,
  Divider,
  Link,
  Grid,
  IconButton,
  Button,
} from "@material-ui/core";
import { BsThreeDots } from "react-icons/bs";
import { BsFillBookmarkFill } from "react-icons/bs";
import moment from "moment";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  jobsbox: {
    border: "1px solid #C8C8C8",
    height: "100%",
    borderRadius: "5px",
    cursor: "pointer",
    "& .doted": {
      marginBottom: "10px",
      "& h5": {
        color: "#323232",
      },
    },
    "& .MuiDivider-root": {
      backgroundColor: "rgba(159, 171, 211, 0.3)",
    },
  },
  mapingbox1: {
    display: "flex",
    padding: "10px",
    justifyContent: "space-between",
    "& .ImageBox": {
      display: "flex",
      "& figure": {
        width: "50px",
        height: "50px",
        borderRadius: "50px",
        overflow: "hidden",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        margin: "0px",
        "& img": {
          minHeight: "100%",
          width: "100%",
        },
      },
      "& h6": {
        fontWeight: "700",
        color: "#323232",
      },
      "& p": {
        color: "#ABABAB",
      },
    },
    "& .TextBox": {
      marginLeft: "10px",
    },
    "& .iconBox": {
      "& svg": {
        fontSize: "17px",
        color: "#4057A7",
      },
    },
  },
  root: {
    height: "100%",
  },
}));

function SaveJobCard({ data, index, type }) {
  const classes = useStyles();
  const history = useHistory();

  return (
    <>
      <Box style={{ height: "100%" }}>
        <Paper className={classes.root} elevation={0}>
          <Box
            className={classes.jobsbox}
            onClick={() =>
              history.push({
                pathname: "/job-description",
                state: data?.jobId,
                search: data?.jobId?._id,
              })
            }
          >
            <Grid container spacing={1}>
              <Grid item xs={12} key={index}>
                <Box className={classes.mapingbox1}>
                  <Box className="ImageBox">
                    <figure>
                      <img src={data?.jobId?.companyLogo} alt="Imager" />
                    </figure>
                    <Box className="TextBox">
                      <Typography variant="h6">
                        {data?.jobId?.compnayName}
                      </Typography>
                      <Typography
                        variant="body1"
                        style={{ wordBreak: "break-all" }}
                      >
                        {" "}
                        <div
                          dangerouslySetInnerHTML={{
                            __html: `${data?.jobId?.description?.slice(
                              0,
                              100
                            )}${
                              data?.jobId?.description?.length > 100
                                ? "..."
                                : ""
                            }`,
                          }}
                        ></div>
                      </Typography>
                      <Typography variant="body2">
                        {data?.jobId?.jobLocation}
                      </Typography>
                      <Box style={{ display: "flex" }}>
                        <Typography variant="body2">
                          {" "}
                          {data?.isapply
                            ? moment(data?.updatedAt).local().fromNow()
                            : moment(data?.createdAt).local().fromNow()}
                        </Typography>

                        {type === "recommend" && (
                          <Button
                            variant="body2"
                            style={{ color: "#4057A7", padding: "0px" }}
                          >
                            Apply
                          </Button>
                        )}
                      </Box>
                    </Box>
                  </Box>
                  <Box className="iconBox">
                    <IconButton disabled>
                      <BsFillBookmarkFill />
                    </IconButton>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </Box>
    </>
  );
}

export default SaveJobCard;
