import React, { useState, useContext } from "react";
import {
  Box,
  TextField,
  Grid,
  Typography,
  makeStyles,
  Button,
} from "@material-ui/core";
import "react-dropzone-uploader/dist/styles.css";
import "react-phone-input-2/lib/style.css";
import ButtonCircularProgress from "src/component/ButtonCircularProgress";
import { dataPostHandler } from "src/apiConfig/service";
import { toast } from "react-toastify";
import { AuthContext } from "src/context/Auth";

const useStyles = makeStyles((theme) => ({
  field: {
    "& input": {
      padding: "19px 12px 17px",
      fontSize: "13px",
      fontWeight: "400",
    },
    marginTop: "0px",
    marginBottom: "0px",
  },
  formControl: {
    width: "100%",
  },
  contactbox: {
    padding: "5px 30px 5px",
    borderRadius: "5px",
    [theme.breakpoints.down("xs")]: {
      padding: "30px 0px 10px",
    },
    "& h5": {
      fontWeight: "500",
      color: "rgba(0, 0, 0, 0.4)",
    },
    "& .usertext": {
      "& p": {
        color: "#000000",
        fontSize: "18px",
        fontWeight: 700,
      },
    },
  },
  buttonboxes: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    maxWidth: "790px",
    margin: "0 auto",
    marginBottom: "16px",
    [theme.breakpoints.down("xs")]: {
      display: "block",
      width: "100%",
      maxWidth: "100%",
    },
    "& button": {
      fontSize: "18px",
      fontWeight: "500",
      margin: "0px 10px",
      marginBottom: "10px",
      [theme.breakpoints.down("xs")]: {
        marginBottom: "10px",
        margin: "0px",
      },
    },
  },
  Imgsec: {
    height: "244px",
    width: "auto",
    display: "flex",
    justifyContent: "center",
    borderRadius: "5px",
    backgroundColor: "#f1f1f1",
    padding: "10px",
    "& img": {
      color: "transparent",
      width: "100%",
      height: "100%",
      objectFit: "contain",
      textAlign: "center",
      borderRadius: "5px",
    },
  },
}));

export default function StepContent3({
  handleBack,
  setBlcok,
  basicDetails,
  iIdDetails,
  setkycModal,
}) {
  const classes = useStyles();
  const auth = useContext(AuthContext);
  const [isLoader, setIsLoader] = useState(false);
  const handleKycSubmit = async (values) => {
    try {
      setIsLoader(true);
      const formData = new FormData();
      formData.append("firstName", basicDetails.firstName);
      formData.append("lastName", basicDetails.lastName);
      formData.append("mobileNumber", basicDetails.mobileNumber);
      formData.append("countryCode", basicDetails.countryCode);
      formData.append("emailAddress", basicDetails.email);
      formData.append("gender", basicDetails.gender);
      formData.append("address", basicDetails.address);
      formData.append("country", basicDetails.country);
      formData.append("city", basicDetails.city);
      formData.append("state", basicDetails.state);
      formData.append("documentFrontSideImage", iIdDetails?.forntImage1);
      formData.append("documentBackSideImage", iIdDetails?.backImage1);
      formData.append("documentType", iIdDetails?.documentType);
      formData.append("documentNumber", iIdDetails?.ducumentNumber);
      const response = await dataPostHandler("applyKyc", formData);

      if (response) {
        toast.success(response.message);
        setIsLoader(false);
        setkycModal(false);
        auth.getUserProfileDatahandler(window.sessionStorage.getItem("token"));
        setBlcok(true);
      } else {
        setIsLoader(false);
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
      console.log(error);
      setIsLoader(false);
    }
  };

  return (
    <>
      <Box className={classes.contactbox}>
        <Box pb={3}>
          <Typography variant="h5">
            Once you have provided all the required information and uploaded the
            necessary documents, you will be asked to confirm your details.
            Please review all the information carefully to ensure that it is
            accurate and up to date. If there are any discrepancies, please
            contact our support team to update your information.
          </Typography>
        </Box>
        <Box className="usertext" pb={2}>
          <Typography>User Information</Typography>
        </Box>
        <Grid container spacing={3}>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Box>
              <TextField
                variant="outlined"
                fullWidth
                className={classes.field}
                value={basicDetails?.firstName}
                disabled
              />
            </Box>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Box>
              <TextField
                variant="outlined"
                fullWidth
                className={classes.field}
                value={basicDetails?.lastName}
                disabled
              />
            </Box>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Box>
              <TextField
                variant="outlined"
                fullWidth
                className={classes.field}
                value={`+${basicDetails?.mobileNumber}`}
                disabled
              />
            </Box>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Box>
              <TextField
                variant="outlined"
                fullWidth
                className={classes.field}
                value={basicDetails?.email}
                disabled
              />
            </Box>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Box>
              <TextField
                variant="outlined"
                fullWidth
                className={classes.field}
                value={basicDetails?.gender}
                disabled
              />
            </Box>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Box>
              <TextField
                variant="outlined"
                fullWidth
                className={classes.field}
                value={basicDetails?.address}
                disabled
              />
            </Box>
          </Grid>

          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Box>
              <TextField
                variant="outlined"
                fullWidth
                className={classes.field}
                value={basicDetails?.country}
                disabled
              />
            </Box>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Box>
              <TextField
                variant="outlined"
                fullWidth
                className={classes.field}
                value={basicDetails?.state}
                disabled
              />
            </Box>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Box>
              <TextField
                variant="outlined"
                fullWidth
                className={classes.field}
                value={basicDetails?.city}
                disabled
              />
            </Box>
          </Grid>
        </Grid>
        <Box className="usertext" pb={2} pt={2}>
          <Typography>Uploded Documents</Typography>
        </Box>
        <Grid container spacing={3}>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Box className={classes.Imgsec}>
              <img
                src={
                  iIdDetails?.forntImage
                    ? iIdDetails?.forntImage
                    : "images/front.png"
                }
                alt=""
                width="100%"
              />
            </Box>
            <Box pt={2}>
              <TextField
                placeholder="Aadhaar Card"
                variant="outlined"
                fullWidth
                className={classes.field}
                value={iIdDetails?.documentType}
                disabled
              />
            </Box>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Box className={classes.Imgsec}>
              <img
                src={
                  iIdDetails?.backImage
                    ? iIdDetails?.backImage
                    : "images/back.png"
                }
                alt=""
                width="100%"
              />
            </Box>
            <Box pt={2}>
              <TextField
                variant="outlined"
                fullWidth
                className={classes.field}
                value={iIdDetails?.ducumentNumber}
                disabled
              />
            </Box>
          </Grid>
        </Grid>

        <Box className={classes.instructions}>
          <Box py={4} textAlign="center" className={classes.buttonboxes}>
            <Button
              className={classes.button}
              variant="contained"
              color="secondary"
              size="large"
              fullWidth
              style={{ backgroundColor: "#828282" }}
              onClick={handleBack}
              disabled={isLoader}
            >
              Previous
            </Button>
            <Button
              variant="contained"
              color="primary"
              size="large"
              fullWidth
              className={classes.button}
              onClick={handleKycSubmit}
              disabled={isLoader}
              style={{ backgroundColor: "#4057A7" }}
            >
              Submit {isLoader && <ButtonCircularProgress />}
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
}
