import React, { useState, useEffect } from "react";
import {
  makeStyles,
  Typography,
  Paper,
  Box,
  Divider,
  Grid,
  IconButton,
  Avatar,
} from "@material-ui/core";
import CourseModal from "src/component/CourseModal";
import { BsFillBookmarkFill } from "react-icons/bs";
import { CiBookmark } from "react-icons/ci";
import JobSearch from "./JobSearch";
import Trending from "./Trending";
import {
  getParticularObjectDataHanndler,
  postDataIdinQuery,
} from "src/apiConfig/service";
import LoadHistory from "src/Skeletons/LoadHistory";
import { useHistory } from "react-router-dom";
import SkillUpCourse from "./SkillUpCourse";

const useStyles = makeStyles((theme) => ({
  skillbox: {
    background: "linear-gradient(208.15deg, #70CDDD -30.61%, #4057A7 140.71%)",
    borderRadius: "5px",
    padding: "15px",
    [theme.breakpoints.down("xs")]: {
      padding: "10px",
    },
    "& .doted": {
      marginBottom: "10px",
      "& h5": {
        color: "#FFFFFF",
      },
    },
    "& .MuiDivider-root": {
      backgroundColor: "rgba(231, 231, 231, 0.2)",
    },
  },
  jobsbox: {
    "& .doted": {
      marginBottom: "10px",
      "& h5": {
        color: "#323232",
      },
    },
    "& .MuiDivider-root": {
      backgroundColor: "rgba(231, 231, 231, 0.2)",
    },
  },
  mapingbox1: {
    display: "flex",
    justifyContent: "space-between",
    cursor: "pointer",
    "& .ImageBox": {
      display: "flex",
      alignItems: "center",
      margionRight: "10px",
      "& .MuiAvatar-root": {
        width: "50px !important",
        height: "50px !important",
      },
      "& h6": {
        fontSize: "14px",
        fontWeight: "400",
        color: "#323232",
      },
      "& p": {
        color: "#ABABAB",
      },
    },
    "& .TextBox": {
      marginLeft: "10px",
    },
    "& .iconBox": {
      "& svg": {
        fontSize: "17px",
        color: "#4057A7",
      },
    },
  },
  root: {
    padding: "15px",
    [theme.breakpoints.down("xs")]: {
      padding: "10px",
    },
  },
}));

function SkillUp() {
  const classes = useStyles();
  const history = useHistory();
  const [skillUpAdCourseData, setSkillUpAdCourseData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [courseData, setCourseData] = useState("");
  const [skillModal, setSkillModal] = useState(false);

  const getSkillUpAdCourseList = async () => {
    try {
      const response = await getParticularObjectDataHanndler("listCourses");
      if (response.responseCode === 200) {
        setIsLoading(false);
        setSkillUpAdCourseData(response.result);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const saveJobHandler = async (id) => {
    try {
      const response = await postDataIdinQuery("savedJob", { _id: id });
      if (response) {
        getSkillUpAdCourseList();
      }
    } catch (error) {}
  };

  useEffect(() => {
    getSkillUpAdCourseList();
  }, []);

  return (
    <>
      {skillUpAdCourseData && skillUpAdCourseData?.course?.length !== 0 && (
        <Box className={classes.skillbox} mb={2}>
          <Box className="doted">
            <Typography variant="h5">Skill Up</Typography>
          </Box>
          <Grid container spacing={1} direction={"column"}>
            {skillUpAdCourseData &&
              skillUpAdCourseData?.course?.slice(0, 4)?.map((data, index) => {
                return (
                  <Grid item xs={12} key={index}>
                    <SkillUpCourse
                      data={data}
                      setSkillModal={setSkillModal}
                      setCourseData={setCourseData}
                      key={index}
                    />
                    <Divider />
                  </Grid>
                );
              })}
          </Grid>
          {skillModal && (
            <CourseModal
              handleCloseCreateEvent={setSkillModal}
              openCreateEvent={skillModal}
              courseData={courseData}
              getSkillUpAdCourseList={getSkillUpAdCourseList}
            />
          )}
          {isLoading &&
            [1].map((data, i) => {
              return <LoadHistory />;
            })}
          {skillUpAdCourseData?.jobs &&
            skillUpAdCourseData?.course?.length >= 5 && (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                mt={1}
              >
                <Typography
                  variant="body1"
                  style={{ color: "#fff", cursor: "pointer" }}
                  onClick={() => history.push("/all-pages")}
                >
                  Show More
                </Typography>
              </Box>
            )}
        </Box>
      )}
      {skillUpAdCourseData && skillUpAdCourseData?.jobs?.length !== 0 && (
        <Box mb={2}>
          <Paper className={classes.root} elevation={0}>
            <Box className={classes.jobsbox}>
              <Box className="doted">
                <Typography variant="h5">Best Suited Jobs/Works</Typography>
              </Box>
              <Grid container spacing={1} direction={"column"}>
                {skillUpAdCourseData &&
                  skillUpAdCourseData?.jobs?.slice(0, 4)?.map((data, index) => {
                    return (
                      <Grid item xs={12} key={`${index}12`}>
                        <Box
                          className={classes.mapingbox1}
                          onClick={() =>
                            history.push({
                              pathname: "/job-description",
                              state: data,
                              search: data?._id,
                            })
                          }
                          key={`${index}12`}
                        >
                          <Box className="ImageBox">
                            <Avatar
                              src={data?.companyLogo}
                              alt="Image"
                              style={{ course: "pointer" }}
                              onClick={() =>
                                history.push({
                                  pathname: "/job-description",
                                  state: data,
                                  search: data?._id,
                                })
                              }
                            />
                            <Box className="TextBox">
                              <Box
                                style={{ course: "pointer" }}
                                onClick={() =>
                                  history.push({
                                    pathname: "/job-description",
                                    state: data,
                                    search: data?._id,
                                  })
                                }
                              >
                                <Typography variant="h6">
                                  {data?.jobTitle}
                                </Typography>
                              </Box>
                              <Typography variant="body1">
                                {data?.compnayName}
                              </Typography>
                              <Typography variant="body2">
                                {data?.jobLocation}
                              </Typography>
                            </Box>
                          </Box>
                          <Box className="iconBox">
                            <IconButton
                              onClick={(event) => {
                                saveJobHandler(data?._id);
                                event.stopPropagation();
                              }}
                            >
                              {data?.saved ? (
                                <BsFillBookmarkFill />
                              ) : (
                                <CiBookmark />
                              )}
                            </IconButton>
                          </Box>
                        </Box>
                        <Box mt={2} mb={1}>
                          <Divider />
                        </Box>
                      </Grid>
                    );
                  })}
                {isLoading &&
                  [1].map((data, i) => {
                    return <LoadHistory />;
                  })}
              </Grid>
              {skillUpAdCourseData?.jobs &&
                skillUpAdCourseData?.jobs?.length >= 5 && (
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Typography
                      variant="body1"
                      style={{ color: "#323232", cursor: "pointer" }}
                      onClick={() => history.push("/job")}
                    >
                      Show More
                    </Typography>
                  </Box>
                )}
            </Box>
          </Paper>
        </Box>
      )}
      <Box mb={2}>
        <JobSearch skillUpAdCourseData={skillUpAdCourseData} />
      </Box>
      {skillUpAdCourseData && skillUpAdCourseData?.trending?.length !== 0 && (
        <Box>
          <Trending
            skillUpAdCourseData={skillUpAdCourseData}
            history={history}
            LoadHistory={LoadHistory}
            isLoading={isLoading}
          />
        </Box>
      )}
    </>
  );
}

export default SkillUp;
