// export const baseurl = "http://172.16.2.12:1974"; //local URL charu
export const baseurl = "https://node-unodata.mobiloitte.io"; //staging URL
// export const baseurl = "https://node.kashdata.com"; //mainnet URL

export const chatSocket = `${baseurl}`;
export const chatApiMainUrl = baseurl.includes(
  "https://node-unodata.mobiloitte.io"
);
let user = `${baseurl}/api/v1/user`;
let url = `${baseurl}/api/v1`;
let kyc = `${baseurl}/api/v1/kyc`;

const apiConfigs = {
  uploadFiles: `${url}/files/uploadFiles`,
  uploadFile: `${url}/files/uploadFile`,

  addLikeOnComent: `${url}/likeComment/addLike`,
  // -------------------Auth---------------------
  signUpEmail: `${user}/signUpEmail`,
  verifyOTPEmail: `${user}/verifyOTPEmail`,
  resendOTPEmail: `${user}/resendOTPEmail`,
  signUpMobileNo: `${user}/signUpMobileNo`,
  verifyOTPMobileNo: `${user}/verifyOTPMobileNo`,
  resendOTPMobileNo: `${user}/resendOTPMobileNo`,
  loginEmail: `${user}/loginEmail`,
  loginMobileNo: `${user}/loginMobileNo`,
  viewMyProfile: `${user}/viewMyProfile`,
  forgotPasswordEmail: `${user}/forgotPasswordEmail`,
  forgotPasswordMobileNo: `${user}/forgotPasswordMobileNo`,
  resetPassword: `${user}/resetPassword`,
  updateProfile: `${user}/updateProfile`,
  updateCoverPic: `${user}/updateCoverPic`,
  userListUsers: `${user}/userList`,
  connect: `${user}/connect`,
  logout: `${user}/logout`,
  otherUserProfile: `${user}/userProfile`,
  suggestions: `${user}/suggestions`,
  checkemail: `${user}/checkemail`,
  listCourses: `${user}/listCourses`,
  listCourses: `${user}/listCourses`,
  search: `${user}/search`,
  purchases: `${user}/purchases`,

  applyKyc: `${kyc}/applyKyc`,
  // -----------dummy-----
  resendOTP: `${user}/resendOTP`,
  login: `${user}/login`,
  editProfile: `${user}/editProfile`,
  // ------------post -------------
  addPost: `${url}/post/addPost`,
  editPost: `${url}/post/editPost`,
  postList: `${url}/post/postList`,
  trending: `${url}/post/trending`,
  viewPost: `${url}/post/viewPost`,
  ListMyPost: `${url}/post/ListMyAllPost`,

  addLike: `${url}/post/addLike`,
  addComment: `${url}/post/addComment`,
  addVote: `${url}/post/addVote`,
  deletePost: `${url}/post/deletePost`,
  listComments: `${url}/post/listComments`,
  reportOnPost: `${url}/reportPost/reportOnPost`,

  //---------library---------
  addMedia: `${url}/library/addMedia`,
  listMedia: `${url}/library/listMedia`,
  deleteMedia: `${url}/library/deleteMedia`,
  closeToDo: `${url}/library/closeToDo`,

  // skills
  listSkill: `${url}/skill/listSkill`,
  // job
  jobPost: `${url}/job/jobPost`,
  myJobPostList: `${url}/job/jobPostList`,
  jobList: `${url}/job/jobList`,
  viewJobPost: `${url}/job/viewJobPost`,

  applyJob: `${url}/job/applyJob`,
  savedJob: `${url}/job/savedJob`,
  savedJobList: `${url}/job/savedJobList`,
  updateJobInfo: `${url}/job/updateJobInfo`,
  deleteJobPost: `${url}/job/deletejob`,

  //Network
  // suggetstion: `${url}/network/suggetstion`,
  followRequest: `${url}/network/followRequest`,
  followRequestList: `${url}/network/followRequestList`,
  acceptRejectFollowRequest: `${url}/network/acceptRejectFollowRequest`,
  //moon light
  addMoonlight: `${url}/moonlight/addMoonlight`,
  listMoonlight: `${url}/moonlight/listMoonlight`,
  deleteMoonlight: `${url}/moonlight/deleteMoonlight`,
  updateMoonlight: `${url}/moonlight/updateMoonlight`,
  listAllUserMoonlight: `${url}/moonlight/listAllUserMoonlight`,
  applyMoonlight: `${url}/moonlight/applyMoonlight`,

  ////network
  networkList: `${user}/network/list`,
  acceptUsers: `${user}/network/accept`,
  userList: `${user}/network/userList`,
  introduce: `${user}/network/introduce`,
  request: `${user}/network/request`,
  applyMoonlightList: `${url}/moonlight/applyMoonlightList`,
  approveMoonlight: `${url}/moonlight/approveMoonlight`,
  myapplyMoonlightList: `${url}/moonlight/myapplyMoonlightList`,
  myworkMoonlightList: `${url}/moonlight/myworkMoonlightList`,

  //notification
  notificationList: `${url}/notification/list`,
  notificationRead: `${url}/notification/read`,
  notificationClear: `${url}/notification/clear`,
  ////library/////
  createTo_Do: `${url}/library/createToDo`,
  listTo_Do: `${url}/library/toDoList`,
  addProject: `${url}/library/addProject`,

  //admin
  listCategory: `${url}/category/listCategory`,
  projectList: `${url}/library/projectList`,
  updateproject: `${url}/library/updateproject`,
  Deleteproject: `${url}/library/Deleteproject`,

  //nft
  upload: `${url}/nft/upload`,
  ipfsUpload: `${url}/nft/ipfsUpload`,
  uploadAsset: `${url}/nft/uploadAsset`,
  create: `${url}/nft/create`,
  sentToMarketplace: `${url}/nft/sentToMarketplace`,
  myAssets: `${url}/nft/myAssets`,
  purchasedAsset: `${url}/nft/purchasedAsset`,
  favourites: `${url}/nft/favourites`,
  viewNft: `${url}/nft/view`,
  buyNft: `${url}/nft/buy`,
  addToFav: `${url}/nft/addToFav`,

  //wallet
  walletList: `${url}/wallet/list`,
  depositAmount: `${url}/account/depositAmount`,
  adminBankAccountList: `${url}/account/adminBankAccountList`,
  // business page
  createPage: `${url}/page/createPage`,
  editPage: `${url}/page/editPage`,
  viewPage: `${url}/page/viewPage`,
  pageList: `${url}/page/pageList`,
  createCourse: `${url}/page/createCourse`,
  editCourse: `${url}/page/editCourse`,

  viewCourse: `${url}/page/viewCourse`,
  courseList: `${url}/page/courseList`,
  otherUserCourseList: `${url}/page/otherUserCourseList`,
  deletePage: `${url}/page/deletePage`,
  deleteCourse: `${url}/page/deleteCourse`,
  addUpdatePageData: `${url}/page/addUpdatePageData`,
  activities: `${url}/page/activities`,
  buyCourse: `${url}/page/buyCourse`,

  // orgination
  listOrganizationTypeWithoutPagiantion: `${url}/organizationType/listOrganizationTypeWithoutPagiantion`,

  //GraphAnalysis
  getTotalNetworkChart: `${url}/analytics/getTotalNetworkChart`,
  getTotalLikesChart: `${url}/analytics/getTotalLikesChart`,
  getTotalCommentsChart: `${url}/analytics/getTotalCommentsChart`,
  getTotalTransactionChart: `${url}/analytics/getTotalTransactionChart`,

  //static
  staticContentList: `${url}/static/staticContentList`,

  //send to blockchain data
  chatHistory: `${url}/socket/chatHistory`,
  saveOnBlkchain: `${url}/socket/saveOnBlkchain`,
};
export default apiConfigs;
