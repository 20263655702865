import React from "react";

const PostedJobs = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.990729 5.72904C1.08225 5.34117 1.21682 4.97458 1.49387 4.67477C1.87233 4.26464 2.3354 4.02173 2.89642 4.01926C4.75758 4.01085 6.61875 4.01332 8.47991 4.01481C8.74113 4.01481 8.90142 4.17262 8.90092 4.40663C8.90043 4.6436 8.72975 4.80093 8.46012 4.80093C6.64249 4.80241 4.82437 4.80043 3.00674 4.80241C2.43385 4.8029 1.97474 5.13981 1.83078 5.66917C1.79565 5.7983 1.7813 5.93731 1.7813 6.07188C1.77883 9.71357 1.77833 13.3557 1.78031 16.9974C1.78081 17.7514 2.28395 18.2595 3.03494 18.26C7.04767 18.2625 11.0609 18.261 15.0736 18.261C15.1216 18.261 15.1701 18.256 15.2389 18.2526C15.2389 18.1769 15.2389 18.1111 15.2389 18.0448C15.2389 16.9752 15.2389 15.9056 15.2389 14.8355C15.2389 14.4268 15.3635 14.3002 15.7663 14.3002C16.8359 14.3002 17.9055 14.3002 18.9755 14.3002C19.0423 14.3002 19.1096 14.3002 19.2002 14.3002C19.2002 14.2195 19.2002 14.1532 19.2002 14.0875C19.2002 11.4288 19.2006 8.77012 19.1997 6.11097C19.1997 5.28428 18.7124 4.80142 17.8822 4.80142C16.1017 4.80142 14.3207 4.80192 12.5401 4.80093C12.2547 4.80093 12.08 4.64954 12.0786 4.40861C12.0771 4.20181 12.2171 4.04201 12.4229 4.01827C12.4659 4.01332 12.5095 4.01332 12.5525 4.01332C14.3578 4.01332 16.1635 4.00887 17.9688 4.01481C18.9429 4.01827 19.7107 4.61738 19.9304 5.53461C19.9699 5.70084 19.9858 5.87696 19.9858 6.04863C19.9892 8.91162 19.9873 11.7741 19.9902 14.6371C19.9902 14.7979 19.9383 14.9191 19.826 15.0314C18.5387 16.3147 17.2529 17.6 15.9701 18.8878C15.8518 19.0065 15.7232 19.052 15.5575 19.052C11.3963 19.0491 7.23518 19.0501 3.07353 19.0496C1.9975 19.0496 1.24997 18.444 1.01893 17.3888C1.01547 17.3724 1.00013 17.3581 0.990234 17.3428C0.990729 13.4715 0.990729 9.60028 0.990729 5.72904ZM16.0339 17.6163C16.8735 16.7768 17.718 15.9328 18.5451 15.1056C17.7338 15.1056 16.8868 15.1056 16.0339 15.1056C16.0339 15.9615 16.0339 16.8 16.0339 17.6163Z"
        fill="white"
      />
      <path
        d="M10.7122 0.0512765C10.8175 0.0834337 10.9249 0.111137 11.0283 0.148241C11.6521 0.374331 12.0692 0.965531 12.0746 1.62649C12.0801 2.27359 11.6734 2.87518 11.059 3.10572C10.9264 3.15519 10.8848 3.21505 10.8853 3.35556C10.8917 4.43109 10.8888 5.50663 10.8888 6.58217C10.8888 6.63164 10.8903 6.68111 10.8883 6.73058C10.8779 7.0007 10.722 7.17337 10.49 7.17386C10.2585 7.17386 10.0942 6.99972 10.0928 6.73108C10.0888 5.97068 10.0913 5.21029 10.0913 4.44989C10.0913 4.06648 10.0952 3.68307 10.0868 3.30015C10.0853 3.24375 10.0369 3.15964 9.98837 3.13787C9.25667 2.81185 8.85742 2.23154 8.90986 1.55228C8.96874 0.787924 9.42092 0.283298 10.2367 0.0705655C10.2481 0.0675971 10.2575 0.0577074 10.2674 0.0507812C10.4153 0.051276 10.5637 0.0512765 10.7122 0.0512765ZM11.2766 1.63391C11.2766 1.19558 10.9249 0.841354 10.489 0.841354C10.0542 0.841354 9.70242 1.19608 9.70242 1.6344C9.70242 2.07224 10.0537 2.42547 10.4895 2.42547C10.9254 2.42547 11.2766 2.07174 11.2766 1.63391Z"
        fill="white"
      />
      <path
        d="M10.4899 8.76221C12.4184 8.76221 14.3468 8.76221 16.2758 8.76221C16.632 8.76221 16.815 8.89183 16.8215 9.14662C16.8279 9.40882 16.6364 9.55032 16.2743 9.55032C12.4174 9.55032 8.56001 9.55032 4.70311 9.55032C4.34246 9.55032 4.1505 9.40734 4.15792 9.14464C4.16485 8.89035 4.34839 8.76172 4.70509 8.76172C6.63304 8.76221 8.56149 8.76221 10.4899 8.76221Z"
        fill="white"
      />
      <path
        d="M10.4697 12.7152C8.5472 12.7152 6.62518 12.7152 4.70267 12.7152C4.34746 12.7152 4.16342 12.5846 4.15748 12.3298C4.15155 12.0676 4.34301 11.9266 4.70515 11.9266C8.56204 11.9266 12.4194 11.9266 16.2763 11.9266C16.3441 11.9266 16.4134 11.9227 16.4801 11.932C16.6815 11.9612 16.8274 12.1354 16.8215 12.3318C16.8151 12.5317 16.6647 12.691 16.4594 12.7098C16.3921 12.7162 16.3233 12.7142 16.2555 12.7142C14.3266 12.7152 12.3982 12.7152 10.4697 12.7152Z"
        fill="white"
      />
      <path
        d="M8.89938 15.09C10.3217 15.09 11.7441 15.0895 13.1664 15.0905C13.4553 15.0905 13.627 15.2201 13.6478 15.4452C13.6671 15.654 13.5493 15.8241 13.3445 15.8692C13.2673 15.886 13.1852 15.887 13.1051 15.887C10.3039 15.888 7.50227 15.888 4.70113 15.8865C4.61504 15.8865 4.52698 15.883 4.44436 15.8622C4.27616 15.8207 4.16088 15.6648 4.15742 15.4947C4.15396 15.319 4.27368 15.1548 4.44981 15.1127C4.53292 15.0929 4.62098 15.0905 4.70706 15.0905C6.10417 15.0895 7.50178 15.09 8.89938 15.09Z"
        fill="white"
      />
    </svg>
  );
};

export default PostedJobs;