import TopTradingSkeleton from "src/Skeletons/TopTradingSkeleton";
import {
  Box,
  TableRow,
  makeStyles,
  TableBody,
  TableCell,
  TableContainer,
  Table,
  TableHead,
} from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import moment from "moment";
import React, { useState, useEffect, useContext } from "react";
import { getDataHandlerAPI } from "src/apiConfig/service";
import DataNotFound from "src/component/DataNotFound";
import { AuthContext } from "src/context/Auth";

const useStyles = makeStyles((theme) => ({
  transactionBox: {
    position: "relative",
    zIndex: "999",
  },
  imgBox: {
    margin: "0px 4px",
    cursor: "pointer",
    maxWidth: "100%",
    width: "auto",
  },
  tableBox: {
    minWidth: "800px",
  },
  mainfilter: {
    "& .displayEnd": {
      marginTop: "30px",
    },
    "& .filterpaper": {
      padding: "30px",
    },
  },
}));

export default function MyJobTable() {
  const classes = useStyles();
  const [appliedUserList, setAppliedUserList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [pageCount, setPageCount] = useState(1);
  const [page, setPage] = useState(1);

  const applyMoonlightListHandler = async () => {
    try {
      const paramsData = {
        page: page,
        limit: "10",
      };
      const response = await getDataHandlerAPI(
        "applyMoonlightList",
        paramsData
      );
      if (response) {
        setIsLoading(false);
        setAppliedUserList(response.docs);
        setPageCount(response.pages);
      } else {
        setIsLoading(false);
        setAppliedUserList([]);
      }
    } catch (error) {
      setIsLoading(false);
      setAppliedUserList([]);
    }
  };
  useEffect(() => {
    applyMoonlightListHandler();
  }, [page]);

  return (
    <Box className={classes.transactionBox}>
      <TableContainer>
        <Table className={classes.tableBox}>
          <TableHead>
            <TableRow>
              <TableCell>S No.</TableCell>

              <TableCell>Job Name</TableCell>
              <TableCell>Price</TableCell>
              <TableCell>Location</TableCell>
              <TableCell>Apply Date</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Apply Status</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {appliedUserList?.map((value, i) => (
              <TableRow>
                <TableCell> {(page - 1) * 15 + i + 1}</TableCell>
                <TableCell>
                  {value?.moonlightId.name ? (
                    <>{value?.moonlightId.name}</>
                  ) : (
                    "--"
                  )}
                </TableCell>
                <TableCell>
                  {value?.moonlightId.price ? (
                    <>{value?.moonlightId.price}</>
                  ) : (
                    "--"
                  )}
                </TableCell>
                <TableCell>
                  {value?.moonlightId.location ? (
                    <>{value?.moonlightId.location}</>
                  ) : (
                    "--"
                  )}
                </TableCell>
                <TableCell>
                  {value?.moonlightId.createdAt ? (
                    <>
                      {moment(value?.moonlightId.createdAt).format("MMM Do YY")}
                    </>
                  ) : (
                    "--"
                  )}
                </TableCell>

                <TableCell
                  style={{
                    color: value.status === "ACTIVE" ? "green" : "red",
                  }}
                >
                  {value.status === "ACTIVE"
                    ? "Active"
                    : value.status === "BLOCK"
                    ? "Blocked"
                    : "--"}
                </TableCell>
                <TableCell
                  style={{
                    color:
                      value.applystatus === "APPROVE"
                        ? "green"
                        : value.applystatus === "REJECT"
                        ? "red"
                        : "orange",
                  }}
                >
                  {value.applystatus === "APPROVE"
                    ? "Approved"
                    : value.applystatus === "REJECT"
                    ? "Rejected"
                    : value.applystatus === "PENDING"
                    ? "Pending"
                    : "--"}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {isLoading && <TopTradingSkeleton />}
      {!isLoading && appliedUserList && appliedUserList.length === 0 && (
        <DataNotFound text="No jobs found" />
      )}
      <Box mt={3} mb={1} style={{ float: "right" }}>
        {!isLoading &&
          appliedUserList &&
          appliedUserList.length >= (page === 1 ? 20 : 0) && (
            <Pagination
              variant="outlined"
              shape="rounded"
              count={pageCount}
              page={page}
              onChange={(e, v) => setPage(v)}
            />
          )}
      </Box>
    </Box>
  );
}
