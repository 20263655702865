import React, { useEffect, useState } from "react";
import { makeStyles, Dialog, IconButton, Grid, Box } from "@material-ui/core";

import { IoClose } from "react-icons/io5";

import { getParticularObjectDataHanndler } from "src/apiConfig/service";
import ButtonCircularProgress from "./ButtonCircularProgress";
import UserDataFeed from "./userdata/UserDataFeed";
import CommentDataFeed from "./userdata/CommentDataFeed";
import DataLoading from "./DataLoading";
import { mediaTypeImg, mediaTypeVideo, viewImage, viewVideo } from "src/utils";

const useStyles = makeStyles((theme) => ({
  loading: {
    minHeight: "60vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  root: {
    // minHeight: "70vh",
    overflow: "auto",
    "& .MuiPaper-root": {
      background: "#000",
      overflow: "auto",
      "& h6, h5, p": {
        color: "#fff",
      },
    },
    "& .MuiDialog-paper": {
      overflowY: "unset",
    },

    "& .bannerImg": {
      width: "100%",
      height: "40vh",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      overflow: "hidden",
      display: "block",
      objectFit: "contain",
      [theme.breakpoints.down("xs")]: {
        height: "30vh",
      },
    },
    "& .databanner": {
      overflow: "hidden",
      "& h5": {
        fontWeight: "700",
      },
    },
    "& h5": {
      fontWeight: 400,
      fontSize: "14px",
    },
    "& .avatarGroup": {
      "& .MuiAvatarGroup-avatar": {
        borderWidth: "0px",
      },
      "& .avatar": {
        width: "28px",
        height: "28px",
      },
    },
  },
  nameIconBox: {
    display: "flex",
    justifyContent: "space-between",
    padding: "8px 0px 16px",
    width: "100%",
    marginTop: "24px",
    [theme.breakpoints.down("xs")]: {
      marginTop: "0px",
    },

    "& .iconBox": {
      minWidth: "87px",
      display: "flex",
      justifyContent: "space-between",
      [theme.breakpoints.down("xs")]: {
        minWidth: "0px",
      },
      "& .MuiIconButton-root": {
        background: "#ffffff",
        color: "#4057A7",
        padding: "9px",
      },
    },
  },
  cancelBtn: {
    position: "absolute",
    top: 0,
    right: 0,
    zIndex: "1",
    "& svg": {
      color: "#fff",
      fontWeight: "700",
    },
  },

  rightBox: {
    padding: "24px 24px 24px 0px",
    [theme.breakpoints.down("xs")]: {
      padding: "10px",
    },
    "& .rightSecond": {
      borderRight: "1px solid rgba(255, 255, 255, 0.3)",

      [theme.breakpoints.down("sm")]: {
        borderLeft: "none",
      },
    },
    "& h6": {
      margin: "2px 0px",
      color: "white",
    },
    "& .commetBox": {
      borderRadius: "9px",
      padding: "8px 9px",
      margin: "6px 0px",
      background: "rgba(255, 255, 255, 0.08)",
    },
    "& .shareBox": {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      [theme.breakpoints.down("xs")]: {
        display: "block",
      },
    },
    "& .mainBox": {
      marginTop: "25px",
      textAlign: "end",
      [theme.breakpoints.down("xs")]: {
        marginTop: "5px",
      },
    },
  },
  commentControl: {
    "& .MuiFormControl-root": {
      backgroundColor: "#000000 !important",
    },

    "& .MuiOutlinedInput-root": {
      border: "1px solid #fff",
      borderRadius: "50px",
    },
    "& .MuiOutlinedInput-input": {
      background: "#000",
      borderRadius: "50px",
      height: "3px",
      color: "#fff",
    },
    "& svg": {
      color: "#fff",
      fontSize: "20px",
    },
  },
  mainTextFl: {
    margin: "16px",
    [theme.breakpoints.down("xs")]: {
      margin: "0px",
    },
    "& .MuiFormControl-root": {
      backgroundColor: "#000000 !important",
    },
  },
  replyflex: {
    position: "relative",
    // "&::after": {
    //   content: "''",
    //   height: "10px",
    //   position: "absolute",
    //   right: "30px",
    //   top: "3px",
    //   borderRight: "1px solid #fff",
    //   [theme.breakpoints.down("sm")]: {
    //     height: "32px",
    //     top: "5px",
    //   },
    //   [theme.breakpoints.down("sm")]: {
    //     height: "10px",
    //     top: "3px",
    //   },
    // },
    "& p": {
      cursor: "pointer",
    },
  },
  comment: {
    // height:"auto",

    maxHeight: "calc(100vh - 300px)",
    overflowY: "auto",
    [theme.breakpoints.down("xs")]: {
      maxHeight: "calc(100vh - 602px)",
      minHeight: "100px",
    },
  },
  emojiBox: {
    position: "absolute",
    // width: "100%",
    top: "10px",
    right: "10px",
    "& .aside": {
      width: "100%",
    },
    "& .EmojiPickerReact": {
      width: "100% !important",
    },
  },
  mainUserBox: {
    margin: "24px",
    [theme.breakpoints.down("xs")]: {
      margin: "10px",
    },
  },
  userfeedBox: {
    margin: "24px",
    [theme.breakpoints.down("xs")]: {
      margin: "0px",
    },
  },
}));

function ViewLiveFeedModal({
  openFeed,
  handleCloseFeed,
  id,
  type,
  modalsOpenView,
  setModalsOpenView,
}) {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [viewData, setViewData] = useState({});
  const [commentListData, setCommentListData] = useState([]);
  // console.log(" ----- idd --- res openFeed", openFeed);

  const viewPostHandler = async (id) => {
    try {
      const dataSend = {
        _id: id,
      };
      const res = await getParticularObjectDataHanndler("viewPost", dataSend);
      if (res) {
        setViewData(res.result);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };
  const listCommentHandler = async (id) => {
    try {
      const dataSend = {
        _id: id,
      };
      const res = await getParticularObjectDataHanndler(
        "listComments",
        dataSend
      );
      console.log("sdfjksdfh", res);

      if (res?.responseCode === 200) {
        setCommentListData(res.result);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        setCommentListData([]);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (id?.id) {
      viewPostHandler(id?.id);
      listCommentHandler(id?.id);
    }
  }, [id?.id]);

  return (
    <Dialog
      open={openFeed}
      onClose={handleCloseFeed}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="lg"
      fullWidth
      className={classes.root}
    >
      {isLoading ? (
        <Box className={classes.loading}>
          {/* <DataLoading /> */}
          <ButtonCircularProgress />
        </Box>
      ) : (
        <>
          <Box className={classes.topBox}>
            <Box>
              <IconButton
                className={classes.cancelBtn}
                onClick={handleCloseFeed}
              >
                <IoClose style={{ color: "rgb(172 168 168)" }} />
              </IconButton>
            </Box>
          </Box>
          <Box
          // sx={{ overflowY: "auto" }}
          >
            <Grid container>
              <Grid item sm={12} pd={4} xs={12} md={6}>
                <UserDataFeed
                  classes={classes}
                  viewData={viewData}
                  handleCloseFeed={handleCloseFeed}
                  type={type}
                  modalsOpenView={modalsOpenView}
                  setModalsOpenView={setModalsOpenView}
                />
              </Grid>
              <Grid item sm={12} xs={12} md={6}>
                <CommentDataFeed
                  callBack={() => viewPostHandler(id?.id)}
                  listCommentHandler={() => listCommentHandler(id?.id)}
                  classes={classes}
                  viewData={viewData}
                  commentListData={commentListData}
                  type={type}
                />
              </Grid>
            </Grid>
          </Box>
        </>
      )}
    </Dialog>
  );
}

export default ViewLiveFeedModal;
