import {
  Box,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  Typography,
} from "@material-ui/core";
import React from "react";
import { IoMdClose } from "react-icons/io";
import ButtonCircularProgress from "./ButtonCircularProgress";

export default function MetmaskDialog({ setOpenMetamask, openMetamask, auth }) {
  return (
    <Dialog
      open={openMetamask}
      onClose={() => !auth.metaMaskLoading && setOpenMetamask(false)}
      fullWidth
      maxWidth="xs"
    >
      <DialogContent>
        <IconButton style={{ position: "absolute", top: "0", right: "0" }}>
          <IoMdClose
            onClick={() => !auth.metaMaskLoading && setOpenMetamask(false)}
          />
        </IconButton>
        <Box align="center">
          <Typography variant="h2">Connect Wallet</Typography>
          <Box mt={5} mb={2}>
            <Box mb={3}>
              <img src="images/MetaMask.png" />{" "}
            </Box>
            <Button
              variant="contained"
              color="primary"
              onClick={() => auth.walletConnect()}
              disabled={auth.metaMaskLoading}
            >
              <Typography variant=""></Typography> Connect{" "}
              {auth.metaMaskLoading && <ButtonCircularProgress />}
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
