import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Paper } from "@material-ui/core";
import CardHeader from "@material-ui/core/CardHeader";
import Skeleton from "@material-ui/lab/Skeleton";
const useStyles = makeStyles((theme) => ({
  PostBox: {
    marginTop: "15px",

    "& .MuiCardHeader-root": {
      padding: "0 0 16px 0",
    },
    "& .MuiCardContent-root": {
      padding: "16px 16px 16px 0",
    },
  },
  earnCard: {
    padding: "5px 24px",
    background: "rgb(246, 246, 246)",
    marginTop: "15px",
    borderRadius: "0px",
    boxShadow: "none",
  },
  earnCard1: {
    minHeight: "270px",
    background: "#fff",
    marginTop: "15px",
    borderRadius: "0px",
    boxShadow: "none",
  },
  earn: {
    marginTop: "10px",
  },
  Post: {
    "& .MuiCardHeader-root": {
      padding: "0px",
    },
  },
}));

export default function LoadHistory({ type }) {
  const classes = useStyles();
  return (
    <div>
      {type === "work" ? (
        <Paper className={classes.earnCard1}>
          <Box className={classes.PostBox1}>
            <CardHeader
              title={
                <Skeleton
                  animation="wave"
                  height={30}
                  width="95%"
                  style={{ marginBottom: 6 }}
                />
              }
              subheader={<Skeleton animation="wave" height={20} width="40%" />}
              description={
                <Skeleton animation="wave" height={20} width="30%" />
              }
            />
          </Box>
        </Paper>
      ) : (
        <Paper
          className={
            type === "rewardHistorySel" ? classes.earn : classes.earnCard
          }
        >
          <Box
            className={
              type === "rewardHistorySel" ? classes.Post : classes.PostBox
            }
          >
            <CardHeader
              title={
                <Skeleton
                  animation="wave"
                  height={30}
                  width="95%"
                  style={{ marginBottom: 6 }}
                />
              }
              subheader={<Skeleton animation="wave" height={20} width="40%" />}
            />
          </Box>
        </Paper>
      )}
    </div>
  );
}
