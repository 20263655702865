import Page from "src/component/Page";
import {
  Box,
  Typography,
  Paper,
  makeStyles,
  Container,
  Grid,
  Button,
  TextField,
  withStyles,
  Dialog,
  DialogContent,
  IconButton,
} from "@material-ui/core";
import React, { useContext } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { AuthContext } from "src/context/Auth";
import CheckIcon from "@material-ui/icons/Check";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Check from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import StepConnector from "@material-ui/core/StepConnector";
import KycSteps from "./KycSteps";
const useStyles = makeStyles((theme) => ({
  root: {
    "& .typoBox": {
      paddingTop: "20px",
      "& h4": {
        color: "#4057A7",
      },
      "& h6": {
        fontWeight: "400",
        color: "rgba(0, 0, 0, 0.4)",
      },
    },

    "& .usertext": {
      "& p": {
        color: "#000000",
        fontSize: "18px",
        fontWeight: 700,
      },
    },
  },
  mainflexbox: {
    "& .buttoncircle": {
      maxWidth: "490px",
      "& button": {
        borderRadius: "50px",
      },
    },
  },

  textboxs: {
    position: "relative",
    zIndex: "1",
    marginLeft: "10px",
    "& li": {
      position: "relative",
      fontSize: "14px",
      fontWeight: "300",
      "&::after": {
        content: "''",
        position: "absolute",
        height: "5px",
        width: "5px",
        borderRadius: "50%",
        left: "-9px",
        top: "14px",
      },
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      maxWidth: "100%",
    },
    "& .MuiList-padding": {
      paddingTop: "0px",
      paddingBottom: "0px",
    },
  },
  boxcolor: {
    background: "#ECECED",
    borderRadius: "8px",
  },
  typoname: {
    textAlign: "center",
    padding: "20px",
    minHeight: "115px",
    "& h6": {
      paddingTop: "10px",
      color: "#808080",
    },
  },
  usetext: {
    color: "#808080",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "27px",
  },
  willcss: {
    paddingTop: "10px",
    "& h5": {
      color: "#4057A7",
      textAlign: "center",
      cursor: "pointer",
    },
  },

  Imgsec: {
    height: "244px",
    width: "auto",
    display: "flex",
    justifyContent: "center",
    borderRadius: "5px",
    backgroundColor: "#f1f1f1",
    padding: "10px",
    "& img": {
      color: "transparent",
      width: "100%",
      height: "100%",
      objectFit: "contain",
      textAlign: "center",
      borderRadius: "5px",
    },
  },
}));

const useQontoStepIconStyles = makeStyles({
  root: {
    color: "#eaeaf0",
    display: "flex",
    height: 22,
    alignItems: "center",
  },
  active: {
    color: "#784af4",
  },
  circle: {
    width: 8,
    height: 8,
    borderRadius: "50%",
    backgroundColor: "currentColor",
  },
  completed: {
    color: "#784af4",
    zIndex: 1,
    fontSize: 18,
  },
});
function QontoStepIcon(props) {
  const classes = useQontoStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {completed ? (
        <Check className={classes.completed} />
      ) : (
        <div className={classes.circle} />
      )}
    </div>
  );
}
QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   */
  active: PropTypes.bool,
  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed: PropTypes.bool,
};
const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 60,
  },
  active: {
    "& $line": {
      backgroundImage:
        "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
    },
  },
  completed: {
    "& $line": {
      backgroundImage:
        "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
    },
  },
  line: {
    marginTop: " 7%",
    borderStyle: "'dashed'",
    overflow: "hidden",
    border: "1px dashed #cccccc",
  },
})(StepConnector);
const useColorlibStepIconStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#ccc",
    borderRadius: "10px",
    height: "73px",
    width: "77px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: "999",
    [theme.breakpoints.down("xs")]: {
      height: "62px",
      width: "62px",
    },
  },
  active: {
    background: "#4057A7",
    borderRadius: "10px",
    height: "73px",
    width: "77px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: "999",
    [theme.breakpoints.down("xs")]: {
      height: "62px",
      width: "62px",
    },
  },
  completed: {
    backgroundColor: "#4057A7",
  },
  imgBox: {
    width: "30px",
    [theme.breakpoints.down("xs")]: {
      width: "20px",
    },
  },
}));

function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  const icons = {
    1: <img src="/images/steper1.png" alt="" className={classes.imgBox} />,
    2: <img src="/images/steper2.png" alt="" className={classes.imgBox} />,
    3: <img src="/images/steper3.png" alt="" className={classes.imgBox} />,
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
}

ColorlibStepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
  icon: PropTypes.node,
};
function getSteps() {
  return ["", "", ""];
}
export default function Registration({ setOpenKycModal, openKycModal }) {
  const classes = useStyles();
  const auth = useContext(AuthContext);

  const [activeStep, setActiveStep] = React.useState(3);
  const [kycModal, setkycModal] = React.useState(false);
  const steps = getSteps();

  return (
    <Page>
      <Container>
        <Dialog
          open={openKycModal}
          onClose={() => setOpenKycModal(false)}
          maxWidth="md"
          fullWidth
        >
          <DialogContent>
            <Box
              style={{
                position: "absolute",
                top: "0",
                right: "39px",
                zIndex: "99999",
              }}
            >
              <IconButton
                onClick={() => setOpenKycModal(false)}
                style={{ background: "#ffffff" }}
              >
                <CloseIcon style={{ color: "#000" }} />
              </IconButton>
            </Box>
            <Box className="scrollDiv">
              <Paper className={classes.root} elevation={0}>
                {auth?.userData?.kycStatus == "NOT_APPLIED" && (
                  <Box className="typoBox">
                    <Typography variant="h4">
                      Please read the below guidelines before submitting your
                      application:
                    </Typography>

                    <Box mt={3}>
                      <Box>
                        <Typography variant="h6">
                          KYC (Know Your Customer) is an important process to
                          ensure that our platform is being used by genuine
                          users who have provided their real identity
                          information. KYC helps us to create a secure and
                          trustworthy environment for all our users. The KYC
                          process includes the following sections.
                        </Typography>
                      </Box>
                      <Box mt={2}>
                        <Typography variant="h5">User Information:</Typography>
                        <Typography variant="h6">
                          This section requires you to provide your personal
                          information such as your first name, last name, mobile
                          number, email address, gender and residential address
                          details. It is important that you provide accurate
                          information as it will be used to verify your
                          identity.
                        </Typography>
                      </Box>
                      <Box mt={2}>
                        <Typography variant="h5">Upload Documents:</Typography>
                        <Typography variant="h6">
                          In this section, you will be required to upload a copy
                          of your government-issued ID card, such as a pan card,
                          aadhaar card or driving license. The document must be
                          valid and show your full name, photo, and date of
                          birth.
                        </Typography>
                      </Box>
                      <Box mt={2}>
                        <Typography variant="h5">
                          Confirm Your Details:
                        </Typography>
                        <Typography variant="h6">
                          Once you have provided all the required information
                          and uploaded the necessary documents, you will be
                          asked to confirm your details. Please review all the
                          information carefully to ensure that it is accurate
                          and up to date. If there are any discrepancies, please
                          contact our support team to update your information.
                        </Typography>
                      </Box>
                    </Box>
                    <Box className={classes.mainflexbox} mt={5}>
                      <Grid container spacing={2} justifyContent="center">
                        <Grid item>
                          <Box className={classes.boxcolor}>
                            <Box className={classes.typoname}>
                              <img
                                src="/images/step1.png"
                                alt=""
                                width="50px"
                              />
                              <Typography variant="h6">Step 1</Typography>
                              <Typography className={classes.usetext}>
                                User Information
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item>
                          <Box className={classes.boxcolor}>
                            <Box className={classes.typoname}>
                              <Box style={{ paddingBottom: "20px" }}>
                                <img
                                  src="/images/step2.png"
                                  alt=""
                                  width="50px"
                                />
                              </Box>
                              <Typography variant="h6">Step 2</Typography>
                              <Typography className={classes.usetext}>
                                Upload Document
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item>
                          <Box className={classes.boxcolor}>
                            <Box className={classes.typoname}>
                              <img
                                src="/images/step3.png"
                                alt=""
                                width="50px"
                              />
                              <Typography variant="h6">Step 3</Typography>
                              <Typography className={classes.usetext}>
                                Confirm Your Details
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>
                      <Box align="center">
                        <Box pt={5} pb={3} className="buttoncircle">
                          <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            onClick={() => {
                              setkycModal(true);
                            }}
                          >
                            Proceed
                          </Button>
                          <KycSteps
                            setkycModal={setkycModal}
                            kycModal={kycModal}
                            setOpenKycModal={setOpenKycModal}
                          />
                          <Box className={classes.willcss}>
                            <Link to="/home" style={{ textDecoration: "none" }}>
                              <Typography variant="h5">
                                I will do it later
                              </Typography>
                            </Link>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                )}
                {auth?.userData?.kycStatus !== "NOT_APPLIED" && (
                  <Box className="typoBox">
                    <Stepper
                      alternativeLabel
                      activeStep={activeStep}
                      connector={<ColorlibConnector />}
                      orientation="horizontal"
                    >
                      {steps.map((label, index) => (
                        <Step key={label} style={{ textAlign: "center" }}>
                          <Typography
                            variant="h6"
                            style={{
                              padding: "6px 0px",
                              fontSize: "18px",
                              color: "#4057A7",
                            }}
                          >
                            Step - {index + 1}
                          </Typography>
                          <StepLabel
                            StepIconComponent={ColorlibStepIcon}
                          ></StepLabel>
                        </Step>
                      ))}
                    </Stepper>
                    <Box className={classes.contactbox}>
                      <Box pb={3}>
                        <Typography variant="h5"></Typography>
                      </Box>
                      <Box className="usertext displaySpaceBetween" pb={2}>
                        <Typography>KYC Details</Typography>
                        <>
                          {auth?.userData?.kycDetail?.kycStatus ==
                            "APPROVE" && (
                            <>
                              <Button
                                variant="contained"
                                color="primary"
                                className={classes.button}
                                style={{
                                  background: "#52A500",
                                  color: "#FFFFFF",
                                }}
                                disabled
                              >
                                <CheckIcon /> KYC Approved
                              </Button>
                            </>
                          )}
                          {auth?.userData?.kycDetail?.kycStatus ==
                            "PENDING" && (
                            <>
                              <Button
                                variant="contained"
                                color="primary"
                                className={classes.button}
                                style={{ background: "#D1913C", color: "#fff" }}
                                disabled
                              >
                                KYC Under Review
                              </Button>
                            </>
                          )}

                          {auth?.userData?.kycDetail?.kycStatus == "REJECT" && (
                            <Button
                              variant="contained"
                              color="primary"
                              className={classes.button}
                              style={{ background: "#F24747", color: "#fff" }}
                              disabled
                            >
                              KYC Rejected
                            </Button>
                          )}
                        </>
                      </Box>
                      <Grid container spacing={3}>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <Box>
                            <TextField
                              variant="outlined"
                              fullWidth
                              className={classes.field}
                              value={auth?.userData?.kycDetail?.firstName}
                              inputProps={{ readOnly: true }}
                            />
                          </Box>
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <Box>
                            <TextField
                              variant="outlined"
                              fullWidth
                              className={classes.field}
                              value={auth?.userData?.kycDetail?.lastName}
                              inputProps={{ readOnly: true }}
                            />
                          </Box>
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <Box>
                            <TextField
                              variant="outlined"
                              fullWidth
                              className={classes.field}
                              value={`+${auth?.userData?.kycDetail?.mobileNumber}`}
                              inputProps={{ readOnly: true }}
                            />
                          </Box>
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <Box>
                            <TextField
                              variant="outlined"
                              fullWidth
                              className={classes.field}
                              value={auth?.userData?.kycDetail?.emailAddress}
                              inputProps={{ readOnly: true }}
                            />
                          </Box>
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <Box>
                            <TextField
                              variant="outlined"
                              fullWidth
                              className={classes.field}
                              value={auth?.userData?.kycDetail?.gender}
                              inputProps={{ readOnly: true }}
                            />
                          </Box>
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <Box>
                            <TextField
                              variant="outlined"
                              fullWidth
                              className={classes.field}
                              value={auth?.userData?.kycDetail?.address}
                              inputProps={{ readOnly: true }}
                            />
                          </Box>
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <Box>
                            <TextField
                              variant="outlined"
                              fullWidth
                              className={classes.field}
                              value={auth?.userData?.kycDetail?.country}
                              inputProps={{ readOnly: true }}
                            />
                          </Box>
                        </Grid>

                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <Box>
                            <TextField
                              variant="outlined"
                              fullWidth
                              className={classes.field}
                              value={auth?.userData?.kycDetail?.city}
                              inputProps={{ readOnly: true }}
                            />
                          </Box>
                        </Grid>
                      </Grid>
                      <Box className="usertext" pb={2} pt={2}>
                        <Typography>Uploded Documents</Typography>
                      </Box>
                      <Grid container spacing={3}>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <Box className={classes.Imgsec}>
                            <img
                              src={
                                auth?.userData?.kycDetail
                                  ?.documentFrontSideImage
                                  ? auth?.userData?.kycDetail
                                      ?.documentFrontSideImage
                                  : "images/front.png"
                              }
                              alt=""
                              width="100%"
                            />
                          </Box>
                          <Box pt={2}>
                            <TextField
                              placeholder="Aadhaar Card"
                              variant="outlined"
                              fullWidth
                              className={classes.field}
                              value={auth?.userData?.kycDetail?.documentType}
                              inputProps={{ readOnly: true }}
                            />
                          </Box>
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <Box className={classes.Imgsec}>
                            <img
                              src={
                                auth?.userData?.kycDetail?.documentBackSideImage
                                  ? auth?.userData?.kycDetail
                                      ?.documentBackSideImage
                                  : "images/back.png"
                              }
                              alt=""
                              width="100%"
                            />
                          </Box>
                          <Box pt={2}>
                            <TextField
                              variant="outlined"
                              fullWidth
                              className={classes.field}
                              value={auth?.userData?.kycDetail?.documentNumber}
                              inputProps={{ readOnly: true }}
                            />
                          </Box>
                        </Grid>
                      </Grid>

                      <Box className={classes.instructions}>
                        <Box
                          py={4}
                          textAlign="center"
                          className={classes.buttonboxes}
                        >
                          {auth?.userData?.kycDetail?.kycStatus == "REJECT" && (
                            <Button
                              variant="contained"
                              color="primary"
                              size="large"
                              onClick={() => {
                                setkycModal(true);
                              }}
                              className={classes.button}
                              style={{
                                background: "#4057A7",
                                color: "#fff",
                                padding: "0px 45px",
                              }}
                            >
                              Re-Submit
                            </Button>
                          )}
                          <KycSteps
                            setkycModal={setkycModal}
                            kycModal={kycModal}
                          />
                          <Box mt={2} pl={1}>
                            {auth?.userData?.kycDetail?.kycStatus ==
                              "REJECT" && (
                              <>
                                <Typography
                                  variant="h5"
                                  style={{
                                    color: "#9d9d9d",
                                    wordBreak: "break-word",
                                  }}
                                >
                                  <span style={{ color: "#4057A7" }}>
                                    Reason :{" "}
                                  </span>{" "}
                                  {auth?.userData?.kycDetail.rejectResion}`
                                </Typography>
                              </>
                            )}
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                )}
              </Paper>
            </Box>
          </DialogContent>
        </Dialog>
      </Container>
    </Page>
  );
}
