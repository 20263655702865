import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Button from "@material-ui/core/Button";
import { Typography, Box, Grid, Hidden } from "@material-ui/core";
import SkillUp from "src/views/pages/Dashboard/SkillUp";
import { MdOutlineMenuOpen } from "react-icons/md";
const useStyles = makeStyles({
  list: {
    width: 350,
    "@media(max-width:425px)": {
      width: "260px",
    },
  },
  fullList: {
    width: "auto",
  },
});

export default function News() {
  const classes = useStyles();
  const [state, setState] = React.useState({
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <Box className="scrollDiv">
        <Grid container direction={"column"} spacing={2}>
          <Grid item xs={12}>
            <Box>
              <SkillUp />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </div>
  );

  return (
    <div>
      <React.Fragment key={"right"}>
        <Box style={{ position: "absolute", right: "0" }}></Box>
        <Button
          onClick={toggleDrawer("right", true)}
          style={{
            zIndex: "99",
            position: "fixed",
            right: "10px",
            top: "48px",
          }}
        >
          <MdOutlineMenuOpen style={{ fontSize: "20px" }} />
        </Button>
        <Drawer
          anchor={"right"}
          open={state["right"]}
          onClose={toggleDrawer("right", false)}
        >
          {list("right")}
        </Drawer>
      </React.Fragment>
    </div>
  );
}
