import React from "react";

const CoreNetworkIcon = () => {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.77899 10.1998C11.2001 9.49974 12.51 9.61347 13.6874 10.6158C14.5723 11.3693 14.9723 12.3624 14.9998 13.4991C15.0073 13.8348 14.8061 14.0343 14.4998 14.0325C14.1936 14.0307 14.0199 13.8324 14.0036 13.4912C13.958 12.5048 13.5805 11.6801 12.7256 11.1114C11.9601 10.6024 10.9701 10.6042 10.3177 11.0555C10.4564 11.7403 10.5927 12.4001 10.7214 13.0625C10.7428 13.1925 10.7507 13.3243 10.7452 13.4559C10.7408 13.771 10.5246 14.0033 10.2452 14.0094C9.96585 14.0155 9.75837 13.7941 9.74524 13.462C9.70837 12.3296 9.39152 11.2878 8.67408 10.3816C6.85549 8.08452 3.61576 8.17209 1.92465 10.5641C1.30533 11.4404 1.01098 12.416 0.996607 13.4748C0.992857 13.7442 0.863493 13.9212 0.601641 13.9887C0.356036 14.0495 0.177927 13.9358 0.0579368 13.7339C0.021986 13.6727 0.0022294 13.6037 0.000441746 13.5332C-0.0283058 11.2513 1.34658 8.71155 3.97136 7.93855C5.99243 7.34436 8.25349 8.13256 9.51589 9.84458C9.6015 9.95892 9.68525 10.0733 9.77899 10.1998Z"
        fill="#A9A9A9"
      />
      <path
        d="M5.07719 6.29032C3.36926 6.29032 1.94513 4.86628 1.93555 3.14132C1.94326 2.30785 2.27621 1.51095 2.86234 0.923067C3.44847 0.335182 4.24065 0.00358618 5.06746 2.89173e-05C5.89428 -0.00352835 6.68923 0.321239 7.28032 0.904059C7.87141 1.48688 8.21109 2.28088 8.22586 3.11425C8.23033 4.84888 6.80813 6.28903 5.07719 6.29032ZM5.07719 5.25934C5.63674 5.25471 6.17232 5.02971 6.56954 4.6324C6.96677 4.23509 7.19416 3.69696 7.20315 3.13294C7.19455 2.57203 6.96774 2.03697 6.57157 1.64301C6.17541 1.24905 5.64159 1.02773 5.08511 1.02672C4.52863 1.0257 3.99403 1.24508 3.59645 1.63759C3.19888 2.0301 2.97015 2.56433 2.95954 3.12521C2.96536 3.6894 3.19027 4.22882 3.58603 4.62778C3.98179 5.02674 4.51689 5.25347 5.07655 5.25934H5.07719Z"
        fill="#A9A9A9"
      />
      <path
        d="M11.1449 3.3868C11.4621 3.38575 11.7763 3.44781 12.0693 3.56936C12.3623 3.69091 12.6284 3.86954 12.852 4.0949C13.0756 4.32025 13.2523 4.58784 13.3719 4.88215C13.4914 5.17645 13.5515 5.49162 13.5486 5.80936C13.5486 7.16899 12.4727 8.23192 11.1046 8.2254C10.4642 8.22004 9.85209 7.96001 9.40303 7.50251C8.95397 7.04501 8.70469 6.42752 8.71005 5.78589C8.7154 5.14425 8.97493 4.53102 9.43156 4.08111C9.88819 3.63119 10.5045 3.38144 11.1449 3.3868ZM11.1449 7.18269C11.3268 7.1837 11.507 7.14814 11.6749 7.07812C11.8428 7.0081 11.995 6.90504 12.1225 6.77504C12.2499 6.64505 12.35 6.49075 12.4169 6.32129C12.4838 6.15184 12.516 5.97066 12.5117 5.7885C12.5059 5.02097 11.8915 4.42625 11.1091 4.43147C10.749 4.44254 10.4077 4.59493 10.1587 4.85579C9.90973 5.11665 9.77302 5.46509 9.77805 5.826C9.78309 6.18691 9.92947 6.53138 10.1856 6.78516C10.4418 7.03895 10.7873 7.18171 11.1475 7.18269H11.1449Z"
        fill="#A9A9A9"
      />
    </svg>
  );
};

export default CoreNetworkIcon;
