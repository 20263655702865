import { Box, makeStyles, Typography } from "@material-ui/core";
import React from "react";
const useStyles = makeStyles((theme) => ({
  contentSection: {
    // height: "488px",
    position: "relative",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    "& h1": {
      position: "absolute",
      top: "78%",
      fontSize: "16px",
    },
    "& img": {
      width: "100%",
      maxWidth: "500px",
      height: "auto",
    },
  },
}));
export default function DataNotFound({ text }) {
  const classes = useStyles();
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
      }}
    >
      <Box width="100%" className={classes.contentSection}>
        <img src="images/datanotfound/datanotfound.gif" alt="No Data Found" />
        <Typography variant="h1" color="primary">
          {text ? text : "No Data Found"}
        </Typography>
      </Box>
    </div>
  );
}
