import { Avatar, Box, Paper, Typography, IconButton } from "@material-ui/core";
import moment from "moment";
import React, { useState, useContext } from "react";
import Report from "src/component/Report";
import { AiOutlineDelete } from "react-icons/ai";
import { BiDotsHorizontalRounded } from "react-icons/bi";
import { SpeedDial, SpeedDialAction, SpeedDialIcon } from "@material-ui/lab";
import { AuthContext } from "src/context/Auth";
import {
  deleteDataAPIHandler,
  postDataIdinWithdata,
} from "src/apiConfig/service";
import { useHistory } from "react-router-dom";
import { RiCloseFill } from "react-icons/ri";
import { GoReport } from "react-icons/go";
import { toast } from "react-toastify";
import ViewLiveFeedModal from "src/component/ViewLiveFeedModal";
import ConfirmationDialog from "src/component/ConfirmationDialog";
import { HiOutlineExternalLink } from "react-icons/hi";

export default function EventCommon({ classes, data, listPostHandler, auth }) {
  const user = useContext(AuthContext);
  const history = useHistory();
  const [open, setOpen] = useState({ firstDial: false, secondDial: false });
  const [isLoading, setIsLoading] = useState(false);
  const [categorySelect, setCategorySelect] = useState("");
  const [reportMsg, setReportMsg] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);
  const [openFeed, setOpenFeed] = useState(false);

  const actions = [
    {
      icon: <GoReport />,
      name: "Report",
    },
    {
      icon: <AiOutlineDelete />,
      name: "Delete",
    },
  ];
  const [modalsOpen, setModalsOpen] = useState({
    reportModals: false,
    deleteModals: false,
    id: "",
  });

  const [modalsOpenView, setModalsOpenView] = useState({
    liveFeeds: false,
    giftModals: false,
    buyModals: false,
    reportModals: false,
    deleteModals: false,
    id: "",
  });

  const handleClose = () => {
    setOpen({ firstDial: false, secondDial: false });
  };
  const handlerModalsOpen = (actionName, id) => {
    setOpen(false);
    switch (actionName) {
      case "Report":
        setModalsOpen({ ...modalsOpen, reportModals: true, id: id });
        break;

      case "Delete":
        setModalsOpen({ ...modalsOpen, deleteModals: true, id: id });
        break;
      default:
        break;
    }
  };
  const handleOpen = (open) => {
    switch (open) {
      case 1:
        setOpen({ ...open, firstDial: true });
        break;
      case 2:
        setOpen({ ...open, secondDial: true });
        break;

      default:
        break;
    }
    // setOpen(true);
  };

  const deeleteHandler = async (id) => {
    const idSend = {
      _id: id,
    };
    try {
      setIsLoading(true);
      const res = await deleteDataAPIHandler("deletePost", idSend);
      if (res) {
        listPostHandler();
        auth.listPostHandler();
        auth.listPostHandlerTrending();
        setModalsOpen({ ...modalsOpen, deleteModals: false });
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };
  const reportHandler = async (id) => {
    const idSend = {
      postId: id,
      description: [{ reason: categorySelect ? categorySelect : reportMsg }],
    };
    if (categorySelect !== "" || reportMsg !== "") {
      try {
        setIsLoading(true);
        const res = await postDataIdinWithdata("reportOnPost", idSend);
        if (res) {
          listPostHandler();
          auth.listPostHandler();
          auth.listPostHandlerTrending();
          setModalsOpen({ ...modalsOpen, reportModals: false });
          setIsLoading(false);
          setCategorySelect("");
          setReportMsg("");
        } else {
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
      }
    } else {
      setIsSubmit(true);
    }
  };
  return (
    <Paper
      className={classes.technology}
      elevation={0}
      style={{ position: "relative", height: "100%", cursor: "pointer" }}
    >
      <Box
        display="flex"
        alignItems="center"
        className="paddingboxes"
        onClick={(event) => {
          event.stopPropagation();
          if (data?.userId?._id !== auth?.userData?._id) {
            history.push({
              pathname: "/other-profile",
              search: data?.userId?._id,
              state: {
                id: data?.userId?._id,
              },
            });
          }
        }}
      >
        <Avatar src={data?.userId?.profilePic && data?.userId?.profilePic} />
        <Box>
          <Typography variant="h6">
            {" "}
            {data?.userId?.firstName ? data?.userId?.firstName : "Anonymous"}
          </Typography>
          <Typography variant="body1" style={{ margin: "0px 10px" }}>
            {" "}
            {moment(data?.createdAt).local().fromNow()}
          </Typography>
        </Box>
      </Box>
      <Box onClick={() => setOpenFeed(true)}>
        <img
          src={
            data?.eventPost?.image ? data?.eventPost?.image : "images/tech.png"
          }
          alt=""
          width="100%"
          style={{
            height: "100%",
            minHeight: "185px",
            maxHeight: "165px",
            objectFit: "cover",
          }}
        />
      </Box>
      <Box className="paddingboxes">
        <Typography variant="body2">
          {moment(data?.eventPost?.startDate).format("lll")}
        </Typography>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          // position="relative"
          className="description"
        >
          <Box display="flex" alignItems="center">
            <Typography
              variant="h6"
              className="Word-BrPre"
              style={{ margin: "0" }}
              onClick={() => window.open(data?.eventPost?.eventLink)}
            >
              {data?.eventPost?.eventName.slice(0, 35)}
              {data?.eventPost?.eventName.length >= 35 && "..."}
            </Typography>
            <IconButton onClick={() => window.open(data?.eventPost?.eventLink)}>
              <HiOutlineExternalLink />
            </IconButton>
          </Box>

          <Box className={classes.iconboxes}>
            <SpeedDial
              ariaLabel="SpeedDial openIcon example"
              icon={
                <SpeedDialIcon
                  openIcon={<RiCloseFill />}
                  icon={<BiDotsHorizontalRounded />}
                  onClick={(event) => event.stopPropagation()}
                />
              }
              className={classes.speedDial}
              onClose={handleClose}
              onOpen={() => handleOpen(1)}
              open={open.firstDial}
              onClick={(event) => event.stopPropagation()}
            >
              {actions
                .filter((itm) =>
                  itm.name === "Report"
                    ? auth?.userData?._id !== data?.userId?._id
                    : itm.name === "Delete"
                    ? auth?.userData?._id === data?.userId?._id
                    : itm
                )
                .map((action) => {
                  return (
                    <SpeedDialAction
                      className={classes.actionspeed}
                      key={action.name}
                      icon={action.icon}
                      tooltipTitle={action.name}
                      onClick={(event) => {
                        event.stopPropagation();
                        auth?.userData?.kycStatus === "APPROVE"
                          ? handlerModalsOpen(action.name, data?._id)
                          : toast.warn(
                              "Please complete your KYC to access this feature."
                            );
                      }}
                    />
                  );
                })}
            </SpeedDial>
          </Box>
        </Box>
      </Box>
      {modalsOpen.reportModals && !isLoading && (
        <Report
          openCreateReport={modalsOpen.reportModals}
          style={{ zIndex: "999" }}
          handleCloseCreateReport={() => {
            setModalsOpen({ ...modalsOpen, reportModals: false });
          }}
          setCategorySelect={(item) => setCategorySelect(item)}
          categorySelect={categorySelect}
          reportHandler={(id) => reportHandler(id)}
          id={modalsOpen}
          isLoading={isLoading}
          reportMsg={reportMsg}
          setReportMsg={(item) => setReportMsg(item)}
          isSubmit={isSubmit}
        />
      )}
      {modalsOpen.deleteModals && (
        <ConfirmationDialog
          open={modalsOpen?.deleteModals}
          handleClose={() =>
            setModalsOpen({ ...modalsOpen, deleteModals: false })
          }
          isLoading={isLoading}
          title="Delete"
          desc="Are you sure, you want to delete?"
          confirmationHandler={() => deeleteHandler(modalsOpen?.id)}
        />
      )}
      {openFeed && (
        <ViewLiveFeedModal
          openFeed={openFeed}
          handleCloseFeed={() => setOpenFeed(false)}
          id={{ ["id"]: data?._id }}
          listPostHandler={listPostHandler}
          type="event"
          modalsOpenView={modalsOpenView}
          setModalsOpenView={setModalsOpenView}
        />
      )}
    </Paper>
  );
}
