import PhotoSkeleton from "src/Skeletons/PhotoSkeleton";
import {
  Box,
  Typography,
  makeStyles,
  Paper,
  FormControl,
  Select,
  MenuItem,
} from "@material-ui/core";

import React from "react";
import ReactApexChart from "react-apexcharts";
const useStyles = makeStyles((theme) => ({
  graphboxMain: {
    padding: "15px",

    "& .apexcharts-svg.apexcharts-zoomable.hovering-zoom": {
      cursor: "pointer",
    },
    "& .apexcharts-tooltip-series-group.apexcharts-active .apexcharts-tooltip-marker":
      {
        display: "none !important",
      },
    "& .apexcharts-tooltip.apexcharts-theme-light .apexcharts-tooltip-title": {
      display: "none !important",
    },
    "& .apexcharts-tooltip-text-y-label": {
      display: "none",
    },
    "& .apexcharts-tooltip.apexcharts-theme-light": {
      display: "flex",
      background: "#40556ec2 !important",
      color: "white !important",
      borderRadius: "9.75949px !important",
      textAligns: "center",
    },
    "& .apexcharts-xaxistooltip": {
      display: "none",
    },
    "& .flexchart": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      overflow: "hidden",
      "& h5": {
        marginLeft: "17px",
        [theme.breakpoints.down("md")]: {
          fontSize: "14px",
        },
      },
      "& p": {
        "& span": {
          color: "#70CDDD",
        },
      },
      "& .MuiFormControl-root": {
        backgroundColor: "transparent",
      },
    },
  },
  selectstyle: {
    "& .MuiSelect-select.MuiSelect-select": {
      marginLeft: "5px",
      fontFamily: '"Mulish",normal',
      fontSize: "10px",
      fontWeight: "400",
      color: "#70CDDD",
      cursor: "pointer",
      transition: "all .2s",
    },
    "& .MuiInputBase-input": {
      backgroundColor: "transparent",
    },
    "& .MuiFormControl-root": {
      backgroundColor: "none",
      borderRadius: "0px",
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderBottom: "none",
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):after": {
      borderBottom: "none",
    },
    "& svg": {
      color: "#AEAEAE",
    },
    "& .MuiInput-underline:after": {
      borderBottom: "none",
    },
    "& .MuiInput-underline:before": {
      borderBottom: "none",
    },
  },
  graphBox: {
    position: "relative",
    "& .loaderBox": {
      position: "absolute",
      background: "#00000038",
      width: "100%",
      height: "100%",
      top: "0",
      right: "0",
      zIndex: "999",
      display: "flex",
      justifyContent: "center",
      borderRadius: "5px",
      alignItems: "center",
    },
  },
}));

const ContentGraphData = ({
  sortBy,
  setSortBy,
  networkData,
  isLoading,
  GraphName,
}) => {
  const classes = useStyles();

  const seriesData = {
    series: [
      {
        name: "Receive",
        data: networkData && networkData?.map((item) => (item ? item?.in : 0)),
      },
      {
        name: "Send",
        data: networkData && networkData.map((item) => (item ? item?.out : 0)),
      },
    ],
  };

  const optionsData = {
    options: {
      chart: {
        zoom: {
          enabled: false,
          autoScaleYaxis: false,
        },
        height: 350,
        type: "area",
      },
      dataLabels: {
        enabled: false,
        style: {
          colors: ["#DD8B8B", "rgba(64, 87, 167, 1)"],
        },
      },
      stroke: {
        curve: "smooth",
        width: 2,
      },
      xaxis: {
        type: "date",
        overwriteCategories:
          networkData && networkData?.map((item) => item && item?.date),
      },
      tooltip: {
        x: {
          format: "yyyy/dd/mm",
        },
      },
      grid: {
        strokeDashArray: 4,
      },
      colors: ["#DD8B8B", "rgba(64, 87, 167, 1)"],
    },
  };
  return (
    <Paper elevation={0} className={classes.graphboxMain}>
      <Box style={{ overflow: "hidden", width: "100%" }}>
        <Box className="flexchart">
          <Typography variant="h5">{GraphName}</Typography>
          <Box display="flex" alignItems="center">
            <Typography variant="body1">Show</Typography>
            <FormControl
              sx={{ m: 1, minWidth: 120 }}
              className={classes.selectstyle}
            >
              <Select
                value={sortBy}
                onChange={(event) => setSortBy(event.target.value)}
                inputProps={{ "aria-label": "Without label" }}
                displayEmpty
                fullWidth
                disabled={isLoading}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                }}
              >
                <MenuItem value={"monthly"}>Monthly</MenuItem>
                <MenuItem value={"yearly"}>Yearly</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Box>
        <Box className={classes.graphBox}>
          <ReactApexChart
            options={optionsData && optionsData.options}
            series={seriesData && seriesData.series}
            type="area"
            height={250}
          />
          {isLoading && (
            <Box className="loaderBox">
              <img src="images/photoloading.gif" width="100%" />
            </Box>
          )}
        </Box>
      </Box>
    </Paper>
  );
};

export default ContentGraphData;
