import React, { useState, useEffect } from "react";
import {
  Box,
  makeStyles,
  Divider,
  Paper,
  Grid,
  Typography,
  TableContainer,
  IconButton,
  FormControl,
  Select,
  MenuItem,
  Button,
} from "@material-ui/core";
import PostedJobs from "src/icons/PostedJobs";
import AppliedJobs from "src/icons/AppliedJobs";
import SavedJobs from "src/icons/SavedJobs";
import { getDataHandlerAPI } from "src/apiConfig/service";
import { KeyboardDatePicker } from "@material-ui/pickers";
import MotionDiv from "src/component/MotionDiv";
import PostSectionCards from "./PostSectionCards";
import SaveJobCard from "./SaveJobCard";
import DataNotFound from "src/component/DataNotFound";
import { Pagination } from "@material-ui/lab";
import Axios from "axios";
import apiConfigs from "src/apiConfig/ApiConfig";

const useStyles = makeStyles((theme) => ({
  mainform: {
    "& .MuiOutlinedInput-root": {
      height: "40px",
      borderRadius: "25px",
      backgroundColor: "#f1f1f1",
      paddingLeft: "13px",
    },
    "& .MuiOutlinedInput-input": {
      padding: "0px 0px 0px 10px",
      broderRadius: "25px",
    },
    "& .searchBox": {
      "& .MuiFormControl-root": {
        borderRadius: "50px",
      },
    },
  },
  root1: {
    "& .activeTav": {
      background: "#4057A7",
    },
    "& .InactiveTav": {
      background: "#4057A7",
      boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.1)",
    },
    "& .buttonbox": {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      cursor: "pointer",
      whiteSpace: "pre",
      [theme.breakpoints.only("xs")]: {
        justifyContent: "start",
      },
      "& .active": {
        background: "transparent",
        color: "#4057A7",
        padding: "23px 10px",
        boxShadow: "none",
        fontSize: "16px",
        borderBottom: "2px solid",
        borderImageBottom: "linear-gradient(90deg, #4C74B4 0%, #67B7D3 100%)",
        "&:hover": {
          boxShadow: "none",
        },
        [theme.breakpoints.only("xs")]: {
          fontSize: "14px",
        },
      },
      "& .inactive": {
        background: "transparent",
        color: "grey",
        padding: "23px 10px",
        boxShadow: "none",
        fontSize: "16px",
        borderBottom: "2px solid transparent",
        "&:hover": {
          boxShadow: "none",
        },
        [theme.breakpoints.only("xs ")]: {
          fontSize: "14px",
        },
      },
    },
  },
  DateBox: {
    "& .MuiOutlinedInput-root": {
      height: "40px",
      borderRadius: "25px",
      backgroundColor: "#f1f1f1",
      paddingLeft: "13px",
    },
    "& .MuiOutlinedInput-input": {
      padding: "0px 0px 0px 10px",
      broderRadius: "25px",
    },
    "& .MuiFormControl-root": {
      borderRadius: "50px",
    },
    "& .MuiIconButton-root": {
      padding: "12px 0px",
    },
  },
  DateBox1: {
    "& .MuiOutlinedInput-root": {
      height: "40px",
      borderRadius: "25px",
      backgroundColor: "#4057A7",
      paddingLeft: "13px",
    },
    "& .MuiOutlinedInput-input": {
      padding: "0px 0px 0px 10px",
      broderRadius: "25px",
    },
    "& .MuiFormControl-root": {
      borderRadius: "50px",
    },
    "& .MuiInputBase-input": {
      backgroundColor: "transparent !important",
      color: "#fff",
    },
    "& .MuiSelect-iconOutlined": {
      color: "#fff",
    },
    "& .MuiIconButton-root": {
      padding: "12px 0px",
    },
  },
}));

const tabViewArray = [
  {
    buttonName: "Posted jobs",
    tabView: "Phots",
    icon: PostedJobs,
    comp: PostSectionCards,
  },
  {
    buttonName: "Applied jobs",
    tabView: "jobpost",
    icon: AppliedJobs,
    comp: PostSectionCards,
  },
  {
    buttonName: "Saved jobs",
    tabView: "SaveJob",
    icon: SavedJobs,
    comp: SaveJobCard,
  },
];

const Job = ({ tabView1 }) => {
  const classes = useStyles();
  const [tabView, setTabView] = useState("Phots");
  const [jobsPostlistData, setJobsPostlistData] = useState([]);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [categoryList, setCategoryList] = useState([]);
  const [selectFilter, setSelectFilter] = useState({
    fromDate: null,
    toDate: null,
    category: "1",
  });

  const getMyPostJob = async () => {
    try {
      const paramsData = {
        categoryId:
          selectFilter?.category !== "1" ? selectFilter?.category : null,
        fromDate: selectFilter?.fromDate ? selectFilter?.fromDate : null,
        toDate: selectFilter?.toDate ? selectFilter?.toDate : null,
        page: page,
        limit: "20",
      };
      const response = await getDataHandlerAPI(
        tabView === "SaveJob"
          ? "savedJobList"
          : tabView === "jobpost"
          ? "jobList"
          : "myJobPostList",
        paramsData
      );
      if (response) {
        setIsLoading(false);
        setJobsPostlistData(
          tabView === "jobpost"
            ? response.docs.filter((data) => data.isApply)
            : response.docs
        );
        setPageCount(response.pages);
      } else {
        setIsLoading(false);
        setJobsPostlistData([]);
      }
    } catch (error) {
      setIsLoading(false);
      setJobsPostlistData([]);
    }
  };

  useEffect(() => {
    if (tabView1 === "MyJobs" || tabView1 === "myJob") {
      getMyPostJob();
    }
  }, [tabView1, page, tabView, selectFilter]);

  const getCategoryList = async () => {
    try {
      const response = await Axios({
        method: "POST",
        url: apiConfigs.listCategory,
      });
      if (response.data.responseCode === 200) {
        setCategoryList(response.data.result.docs);
      } else {
        setCategoryList([]);
      }
    } catch (error) {
      console.log(error);
      setCategoryList([]);
    }
  };

  useEffect(() => {
    getCategoryList();
  }, []);

  return (
    <div>
      <Box className="scrollDiv">
        <Box style={{ padding: "60px 2px 0px 15px" }}>
          <Paper className={`${classes.followersBox} paddingBox`} elevation={0}>
            <Box mt={1} mb={2}>
              <Typography variant="h5">Categories</Typography>
            </Box>
            <TableContainer>
              <Box className={classes.root1}>
                <Grid container>
                  <Grid item md={10} xs={12}>
                    <Box className="buttonbox">
                      {tabViewArray.map((item, i) => {
                        const Icon = item?.icon;
                        return (
                          <Box
                            onClick={() => setTabView(item?.tabView)}
                            className={
                              tabView === item?.tabView ? "active" : "inactive"
                            }
                          >
                            <IconButton
                              className={
                                tabView === "Phots"
                                  ? "activeTav"
                                  : "InactiveTav"
                              }
                            >
                              <Icon
                                className={
                                  tabView === item?.tabView
                                    ? "activeboxTabs"
                                    : "inActiveboxTabs"
                                }
                              />
                            </IconButton>
                            &nbsp;&nbsp;&nbsp; {item?.buttonName}
                          </Box>
                        );
                      })}
                    </Box>
                  </Grid>
                </Grid>
                <Box>
                  <Divider style={{ bakground: "rgba(0, 0, 0, 0.4)" }} />
                </Box>
              </Box>
            </TableContainer>
            <Box mt={2}>
              <Grid container spacing={2} style={{ justifyContent: "end" }}>
                <Grid item xs={6} sm={3} md={2}>
                  <Box className={classes.DateBox}>
                    <KeyboardDatePicker
                      inputVariant="outlined"
                      format="DD/MM/YYYY"
                      placeholder="DD/MM/YYYY"
                      disableFuture
                      InputProps={{ readOnly: true }}
                      fullWidth
                      value={selectFilter?.fromDate}
                      onChange={(date) =>
                        setSelectFilter({
                          ...selectFilter,
                          ["fromDate"]: new Date(date),
                        })
                      }
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={6} sm={3} md={2}>
                  <Box className={classes.DateBox}>
                    <KeyboardDatePicker
                      inputVariant="outlined"
                      format="DD/MM/YYYY"
                      placeholder="DD/MM/YYYY"
                      fullWidth
                      value={selectFilter?.toDate}
                      InputProps={{ readOnly: true }}
                      disableFuture
                      onChange={(date) =>
                        setSelectFilter({
                          ...selectFilter,
                          ["toDate"]: new Date(date),
                        })
                      }
                      disabled={!selectFilter?.fromDate}
                      minDate={selectFilter?.fromDate}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={6} sm={3} md={2}>
                  <Box className={classes.DateBox1}>
                    <FormControl variant="outlined" fullWidth>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={selectFilter?.category}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                          getContentAnchorEl: null,
                        }}
                        onChange={(e) =>
                          setSelectFilter({
                            ...selectFilter,
                            ["category"]: e.target.value,
                          })
                        }
                      >
                        <MenuItem value="1" disabled>
                          All Category
                        </MenuItem>
                        {categoryList &&
                          categoryList.map((item, i) => {
                            return (
                              <MenuItem value={item._id}>{item?.name}</MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl>
                  </Box>
                </Grid>
                <Grid item xs={6} sm={3} md={2}>
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    style={{ padding: "8px 16px", height: "40px" }}
                    onClick={() => {
                      setSelectFilter({
                        fromDate: null,
                        toDate: null,
                        category: "1",
                      });
                    }}
                  >
                    Reset
                  </Button>
                </Grid>
              </Grid>
            </Box>

            <Box>
              <Box className="MainContent" mt={2}>
                {tabViewArray
                  .filter((val) => val.tabView === tabView)
                  .map((item, i) => {
                    const ViewPostData = item.comp;
                    return (
                      <MotionDiv>
                        <Grid container spacing={2}>
                          {jobsPostlistData &&
                            jobsPostlistData.map((data, index) => {
                              return (
                                <Grid item xs={12} sm={6} md={6} lg={6}>
                                  <Box style={{ height: "100%" }}>
                                    <ViewPostData
                                      data={data}
                                      index={index}
                                      getMyPostJob={getMyPostJob}
                                      jobType={item}
                                    />
                                  </Box>
                                </Grid>
                              );
                            })}
                        </Grid>

                        {!isLoading &&
                          jobsPostlistData &&
                          jobsPostlistData.length >= (page === 1 ? 20 : 0) && (
                            <Pagination
                              variant="outlined"
                              shape="rounded"
                              count={pageCount}
                              page={page}
                              onChange={(e, v) => setPage(v)}
                            />
                          )}
                        {jobsPostlistData && jobsPostlistData.length === 0 && (
                          <DataNotFound text="No jobs found" />
                        )}
                      </MotionDiv>
                    );
                  })}
              </Box>
            </Box>
          </Paper>
        </Box>
      </Box>
    </div>
  );
};

export default Job;
