import React from "react";
import {
  makeStyles,
  Typography,
  Paper,
  Box,
  Grid,
  Button,
} from "@material-ui/core";
import { CiStopwatch } from "react-icons/ci";
import { CiLocationOn } from "react-icons/ci";
import { useHistory } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  jobsbox: {
    padding: "15px",
    borderRadius: "5px",

    "& .MuiDivider-root": {
      backgroundColor: "rgba(159, 171, 211, 0.3)",
    },
  },
  mapingbox1: {
    "& .TextBox": {
      marginLeft: "10px",

      "& h5": {
        fontWeight: "500",
        fontSize: "14px",
        lineHeight: "159.5%",
        color: "rgba(50, 50, 50, 0.8)",
      },
    },
  },
  root: {
    paddingTop: "15px",
    minHeight: "270px",
    position: "relative",
    "&:hover": {
      boxShadow: " 2px 17px 27px rgb(0 0 0 / 25%)",
    },
  },
}));

function SaveJobCard({ data, index, type }) {
  const classes = useStyles();
  const history = useHistory();
  return (
    <>
      <Box>
        <Paper className={classes.root} elevation={0}>
          <Box className={classes.jobsbox}>
            <Grid container spacing={1}>
              <Grid item xs={12} key={index}>
                <Box className={classes.mapingbox1}>
                  <Box className="ImageBox">
                    <Box className="TextBox">
                      <Typography variant="h4">
                        {data?.moonlightId?.name}
                      </Typography>
                      <Box mt={1} mb={1} display="flex">
                        <Box style={{ display: "flex", alignItems: "center" }}>
                          <CiStopwatch />

                          <Typography variant="h6">
                            {data?.moonlightId?.time}
                          </Typography>
                        </Box>
                        <Box
                          style={{
                            display: "flex",
                            alignItems: "center",
                            paddingLeft: "4px",
                          }}
                        >
                          <CiLocationOn />

                          <Typography variant="h6">
                            {data?.moonlightId?.location}
                          </Typography>
                        </Box>
                      </Box>
                      <Typography variant="h5">
                        {" "}
                        <div
                          dangerouslySetInnerHTML={{
                            __html: data?.moonlightId?.description,
                          }}
                        ></div>
                        {/* {data?.moonlightId?.description?.slice(0, 260)}
                        {data?.moonlightId?.description.length >= 260 && "..."} */}
                      </Typography>
                      <Box
                        style={{
                          position: "absolute",
                          bottom: "15px",
                          width: "88%",
                        }}
                      >
                        <Box
                          mt={2}
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Box>
                            <Typography variant="h6">
                              ₹{" "}
                              <span
                                style={{
                                  color: "#4057A7",
                                  wordBreak: "break-word",
                                }}
                              >
                                {data?.moonlightId?.price}
                              </span>
                            </Typography>
                          </Box>
                          <Box>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() =>
                                history.push({
                                  pathname: "/viewwork",
                                  state: {
                                    data: data,
                                  },
                                })
                              }
                            >
                              View Details
                            </Button>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </Box>
    </>
  );
}

export default SaveJobCard;
