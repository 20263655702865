import React, { useState } from "react";
import {
  makeStyles,
  Typography,
  Paper,
  Box,
  Divider,
  Grid,
  IconButton,
  Button,
  Avatar,
} from "@material-ui/core";
import { CiBookmark } from "react-icons/ci";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { deleteDataAPIHandler } from "src/apiConfig/service";
import { Delete, Edit } from "@material-ui/icons";
import HireModal from "src/component/HIreJobModal";
import ConfirmationDialog from "src/component/ConfirmationDialog";

const useStyles = makeStyles((theme) => ({
  jobsbox: {
    cursor: "pointer",
    "& .doted": {
      marginBottom: "10px",
      "& h5": {
        color: "#323232",
      },
    },
    "& .MuiDivider-root": {
      backgroundColor: "rgba(159, 171, 211, 0.3)",
    },
  },
  mapingbox1: {
    display: "flex",
    justifyContent: "space-between",
    height: "117px",
    "& .ImageBox": {
      display: "flex",
      "& .MuiAvatar-root": {
        width: "50px",
        height: "50px",
        cursor: "pointer",
      },

      "& h6": {
        // fontSize: "14px",
        fontWeight: "700",
        color: "#323232",
      },
      "& p": {
        color: "#ABABAB",
      },
    },
    "& .TextBox": {
      marginLeft: "10px",
    },
    "& .iconBox": {
      "& svg": {
        fontSize: "17px",
        color: "#4057A7",
      },
    },
  },
  root: {
    paddingTop: "15px",
    [theme.breakpoints.down("xs")]: {
      padding: "10px",
    },
  },
}));

function PostSectionCards({ data, index, type, getMyPostJob, jobType }) {
  const classes = useStyles();
  const history = useHistory();
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [jobDeleteLoading, setJobDeleteLoading] = useState(false);

  console.log("sjdfhjksdf", jobType);

  const deletePageHandler = async () => {
    try {
      setJobDeleteLoading(true);
      const res = await deleteDataAPIHandler("deleteJobPost", {
        jobId: data?._id,
      });

      if (res.responseCode === 200) {
        setJobDeleteLoading(false);
        getMyPostJob();
        setDeleteModal(false);
      } else {
        setJobDeleteLoading(false);
      }
    } catch (error) {
      setJobDeleteLoading(false);
    }
  };

  return (
    <>
      <Box>
        <Paper className={classes.root} elevation={0}>
          <Box className={classes.jobsbox}>
            <Grid container spacing={1}>
              <Grid item xs={12} key={index}>
                <Box className={classes.mapingbox1}>
                  <Box className="ImageBox">
                    <Avatar
                      onClick={() =>
                        history.push({
                          pathname: "/job-description",
                          state: data,
                          search: data?._id,
                        })
                      }
                      src={data?.companyLogo}
                      alt=""
                    />

                    <Box
                      className="TextBox"
                      onClick={() =>
                        history.push({
                          pathname: "/job-description",
                          state: data,
                          search: data?._id,
                        })
                      }
                    >
                      <Typography variant="h6">{data?.compnayName}</Typography>
                      <Typography
                        variant="body1"
                        style={{ wordBreak: "break-all" }}
                      >
                        <div
                          dangerouslySetInnerHTML={{
                            __html: `${data?.description?.slice(0, 60)}${
                              data?.description?.length > 60 ? "..." : ""
                            }`,
                          }}
                        ></div>
                      </Typography>
                      <Typography variant="body2">
                        {data?.jobLocation}
                      </Typography>

                      <Box style={{ display: "flex" }}>
                        <Typography variant="body2">
                          {" "}
                          {data?.isapply
                            ? moment(data?.updatedAt).local().fromNow()
                            : moment(data?.createdAt).local().fromNow()}
                        </Typography>

                        {type === "recommend" && (
                          <Button
                            variant="body2"
                            style={{ color: "#4057A7", padding: "0px" }}
                          >
                            Apply
                          </Button>
                        )}
                      </Box>
                    </Box>
                  </Box>
                  {jobType?.buttonName === "Posted jobs" && (
                    <Box>
                      <IconButton
                        onClick={() => {
                          setEditModal(true);
                        }}
                      >
                        <Edit style={{ color: "#4057A7" }} />
                      </IconButton>
                      <IconButton
                        onClick={() => {
                          setDeleteModal(true);
                        }}
                      >
                        <Delete style={{ color: "#4057A7" }} />
                      </IconButton>
                    </Box>
                  )}
                </Box>
                <Box mt={2} mb={1}>
                  <Divider />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Paper>
        {editModal && (
          <HireModal
            openCreatePost={editModal}
            handleCloseCreatePost={() => setEditModal(false)}
            pageJOBHAndler={getMyPostJob}
            jobId={data}
            type="edit"
          />
        )}
        {deleteModal && (
          <ConfirmationDialog
            open={deleteModal}
            handleClose={() => setDeleteModal(false)}
            isLoading={jobDeleteLoading}
            confirmationHandler={() => deletePageHandler()}
            desc="Are you sure, you want to delete this job?"
            title="Delete"
          />
        )}
      </Box>
    </>
  );
}

export default PostSectionCards;
