import {
  Box,
  makeStyles,
  IconButton,
  Typography,
  Divider,
} from "@material-ui/core";
import React, { useMemo } from "react";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  NftCardsBox: {
    position: "relative",
    borderRadius: "20px",
    minHeight: "270px",
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    border: "1px solid #80808052",
    transition: "0.5s",
    "&:hover": {
      transform: "translateY(-5px)",
      boxShadow:
        " rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px",
    },
    "& .likeBox": {
      position: "absolute",
      display: "flex",
      alignItems: "center",
      background: "rgba(0, 0, 0, 0.15)",
      border: "1px solid rgba(255, 255, 255, 0.3)",
      borderRadius: "39px",
      padding: " 5px 10px",
      right: "10px",
      top: "10px",
      "& .MuiIconButton-root": {
        padding: "0px",
        fontSize: "25px",
        marginRight: "10px",
      },
    },
    "& h5": {
      color: "#70CDDD",
      fontWeight: "700",
    },
    "& h4": {
      color: "#fff",
      "& span": {
        fontSize: "15px",
        fontWeight: "400",
        [theme.breakpoints.down("xs")]: {
          fontSize: "12px",
        },
      },
    },
    "& .detailBox": {
      background: "rgba(0, 0, 0, 0.5)",
      borderRadius: "20px",
      padding: "15px",
      position: "absolute",
      bottom: "0px",
      width: "calc(100% - 52px)",
      margin: "10px",
      "&:before": {
        content: "''",
        position: "absolute",
        top: "0",
        left: "0",
        height: "100%",
        width: "100%",
        backdropFilter: "blur(5px)",
        borderRadius: "20px",
      },
    },
    "& .displayBox": {
      display: "flex",
      position: "relative",
      justifyContent: "space-between",
      alignItems: "center",
      "& .MuiIconButton-root": {
        padding: "8px",
      },
    },
    "& video": {
      height: "100%",
      width: "100%",
      maxHeight: "271px",
      borderRadius: "20px",
      objectFit: "cover",
    },
  },
}));

export default function NftCards({ data, index, tabView6 }) {
  const classes = useStyles();
  const history = useHistory();

  const nftData = useMemo(() => {
    return tabView6 === "bought" || tabView6 === "favourite"
      ? data?.nftId
      : data;
  }, [data]);

  return (
    <Box
      className={classes.NftCardsBox}
      style={{
        backgroundImage: `url(${
          nftData?.mediaType?.split("/")[0] === "image"
            ? nftData?.mediaFile
            : nftData?.mediaType === "application/pdf"
            ? "images/pdf.png"
            : nftData?.mediaType?.split("/")[0] !== "video" && "images/docs.png"
        })`,
      }}
    >
      {nftData?.mediaType?.split("/")[0] === "video" && (
        <video
          controls={true}
          autoplay={false}
          loop
          muted={false}
          playsinline="true"
          width="160px"
          height="120px"
        >
          <source
            src={nftData && nftData?.mediaFile}
            type="video/mp4"
            accept="video/*"
          />
        </video>
      )}

      <Box className="detailBox">
        <Box>
          <Box className="displayBox">
            <Typography variant="h5">{nftData?.tokenName}</Typography>
          </Box>
          <Box my={0.5}>
            <Divider
              style={{
                background: "#fff",
                width: "100%",
                position: "relative",
              }}
            />
          </Box>
          <Box className="displayBox">
            <Box
              onClick={() =>
                history.push({
                  pathname: "/asset-details",
                  search: nftData?._id,
                  state: tabView6,
                })
              }
            >
              <Typography
                variant="h6"
                style={{
                  color: "#fff",
                  textDecoration: "underline",
                  cursor: "pointer",
                  fontWeight: "300",
                }}
              >
                View Details
              </Typography>
            </Box>
            <Typography variant="h4">
              {nftData?.buyPrice} AVAX <span>Price</span>
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
