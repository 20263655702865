import {
  deleteDataAPIHandler,
  postDataIdinWithdata,
  putAPIHandler,
} from "src/apiConfig/service";
import {
  Box,
  Paper,
  Typography,
  Avatar,
  Button,
  withStyles,
} from "@material-ui/core";
import React, { useState, useContext } from "react";
import moment from "moment";
import LinearProgress from "@material-ui/core/LinearProgress";
import { SpeedDial, SpeedDialAction, SpeedDialIcon } from "@material-ui/lab";
import { AuthContext } from "src/context/Auth";
import { BiDotsHorizontalRounded } from "react-icons/bi";
import { AiOutlineDelete } from "react-icons/ai";
import Report from "src/component/Report";
import PoolCreate from "./PoolCreate";
import PoolCard from "./PoolCard";
import { useHistory } from "react-router-dom";
import { RiCloseFill } from "react-icons/ri";
import { GoReport } from "react-icons/go";
import { toast } from "react-toastify";
import ConfirmationDialog from "src/component/ConfirmationDialog";

export default function PoolCommon({ classes, data, listPostHandler, auth }) {
  const user = useContext(AuthContext);
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState({ firstDial: false, secondDial: false });
  const [categorySelect, setCategorySelect] = useState("");
  const [reportMsg, setReportMsg] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);
  const [isLoadingVote, setIsLoadingVote] = useState(false);
  const [modalsOpen, setModalsOpen] = useState({
    reportModals: false,
    deleteModals: false,
    id: "",
  });
  const actions = [
    {
      icon: <GoReport />,
      name: "Report",
    },
    {
      icon: <AiOutlineDelete />,
      name: "Delete",
    },
  ];
  const handleOpen = (open) => {
    switch (open) {
      case 1:
        setOpen({ ...open, firstDial: true });
        break;
      case 2:
        setOpen({ ...open, secondDial: true });
        break;

      default:
        break;
    }
  };
  const handleClose = () => {
    setOpen({ firstDial: false, secondDial: false });
  };
  const handlerModalsOpen = (actionName, id) => {
    setOpen(false);
    switch (actionName) {
      case "Report":
        setModalsOpen({ ...modalsOpen, reportModals: true, id: id });
        break;

      case "Delete":
        setModalsOpen({ ...modalsOpen, deleteModals: true, id: id });
        break;
      default:
        break;
    }
  };
  const addVoteHandler = async (optionId, postId) => {
    try {
      setIsLoadingVote(true);
      const res = await putAPIHandler(
        "addVote",
        { optionId: optionId },
        { postId: postId }
      );
      if (res?.responseCode === 200) {
        listPostHandler();
        auth.listPostHandler();
        setIsLoadingVote(false);
      } else {
        setIsLoadingVote(false);
      }
    } catch (error) {
      setIsLoadingVote(false);
    }
  };
  const deeleteHandler = async (id) => {
    const idSend = {
      _id: id,
    };
    try {
      setIsLoading(true);
      const res = await deleteDataAPIHandler("deletePost", idSend);
      if (res) {
        listPostHandler();
        auth.listPostHandler();
        auth.listPostHandlerTrending();
        setModalsOpen({ ...modalsOpen, deleteModals: false });
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const calculatePercent = (votes, total) => {
    if (votes !== 0 && total[0]?.vote !== 0) {
      return `${parseInt((votes / total) * 100)}%`;
    }
    return "0";
  };

  const reportHandler = async (id) => {
    const idSend = {
      postId: id,
      description: [{ reason: categorySelect ? categorySelect : reportMsg }],
    };
    if (categorySelect !== "" || reportMsg !== "") {
      try {
        setIsLoading(true);
        const res = await postDataIdinWithdata("reportOnPost", idSend);
        if (res) {
          listPostHandler();
          auth.listPostHandler();
          auth.listPostHandlerTrending();
          setModalsOpen({});
          setIsLoading(false);
          setCategorySelect("");
          setReportMsg("");
        } else {
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
      }
    } else {
      setIsSubmit(true);
    }
  };

  return (
    <Paper
      className={classes.cryptobox}
      elevation={0}
      style={{ position: "relative", padding: "0", height: "100%" }}
    >
      <Box p={1.8}>
        <Box
          display="flex"
          alignItems="center"
          style={{ cursor: "pointer" }}
          onClick={(event) => {
            event.stopPropagation();
            if (data?.userId?._id !== auth?.userData?._id) {
              history.push({
                pathname: "/other-profile",
                search: data?.userId?._id,
                state: {
                  id: data?.userId?._id,
                },
              });
            }
          }}
        >
          <Avatar src={data?.userId?.profilePic && data?.userId?.profilePic} />
          <Box>
            <Typography variant="h6">
              {data?.userId?.firstName ? data?.userId?.firstName : "Anonymous"}
            </Typography>
            <Typography variant="body1" style={{ margin: "0px 10px" }}>
              {moment(data?.createdAt).local().fromNow()}
            </Typography>
          </Box>
        </Box>
        <Box mt={2} className="rebundbox">
          <Box>
            <Typography variant="h4">{data?.pollPost?.question}</Typography>
            <Typography
              variant="h5"
              style={{ color: "#3f51b5", fontWeight: "500" }}
            >
              The author can see how your vote.
            </Typography>
          </Box>

          <Box mt={2}>
            {data.isVoted ? (
              <PoolCard pollDetails={data?.pollPost?.options} data={data} />
            ) : (
              <PoolCreate
                addVoteHandler={(item_id, data_id) =>
                  addVoteHandler(item_id, data_id)
                }
                pollDetails={data?.pollPost?.options}
                data={data}
                isLoadingVote={isLoadingVote}
              />
            )}
          </Box>
        </Box>
        <Box className={classes.iconboxes2}>
          <SpeedDial
            style={{ top: "0px", zIndex: "999" }}
            ariaLabel="SpeedDial openIcon example"
            direction="left"
            icon={
              <SpeedDialIcon
                openIcon={<RiCloseFill />}
                icon={<BiDotsHorizontalRounded />}
                onClick={(event) => event.stopPropagation()}
              />
            }
            className={classes.speedDial}
            onClose={handleClose}
            onOpen={() => handleOpen(1)}
            open={open.firstDial}
            onClick={(event) => event.stopPropagation()}
          >
            {actions
              .filter((itm) =>
                itm.name === "Report"
                  ? auth?.userData?._id !== data?.userId?._id
                  : itm.name === "Delete"
                  ? auth?.userData?._id === data?.userId?._id
                  : itm
              )
              .map((action) => {
                return (
                  <SpeedDialAction
                    className={classes.actionspeed}
                    style={{ zIndex: "999" }}
                    key={action.name}
                    icon={action.icon}
                    tooltipTitle={action.name}
                    onClick={(event) => {
                      event.stopPropagation();
                      auth?.userData?.kycStatus === "APPROVE"
                        ? handlerModalsOpen(action.name, data?._id)
                        : toast.warn(
                            "Please complete your KYC to access this feature."
                          );
                    }}
                  />
                );
              })}
          </SpeedDial>
        </Box>
      </Box>

      {modalsOpen.reportModals && (
        <Report
          openCreateReport={modalsOpen.reportModals}
          handleCloseCreateReport={() => {
            setModalsOpen({ ...modalsOpen, reportModals: false });
          }}
          setCategorySelect={(item) => setCategorySelect(item)}
          categorySelect={categorySelect}
          reportHandler={(id) => reportHandler(id)}
          id={modalsOpen}
          isLoading={isLoading}
          reportMsg={reportMsg}
          setReportMsg={(item) => setReportMsg(item)}
          isSubmit={isSubmit}
        />
      )}
      {modalsOpen.deleteModals && (
        <ConfirmationDialog
          open={modalsOpen?.deleteModals}
          handleClose={() =>
            setModalsOpen({ ...modalsOpen, deleteModals: false })
          }
          isLoading={isLoading}
          title="Delete"
          desc="Are you sure, you want to delete?"
          confirmationHandler={() => deeleteHandler(modalsOpen?.id)}
        />
      )}
    </Paper>
  );
}
const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 5,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "#07bc0c",
  },
}))(LinearProgress);
