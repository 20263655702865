import React from 'react'

const Library = ({ className }) => {
  return (
    <svg
      width="20"
      height="19"
      viewBox="0 0 20 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.9231 5.38806C19.9231 5.32504 20 5.26202 20 5.19901C20 5.19901 20 5.19901 20 5.13599C20 5.13599 20 5.13599 20 5.07297C20 5.00995 20 4.94693 20 4.88391C20 4.88391 20 4.88391 20 4.8209C20 4.75788 19.9231 4.69486 19.9231 4.63184C19.8462 4.56882 19.8462 4.56882 19.7692 4.5058L11.3077 0.0945274C11.0769 -0.0315091 10.6923 -0.0315091 10.4615 0.0945274L0.461538 5.76617L0.384615 5.82919C0.384615 5.82919 0.384615 5.82919 0.307692 5.82919C0.230769 5.89221 0.230769 5.95522 0.153846 6.01824C0.153846 6.01824 0.153846 6.01824 0.153846 6.08126C3.43873e-08 6.27032 0 6.33333 0 6.39635V10.1774V13.9585C0 14.1476 0.153846 14.3997 0.384615 14.4627L8.84615 18.874C9 18.937 9.15385 19 9.23077 19C9.38462 19 9.53846 18.937 9.69231 18.874L19.6923 13.2023C19.8462 13.0763 20 12.9502 20 12.7612C20 12.5721 19.9231 12.3831 19.7692 12.257C19.0769 11.6899 18.9231 10.8706 19.3846 10.1144L19.9231 9.16915C19.9231 9.10614 20 9.04312 20 8.9801V8.91708C20 8.85406 20 8.72803 19.9231 8.66501V8.60199C19.9231 8.53897 19.8462 8.47595 19.7692 8.41294C19.0769 7.84577 18.9231 7.02653 19.3846 6.27032L19.9231 5.38806ZM18.1538 8.79105L9.23077 13.8325L1.53846 9.86236V7.5937L8.84615 11.3748C9 11.4378 9.15385 11.5008 9.23077 11.5008C9.38462 11.5008 9.53846 11.4378 9.69231 11.3748L17.6154 6.9005C17.5385 7.53068 17.6923 8.16086 18.1538 8.79105ZM9.23077 17.6136L1.53846 13.6434V11.3748L8.84615 15.1559C9 15.2189 9.15385 15.2819 9.23077 15.2819C9.38462 15.2819 9.53846 15.2189 9.69231 15.1559L17.6154 10.6816C17.5385 11.3748 17.6923 12.068 18.1538 12.6352L9.23077 17.6136Z"
        fill="white"
      />
    </svg>
  )
}

export default Library
