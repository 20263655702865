import React from "react";
import { Box, Grid, Hidden } from "@material-ui/core";
import SkillUp from "src/views/pages/Dashboard/SkillUp";
import Page from "src/component/Page";
import HireModal from "src/component/HIreJobModal";
import Job from "./Job";
import News from "src/component/RecommendedPage/News";

const Index = ({ postjob, postModalOpen, setPostModalOpen, tabView1 }) => {
  return (
    <Page title="Job">
      <Box className="scrollDiv">
        <Grid container spacing={1}>
          <Grid item xs={12} md={9} lg={9}>
            <Box>{postjob == "postjob" && <Job tabView1={tabView1} />}</Box>
          </Grid>
          <Hidden smDown>
            <Grid item xs={12} md={3} lg={3}>
              <Box className="scrollDiv">
                <Grid container direction={"column"} spacing={1}>
                  <Grid item xs={12}>
                    <Box mt={8}>
                      <SkillUp />
                    </Box>
                  </Grid>
                  <Grid item xs={12}></Grid>
                </Grid>
              </Box>
            </Grid>
          </Hidden>
          <Hidden mdUp>
            <News />
          </Hidden>
        </Grid>
      </Box>
      <HireModal
        openCreatePost={postModalOpen}
        handleCloseCreatePost={() => setPostModalOpen(false)}
      />
    </Page>
  );
};

export default Index;
