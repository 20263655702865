import React, { useContext, useState } from "react";
import {
  makeStyles,
  Typography,
  Paper,
  Box,
  Divider,
  Grid,
  IconButton,
  Button,
} from "@material-ui/core";
import { CiBookmark } from "react-icons/ci";
import { BsFillBookmarkFill } from "react-icons/bs";
import ApplyJobModal from "src/component/ApplyJobModal";
import { AuthContext } from "src/context/Auth";
import moment from "moment";
import { postDataIdinQuery } from "src/apiConfig/service";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  jobsbox: {
    "& .doted": {
      marginBottom: "10px",
      "& h5": {
        color: "#323232",
      },
    },
    "& .MuiDivider-root": {
      backgroundColor: "rgba(159, 171, 211, 0.3)",
    },
  },
  mapingbox1: {
    display: "flex",
    justifyContent: "space-between",
    cursor: "pointer",
    "& .ImageBox": {
      display: "flex",
      "& figure": {
        width: "50px",
        height: "50px",
        borderRadius: "50px",
        overflow: "hidden",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        margin: "0px",
        "& img": {
          minHeight: "100%",
          width: "100%",
        },
      },
    },
    "& p": {
      color: "#ABABAB",
      fontSize: "11px",
      fontWeight: "300",
    },
    "& .TextBox": {
      marginLeft: "10px",
      "& h6": {
        color: "#ABABAB",
        fontWeight: "300",
        fontSize: "13px",
      },
    },
    "& svg": {
      fontSize: "20px",
      color: "#4057A7",
    },
  },
  root: {
    paddingTop: "15px",
    [theme.breakpoints.down("xs")]: {
      padding: "10px",
    },
  },
}));

function SkillUp({ data, index, type, callBack }) {
  const classes = useStyles();
  const history = useHistory();
  const user = useContext(AuthContext);
  const userData = user?.userData;
  const [jobDataID, setJobDataId] = useState({});
  const [openCreatePost, setOpenCreatePost] = useState(false);

  const saveJobHandler = async (id) => {
    try {
      const response = await postDataIdinQuery("savedJob", { _id: id });
      if (response) {
        callBack();
      }
    } catch (error) {}
  };
  return (
    <>
      <Box>
        <Paper className={classes.root} elevation={0}>
          <Box className={classes.jobsbox}>
            <Grid container spacing={1} direction={"column"}>
              <Grid item xs={12} key={index}>
                <Box
                  className={classes.mapingbox1}
                  onClick={() =>
                    history.push({
                      pathname: "/job-description",
                      state: data,
                      search: data?._id,
                    })
                  }
                >
                  <Box className="ImageBox">
                    <Box
                      onClick={() =>
                        history.push({
                          pathname: "/job-description",
                          state: data,
                          search: data?._id,
                        })
                      }
                      style={{ cursor: "pointer" }}
                    >
                      <figure>
                        <img src={data?.companyLogo} alt="" />
                      </figure>
                    </Box>
                    <Box className="TextBox">
                      <Box
                        onClick={() =>
                          history.push({
                            pathname: "/job-description",
                            state: data,
                            search: data?._id,
                          })
                        }
                        style={{ cursor: "pointer" }}
                      >
                        <Typography variant="h5">{data?.jobTitle}</Typography>
                      </Box>
                      <Typography variant="body1" style={{ color: "#000" }}>
                        {data?.compnayName}
                      </Typography>
                      <Typography variant="h6">{data?.jobLocation}</Typography>
                      <Box display="flex" alignItems="center">
                        <Typography variant="body2">
                          {data?.isapply
                            ? moment(data?.updatedAt).local().fromNow()
                            : moment(data?.createdAt).local().fromNow()}
                        </Typography>

                        {type === "recommend" &&
                          userData?._id !== data?.userId && (
                            <Button
                              variant="body2"
                              disabled={data?.isApply}
                              style={{ color: "#4057A7", padding: "0px" }}
                              onClick={() => {
                                setOpenCreatePost(true);
                                setJobDataId(data);
                              }}
                            >
                              {data?.isApply ? (
                                <span style={{ color: "#1a9d1a" }}>
                                  Applied
                                </span>
                              ) : (
                                "Apply"
                              )}
                            </Button>
                          )}
                      </Box>
                    </Box>
                  </Box>
                  <Box className="iconBox">
                    <IconButton
                      onClick={(event) => {
                        saveJobHandler(data?._id);
                        event.stopPropagation();
                      }}
                    >
                      {data?.saved ? <BsFillBookmarkFill /> : <CiBookmark />}
                    </IconButton>
                  </Box>
                </Box>
                <Box mt={2} mb={1}>
                  <Divider />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </Box>
      <ApplyJobModal
        openCreatePost={openCreatePost}
        setOpenCreatePost={setOpenCreatePost}
        handleCloseCreatePost={() => setOpenCreatePost(false)}
        jobDataID={jobDataID}
        userData={userData}
        callBack={callBack}
      />
    </>
  );
}

export default SkillUp;
