import React from "react";
import {
  TableContainer,
  Table,
  Box,
  TableCell,
  TableBody,
  makeStyles,
  TableRow,
} from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
const useStyles = makeStyles((theme) => ({
  PostBox: {
    backgroundColor: "theme",
    position: "relative",
    padding: "12px",
    "& .MuiCardHeader-root": {
      padding: "0 0 16px 0",
    },
    "& .MuiCardContent-root": {
      padding: "16px 16px 16px 0",
    },
  },
  postImg: {
    height: 300,
  },
}));
export default function TopTradingSkeleton({ type }) {
  const classes = useStyles();
  return (
    <Box className={classes.PostBox}>
      <TableContainer>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell style={{ width: "215px" }}>
                <Box
                  className={classes.mainBoxTable}
                  display="flex"
                  alignItems="center"
                >
                  <Box
                    className={classes.mainBoxTable}
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                  >
                    <Skeleton
                      animation="wave"
                      width={60}
                      height={10}
                      style={{ marginBottom: "" }}
                    />
                    <Skeleton animation="wave" width={30} height={10} />
                  </Box>
                </Box>
              </TableCell>
              <TableCell>
                <Skeleton
                  animation="wave"
                  height={10}
                  width="40%"
                  style={{ marginBottom: 6 }}
                />
              </TableCell>
              <TableCell>
                <Skeleton
                  animation="wave"
                  height={10}
                  width="40%"
                  style={{ marginBottom: 6 }}
                />
              </TableCell>

              <TableCell>
                <Skeleton
                  animation="wave"
                  height={10}
                  width="40%"
                  style={{ marginBottom: 6 }}
                />
              </TableCell>
              <TableCell>
                <Skeleton
                  animation="wave"
                  height={10}
                  width="40%"
                  style={{ marginBottom: 6 }}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
