import _ from "lodash";
import { colors, createMuiTheme, responsiveFontSizes } from "@material-ui/core";
import typography from "./typography";

const baseOptions = {
  direction: "ltr",
  typography,
  overrides: {
    MuiButton: {
      contained: {
        "& .Mui-disabled": {
          color: "rgb(73 68 68 / 26%) !important",
          boxShadow: " none",
          backgroundColor: "#f50057",
        },
      },
      root: {
        borderRadius: "45px",
      },
      outlinedPrimary: {
        border: "1px solid #4057A7",
      },
      outlinedSizeSmall: {
        height: "32px",
        fontSize: "14px",
        padding: "7px 35px",
      },
      containedSizeSmall: {
        height: "32px",
        fontSize: "14px",
        padding: "7px 35px",
      },
      containedSizeLarge: {
        height: "50px",
        fontSize: "18px",
        padding: "7px 35px",
        "@media(max-width:600px)": {
          height: "40px",
          fontSize: "16px",
          padding: "7px 25px",
        },
      },
      outlinedSizeLarge: {
        height: "50px",
        padding: "7px 35px",
        fontSize: "18px",
        "@media(max-width:600px)": {
          height: "40px",
          fontSize: "16px",
          padding: "7px 25px",
        },
      },
    },
    MuiTableCell: {
      head: {
        color: "#A9A9A9",
      },
    },
    MuiTableCell: {
      body: {
        color: "#323232",
      },
    },
    MuiPaper: {
      root: {
        backgroundColor: "#fff",
      },
    },
    MuiFormControl: {
      root: {
        backgroundColor: "#F1F1F1",
        borderRadius: "5px",
      },
    },
    MuiInputBase: {
      root: {
        fontSize: "13px",
        "& .Mui-disabled": {
          color: "rgb(40 38 38 / 50%) !important",
          // boxShadow: " none",
          // backgroundColor: "#f50057",
        },
      },
      input: {
        backgroundColor: "#F1F1F1",
        borderRadius: "5px",
      },
      // disabled: {
      //   color: "#14131380",
      //   cursor: "default",
      // },
    },
    MuiOutlinedInput: {
      notchedOutline: {
        borderColor: "transparent",
      },
      input: {
        "& ::-webkit-autofill": {
          WebkitBoxShadow: "none !important",
          webkitBackgroundClip: "text !important",
          webkitTextFillColor: "#242424 !important",
        },
      },
    },
    MuiList: {
      padding: {
        paddingLeft: "8px",
        paddingRight: "8px",
      },
    },
  },
};

const themesOptions = [
  {
    name: "LIGHT",
    overrides: {},
    palette: {
      type: "light",
      action: {
        active: colors.blueGrey[600],
      },
      background: {
        default: "rgba(255, 255, 255, 1);",
        dark: "#f4f6f8",
        paper: "#ECECEC",
      },
      primary: {
        main: "#4057A7",
      },
      secondary: {
        main: "#828282",
      },
      text: {
        primary: colors.blueGrey[900],
        secondary: colors.blueGrey[600],
      },
    },
    // shadows: softShadows,
  },
  {
    name: "DARK",
    palette: {
      type: "dark",
      action: {
        active: "rgba(255, 255, 255, 0.54)",
        hover: "rgba(255, 255, 255, 0.04)",
        selected: "rgba(255, 255, 255, 0.08)",
        disabled: "rgba(31, 31, 31, 38%)",
        disabledBackground: "rgba(241, 241, 241, 1)",
        focus: "rgba(255, 255, 255, 0.12)",
      },
      background: {
        default: "#F5F5F5;",
        dark: "#1c2025",
        paper: "#ffffff",
      },

      text: {
        primary: "#404040",
        secondary: "#202020",
      },
    },
  },
];

export const createTheme = (config = {}) => {
  let themeOptions = themesOptions.find((theme) => theme.name === config.theme);

  if (!themeOptions) {
    console.warn(new Error(`The theme ${config.theme} is not valid`));
    [themeOptions] = themesOptions;
  }

  let theme = createMuiTheme(
    _.merge({}, baseOptions, themeOptions, { direction: config.direction })
  );

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
};
