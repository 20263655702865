import { postDataIdinQueryWithdata } from "src/apiConfig/service";
import {
  Box,
  FormControl,
  Typography,
  TextField,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import { Send } from "@material-ui/icons";
import React, { useContext, useState } from "react";
import { FaRegSmileBeam } from "react-icons/fa";
import Picker from "emoji-picker-react";

import CommentList from "./CommentList";
import UserListFeed from "./UserListFeed";
import { toast } from "react-toastify";
import { AuthContext } from "src/context/Auth";
import SeeMoreComponent from "../SeeMoreComponent";
export default function CommentDataFeed({
  classes,
  viewData,
  callBack,
  type,
  commentListData,
  listCommentHandler,
}) {
  const auth = useContext(AuthContext);
  const [comment, setComment] = useState("");
  const [error, setError] = useState("");
  const [showPicker, setShowPicker] = useState(false);

  const addCommentHandler = async () => {
    if (comment !== "") {
      try {
        const res = await postDataIdinQueryWithdata(
          "addComment",
          { postId: viewData?._id },
          { comments: comment }
        );
        if (res) {
          callBack();
          setComment("");
          listCommentHandler();
        }
      } catch (error) {}
    } else {
      setError("Please enter comment");
    }
  };

  const onEmojiClick = (e, emojiObject) => {
    const sym = e.unified.split("_");
    const codeArray = [];
    sym.forEach((el) => codeArray.push("0x" + el));
    let emoji = String.fromCodePoint(...codeArray);
    setComment(comment + emoji);
    setShowPicker(false);
  };
  const [sliceCount, setsliceCount] = useState(5);
  function seeMoreHandle(listComment) {
    return listComment && listComment?.slice(0, sliceCount);
  }
  return (
    <Box className={classes.rightBox}>
      <Box>
        <Box className={classes.userfeedBox}>
          <UserListFeed
            listPostHandler={callBack}
            classes={classes}
            viewData={viewData}
            auth={auth}
            type={type}
          />
          <Box className={classes.mainTextFl}>
            {showPicker && (
              <Box className={classes.emojiBox}>
                <Picker onEmojiClick={onEmojiClick} onClose height="350px" />
              </Box>
            )}
            <FormControl fullWidth className={classes.commentControl}>
              <TextField
                type="text"
                variant="outlined"
                placeholder="Add a comment..."
                fullWidth
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    auth?.userData?.kycStatus === "APPROVE"
                      ? addCommentHandler()
                      : toast.warn(
                          "Please complete your KYC to access this feature."
                        );
                  }
                }}
                size="lg"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() =>
                          auth?.userData?.kycStatus === "APPROVE"
                            ? setShowPicker((val) => !val)
                            : toast.warn(
                                "Please complete your KYC to access this feature."
                              )
                        }
                      >
                        <FaRegSmileBeam />
                      </IconButton>

                      <IconButton>
                        <Send
                          onClick={() =>
                            auth?.userData?.kycStatus === "APPROVE"
                              ? (() => {
                                  addCommentHandler();
                                })()
                              : toast.warn(
                                  "Please complete your KYC to access this feature."
                                )
                          }
                        />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
          </Box>
        </Box>
        <Box className="mainBox">
          <Typography variant="body1">Comments</Typography>
        </Box>
        <Box className={classes.comment}>
          {commentListData &&
            commentListData &&
            seeMoreHandle(commentListData)?.map((comment) => {
              return (
                <CommentList
                  classes={classes}
                  comment={comment}
                  viewData={viewData}
                  commentListData={commentListData}
                  listCommentHandler={listCommentHandler}
                />
              );
            })}
          <SeeMoreComponent
            arrayData={commentListData}
            seeMoreHandle={seeMoreHandle}
            showLimit={5} ///default set value
            setsliceCount={setsliceCount}
            sliceCount={sliceCount}
          />
        </Box>
      </Box>
    </Box>
  );
}
