import NftCards from "src/component/NftCards";
import { Box, makeStyles, Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { getParticularObjectDataHanndler } from "src/apiConfig/service";
import { Pagination } from "@material-ui/lab";
import DataNotFound from "src/component/DataNotFound";
import PhotoSkeleton from "src/Skeletons/PhotoSkeleton";
import Page from "src/component/Page";

const useStyles = makeStyles((theme) => ({
  CreatedBox: {
    padding: "60px 15px",
  },
}));

export default function Bought({ tabView6 }) {
  const classes = useStyles();
  const [isLoading, setIsloading] = useState(true);
  const [allNftData, setAllNftData] = useState([]);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);

  const listAllNfts = async () => {
    try {
      const apiEndPoint =
        tabView6 === "created" || tabView6 === "onsale"
          ? "myAssets"
          : tabView6 === "bought"
          ? "purchasedAsset"
          : tabView6 === "favourite" && "favourites";
      const typeData =
        tabView6 === "created"
          ? {
              type: "other",
            }
          : tabView6 === "onsale"
          ? {
              type: "own",
            }
          : {};

      const response = await getParticularObjectDataHanndler(apiEndPoint, {
        ...typeData,
        search: search ? search : undefined,
        page: page,
        limit: "12",
      });
      if (response.responseCode === 200) {
        setIsloading(false);
        setAllNftData(response.result.docs);
        setTotalPage(response.result.pages);
      } else {
        setIsloading(false);
        setAllNftData([]);
      }
    } catch (error) {
      console.log(error);
      setAllNftData([]);
      setIsloading(false);
    }
  };

  useEffect(() => {
    if (tabView6) {
      listAllNfts();
    }
  }, [tabView6]);

  return (
    <Page title="My asset">
      <Box className="scrollDiv">
        <Box className={classes.CreatedBox}>
          <Grid container spacing={3}>
            {allNftData &&
              allNftData.map((value, i) => (
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <NftCards data={value} index={i} tabView6={tabView6} />
                </Grid>
              ))}
            {isLoading &&
              [1, 2, 3, 4].map((itm) => {
                return (
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <PhotoSkeleton />
                  </Grid>
                );
              })}
            {!isLoading && allNftData && allNftData.length === 0 && (
              <DataNotFound />
            )}
            {!isLoading &&
              allNftData &&
              allNftData.length >= (page === 1 ? 12 : 0) && (
                <Pagination
                  variant="outlined"
                  shape="rounded"
                  count={totalPage}
                  page={page}
                  onChange={(e, v) => setPage(v)}
                />
              )}
          </Grid>
        </Box>
      </Box>
    </Page>
  );
}
