import React, { useState } from "react";
import {
  makeStyles,
  Dialog,
  DialogContent,
  IconButton,
  Typography,
  Box,
  Avatar,
  Button,
  FormHelperText,
  Grid,
} from "@material-ui/core";
import { IoClose } from "react-icons/io5";
import SuccessfullModal from "./SuccessfullModal";
import "react-phone-input-2/lib/style.css";
import { toast } from "react-toastify";
import {
  marketplace,
  creteAssest,
  toeknAddress,
  tokenName,
} from "src/constants";
import MarketplaceABI from "src/ABI/MarketplaceABI.json";
import TokenABI from "src/ABI/TokenABI.json";
import ButtonCircularProgress from "src/component/ButtonCircularProgress";
import web3 from "web3";
import { getContract } from "src/utils";
import Axios from "axios";
import apiConfigs from "src/apiConfig/ApiConfig";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& .MuiDialog-paperWidthXs": {
      width: "100%",
      maxWidth: "398px",
    },
  },
  mainmodalBox: {
    "& h5": {
      fontWeight: "700",
      color: "#4057A7",
    },
  },
  cancelBtn: {
    position: "absolute",
    top: 0,
    right: 0,
    "& svg": {
      color: "#fff",
      fontWeight: "700",
    },
  },

  bgcolorbox: {
    background: " #4057A7",
    padding: "14px",
    "& h6": {
      fontSize: "16px",
      fontWeight: 700,
      textAlign: "left",
      color: "#ffffff",
    },
  },
  videoBox: {
    "& .MuiAvatar-root": {
      width: "160px",
      height: "120px",
      borderRadius: "5px",
    },
  },
  threeBox: {
    textAlign: "center",
    marginTop: "30px",
  },
}));

function PollModal({
  handleOpenBuy,
  handleCloseBuy,
  listPostHandler,
  data,
  auth,
}) {
  const classes = useStyles();
  const [handleOpen, setHandleOpen] = React.useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);

  const buyNftHandle = async () => {
    try {
      setIsLoading(true);
      const contractObjToken = getContract(
        toeknAddress,
        TokenABI,
        auth?.library,
        auth?.account
      );
      const createObjMar = getContract(
        marketplace,
        MarketplaceABI,
        auth?.library,
        auth?.account
      );
      const priceToSt = data?.buyPrice.toString();
      const acceptToken = await contractObjToken.approve(
        marketplace,
        web3.utils.toWei(priceToSt, "ether")
      );
      await acceptToken.wait();

      const safeExecute = await createObjMar.safeExecuteOrder(
        creteAssest,
        data?.tokenId,
        web3.utils.toWei(priceToSt, "ether")
      );
      await safeExecute.wait();
      buyNftHandleApi();
    } catch (error) {
      console.log(error);
      toast.error(error.message);
      setIsLoading(false);
    }
  };

  const buyNftHandleApi = async () => {
    try {
      const response = await Axios({
        method: "POST",
        url: `${apiConfigs.buyNft}/${data?._id}`,
        headers: {
          token: window.sessionStorage.getItem("token"),
        },
        data: { price: data?.buyPrice },
      });
      if (response.data.responseCode === 200) {
        toast.success(response.data.responseMessage);
        setIsLoading(false);
        listPostHandler();
        handleCloseBuy();
        setHandleOpen(true);
      } else {
        toast.error(response.data.responseMessage);
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      toast.error(error.response.data.responseMessage);
    }
  };
  return (
    <>
      <Dialog
        open={handleOpenBuy}
        onClose={!isLoading && handleCloseBuy}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="xs"
        fullWidth
        className={classes.root}
      >
        <Box align="center" className={classes.bgcolorbox}>
          <Box>
            <Typography variant="h6">Buy</Typography>
          </Box>
        </Box>
        <DialogContent>
          <IconButton
            className={classes.cancelBtn}
            onClick={!isLoading && handleCloseBuy}
          >
            <IoClose />
          </IconButton>

          <Box className={classes.mainmodalBox}>
            <Box className={classes.videoBox} align="center">
              {data?.mediaType?.split("/")[0] === "image" ? (
                <Avatar
                  src={
                    data?.mediaFile ? data?.mediaFile : "/images/Buyvideo.png"
                  }
                />
              ) : (
                <video
                  controls={false}
                  autoplay={false}
                  loop
                  muted
                  playsinline="true"
                  width="160px"
                  height="120px"
                >
                  <source
                    src={data && data?.mediaFile}
                    type="video/mp4"
                    accept="video/*"
                  />
                </video>
              )}
            </Box>

            <Box className="typoBox1" mt={1} align="center">
              <Typography variant="body2">{data?.tokenName}</Typography>
            </Box>

            <Box className={classes.threeBox}>
              <Box className="typoBox">
                <Typography variant="h5">
                  {data?.buyPrice} {tokenName}
                </Typography>
              </Box>

              <Box pt={1} pb={2}>
                <FormHelperText error className="displayFlexCenter">
                  {isSubmit &&
                    Number(auth?.tokenBalance) <= Number(data?.buyPrice) &&
                    "Your balance is low."}
                </FormHelperText>
                <Button
                  variant="contained"
                  color="primary"
                  size="medium"
                  disabled={isLoading}
                  onClick={() =>
                    auth?.account
                      ? Number(auth?.tokenBalance) >= Number(data?.buyPrice)
                        ? (() => {
                            if (data?.userId?.walletAddress !== auth?.account) {
                              setIsSubmit(false);
                              buyNftHandle();
                            } else {
                              toast.warn(
                                "Owners cannot purchase their own asset."
                              );
                            }
                          })()
                        : setIsSubmit(true)
                      : auth.setOpenMetamask(true)
                  }
                >
                  {auth?.account ? (
                    <>Buy {isLoading && <ButtonCircularProgress />}</>
                  ) : (
                    "Connect Wallet"
                  )}
                </Button>
              </Box>

              <Box pb={1}>
                <Box pb={1}>
                  {auth?.account && auth?.tokenBalance && (
                    <Typography variant="body1">
                      Available Balance {auth?.tokenBalance} {tokenName}
                    </Typography>
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>

      <SuccessfullModal
        handleOpen={handleOpen}
        handleclose={() => setHandleOpen(false)}
        data="Buy Successfully"
      />
    </>
  );
}

export default PollModal;
