import React, { useEffect } from "react";
import { Box, makeStyles, Grid, Typography } from "@material-ui/core";
import CommonNews from "./CommonNews";
import EventCommon from "./EventCommon";
import PoolCommon from "./PoolCommon";
import HomePageSkeleton from "src/Skeletons/HomePageSkeleton";
import InfiniteScroll from "react-infinite-scroll-component";

const useStyles = makeStyles((theme) => ({
  bannerbox: {
    zIndex: "1",
    "& .databanner": {
      overflow: "hidden",
      position: "absolute",
      top: "15px",
      left: "20px",
      zIndex: "1",
      "& h6": {
        color: "#fff",
        margin: "0px 8px",
      },
      "& p": {
        color: "#fff",
      },
    },
  },
  shadowbox: {
    "& .active": {
      position: "relative",
      zIndex: "2",
    },
  },
  iconboxes: {
    zIndex: "2",
    bottom: "8px",
    right: "0px",
    flexGrow: 1,
    "& .MuiSpeedDialAction-fab": {
      color: "#4057A7",
      background: "#FFFFFF",
    },
    "& .MuiFab-sizeSmall": {
      background: "#FFFFFF",
      color: "#4057A7",
    },
  },
  iconboxes2: {
    position: "absolute",
    zIndex: "2",
    top: "40px",
    right: "0px",
    flexGrow: 1,
    "& .MuiSpeedDialAction-fab": {
      color: "#4057A7",
      background: "#FFFFFF",
    },
    "& .MuiFab-sizeSmall": {
      background: "#FFFFFF",
      color: "#4057A7",
    },
  },
  speedDial: {
    position: "absolute",
    bottom: theme.spacing(1),
    right: theme.spacing(2),
    "& .MuiFab-primary": {
      color: "#70CDDD",
      border: "1px solid #70CDDD",
      background: "transparent",
    },
    "& .MuiSpeedDialIcon-root": {
      height: "24px",
      display: "flex",
      alignItems: "center",
      fontSize: "20px",
    },
    "& .MuiFab-root": {
      width: "36px",
      height: "36px",
      fontSize: "18px",
    },
    "& .MuiSpeedDialAction-fab": {
      margin: "4px",
    },
  },
  iconboxes1: {
    background: "transparent",
    width: "30px",
    height: "30px",
    borderRadius: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #70CDDD",
    position: "absolute",
    zIndex: "9",
    bottom: "14px",
    right: "12px",
    "& svg": {
      color: "#70CDDD",
    },
  },
  construction: {
    background: "transparent",
    width: "30px",
    height: "30px",
    borderRadius: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #70CDDD",
    "& svg": {
      color: "#70CDDD",
    },
  },
  subtext: {
    position: "absolute",
    bottom: "15px",
    left: "20px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    maxWidth: "633px",
    zIndex: "9",
    width: "calc(100% - 100px) !important",
    "& h5": {
      color: "#fff",
      width: "100%",
      maxWidth: "341px",
      fontSize: "14px",
    },
  },
  subtext1: {
    position: "absolute",
    bottom: "15px",
    left: "20px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    width: "calc(100% - 100px) !important",
    zIndex: "2",
    "& h5": {
      color: "#fff",
      width: "100%",
      maxWidth: "243px",
      fontSize: "14px",
    },
  },
  cryptobox: {
    padding: "15px",
    "& .MuiAvatar-root": {
      border: " 1px solid #70CDDD",
    },
    "& h6": {
      margin: "0px 10px",
    },
    "& h4": {
      fontWeight: "600",
    },
    "& h5": {
      color: "#282828",
      fontWeight: "300",
      "& span": {
        color: "#4057A7",
        fontWeight: "500",
      },
    },
    "& .rebundbox": {
      // border: "1px solid #E2E2E2",
      borderRadius: " 10px",
      padding: "15px",
      "& button": {
        height: "40px",
        fontSize: "16px",
        color: "#4057A7",
        fontWeight: "500",
        borderRadius: "10px",
        marginBottom: "8px",
      },
      "& h6": {
        fontWeight: "300",
        margin: "7px 0px 0px",
      },
    },
  },
  technology: {
    "& .description": {
      "& h5": {
        width: "calc(100% - 41px)",
      },
    },
    "& .MuiAvatar-root": {
      border: " 1px solid #70CDDD",
    },
    "& h6": {
      margin: "0px 10px",
    },
    "& .paddingboxes": {
      padding: "13px 15px",
    },
    "& p": {
      color: "#464646",
    },
    "& h5": {
      // margin: "0px 25px 0px 0px",
      color: "#000000",
      fontWeight: "500",
      fontSize: "14px",
      width: "100%",
      maxWidth: "271px",
    },
  },
  actionspeed: {
    "&.MuiSpeedDial-directionUp .MuiSpeedDial-actions": {
      paddingBottom: "35px",
    },
  },
  heightBox: {
    height: "600px",
    overflow: "auto",
  },
}));

export default function NewsFeed({
  listPost,
  isLoading,
  listPostHandler,
  totalPages,
  auth,
  pagetype,
}) {
  const classes = useStyles();
  const obj = { lg: 4, md: 4 };
  function checkFirstGrid(index) {
    if (index === 0 || index % 5 === 0) {
      return {
        lg: 8,
        md: 8,
      };
    } else {
      return obj;
    }
  }

  const callback = (entries) => {
    var postData = [];
    postData = entries;
    postData.forEach((element) => {
      let child = element.target.childNodes[0];

      child.play().then(() => {
        //if this video is not in viewport then pause it
        if (!child.paused && !element.isIntersecting) {
          child.pause();
        }
      });
    });
  };

  const observer = new IntersectionObserver(callback, {
    threshold: 0.6,
  });

  useEffect(() => {
    let videos = document.querySelectorAll(".videos");
    videos.forEach((video) => {
      observer.observe(video);
    });
    return () => {
      observer.disconnect();
    };
  }, [listPost]);

  return (
    <Box className={classes.bannerbox}>
      <Box className={classes.shadowbox}>
        <Box className={`${classes.heightBox} postcss`} id="scrollableDiv1">
          <InfiniteScroll
            dataLength={listPost?.length - 3}
            next={() => {
              listPostHandler(totalPages?.currentPage + 1, listPost);
            }}
            hasMore={
              totalPages?.totalPage !== totalPages?.currentPage ? true : false
            }
            loader={
              isLoading && (
                <Grid container spacing={2}>
                  {[1, 2].map((data, i) => {
                    return (
                      <Grid
                        item
                        lg={checkFirstGrid(i)?.lg}
                        md={checkFirstGrid(i)?.md}
                        xs={12}
                        sm={12}
                      >
                        <HomePageSkeleton />
                      </Grid>
                    );
                  })}
                </Grid>
              )
            }
            endMessage={
              listPost &&
              listPost.length !== 0 && (
                <Typography
                  variant="body1"
                  style={{
                    textAlign: "center",
                    color: "#808080d4",
                    padding: "10px 0px",
                  }}
                >
                  Yay! You have seen it all
                </Typography>
              )
            }
            scrollableTarget="scrollableDiv1"
            style={{ overflow: "hidden" }}
          >
            <Grid container spacing={2}>
              {listPost &&
                listPost.map((data, i) => {
                  return (
                    <Grid
                      item
                      lg={checkFirstGrid(i)?.lg}
                      md={checkFirstGrid(i)?.md}
                      xs={12}
                      sm={12}
                    >
                      {data?.postType === "MEDIA" ||
                      data?.postType === "NFT" ? (
                        <CommonNews
                          listPostHandler={() => listPostHandler(1, [])}
                          classes={classes}
                          data={data}
                          index={i}
                          auth={auth}
                          pagetype={pagetype}
                        />
                      ) : data?.postType === "EVENT" ? (
                        <EventCommon
                          listPostHandler={() => listPostHandler(1, [])}
                          classes={classes}
                          data={data}
                          index={i}
                          auth={auth}
                        />
                      ) : data?.postType === "POLL" ? (
                        <PoolCommon
                          listPostHandler={() => listPostHandler(1, [])}
                          classes={classes}
                          data={data}
                          index={i}
                          auth={auth}
                        />
                      ) : null}
                    </Grid>
                  );
                })}
            </Grid>
          </InfiniteScroll>
        </Box>
      </Box>
    </Box>
  );
}
