import React, { useContext, useState } from "react";
import Carousel from "react-material-ui-carousel";
import { Box } from "@material-ui/core";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import BannerMain from "./BannerMain";
import DataNotFound from "src/component/DataNotFound";
import { AuthContext } from "../../../context/Auth";
import PhotoSkeleton from "src/Skeletons/PhotoSkeleton";

export default function BannerSlider() {
  const auth = useContext(AuthContext);
  const [modalsOpen, setModalsOpen] = useState({
    liveFeeds: false,
    giftModals: false,
    buyModals: false,
    reportModals: false,
    deleteModals: false,
    id: "",
  });
  const [modalsOpenView, setModalsOpenView] = useState({
    liveFeeds: false,
    giftModals: false,
    buyModals: false,
    reportModals: false,
    deleteModals: false,
    id: "",
  });

  return (
    <>
      {auth?.tsLoadingTrending ? (
        <PhotoSkeleton />
      ) : (
        <>
          <Carousel
            NextIcon={<NavigateNextIcon style={{ fontSize: "3.5rem" }} />}
            PrevIcon={<NavigateBeforeIcon style={{ fontSize: "3.5rem" }} />}
            navButtonsProps={{
              style: {
                backgroundColor: "transparent",
                zIndex: "-1",
              },
            }}
            Fade={true}
            stopAutoPlayOnHover={true}
            autoPlay={
              modalsOpen?.giftModals ||
              modalsOpen?.buyModals ||
              modalsOpen?.reportModals ||
              modalsOpen?.deleteModals ||
              modalsOpen?.liveFeeds ||
              modalsOpenView?.giftModals ||
              modalsOpenView?.buyModals ||
              modalsOpenView?.reportModals ||
              modalsOpenView?.liveFeeds ||
              modalsOpenView?.deleteModals
                ? false
                : true
            }
            interval={5000}
            animation={"slide"}
            swipe={true}
            navButtonsAlwaysVisible={false}
            navButtonsWrapperProps={{
              style: {
                zIndex: "-1",
              },
            }}
          >
            {auth?.listPost &&
              auth?.listPost?.map((data, index) => {
                return (
                  <Box>
                    <BannerMain
                      data={data}
                      key={`banner ${index}`}
                      listPostHandler={() => {
                        auth.listPostHandler();
                        auth.listPostHandlerTrending();
                        auth.listPostHandlerBottom(1, []);
                      }}
                      modalsOpen={modalsOpen}
                      setModalsOpen={setModalsOpen}
                      modalsOpenView={modalsOpenView}
                      setModalsOpenView={setModalsOpenView}
                    />
                  </Box>
                );
              })}
          </Carousel>
          {auth?.listPost && auth?.listPost.length === 0 && <DataNotFound />}
        </>
      )}
    </>
  );
}
