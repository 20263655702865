import { Avatar, Box, Typography } from "@material-ui/core";
import moment from "moment";
import React, { useState } from "react";
import SeeMoreComponent from "../SeeMoreComponent";

export default function CommentReplyList({ comment }) {
  const [sliceCount, setsliceCount] = useState(5);
  function seeMoreHandle(listComment) {
    return listComment && listComment?.slice(0, sliceCount);
  }
  return (
    <>
      {comment &&
        seeMoreHandle(comment?.comments?.replies).map((reply) => {
          return (
            <>
              <Box display="flex" alignItems="flex-start" mb={1} ml={4}>
                <Avatar
                  src={
                    reply?.userId?.profilePic ? reply?.userId?.profilePic : ""
                  }
                  style={{ width: "30px", height: "30px" }}
                />
                <Box>
                  <Typography
                    style={{
                      wordBreak: "break-word",
                      whiteSpace: "pre",
                      marginLeft: "5px",
                    }}
                    variant="h6"
                  >
                    {reply?.userId?.firstName
                      ? reply?.userId?.firstName
                      : "Anonymous"}
                  </Typography>
                  <Typography
                    variant="body1"
                    style={{
                      color: "rgba(255, 255, 255, 0.7)",
                      wordBreak: "break-word",
                      marginLeft: "5px",
                    }}
                  >
                    {reply?.comment}{" "}
                    <Typography variant="body1">
                      {" "}
                      {moment(reply?.createdAt).local().fromNow()}
                    </Typography>
                  </Typography>
                </Box>
              </Box>
            </>
          );
        })}
      <SeeMoreComponent
        arrayData={comment?.comments?.replies}
        seeMoreHandle={seeMoreHandle}
        showLimit={5} ///default set value
        setsliceCount={setsliceCount}
        sliceCount={sliceCount}
      />
    </>
  );
}
