import {
  Box,
  Typography,
  Button,
  TableContainer,
  Grid,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React, { useEffect, useState, useContext } from "react";
import { useLocation, useHistory } from "react-router-dom";
import Photos from "src/views/pages/MyLiberary/Photos";
import EBook from "src/views/pages/MyLiberary/EBook";
import Projects from "src/views/pages/MyLiberary/Projects";
import Purchased from "src/views/pages/MyLiberary/Purchased";
import Document from "src/views/pages/MyLiberary/Document";
import Indexx from "src/views/pages/JOB/Index";
import Dashboard from "src/views/pages/Dashboard/index";
import { AuthContext } from "src/context/Auth";
import LibraryEvent from "src/views/pages/MyLiberary/LibraryEvent";
import AnalyticsMain from "src/views/pages/Content/Index";
import WorkIndex from "src/views/pages/Work/Work";
import MyJobIndex from "src/views/pages/JOB/MyJobIndex";
import Job from "src/views/pages/JOB/Job";
import MotionDiv from "src/component/MotionDiv";
import Bought from "src/views/pages/Asset/Bought";
import Tododata from "src/views/pages/MyLiberary/Tododata";
import PageAdd from "src/views/pages/BussinessPage/PageAdd";

const useStyles = makeStyles((theme) => ({
  iconbtn: {
    position: "absolute",
    top: "0",
    right: "0",
  },
  MenuHome: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    height: "45px",
    background: "#fff",
    position: "fixed",
    zIndex: 99,
    left: 70,
    width: "100%",
    "@media (max-width: 1280px)": {
      zIndex: 9,
      left: 0,
    },
    "& h4": {
      fontSize: "17px",
      padding: "0 10px",
    },

    " & .TableContained": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      maxWidth: "1350px",
    },
  },
  root1: {
    // display: "flex",
    // justifyContent: "space-between",
    [theme.breakpoints.down("xs")]: {
      padding: "10px",
    },
    "& .nameBox": {
      display: "flex",
      alingItem: "center",
    },
    "& .minbox": {
      display: "flex",
      minWidth: "800px",
      alignItems: "center",
      justifyContent: "space-between",
      [theme.breakpoints.down("xs")]: {
        display: "block",
      },
      [theme.breakpoints.down("sm")]: {
        marginTop: "11px",
      },
    },

    "& .buttonbox": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      whiteSpace: "pre",

      [theme.breakpoints.only("xs")]: {
        justifyContent: "start",
      },
      "& .active": {
        backgroundColor: "transparent",
        color: "#4057A7",
        padding: "9px 10px",
        borderRadius: "0px",
        boxShadow: "none",
        fontSize: "16px",
        fontWeight: "700",
        "&:hover": {
          boxShadow: "none",
        },
        [theme.breakpoints.only("xs")]: {
          fontSize: "14px !important",
        },
      },
      "& .inactive": {
        padding: "9px 10px",
        color: "#878787",
        backgroundColor: "transparent",
        borderRadius: "0px",
        boxShadow: "none",
        fontSize: "16px",
        fontWeight: "700",

        "&:hover": {
          boxShadow: "none",
        },
        [theme.breakpoints.only("xs")]: {
          fontSize: "14px",
        },
      },
    },
  },
}));

const assettabViewArray = [
  {
    buttonName: "Created",
    tabView: "created",
  },
  {
    buttonName: "Bought",
    tabView: "bought",
  },
  {
    buttonName: "On Sale",
    tabView: "onsale",
  },
  {
    buttonName: "Favourites",
    tabView: "favourite",
  },
];
export default function Topnav() {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const [pathdata, setPathdata] = useState();
  const [tabViewl, setTabView] = useState("Phots");
  const [addPageModal, setAddPageModal] = useState(false);
  const handleCloseCreateEvent = () => {
    setAddPageModal(false);
  };
  const auth = useContext(AuthContext);
  const [tabView1, setTabView1] = useState("home");
  const [tabView2, setTabView2] = useState("homepage");
  const [tabView3, setTabView3] = useState("Analytics");
  const [tabView4, setTabView4] = useState("Works");
  const [tabView5, setTabView5] = useState("my-chat");
  const [tabView6, setTabView6] = useState("created");
  const [postModalOpen, setPostModalOpen] = useState(false);

  useEffect(() => {
    if (location.pathname.substring(1, location.pathname.length)) {
      const id = location.pathname.substring(1, location.pathname.length);
      setPathdata(id);
      if (id === "self-about") {
        auth.setSelfTabShow("self-about");
      }
    }
  }, [location]);

  return (
    <>
      <Box>
        {pathdata === "home" ? (
          <>
            <Box className={classes.MenuHome}>
              <TableContainer>
                <Box className={classes.root1}>
                  <Box className="minbox">
                    <Box className="buttonbox">
                      <Button
                        variant="contained"
                        onClick={() => setTabView2("homepage")}
                        className={
                          tabView2 === "homepage" ? "active" : "inactive"
                        }
                      >
                        Home
                      </Button>
                    </Box>
                    <PageAdd
                      openCreateEvent={addPageModal}
                      handleCloseCreateEvent={handleCloseCreateEvent}
                    />
                  </Box>
                  {/* <Box className="nameBox">
                    <Typography variant="h6">
                      {auth.userData.firstName
                        ? auth.userData.firstName
                        : "" + " " + auth.userData.lastName
                        ? auth.userData.lastName
                        : ""}
                    </Typography>
                  </Box> */}
                </Box>
              </TableContainer>
            </Box>
          </>
        ) : pathdata === "self-about" ? (
          <>
            <>
              <Box className={classes.MenuHome}>
                <TableContainer>
                  <Box className={classes.root1}>
                    <Box className="minbox">
                      <Box className="buttonbox">
                        <Button
                          variant="contained"
                          onClick={() => {
                            history.push("/home");
                            setTabView2("homepage");
                          }}
                          className={
                            auth.selfTabShow === "self-home"
                              ? "active"
                              : "inactive"
                          }
                        >
                          Home
                        </Button>
                        <Button
                          variant="contained"
                          onClick={() => auth.setSelfTabShow("self-about")}
                          className={
                            auth.selfTabShow === "self-about"
                              ? "active"
                              : "inactive"
                          }
                        >
                          About
                        </Button>
                        <Button
                          variant="contained"
                          onClick={() =>
                            auth.setSelfTabShow("self-professional")
                          }
                          className={
                            auth.selfTabShow === "self-professional"
                              ? "active"
                              : "inactive"
                          }
                        >
                          Professional Information
                        </Button>
                        <Button
                          variant="contained"
                          onClick={() => auth.setSelfTabShow("self-Skills")}
                          className={
                            auth.selfTabShow === "self-Skills"
                              ? "active"
                              : "inactive"
                          }
                        >
                          Skills
                        </Button>
                        <Button
                          variant="contained"
                          onClick={() => auth.setSelfTabShow("self-Events")}
                          className={
                            auth.selfTabShow === "self-Events"
                              ? "active"
                              : "inactive"
                          }
                        >
                          Events
                        </Button>
                        <Button
                          variant="contained"
                          onClick={() => auth.setSelfTabShow("self-awards")}
                          className={
                            auth.selfTabShow === "self-awards"
                              ? "active"
                              : "inactive"
                          }
                        >
                          Awards & Achievements
                        </Button>
                        <Button
                          variant="contained"
                          onClick={() => auth.setSelfTabShow("self-hobbies")}
                          className={
                            auth.selfTabShow === "self-hobbies"
                              ? "active"
                              : "inactive"
                          }
                        >
                          Hobbies & Fun Quotient
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                </TableContainer>
              </Box>
            </>
          </>
        ) : pathdata === "work" ? (
          <>
            <Box className={classes.MenuHome}>
              <Typography variant="h4" color="primary">
                Home
              </Typography>
            </Box>
          </>
        ) : pathdata === "network" ? (
          <>
            <Box className={classes.MenuHome}>
              <TableContainer>
                <Box className={classes.root1}>
                  <Box className="minbox">
                    <Box className="buttonbox">
                      <Button
                        variant="contained"
                        onClick={() => {
                          history.push("/home");
                          setTabView2("homepage");
                        }}
                        className={
                          auth.selfTabShow === "home" ? "active" : "inactive"
                        }
                      >
                        Home
                      </Button>
                      <Button
                        variant="contained"
                        onClick={() => auth.setSelfTabShow("my-network")}
                        className={
                          auth.selfTabShow === "my-network"
                            ? "active"
                            : "inactive"
                        }
                      >
                        My Network
                      </Button>
                      <Button
                        variant="contained"
                        onClick={() => auth.setSelfTabShow("manage-my-network")}
                        className={
                          auth.selfTabShow === "manage-my-network"
                            ? "active"
                            : "inactive"
                        }
                      >
                        Manage My Network
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </TableContainer>
            </Box>
          </>
        ) : pathdata === "library" ? (
          <>
            <Box className={classes.MenuHome}>
              <TableContainer>
                <Box className="TableContained">
                  <Box className={classes.root1}>
                    <Box className="minbox">
                      <Box className="buttonbox">
                        <Button
                          variant="contained"
                          onClick={() => {
                            history.push("/home");
                            setTabView2("homepage");
                          }}
                          className={
                            tabViewl === "Phots" ? "active" : "inactive"
                          }
                        >
                          Home
                        </Button>
                        <Button
                          variant="contained"
                          onClick={() => setTabView("Phots")}
                          className={
                            tabViewl === "Phots" ? "active" : "inactive"
                          }
                        >
                          Photos
                        </Button>
                        <Button
                          variant="contained"
                          onClick={() => setTabView("EBook")}
                          className={
                            tabViewl === "EBook" ? "active" : "inactive"
                          }
                          style={{ marginLeft: "8px" }}
                        >
                          E Book
                        </Button>
                        <Button
                          variant="contained"
                          onClick={() => setTabView("todo")}
                          className={
                            tabViewl === "todo" ? "active" : "inactive"
                          }
                          style={{ marginLeft: "8px" }}
                        >
                          To Do List
                        </Button>
                        <Button
                          variant="contained"
                          onClick={() => setTabView("Documents")}
                          className={
                            tabViewl === "Documents" ? "active" : "inactive"
                          }
                          style={{ marginLeft: "8px" }}
                        >
                          Documents
                        </Button>
                        <Button
                          variant="contained"
                          onClick={() => setTabView("Projects")}
                          className={
                            tabViewl === "Projects" ? "active" : "inactive"
                          }
                          style={{ marginLeft: "8px" }}
                        >
                          Projects
                        </Button>
                        <Button
                          variant="contained"
                          onClick={() => setTabView("Purchased")}
                          className={
                            tabViewl === "Purchased" ? "active" : "inactive"
                          }
                          style={{ marginLeft: "8px" }}
                        >
                          Purchased Content
                        </Button>
                        <Button
                          variant="contained"
                          onClick={() => setTabView("LibraryEvent")}
                          className={
                            tabViewl === "LibraryEvent" ? "active" : "inactive"
                          }
                          style={{ marginLeft: "8px" }}
                        >
                          Events
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </TableContainer>
            </Box>
          </>
        ) : pathdata === "mycontent" ? (
          <>
            <Box className={classes.MenuHome}>
              <Box className={classes.root1}>
                <Box className="minbox">
                  <Box className="buttonbox">
                    <Button
                      variant="contained"
                      onClick={() => {
                        history.push("/home");
                        setTabView2("homepage");
                      }}
                      className={
                        tabView3 === "Analytics-home" ? "active" : "inactive"
                      }
                    >
                      Home
                    </Button>
                    <Button
                      variant="contained"
                      onClick={() => setTabView3("Analytics")}
                      className={
                        tabView3 === "Analytics" ? "active" : "inactive"
                      }
                    >
                      My Content Analytics
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          </>
        ) : pathdata === "job" || pathdata === "job-description" ? (
          <>
            <Box className={classes.MenuHome}>
              <TableContainer>
                <Box className={classes.root1}>
                  <Box className="minbox">
                    <Box className="buttonbox">
                      <Button
                        variant="contained"
                        onClick={() => {
                          history.push("/home");
                          setTabView1("homepage");
                        }}
                        className={tabView1 === "home" ? "active" : "inactive"}
                      >
                        Home
                      </Button>
                      <Button
                        variant="contained"
                        onClick={() => {
                          setTabView1("jobpost");
                          history.push("/job");
                        }}
                        className={
                          tabView1 === "jobpost" ? "active" : "inactive"
                        }
                      >
                        Jobs
                      </Button>
                      <Button
                        variant="contained"
                        onClick={() => {
                          setTabView1("myJob");
                          history.push("/job");
                        }}
                        className={tabView1 === "myJob" ? "active" : "inactive"}
                        style={{ marginLeft: "8px" }}
                      >
                        My Jobs
                      </Button>
                      <Button
                        variant="contained"
                        onClick={() => {
                          setTabView1("postjob");
                          setPostModalOpen(true);
                          history.push("/job");
                        }}
                        className={
                          tabView1 === "postjob" ? "active" : "inactive"
                        }
                        style={{ marginLeft: "8px" }}
                      >
                        Post A Job
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </TableContainer>
            </Box>
          </>
        ) : pathdata === "mywork" || pathdata === "viewwork" ? (
          <Box className={classes.MenuHome}>
            <Box className={classes.root1}>
              <Box className="minbox">
                <Box className="buttonbox">
                  <Button
                    variant="contained"
                    // onClick={() => setTabView4("Home-work")}
                    onClick={() => {
                      history.push("/home");
                      setTabView2("homepage");
                    }}
                    className={tabView4 === "Home-work" ? "active" : "inactive"}
                  >
                    Home
                  </Button>
                  <Button
                    variant="contained"
                    onClick={() => {
                      history.push("/mywork");
                      setTabView4("Works");
                    }}
                    className={tabView4 === "Works" ? "active" : "inactive"}
                  >
                    My Works
                  </Button>
                  <Button
                    variant="contained"
                    onClick={() => {
                      history.push("/mywork");
                      setTabView4("Moonlight");
                    }}
                    className={tabView4 === "Moonlight" ? "active" : "inactive"}
                  >
                    Moon light
                  </Button>
                  <Button
                    variant="contained"
                    onClick={() => {
                      history.push("/mywork");
                      setTabView4("AddMoonlight");
                    }}
                    className={
                      tabView4 === "AddMoonlight" ? "active" : "inactive"
                    }
                  >
                    Add Moonlight
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        ) : pathdata === "chat" ? (
          <Box className={classes.MenuHome}>
            <Box className={classes.root1}>
              <Box className="minbox">
                <Box className="buttonbox">
                  <Button
                    variant="contained"
                    // onClick={() => setTabView5("homepage")}
                    onClick={() => {
                      history.push("/home");
                      setTabView2("homepage");
                    }}
                    className={tabView5 === "homepage" ? "active" : "inactive"}
                  >
                    Home
                  </Button>
                  <Button
                    variant="contained"
                    onClick={() => setTabView5("my-chat")}
                    className={tabView5 === "my-chat" ? "active" : "inactive"}
                  >
                    Chat
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        ) : pathdata === "other-profile" ? (
          <Box className={classes.MenuHome}>
            <Box className={classes.root1}>
              <Box className="minbox">
                <Box className="buttonbox">
                  <Button
                    variant="contained"
                    // onClick={() => setTabView4("Home-work")}
                    onClick={() => {
                      history.push("/home");
                      setTabView2("homepage");
                    }}
                    className={tabView4 === "Home-work" ? "active" : "inactive"}
                  >
                    Home
                  </Button>
                  <Button
                    variant="contained"
                    // onClick={() => setTabView4("Works")}
                    className={
                      pathdata === "other-profile" ? "active" : "inactive"
                    }
                  >
                    Other Profile
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        ) : pathdata === "asset" || pathdata === "asset-details" ? (
          <Box className={classes.MenuHome}>
            <Box className={classes.root1}>
              <Box className="minbox">
                <Box className="buttonbox">
                  {assettabViewArray &&
                    assettabViewArray.map((data, i) => {
                      return (
                        <Button
                          variant="contained"
                          onClick={() => {
                            setTabView6(data?.tabView);
                            pathdata === "asset-details" &&
                              history.push("/asset");
                          }}
                          className={
                            tabView6 !== data?.tabView ||
                            pathdata === "asset-details"
                              ? "inactive"
                              : "active"
                          }
                        >
                          {data?.buttonName}
                        </Button>
                      );
                    })}
                </Box>
              </Box>
            </Box>
          </Box>
        ) : pathdata === "wallet" ? (
          <Box className={classes.MenuHome}>
            <Box className={classes.root1}>
              <Box className="minbox">
                <Box className="buttonbox">
                  <Button
                    variant="contained"
                    // onClick={() => setTabView5("homepage")}
                    onClick={() => {
                      history.push("/home");
                      setTabView2("homepage");
                    }}
                    className={tabView5 === "homepage" ? "active" : "inactive"}
                  >
                    Home
                  </Button>
                  <Button
                    variant="contained"
                    // onClick={() => setTabView5("homepage")}

                    className="active"
                  >
                    My Wallet
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        ) : (
          <Box className={classes.MenuHome}>
            <Box className={classes.root1}>
              <Box className="minbox">
                <Box className="buttonbox">
                  <Button
                    variant="contained"
                    // onClick={() => setTabView5("homepage")}
                    onClick={() => {
                      history.push("/home");
                      setTabView2("homepage");
                    }}
                    className={tabView5 === "homepage" ? "active" : "inactive"}
                  >
                    Home
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        )}
        {/* <Box className="nameBox">
          <Typography variant="h6">
            {auth.userData.firstName
              ? auth.userData.firstName
              : "" + " " + auth.userData.lastName
              ? auth.userData.lastName
              : ""}
          </Typography>
        </Box> */}
      </Box>

      {pathdata === "library" && (
        <>
          <Box className="MainContent">
            {tabViewl === "Phots" && (
              <MotionDiv>
                <Photos />{" "}
              </MotionDiv>
            )}
            {tabViewl === "EBook" && (
              <MotionDiv>
                <EBook />{" "}
              </MotionDiv>
            )}
            {tabViewl === "todo" && (
              <MotionDiv>
                <Tododata />
              </MotionDiv>
            )}
            {tabViewl === "Documents" && (
              <MotionDiv>
                <Document />
              </MotionDiv>
            )}
            {tabViewl === "Projects" && (
              <MotionDiv>
                <Projects />
              </MotionDiv>
            )}
            {tabViewl === "Purchased" && (
              <MotionDiv>
                <Purchased />
              </MotionDiv>
            )}
            {tabViewl === "LibraryEvent" && (
              <MotionDiv>
                <Box style={{ padding: "60px 21px 0px 21px" }}>
                  <LibraryEvent />
                </Box>
              </MotionDiv>
            )}
          </Box>
        </>
      )}

      {pathdata === "job" && (
        <>
          {tabView1 === "myJob" ? (
            <>
              <MotionDiv>
                <MyJobIndex tabView1={tabView1} />
              </MotionDiv>
            </>
          ) : (
            ""
          )}
          {tabView1 === "jobpost" ? (
            <MotionDiv>
              {/*  <JobDescription />*/}
              <Job tabView1={tabView1} />
            </MotionDiv>
          ) : (
            ""
          )}
          {tabView1 === "postjob" ? (
            <MotionDiv>
              <Indexx
                postjob="postjob"
                postModalOpen={postModalOpen}
                tabView1={tabView1}
                setPostModalOpen={(item) => setPostModalOpen(item)}
              />
            </MotionDiv>
          ) : (
            ""
          )}
          {tabView1 === "home" ? (
            <MotionDiv>
              <Job tabView1={tabView1} />
            </MotionDiv>
          ) : (
            ""
          )}
        </>
      )}

      {pathdata === "home" && (
        <>
          {tabView2 === "homepage" && (
            <>
              <MotionDiv>
                <Dashboard homepage="homepage" />
              </MotionDiv>
            </>
          )}
          {tabView2 === "MyJobs" && (
            <>
              <MotionDiv>
                <Dashboard MyJobs="MyJobs" tabView2={tabView2} />
              </MotionDiv>
            </>
          )}
        </>
      )}

      {pathdata === "mycontent" && (
        <>
          {setTabView3 === "Analytics" ? (
            <>
              <MotionDiv>
                <AnalyticsMain Analytics="Analytics" />
              </MotionDiv>
            </>
          ) : (
            ""
          )}
        </>
      )}

      {pathdata === "mywork" && (
        <>
          {setTabView4 === "Works" && (
            <>
              <MotionDiv>
                <WorkIndex Works="Works" />
              </MotionDiv>
            </>
          )}
          {tabView4 === "Moonlight" && (
            <>
              <MotionDiv>
                <Dashboard tabView4={tabView4} />
              </MotionDiv>
            </>
          )}

          {tabView4 === "AddMoonlight" && (
            <>
              <MotionDiv>
                <Dashboard
                  tabView4={tabView4}
                  setTabView4={(item) => setTabView4(item)}
                />
              </MotionDiv>
            </>
          )}
        </>
      )}
      {pathdata === "chat" && (
        <>
          {tabView5 === "my-chat" ? (
            <>
              <MotionDiv>{/* <ChatHistory my-chat="my-chat" /> */}</MotionDiv>
            </>
          ) : (
            ""
          )}
          {tabView5 === "homepage" ? (
            <>
              <MotionDiv>
                <Dashboard homepage="homepage" />
              </MotionDiv>
            </>
          ) : (
            ""
          )}
        </>
      )}
      {pathdata === "asset" && (
        <MotionDiv>
          <Bought tabView6={tabView6} />
        </MotionDiv>
      )}
    </>
  );
}
