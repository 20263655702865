import React from "react";

const Following = () => {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.881395 14.7996L0.832924 14.6576C0.597902 14.7379 0.367653 14.6692 0.262565 14.4878C0.182242 14.3395 0.143631 14.1723 0.150856 14.0039C0.292676 11.2341 1.63329 9.26078 4.10715 8.02261L4.1075 8.02244C4.16335 7.9943 4.21923 7.96874 4.28948 7.9366C4.32118 7.9221 4.3558 7.90627 4.39466 7.88826L4.61694 7.78522L4.4241 7.6341C3.19326 6.66955 2.58348 5.43666 2.70835 3.8925L2.70835 3.89249C2.79767 2.78723 3.29809 1.86984 4.1421 1.12926C5.74399 -0.275078 8.26232 -0.15234 9.767 1.39064C11.3083 2.97338 11.4741 5.82811 9.28974 7.57636L9.10634 7.72314L9.31666 7.82777C9.43655 7.88741 9.55398 7.94406 9.66936 7.99972C9.91873 8.12002 10.1586 8.23572 10.3931 8.36705C10.5758 8.46949 10.6778 8.5975 10.7175 8.7241C10.7566 8.84896 10.7412 8.99106 10.6535 9.13765C10.5733 9.26984 10.4608 9.34449 10.3322 9.36814C10.2008 9.39229 10.0389 9.36542 9.86344 9.26669C7.68454 8.03935 5.23356 8.18658 3.32192 9.72224L3.32192 9.72225C1.95136 10.8234 1.24331 12.2849 1.1909 14.0465C1.18542 14.2271 1.14835 14.361 1.0896 14.4572C1.03253 14.5506 0.949667 14.6175 0.832692 14.6577L0.881395 14.7996ZM0.881395 14.7996C1.17738 14.698 1.32884 14.4462 1.34083 14.0511L0.00102177 13.9968C-0.00752182 14.1933 0.0376418 14.3883 0.131657 14.561C0.281226 14.8217 0.596773 14.8968 0.881395 14.7996ZM6.78762 7.33039C7.60047 7.34023 8.38397 7.0269 8.96591 6.45927C9.5481 5.89139 9.88085 5.11549 9.89097 4.30227C9.90109 3.48904 9.58774 2.70511 9.01986 2.12292C8.45198 1.54072 7.67609 1.20797 6.86286 1.19785L6.86249 1.19785C5.12971 1.18063 3.7412 2.52411 3.72657 4.24258C3.72657 4.2426 3.72657 4.24262 3.72657 4.24264L3.87656 4.24392C3.87314 4.62853 3.94598 5.01 4.09088 5.36629C4.23578 5.72257 4.44986 6.04661 4.72074 6.31967C4.99162 6.59272 5.31394 6.80938 5.66906 6.95711C6.0235 7.10457 6.40361 7.18045 6.78749 7.18039L6.78762 7.33039Z"
        fill="#A9A9A9"
        stroke="white"
        stroke-width="0.3"
      />
      <path
        d="M11.5755 14.4094L11.4509 14.3258C11.3102 14.5357 11.1774 14.631 11.0517 14.6575C10.9267 14.6838 10.7705 14.6501 10.5643 14.5144L10.5642 14.5144C9.94158 14.1049 9.32166 13.6925 8.70447 13.2771L8.70421 13.2769C8.53424 13.163 8.44058 13.0326 8.4066 12.9077C8.37332 12.7854 8.39233 12.651 8.48105 12.5135C8.56812 12.3786 8.68908 12.3032 8.82368 12.2829C8.96008 12.2624 9.1243 12.2969 9.29494 12.4092L9.2952 12.4093C9.62108 12.6228 9.94435 12.84 10.2753 13.0623C10.4044 13.149 10.5346 13.2365 10.6666 13.3249L10.7887 13.4066L10.8729 13.2862C10.906 13.239 10.938 13.1942 10.9688 13.151C11.0171 13.0835 11.0626 13.0198 11.1048 12.957L11.105 12.9567C11.7807 11.9469 12.4569 10.9349 13.1334 9.92048C13.2601 9.73131 13.4007 9.62609 13.5326 9.58672C13.6605 9.54854 13.7972 9.56693 13.9356 9.65861C14.072 9.74894 14.1403 9.86851 14.1533 10.0037C14.1667 10.1433 14.1221 10.3157 13.9987 10.5035C13.1528 11.7768 12.3035 13.051 11.4508 14.326L11.5755 14.4094ZM11.5755 14.4094C12.4283 13.1341 13.2778 11.8598 14.1238 10.5862L10.75 13.2002C10.6185 13.1122 10.4886 13.0249 10.3597 12.9383C10.0284 12.7158 9.70406 12.4978 9.3774 12.2839C8.98612 12.0264 8.57339 12.0939 8.35503 12.4322C8.13667 12.7704 8.23638 13.144 8.62072 13.4015C9.23835 13.8172 9.85871 14.2299 10.4818 14.6397C10.9286 14.9338 11.2726 14.8612 11.5755 14.4094Z"
        fill="#A9A9A9"
        stroke="white"
        stroke-width="0.3"
      />
    </svg>
  );
};

export default Following;