import { handleNegativeValue } from "src/utils";
import {
  Box,
  Button,
  Grid,
  TextField,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React from "react";
import { FiSearch } from "react-icons/fi";
import PlacesAutocomplete from "react-places-autocomplete";

const useStyles = makeStyles((theme) => ({
  mainform: {
    "& .MuiOutlinedInput-root": {
      height: "40px",
      borderRadius: "25px",
      backgroundColor: "#f1f1f1",
      paddingLeft: "13px",
    },
    "& .MuiOutlinedInput-input": {
      padding: "0px 0px 0px 10px",
      broderRadius: "25px",
    },
    "& .searchBox": {
      "& .MuiFormControl-root": {
        borderRadius: "50px",
      },
    },
    "& svg": {
      position: "absolute",
      right: "25px",
      top: "11px",
      fontSize: "17px",
      color: "#AFAFAF",
      cursor: "pointer",
    },
  },
  jobsbox: {
    "& h5": {
      fontSize: "18px",
      paddingBottom: "5px",
    },
  },
  mainBox: {
    border: "1px solid #C8C8C8",
    borderRadius: "5px",
    paddingLeft: "20px",
    "& h6": {
      color: "#7A7A7A",
      fontWeight: "300",
    },
  },
  jobsuggest: {
    background: "#fff",
    position: "absolute",
    padding: "10px",
    borderRadius: "10px",
    width: "100%",
    zIndex: "999",
  },
}));

export default function JobFilter({
  setJobFilter,
  jobFilter,
  setFilterSta,
  auth,
}) {
  const classes = useStyles();

  return (
    <Box>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={6} sm={4} md={3}>
          <Box mb={2} className={classes.mainform}>
            <Box className="searchBox">
              <TextField
                type="text"
                variant="outlined"
                fullWidth
                placeholder="Search Jobs"
                name="search"
                value={jobFilter.search}
                onChange={(e) => {
                  if (e.target.value.length <= 256) {
                    setJobFilter({ ...jobFilter, ["search"]: e.target.value });
                  }
                }}
                InputProps={{
                  endAdornment: (
                    <FiSearch
                      position="end"
                      style={{ fontSize: "16px", color: "#AFAFAF" }}
                    />
                  ),
                }}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={6} sm={3} md={3}>
          <Box
            mb={2}
            className={classes.mainform}
            style={{ position: "relative" }}
          >
            <Box className="searchBox">
              <TextField
                type="number"
                variant="outlined"
                fullWidth
                name="experience"
                placeholder="Experience"
                value={jobFilter.exprience}
                onChange={(e) => {
                  if (e.target.value.length <= 2) {
                    setJobFilter({
                      ...jobFilter,
                      ["exprience"]: e.target.value,
                    });
                  }
                }}
                onKeyPress={(event) => {
                  if (
                    event?.key === "-" ||
                    event?.key === "+" ||
                    event?.key === "*" ||
                    event?.key === "/"
                  ) {
                    event.preventDefault();
                  }
                }}
                onKeyDown={(event) => handleNegativeValue(event)}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={6} sm={3} md={3}>
          <Box
            mb={2}
            className={classes.mainform}
            style={{ position: "relative" }}
          >
            <Box className="searchBox">
              <PlacesAutocomplete
                className={classes.autocompleteClass}
                value={auth.address}
                name="jobLocation"
                onChange={auth.handleAddressChange}
                onSelect={auth.handleSelectLocation}
              >
                {({
                  getInputProps,
                  suggestions,
                  getSuggestionItemProps,
                  loading,
                }) => (
                  <Box className="minbox">
                    <Box>
                      <TextField
                        fullWidth
                        variant="outlined"
                        placeholder="Search Location"
                        {...getInputProps({
                          placeholder: "Job Location",
                          className: "location-search-input",
                        })}
                        inputProps={{ "aria-label": "search" }}
                      />
                    </Box>
                    <Box className={classes.jobsuggest}>
                      {loading && (
                        <div style={{ marginTop: "5px" }}>Loading...</div>
                      )}
                      {suggestions &&
                        suggestions.map((suggestion) => {
                          const className = suggestion.active
                            ? "suggestion-item--active"
                            : "suggestion-item";

                          const style =
                            suggestion.active === true
                              ? {
                                  backgroundColor: "#fff",
                                  cursor: "pointer",
                                  color: "#404040",
                                  borderBottom: "1px solid #e7e7e7",
                                  marginTop: "9px",
                                  fontSize: "14px",
                                  fontWeight: "400",
                                  padding: "4px 30px 8px 0px",
                                }
                              : {
                                  backgroundColor: "#ffffff",
                                  cursor: "pointer",
                                  color: "#404040",
                                  borderBottom: "1px solid #e7e7e7",
                                  marginTop: "9px",
                                  fontSize: "14px",
                                  fontWeight: "400",
                                  padding: "4px 30px 8px 0px",
                                };
                          return (
                            <div
                              {...getSuggestionItemProps(suggestion, {
                                className,
                                style,
                              })}
                            >
                              <Box className="imgbox">
                                <Box className="typo2">
                                  <Typography
                                    variant="body2"
                                    style={{ fontWeight: "600" }}
                                  >
                                    {suggestion.description}
                                  </Typography>
                                </Box>
                              </Box>
                            </div>
                          );
                        })}
                    </Box>
                  </Box>
                )}
              </PlacesAutocomplete>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={6} sm={2} md={1}>
          <Box mb={2} className={classes.mainform}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              height="100%"
              style={{ padding: "8px 16px" }}
              onClick={() => {
                setJobFilter({
                  search: "",
                  exprience: "",
                });
                auth.setLatLong({
                  lat: "",
                  long: "",
                });
                auth.setAddress("");
                setFilterSta(true);
              }}
            >
              Reset
            </Button>
          </Box>
        </Grid>
        <Grid item xs={6} sm={2} md={1}>
          <Box mb={2} className={classes.mainform}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              height="100%"
              style={{ padding: "8px 16px" }}
              onClick={() => setFilterSta(true)}
            >
              Search
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
