import React from "react";
import {
  Box,
  makeStyles,
  Typography,
  IconButton,
  Dialog,
  DialogContent,
} from "@material-ui/core";
import "react-datepicker/dist/react-datepicker.css";
import { GrClose } from "react-icons/gr";
const useStyles = makeStyles((theme) => ({
  DialogClass: {
    " & .MuiDialog-paperFullWidth": {
      borderRadius: "15px",
    },
    " & svg": {
      color: "#A3B7FF",
    },
  },
}));

function Successfully({ data, handleclose, handleOpen }) {
  const classes = useStyles();
  return (
    <Box>
      <Dialog
        fullWidth="xs"
        maxWidth="xs"
        open={handleOpen}
        keepMounted
        onClose={() => handleclose()}
        className={classes.DialogClass}
      >
        <Box display="flex" justifyContent="end">
          <IconButton>
            <GrClose onClick={() => handleclose()} />
          </IconButton>
        </Box>
        <DialogContent>
          <Box align="center">
            <img src="images/sucess.png" alt="dumy" />
          </Box>

          <Box my={3} align="center">
            <Typography
              variant="h4"
              style={{ color: "#52A500", maxWidth: "200px", fontWeight: "500" }}
            >
              {data ? data : "---"}
            </Typography>
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
}

export default Successfully;
