import { userDataContrectAddress } from "src/constants";
import { getContract } from "src/utils";
import UserDataABI from "src/ABI/UserDataABI.json";
import { toast } from "react-toastify";

export const handleSubmitBlcDataFun = async (values, auth) => {
  try {
    const contractObjToken = getContract(
      userDataContrectAddress,
      UserDataABI,
      auth.library,
      auth.account
    );
    const userId = auth.userData._id.toString();
    const uploadEditProfileBlc = await contractObjToken.saveUserData(
      userId,
      JSON.stringify(values)
    );
    await uploadEditProfileBlc.wait();
    return uploadEditProfileBlc.hash;
  } catch (error) {
    console.log(error);
    toast.error(error.message);
    return false;
  }
};
