import React, { useState, useEffect, useContext } from "react";
import {
  Button,
  Box,
  TextField,
  Grid,
  FormControl,
  Select,
  MenuItem,
  Typography,
  Container,
  FormHelperText,
  makeStyles,
} from "@material-ui/core";
import "react-dropzone-uploader/dist/styles.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Form, Formik } from "formik";
import * as yep from "yup";
import { AuthContext } from "src/context/Auth";
import axios from "axios";
import { Autocomplete } from "@material-ui/lab";
import AutoCompleteFil from "src/component/AutoCompleteFil";

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: "100%",
    "& em": {
      fontStyle: "inherit !important",
    },
  },
  contactbox: {
    borderRadius: "5px",
    [theme.breakpoints.down("xs")]: {
      padding: "30px 0px 10px",
    },
    "& h5": {
      fontWeight: "400",
      color: "rgba(0, 0, 0, 0.4)",
    },
  },
  outlineborder1: {
    "& .react-tel-input .flag-dropdown ": {
      position: "absolute",
      top: "10px",
      bottom: "0",
      background: "#1E1E1E",
      padding: "0",
      borderRight: "1px solid #B7B1B1",
      border: "none",
      borderRadius: "10px 0 0 10px",
      height: "31px",
    },
    "& .react-tel-input .selected-flag": {
      borderRadius: "0px",
      backgroundColor: "#f1f1f1",

      "&:hover": {
        backgroundColor: "#f1f1f1",
      },
    },

    "& .react-tel-input .flag-dropdown.open .selected-flag": {
      background: "rgb(33, 32, 32)",
      borderRadius: "0px",
      "&:hover": {
        backgroundColor: "#f1f1f1",
      },
    },
    "& .react-tel-input .flag-dropdown.open": {
      background: "rgb(33, 32, 32)",
    },
    "& .react-tel-input .form-control": {
      backgroundColor: "#f1f1f1",
      width: "100%",
      borderRadius: "0px",
      height: "51px",
      color: "#000000",
      border: "none",
      position: "relative",
      fontSize: "14px",
      letterSpacing: ".01rem",
      marginTop: "0 !important",
      marginBottom: "0 !important",
      paddingLeft: "48px",
      marginLeft: "0",
      lineHeight: "25px",
      outline: "none",
    },
    "& .react-tel-input .country-list": {
      background: theme.palette.background.darkgrey,
    },
    ".react-tel-input .country-list .country": {
      "&:hover": {
        background: theme.palette.background.yellow,
      },
    },
  },
  buttonboxes: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    maxWidth: "790px",
    margin: "0 auto",
    // marginBottom: "16px",
    [theme.breakpoints.down("xs")]: {
      display: "block",
      width: "100%",
      maxWidth: "100%",
    },
    "& button": {
      fontSize: "18px",
      fontWeight: "500",
      margin: "0px 10px",
      marginBottom: "10px",
      [theme.breakpoints.down("xs")]: {
        marginBottom: "10px",
        margin: "0px",
      },
    },
  },
}));

export default function StepContent({
  setBasicDetails,
  activeStep,
  handleBack,
  steps,
  setBlcok,
  handleNext,
  basicDetails,
}) {
  const classes = useStyles();
  const auth = useContext(AuthContext);
  const userData = auth?.userData;
  const [cities, setCities] = useState([]);
  const [states, setStates] = useState([]);
  const [countryCode, setCountryCode] = useState("+91");
  const [showStates, setShowStates] = useState([]);
  const [countyName, setCountyName] = useState("");
  const [countries, setCountries] = useState([]);
  const [showCities, setShowCities] = useState([]);
  const formValidationSchema = yep.object().shape({
    firstName: yep
      .string()
      .required("First name is required.")
      .matches(/^[A-Za-z]+$/, "Only alphabets are allowed.")
      .min(3, "Please enter atleast 3 characters.")
      .max(256, "You can enter only 256 characters."),
    lastName: yep
      .string()
      .required("Last name is required.")
      .matches(/^[A-Za-z]+$/, "Only alphabets are allowed.")
      .min(3, "Please enter atleast 3 characters.")
      .max(256, "You can enter only 256 characters."),
    mobileNumber: yep
      .string()
      .required("Phone number is required.")
      .max(13, "Enter a valid phone number.")
      .min(7, "Must be only 7 digits.")
      .test(
        "notAllRepeatedDigits",
        "Phone number cannot have all repeated digits.",
        (value) => {
          const numericValue = value?.replace(/[^0-9]/g, "");
          return !/(\d)\1{6,}/.test(numericValue);
        }
      ),
    email: yep
      .string()
      .max(256, "Should not exceeds 256 characters.")
      .email("Please enter a valid email address.")
      .required("Email is required."),
    gender: yep.string().required("Gender is required."),
    address: yep.string().required("Address is required."),
    country: yep.string().nullable().required("Country is required."),
    state: yep.string().nullable().required("State is required."),
    city: yep.string().nullable().required("City is required."),
  });

  useEffect(() => {
    axios.get("/json/countries.json").then(function (response) {
      setCountries(response.data.countries);
      axios.get("/json/states.json").then(function (response) {
        setStates(response.data.states);
        axios.get("/json/cities.json").then(function (response) {
          setCities(response.data.cities);
        });
      });
    });
    if (basicDetails.country !== "") {
      changeCountryList(basicDetails.country);
    }
  }, [basicDetails]);

  const changeStateList = (name) => {
    const selectted = states?.filter((cont) => {
      return cont.name === name;
    });
    if (selectted.length !== 0) {
      const allCity = cities?.filter((city) => {
        return city.state_id === selectted[0]?.id;
      });
      setShowCities(allCity);
    }
  };

  const changeCountryList = (name) => {
    const selectted = countries?.filter((cont) => {
      return cont.name === name;
    });
    setCountyName(name);
    const allState = states?.filter((state) => {
      return state.country_id === selectted[0]?.id;
    });
    setShowStates(allState);
  };

  return (
    <>
      <Box className={classes.contactbox}>
        <Formik
          initialValues={{
            firstName: userData.firstName ? userData.firstName : "",
            lastName: userData.lastName ? userData.lastName : "",
            mobileNumber: userData.mobileNumber ? userData.mobileNumber : "",
            email: userData.email ? userData.email : "",
            gender: basicDetails.gender ? basicDetails.gender : "",
            address: basicDetails.address ? basicDetails.address : "",
            country: basicDetails.country
              ? basicDetails.country
              : userData.country
              ? userData.country
              : "",
            state: basicDetails.state
              ? basicDetails.state
              : userData.state
              ? userData.state
              : "",
            city: basicDetails.city
              ? basicDetails.city
              : userData.city
              ? userData.city
              : "",
          }}
          validationSchema={formValidationSchema}
          onSubmit={(values) => {
            const dataSend = {
              firstName: values.firstName,
              lastName: values.lastName,
              mobileNumber: values.mobileNumber,
              email: values.email,
              gender: values.gender,
              address: values.address,
              country: values.country,
              state: values.state,
              city: values.city,
              countryCode: countryCode,
            };
            setBasicDetails(dataSend);
            if (activeStep === steps?.length - 1) {
              setBlcok(true);
            } else {
              handleNext();
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values,
            setFieldValue,
          }) => (
            <Form>
              <Container>
                <Box mb={4}>
                  <Typography variant="h5">
                    This section requires you to provide your personal
                    information such as your first name, last name, mobile
                    number, email address, gender and residential address
                    details. It is important that you provide accurate
                    information as it will be used to verify your identity.
                  </Typography>
                </Box>
                <Grid container spacing={3}>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <Box>
                      <TextField
                        type="text"
                        placeholder="First Name"
                        variant="outlined"
                        fullWidth
                        name="firstName"
                        value={values.firstName}
                        error={Boolean(touched.firstName && errors.firstName)}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                    </Box>
                    <FormHelperText error>
                      {touched.firstName && errors.firstName}
                    </FormHelperText>
                  </Grid>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <Box>
                      <TextField
                        type="text"
                        placeholder="Last name"
                        variant="outlined"
                        fullWidth
                        name="lastName"
                        value={values.lastName}
                        error={Boolean(touched.lastName && errors.lastName)}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                    </Box>
                    <FormHelperText error>
                      {touched.lastName && errors.lastName}
                    </FormHelperText>
                  </Grid>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <Box>
                      <FormControl
                        fullWidth
                        variant="filled"
                        className={classes.outlineborder1}
                      >
                        <PhoneInput
                          country={"in"}
                          name="mobileNumber"
                          type="text"
                          fullWidth
                          value={values.mobileNumber}
                          error={Boolean(
                            touched.mobileNumber && errors.mobileNumber
                          )}
                          onBlur={handleBlur}
                          onChange={(mobileNumber, e) => {
                            setCountryCode(e.dialCode);
                            setFieldValue("mobileNumber", mobileNumber);
                          }}
                        />
                      </FormControl>
                    </Box>
                    <FormHelperText error className={classes.helperText}>
                      {touched.mobileNumber && errors.mobileNumber}
                    </FormHelperText>
                  </Grid>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <Box>
                      <TextField
                        type="text"
                        placeholder="Email address"
                        variant="outlined"
                        fullWidth
                        name="email"
                        disabled
                        value={values.email}
                        error={Boolean(touched.email && errors.email)}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                    </Box>
                    <FormHelperText error>
                      {touched.email && errors.email}
                    </FormHelperText>
                  </Grid>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <Box>
                      <FormControl
                        variant="outlined"
                        className={classes.formControl}
                      >
                        <Select
                          displayEmpty
                          inputProps={{ "aria-label": "Without label" }}
                          fullWidth
                          name="gender"
                          value={values?.gender}
                          error={Boolean(touched.gender && errors.gender)}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        >
                          <MenuItem value="">Gender</MenuItem>
                          <MenuItem value="MALE">Male</MenuItem>
                          <MenuItem value="FEMALE">Female</MenuItem>
                          <MenuItem value="OTHER">Other</MenuItem>
                        </Select>
                      </FormControl>
                      <FormHelperText error>
                        {touched.gender && errors.gender}
                      </FormHelperText>
                    </Box>
                  </Grid>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <Box>
                      <TextField
                        type="text"
                        placeholder="Address"
                        variant="outlined"
                        fullWidth
                        name="address"
                        value={values.address}
                        error={Boolean(touched.address && errors.address)}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                    </Box>
                    <FormHelperText error>
                      {touched.address && errors.address}
                    </FormHelperText>
                  </Grid>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <Box>
                      <FormControl
                        variant="outlined"
                        className={classes.formControl}
                      >
                        <AutoCompleteFil
                          optionsData={countries.map(
                            (item, i) => item && item?.name
                          )}
                          getOptionLabel={(option) => option && option?.name}
                          name="country"
                          autoFilValue={values.country}
                          setAutoFilValue={setFieldValue}
                          handleBlur={handleBlur}
                          touched={touched}
                          errors={errors}
                          placeholder="Enter country"
                          changeCountry={changeCountryList}
                          type="kycFil"
                        />
                      </FormControl>
                      <FormHelperText error>
                        {touched.country && errors.country}
                      </FormHelperText>
                    </Box>
                  </Grid>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <Box>
                      <FormControl
                        variant="outlined"
                        className={classes.formControl}
                      >
                        <AutoCompleteFil
                          optionsData={showStates.map((item, i) => item.name)}
                          name="state"
                          autoFilValue={
                            values.state === null ? "" : values.state
                          }
                          setAutoFilValue={setFieldValue}
                          handleBlur={handleBlur}
                          touched={touched}
                          errors={errors}
                          placeholder="Enter state"
                          changeCountry={changeStateList}
                          type="kycFil"
                        />
                      </FormControl>
                      <FormHelperText error>
                        {touched.state && errors.state}
                      </FormHelperText>
                    </Box>
                  </Grid>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <Box>
                      <TextField
                        type="text"
                        placeholder="City"
                        variant="outlined"
                        fullWidth
                        name="city"
                        value={values.city}
                        error={Boolean(touched.city && errors.city)}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                    </Box>
                    <FormHelperText error>
                      {touched.city && errors.city}
                    </FormHelperText>
                  </Grid>
                </Grid>
              </Container>

              <Box className={classes.instructions}>
                <Box py={4} textAlign="center" className={classes.buttonboxes}>
                  <Button
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    className={classes.button}
                    variant="contained"
                    color="secondary"
                    size="large"
                    fullWidth
                    style={{ backgroundColor: "#828282" }}
                  >
                    Previous
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    fullWidth
                    className={classes.button}
                    type="submit"
                  >
                    {activeStep === steps.length - 1 ? "Submit" : "Next"}
                  </Button>
                </Box>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </>
  );
}
