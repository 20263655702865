import React, { useState, useContext } from "react";
import {
  makeStyles,
  Dialog,
  DialogContent,
  IconButton,
  FormControl,
  Typography,
  TextField,
  Grid,
  Box,
  MenuItem,
  Avatar,
  Select,
  Button,
  Divider,
  FormHelperText,
  InputAdornment,
} from "@material-ui/core";
import Picker from "emoji-picker-react";
import { FaRegSmileBeam } from "react-icons/fa";
import { IoImageOutline } from "react-icons/io5";
import { IoClose } from "react-icons/io5";
import { IoMdGlobe } from "react-icons/io";
import { IoLogoYoutube } from "react-icons/io5";
import ImageAndVedioModal from "./ImageAndVedioModal";
import { dataPostHandler } from "src/apiConfig/service";
import ButtonCircularProgress from "./ButtonCircularProgress";
import { AuthContext } from "../context/Auth";
import { HiOutlineUserGroup } from "react-icons/hi";
import { toast } from "react-toastify";
import Axios from "axios";
import apiConfigs from "src/apiConfig/ApiConfig";
import {
  creteAssest,
  marketplace,
  toeknAddress,
  tokenName,
} from "src/constants";
import { KeyboardDateTimePicker } from "@material-ui/pickers";
import moment from "moment";
import MarketplaceABI from "src/ABI/MarketplaceABI.json";
import NFTABI from "src/ABI/NFTABI.json";
import { getContract, handleNegativeValue } from "src/utils";
import web3 from "web3";
import { AiOutlineCloudUpload } from "react-icons/ai";

const useStyles = makeStyles((theme) => ({
  mainmodalBox: {
    "& .MuiInputBase-root": {
      boxShadow: "none !important",
      background: "#F1F1F1",
    },

    "& .formControl": {
      width: "100%",
      backgroundColor: "transparent",
      border: "none",
      color: "#fff",
      "&:focus-visible": {
        outline: "none",
      },
    },

    "& .addImagegIcon": {
      cursor: "pointer",
      position: "relative",
      "& input": {
        top: 0,
        left: 0,
        position: "absolute",
        width: "100%",
        height: "100%",
        opacity: 0,
      },
      "& svg": {
        color: "#ACACAC",
        fontSize: "22px",
      },
    },
  },
  cancelBtn: {
    position: "absolute",
    top: 0,
    right: 0,
    "& svg": {
      color: "#fff",
      fontWeight: "700",
    },
  },
  imgPickerBox: {
    "& p": {
      fontSize: "14px",
      lineHeight: "17px",
      color: "#9E9E9E",
      paddingTop: "20px",
    },
  },
  commentControl: {
    background: "#ffffff",

    "& .commentField": {
      background: "#ffffff",
    },

    "& .allIconInput": {
      "& svg": {
        fontSize: "28px",
      },
      "@media(max-width:408px)": {
        padding: "4px",
        "& svg": {
          fontSize: "17px",
        },
      },
    },
  },

  imagelist: {
    display: "flex",
    alignItems: "center",
    "& .MuiAvatar-root": {
      width: "66px",
      height: "66px",
      border: "3px solid #C1C9E2",
    },

    "& p": {
      color: "#909090",
    },
    "& .leftbox": {
      marginLeft: "10px",
    },
  },
  flexboxes: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    "@media(max-width:475px)": {
      display: "grid",
    },
    "& button": {
      border: "1px solid rgba(50, 50, 50, 0.6)",
      padding: "5px 15px",
      fontSize: "15px",
      color: "rgba(50, 50, 50, 0.6)",
    },
    "& .MuiFormControl-root": {
      backgroundColor: "none",
    },
    "& .formmanage": {
      margin: "0px 12px",
      "@media(max-width:475px)": {
        margin: "9px 0px",
      },
      "& p": {
        color: "#727272",
        fontWeight: "500",
      },
      "& .MuiAvatar-root": {
        fontSize: "18px",
        fontWeight: "18px",
      },
    },
  },

  maindialouge: {
    width: "100%",
    "& .helpText": {
      textAlign: "end",
      marginTop: "0px",
    },
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },

  friendControl: {
    borderRadius: "50px",
    "& .MuiSelect-select.MuiSelect-select": {
      padding: "10px 42px 10px 10px !important",
      borderRadius: "50px",
      display: "flex",
      background: " #fff",
      border: "1px solid #B5B5B5",
      alignItems: "center",
    },
    "& .MuiAvatar-root": {
      width: "18px",
      height: "18px",
    },
    "& .MuiDivider-root": {
      backgroundColor: "rgba(231, 231, 231, 0.2)",
    },
    "& .MuiSelect-icon": {
      right: "8px",
      top: "12px",
    },
    "& .MuiInput-underline:before": {
      borderBottom: "none !important",
    },
    "& .MuiInput-underline:after": {
      borderBottom: "none !important",
    },
    "& .MuiListItem-root.Mui-selected": {
      color: "#4057a7",
    },
    "& .MuiMenu-paper": {
      borderRadius: "10px !important",
    },

    "& .selected": {
      borderRadius: "20px !important",
      "& .MuiPopover-paper": {
        marginTop: "43px !important",
        borderRadius: "20px !important",
      },
    },
    "& svg": {
      color: "#979393",
      fontSize: "17px",
    },
    "& p": {
      paddingLeft: "10px",
    },
    "& .textClx": {
      maxWidth: "234px",
      "@media(max-width:668px)": {
        maxWidth: "150px",
      },
      "@media(max-width:585px)": {
        maxWidth: "54px",
      },
    },
  },
  buttonpost: {
    fontSize: "18px",
    fontWeight: "500",
    padding: "8px 60px",
  },
  iconBox: {
    "& .MuiDivider-root": {
      width: "2px",
      height: "19px",
    },
    "& .allIconInput": {
      "& svg": {
        fontSize: "30px",
      },
      "@media(max-width:408px)": {
        padding: "4px",
        "& svg": {
          fontSize: "17px",
        },
      },
    },
    display: "flex",
    alignItems: "center",
  },
  imgVedioBox: {
    width: "100%",
    height: "200px",
    position: "relative",
    marginTop: "10px",
    "& img": {
      color: "transparent",
      width: "100%",
      height: "100%",
      objectFit: "contain",
      textAlign: "center",
      background: "#f0f8ff",
    },
  },
  cancelBtn1: {
    background: "#000000",
    top: "6px",
    right: "10px",
    position: "absolute",
    "&:hover": {
      backgroundColor: "#4057a7 !important",
    },
  },
  mainBoxEmoji: {
    position: "relative",
  },
  emojiBox: {
    position: "absolute",
    display: "flex",
    justifyContent: "left",
    width: "100%",
    bottom: "-30px",
    left: "0 !important",
    zIndex: "1",
    [theme.breakpoints.down("md")]: {
      left: "0% !important",
    },
    [theme.breakpoints.down("sm")]: {
      left: "0% !important",
    },
  },
  datefiledBox: {
    "& .MuiInputBase-root": {
      background: "#F1F1F1",
    },
  },
}));

function CreatePostModal({
  listPostHandler,
  openCreatePost,
  handleCloseCreatePost,
  pageData,
}) {
  const auth = useContext(AuthContext);
  const classes = useStyles();
  const [imageAndVideo, setImageAndVideo] = useState("");
  const [imageAndVideo1, setImageAndVideo1] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [inputStr, setInputStr] = useState("");
  const [status, setStatus] = useState("PUBLIC");
  const [showPicker, setShowPicker] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [expireDate, setExpireDate] = useState(moment().add(1, "h"));
  const [nftPrice, setNftPrice] = useState("");
  const [status1, setStatus1] = useState("Mobiloitte");
  const [openModal, setOpenModal] = useState("");
  const [openModalOpen, setOpenModalOpen] = useState(false);
  const [profileImage642, setProfileImage642] = useState("");

  const onEmojiClick = (e, emojiObject) => {
    const sym = e.unified.split("_");
    const codeArray = [];
    sym.forEach((el) => codeArray.push("0x" + el));
    let emoji = String.fromCodePoint(...codeArray);
    setInputStr(inputStr + emoji);
    setShowPicker(false);
  };

  const uploadIpfs = async () => {
    try {
      setIsLoading(true);
      const formdata = new FormData();
      formdata.append("file", imageAndVideo1);
      const apiMethod = {
        method: "POST",
        headers: {
          token: window.sessionStorage.getItem("token"),
        },
        data: formdata,
      };
      const response = await Axios({
        ...apiMethod,
        url: apiConfigs.upload,
      });
      const res = await Axios({
        ...apiMethod,
        url: apiConfigs.ipfsUpload,
      });
      if (response.data.responseCode === 200 && res.data.responseCode === 200) {
        uploadNft(response, res);
      } else {
        toast.error(response.data.responseMessage);
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.responseMessage);
      setIsLoading(false);
    }
  };

  const uploadNft = async (imgCld, OjbIpfs) => {
    try {
      const formData = new FormData();
      formData.append("tokenName", inputStr);
      formData.append("image", OjbIpfs?.data?.result?.imageUrl);

      const response = await Axios({
        method: "POST",
        url: apiConfigs.uploadAsset,
        headers: {
          token: window.sessionStorage.getItem("token"),
        },
        data: formData,
      });
      if (response.data.responseCode === 200) {
        if (auth.yourWalletBalance > 0) {
          createAssesstHandler(OjbIpfs, imgCld);
        } else {
          toast.error("Low Balance");
          setIsLoading(false);
        }
      } else {
        toast.error(response.data.responseMessage);
        setIsLoading(false);
      }
    } catch (error) {
      toast.error(error.response.data.responseMessage);
      setIsLoading(false);
    }
  };

  const createAssesstHandler = async (OjbIpfs, imgCld) => {
    try {
      const createObject = getContract(
        creteAssest,
        NFTABI,
        auth.library,
        auth.account
      );
      const result = await createObject.create(
        OjbIpfs?.data?.result?.ipfsHash,
        inputStr,
        {
          from: auth.account,
        }
      );
      await result.wait();

      const tokenbig = await createObject.totalSupply();
      const tokenId = tokenbig.toString();
      const formData = {
        tokenId: tokenId - 1,
        title: inputStr,
        tokenName: inputStr,
        uri: OjbIpfs?.data?.result?.ipfsHash,
        mediaFile: imgCld?.data?.result,
        creatorAddress: auth?.account.toString(),
        contractAddress: creteAssest,
        buyPrice: nftPrice,
        expireDate: expireDate,
        mediaType: OjbIpfs?.data?.result?.type,
      };

      const responseAddNftData = await addnft(formData);

      const NFTApprovalID = await createObject.approve(
        marketplace,
        tokenId - 1
      );
      await NFTApprovalID.wait();

      const createObjMar = getContract(
        marketplace,
        MarketplaceABI,
        auth?.library,
        auth?.account
      );

      const marketPlace = await createObjMar.createOrder(
        creteAssest,
        tokenId - 1,
        web3.utils.toWei(nftPrice, "ether"),
        moment(expireDate).unix(),
        toeknAddress,
        0
      );
      await marketPlace.wait();

      const formPlaceOrder = {
        buyPrice: nftPrice,
        expireDate: expireDate,
      };

      placeOrderMarketPlace(
        formPlaceOrder,
        responseAddNftData?.data?.result?._id
      );
    } catch (error) {
      console.log(error);
      toast.error(error?.message);
      setIsLoading(false);
    }
  };

  const addnft = async (formData) => {
    try {
      const response = await Axios({
        method: "POST",
        url: apiConfigs.create,
        headers: {
          token: window.sessionStorage.getItem("token"),
        },
        data: formData,
      });
      if (response.data.responseCode === 200) {
        return response;
      } else {
        toast.error(response.data.responseMessage);
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      toast.error(error.response.data.responseMessage);
    }
  };

  const placeOrderMarketPlace = async (formPlaceOrder, id) => {
    try {
      const response = await Axios({
        method: "PUT",
        url: `${apiConfigs.sentToMarketplace}/${id}`,
        headers: {
          token: window.sessionStorage.getItem("token"),
        },
        data: formPlaceOrder,
      });
      if (response.data.responseCode === 200) {
        handleCloseCreatePost();
        toast.success(response.data.responseMessage);
        setIsLoading(false);
        listPostHandler();
        auth.listPostHandler();
        auth.listPostHandlerTrending();

        setImageAndVideo("");
        setImageAndVideo1("");
        setStatus("PUBLIC");
        setInputStr("");
        setNftPrice("");
        setExpireDate(undefined);
      } else {
        toast.error(response.data.responseMessage);
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      toast.error(error.response.data.responseMessage);
    }
  };

  const addPostHandler = async () => {
    try {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("title", inputStr);
      if (pageData?.type === "page") {
        formData.append("pageId", pageData?.id);
      }
      if (imageAndVideo1) {
        formData.append("mediaFile", imageAndVideo1);
      }
      formData.append("postType", "MEDIA");
      formData.append("sharedTo", status);
      const res = await dataPostHandler("addPost", formData);
      if (res.data.responseCode === 200) {
        handleCloseCreatePost();
        setIsLoading(false);
        listPostHandler();
        auth.listPostHandler();
        auth.listPostHandlerTrending();

        setImageAndVideo("");
        setImageAndVideo1("");
        setStatus("PUBLIC");
        setInputStr("");
      } else {
        toast.error(res.data.responseMessage);
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  return (
    <Dialog
      open={openCreatePost}
      onClose={!isLoading && handleCloseCreatePost}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="sm"
      fullWidth
      className={classes.maindialouge}
    >
      <Box className="bgcolorbox">
        <Box>
          <Typography variant="h5">Create a post</Typography>
        </Box>
      </Box>
      <DialogContent>
        <IconButton
          className={classes.cancelBtn}
          onClick={!isLoading && handleCloseCreatePost}
        >
          <IoClose />
        </IconButton>
        <Box className={classes.mainmodalBox}>
          <Box mt={2}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box className={classes.flexboxes}>
                  <Box className={classes.imagelist}>
                    <Avatar
                      src={
                        auth?.userData?.profilePic && auth?.userData?.profilePic
                      }
                      alt="dataImage"
                      width="100%"
                    />
                  </Box>
                  <Box className="formmanage">
                    <FormControl
                      inputVariant="outlined"
                      className={classes.friendControl}
                      fullWidth
                    >
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={status1}
                        onChange={(event) => setStatus1(event.target.value)}
                        className="selected"
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                          getContentAnchorEl: null,
                        }}
                        disabled={isLoading || pageData?.type === "page"}
                      >
                        <MenuItem value="Mobiloitte">
                          <Avatar
                            alt={
                              auth?.userData?.firstName
                                ? auth?.userData?.firstName
                                : ""
                            }
                            src={
                              auth?.userData?.profilePic &&
                              auth?.userData?.profilePic
                            }
                          />
                          <Typography
                            variant="body2"
                            style={{
                              margin: "0px 6px",
                              fontSize: "12px",
                              color: "#4057A7",
                              fontWeight: "600",
                            }}
                            className="textEllipsis textClx"
                          >
                            {auth?.userData?.firstName
                              ? auth?.userData?.firstName
                              : ""}{" "}
                            {auth?.userData?.lastName
                              ? auth?.userData?.lastName
                              : ""}
                          </Typography>
                        </MenuItem>
                        <Divider />
                      </Select>
                    </FormControl>
                  </Box>
                  <Box>
                    <FormControl
                      inputVariant="outlined"
                      className={classes.friendControl}
                      fullWidth
                    >
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={status}
                        onChange={(e) =>
                          e.target.value === "PRIVATE"
                            ? auth?.userData?.kycStatus === "APPROVE"
                              ? setStatus(e.target.value)
                              : toast.warn(
                                  "Please complete your KYC to access this feature."
                                )
                            : setStatus(e.target.value)
                        }
                        className="selected"
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                          getContentAnchorEl: null,
                        }}
                        disabled={isLoading || pageData?.type === "page"}
                      >
                        <MenuItem value="PUBLIC">
                          {" "}
                          <IoMdGlobe
                            style={{ fontSize: "14px", color: "#4057A7" }}
                          />
                          <Typography
                            variant="body2"
                            style={{
                              margin: "0px 6px",
                              fontSize: "12px",
                              color: "#4057A7",
                              fontWeight: "600",
                            }}
                          >
                            Public
                          </Typography>
                        </MenuItem>
                        <Divider />
                        <MenuItem value="PRIVATE">
                          <HiOutlineUserGroup
                            style={{
                              fontSize: "14px",
                              color: "#4057A7",
                            }}
                          />
                          <Typography
                            style={{
                              margin: "0px 6px",
                              fontSize: "12px",
                              color: "#4057A7",
                              fontWeight: "600",
                            }}
                            variant="body2"
                          >
                            Private
                          </Typography>
                        </MenuItem>
                        <Divider />
                      </Select>
                    </FormControl>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>

          <Box pt={2} pb={2} className={classes.mainBoxEmoji}>
            {showPicker && (
              <Box className={classes.emojiBox}>
                <Picker
                  onEmojiClick={!isLoading && onEmojiClick}
                  onClose
                  height="350px"
                />
              </Box>
            )}

            <FormControl fullWidth className={classes.commentControl}>
              <TextField
                className="commentField"
                fullWidth
                multiline
                disabled={isLoading}
                rowsMax={5}
                rows={5}
                variant="outlined"
                onChange={(e) => setInputStr(e.target.value)}
                value={inputStr}
                placeholder="What do you want to talk about?"
                inputProps={{ maxLength: 256 }}
              />
            </FormControl>
            <FormHelperText className="helpText">
              {inputStr.length} / 256
            </FormHelperText>
            <FormHelperText error>
              {isSubmit && inputStr === "" && "Please enter the description."}
              {isSubmit &&
                inputStr.length > 256 &&
                "Description must not be greater than 256 characters."}
            </FormHelperText>
          </Box>
          {status === "PRIVATE" && (
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Box py={1}>
                  <Typography variant="body2">NFT Price :</Typography>
                </Box>
                <TextField
                  fullWidth
                  type="number"
                  variant="outlined"
                  placeholder="Enter price"
                  value={nftPrice}
                  onChange={(e) => setNftPrice(e.target.value)}
                  disabled={isLoading}
                  onKeyPress={(event) => {
                    if (
                      event?.key === "-" ||
                      event?.key === "+" ||
                      event?.key === "*" ||
                      event?.key === "/"
                    ) {
                      event.preventDefault();
                    }
                  }}
                  onKeyDown={(event) => handleNegativeValue(event)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {tokenName}
                      </InputAdornment>
                    ),
                  }}
                />
                <FormHelperText error>
                  {isSubmit && nftPrice === "" && "Please enter NFT Price."}
                  {isSubmit &&
                    nftPrice !== "" &&
                    nftPrice <= 0 &&
                    "Please enter valid NFT Price."}
                </FormHelperText>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box className={classes.datefiledBox}>
                  <Box py={1}>
                    <Typography variant="body2">Expire Date :</Typography>
                  </Box>
                  <KeyboardDateTimePicker
                    inputVariant="outlined"
                    format="DD/MM/YYYY h:mm"
                    placeholder="DD/MM/YYYY H:MM"
                    disablePast
                    InputProps={{ readOnly: true }}
                    fullWidth
                    value={expireDate}
                    onChange={(date) => setExpireDate(new Date(date))}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                    disabled={isLoading}
                  />
                  <FormHelperText error>
                    {isSubmit &&
                      expireDate === undefined &&
                      "Please enter expire Date."}
                  </FormHelperText>
                </Box>
              </Grid>
            </Grid>
          )}

          {imageAndVideo && (
            <Box className={classes.imgVedioBox}>
              {openModal === "image" ? (
                <img
                  width="100%"
                  src={imageAndVideo}
                  alt="Post"
                  height="100%"
                />
              ) : openModal === "docx" ? (
                <img
                  width="100%"
                  src={
                    profileImage642?.type === "application/pdf"
                      ? "images/pdf.png"
                      : "images/docs.png"
                  }
                  alt="Post"
                  height="100%"
                />
              ) : (
                <video
                  width="100%"
                  src={imageAndVideo}
                  alt="Post"
                  height="100%"
                />
              )}
              <IconButton
                disabled={isLoading}
                size="small"
                className={classes.cancelBtn1}
                onClick={() => {
                  setImageAndVideo("");
                  setImageAndVideo1("");
                }}
              >
                <IoClose color="#ffffff" />
              </IconButton>
            </Box>
          )}

          <Grid container alignItems="center">
            <Grid item xs={12}>
              <Box>
                <IconButton disabled={isLoading}>
                  <FaRegSmileBeam
                    color="rgba(50, 50, 50, 0.6)"
                    onClick={() => setShowPicker((val) => !val)}
                  />
                </IconButton>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Box className={classes.iconBox}>
                  <IconButton
                    disabled={isLoading}
                    className="allIconInput"
                    onClick={() => {
                      setOpenModal("image");
                      setOpenModalOpen(true);
                    }}
                  >
                    <IoImageOutline color=" rgba(50, 50, 50, 0.6)" />
                  </IconButton>
                  <IconButton
                    disabled={isLoading}
                    className="allIconInput"
                    onClick={() => {
                      setOpenModal("video");
                      setOpenModalOpen(true);
                    }}
                  >
                    <IoLogoYoutube color="rgba(50, 50, 50, 0.6)" />
                  </IconButton>
                  {status === "PRIVATE" && (
                    <IconButton
                      disabled={isLoading}
                      className="allIconInput"
                      onClick={() => {
                        setOpenModal("docx");
                        setOpenModalOpen(true);
                      }}
                    >
                      <AiOutlineCloudUpload color="rgba(50, 50, 50, 0.6)" />
                    </IconButton>
                  )}
                  <Divider />

                  {status === "PRIVATE" && isSubmit && imageAndVideo === "" && (
                    <FormHelperText error>
                      Please select a media file
                    </FormHelperText>
                  )}
                </Box>
                <Box>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    style={{ padding: "20px 30px" }}
                    /* fullWidth */
                    disabled={isLoading}
                    className={classes.buttonpost}
                    onClick={() => {
                      setIsSubmit(true);
                      if (
                        inputStr.length > 256 ||
                        inputStr == "" ||
                        status == "" ||
                        (status === "PRIVATE" &&
                          (imageAndVideo1 == "" ||
                            nftPrice == "" ||
                            nftPrice <= 0 ||
                            expireDate == undefined))
                      ) {
                        return;
                      }
                      setIsSubmit(false);
                      if (status === "PRIVATE") {
                        if (auth.account) {
                          uploadIpfs();
                        } else {
                          auth.setOpenMetamask(true);
                        }
                      } else {
                        addPostHandler();
                      }
                    }}
                  >
                    {status === "PRIVATE" && !auth.account ? (
                      "Connect Wallet"
                    ) : (
                      <>
                        {status === "PRIVATE" ? "Create Asset" : "Post"}{" "}
                        {isLoading && <ButtonCircularProgress />}
                      </>
                    )}
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>

          {openModal && (
            <ImageAndVedioModal
              openImageAndVideo={openModalOpen}
              handleCloseImageAndVideo={(data, data1) => {
                setImageAndVideo(data);
                setImageAndVideo1(data1);
              }}
              setOpenModalOpen={(item) => setOpenModalOpen(item)}
              type={openModal}
              profileImage642={profileImage642}
              setProfileImage642={setProfileImage642}
            />
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default CreatePostModal;
