import React, { useEffect, useState } from "react";
import {
  makeStyles,
  Typography,
  Box,
  Divider,
  Grid,
  IconButton,
} from "@material-ui/core";
import { MdArrowDropDown } from "react-icons/md";
import Axios from "axios";
import LoadHistory from "src/Skeletons/LoadHistory";
import moment from "moment";
import DataNotFound from "src/component/DataNotFound";
const useStyles = makeStyles((theme) => ({
  jobsearchbox: {
    background: "linear-gradient(208.15deg, #70CDDD -30.61%, #4057A7 140.71%)",
    borderRadius: "5px",
    padding: "15px",
    "& .heightBox": {
      height: "600px",
      // overflowY: "scroll",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "10px",
    },
    "& .doted": {
      marginBottom: "10px",
      "& h5": {
        color: "#FFFFFF",
      },
    },
    "& .MuiDivider-root": {
      backgroundColor: "rgba(231, 231, 231, 0.2)",
    },
  },
  mapingbox: {
    "& h5": {
      color: "#fff",
      margin: "5px 0px",
    },
    "& p": {
      color: "#fff",
      "& span": {
        fontWeight: "700",
      },
    },
  },
}));

function JobSearch() {
  const classes = useStyles();
  const [newsData, setNewsData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getNewsListData = async () => {
    try {
      setIsLoading(true);
      const response = await Axios({
        method: "GET",
        url: "https://newsapi.org/v2/everything?q=tesla&from=2023-06-06&sortBy=publishedAt&apiKey=0bc6db3eec5445ce8307bb32e7dc601d",
        // url: "https://newsapi.ai/?referral=e4924236-78f4-4921-b4de-f7b556f1940f",
      });
      if (response?.status === 200) {
        setIsLoading(false);
        setNewsData(response?.data?.articles);
      } else {
        setIsLoading(false);
        setNewsData([]);
      }
    } catch (error) {
      setIsLoading(false);
      setNewsData([]);
      console.log(error);
    }
  };
  useEffect(() => {
    getNewsListData();
  }, []);

  const [sliceCount, setsliceCount] = useState(5);
  function seeMoreHandle(listPhoto) {
    return listPhoto && listPhoto?.slice(0, sliceCount);
  }
  return (
    <Box className={classes.jobsearchbox}>
      <Box className="doted">
        <Typography variant="h5">Latest News</Typography>
      </Box>
      <Box className="heightBox">
        <Grid container spacing={1} direction={"column"}>
          {newsData &&
            seeMoreHandle(newsData)?.map((data, index) => {
              return (
                <Grid item xs={12} key={index}>
                  <Box className={classes.mapingbox}>
                    <Box className="TextBox">
                      <Typography variant="body1">
                        <span>{data?.Text}</span>
                        {moment(data?.publishedAt).format("LL")}
                      </Typography>
                      <Typography
                        variant="h5"
                        onClick={() => window.open(`${data?.url}`)}
                        style={{ cursor: "pointer" }}
                      >
                        {data?.title}
                      </Typography>
                      <Typography variant="body1">{data?.content}</Typography>
                    </Box>
                  </Box>
                  <Box mt={2} mb={1}>
                    <Divider />
                  </Box>
                </Grid>
              );
            })}
          {isLoading &&
            [1, 2, 3, 4, 5].map((data, i) => {
              return <LoadHistory />;
            })}
          {!isLoading && newsData && newsData?.length === 0 && <DataNotFound />}
        </Grid>
      </Box>
      {newsData && newsData?.length !== 0 && (
        <>
          <Box align="end">
            <Typography variant="body1" style={{ color: "#fff" }}>
              Length:{seeMoreHandle(newsData)?.length}
            </Typography>
          </Box>
          <Box display="flex" alignItems="center" justifyContent="center">
            {5 < sliceCount && (
              <>
                <IconButton
                  onClick={() => {
                    seeMoreHandle(newsData);
                    setsliceCount(sliceCount - 5);
                  }}
                >
                  <Typography variant="body1" style={{ color: "#fff" }}>
                    Show Less
                  </Typography>
                  <MdArrowDropDown
                    color="#FFFFFF"
                    style={{ transform: "rotate(180deg)" }}
                  />
                </IconButton>
              </>
            )}
            {newsData?.length > sliceCount && (
              <IconButton
                onClick={() => {
                  seeMoreHandle(newsData);
                  setsliceCount(sliceCount + 5);
                }}
              >
                <Typography variant="body1" style={{ color: "#fff" }}>
                  Show More
                </Typography>
                <MdArrowDropDown color="#FFFFFF" />
              </IconButton>
            )}
          </Box>
        </>
      )}
    </Box>
  );
}

export default JobSearch;
