import firebase from "firebase";

var firebaseConfig = {
  apiKey: "AIzaSyCCNAqUvxVdSc_ctvAfqp_Qiu9Xa1mbL6U",
  authDomain: "uno-owndata.firebaseapp.com",
  projectId: "uno-owndata",
  storageBucket: "uno-owndata.appspot.com",
  messagingSenderId: "1059568280468",
  appId: "1:1059568280468:web:1201d0aa267274ce70e581",
  measurementId: "G-9NLWZCZK7T",
};
firebase.initializeApp(firebaseConfig);

export default firebase;
