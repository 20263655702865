import React from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";

const AutoCompleteFil = ({
  optionsData,
  autoFilValue,
  setAutoFilValue,
  name,
  handleBlur,
  isLoader,
  touched,
  errors,
  placeholder,
  changeCountry,
  type,
}) => {
  return (
    <div>
      <Autocomplete
        id="combo-box-demo"
        style={{ width: "100%" }}
        options={optionsData}
        value={autoFilValue}
        name={name}
        onChange={(event, newValue) => {
          setAutoFilValue(name, newValue);
          changeCountry(newValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant={type === "kycFil" ? "outlined" : undefined}
            placeholder={placeholder}
            error={Boolean(touched.name && errors.name)}
            onBlur={handleBlur}
          />
        )}
        onInputChange={(event, newInputValue) => {
          setAutoFilValue(name, newInputValue);
        }}
        onClick={() => {
          if (autoFilValue !== null && !optionsData.includes(autoFilValue)) {
            setAutoFilValue(name, null);
          }
        }}
        disabled={isLoader}
      />
    </div>
  );
};

export default AutoCompleteFil;
