import React, { useState, useEffect, useMemo } from "react";

import {
  ProSidebar,
  Menu,
  MenuItem,
  SidebarHeader,
  SidebarContent,
} from "react-pro-sidebar";
import { Avatar, Box, Typography } from "@material-ui/core";
import { CgClose } from "react-icons/cg";
import { Link } from "react-router-dom";

import { FiMenu } from "react-icons/fi";
import { useHistory } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import Arena from "src/icons/Arena";
import Library from "src/icons/Library";
import Myself from "src/icons/Myself";
import Jobs from "src/icons/Jobs";
import MyNetwork from "src/icons/MyNetwork";
import MyWork from "src/icons/MyWork";
import { useContext } from "react";
import { AuthContext } from "src/context/Auth";
import { IoWallet } from "react-icons/io5";
import Analytic from "src/icons/Analytic";
import { FaClipboardCheck } from "react-icons/fa";
import { tokenName } from "src/constants";
import Registration from "src/views/pages/KYC/Registration";

const Sidenav = ({ setMenuCollapse, menuCollapse }) => {
  const [openKycModal, setOpenKycModal] = useState(false);
  const history = useHistory();
  const menuIconClick = () => {
    menuCollapse ? setMenuCollapse(false) : setMenuCollapse(true);
  };
  const RouteEndPath = window.location.pathname;

  const logoutHandler = () => {
    history.push("/");
    window.localStorage.removeItem("token");
  };
  const auth = useContext(AuthContext);
  const memoizedButtonClicked = useMemo(
    (menuCollapse) => menuCollapse,
    [menuCollapse]
  );

  return (
    <>
      <div id="header">
        <ProSidebar collapsed={menuCollapse}>
          <SidebarHeader>
            <Box
              mb={3}
              className="closemenu"
              onClick={() => {
                menuIconClick();
              }}
            >
              {menuCollapse ? <FiMenu /> : <CgClose />}
            </Box>
          </SidebarHeader>
          <SidebarContent className="scrollDiv1">
            <Menu iconShape="square">
              <MenuItem
                className={
                  RouteEndPath == "/home" ? "activeboxTabs" : "inActiveboxTabs"
                }
                component={Link}
                onClick={() => {
                  history.push("/home");
                }}
                active={true}
                icon={
                  <Arena
                    className={RouteEndPath == "/home" ? "activebox" : ""}
                  />
                }
              >
                <span className={RouteEndPath == "/home" ? "activeText" : ""}>
                  Arena
                </span>
              </MenuItem>
              <MenuItem
                className={
                  RouteEndPath == "/self-about"
                    ? "activeboxTabs"
                    : "inActiveboxTabs"
                }
                component={Link}
                onClick={() => {
                  auth.setSelfTabShow("self-about");
                  history.push("/self-about");
                }}
                active={true}
                icon={
                  <Myself
                    className={RouteEndPath == "/self-about" ? "activebox" : ""}
                  />
                }
              >
                <span
                  className={RouteEndPath == "/self-about" ? "activeText" : ""}
                >
                  Myself
                </span>
              </MenuItem>
              <MenuItem
                className={
                  RouteEndPath == "/mywork"
                    ? "activeboxTabs"
                    : "inActiveboxTabs"
                }
                component={Link}
                onClick={() => {
                  history.push("/mywork");
                }}
                icon={
                  <MyWork
                    className={RouteEndPath == "/mywork" ? "activebox" : ""}
                  />
                }
              >
                <span className={RouteEndPath == "/mywork" ? "activeText" : ""}>
                  Moonlights
                </span>
              </MenuItem>
              <MenuItem
                className={
                  RouteEndPath == "/network"
                    ? "activeboxTabs"
                    : "inActiveboxTabs"
                }
                component={Link}
                onClick={() => {
                  history.push("/network");
                  auth.setSelfTabShow("my-network");
                }}
                icon={
                  <MyNetwork
                    className={RouteEndPath == "/network" ? "activebox" : ""}
                  />
                }
              >
                <span className={RouteEndPath == "/network" ? "activebox" : ""}>
                  {" "}
                  My Network
                </span>
              </MenuItem>
              <MenuItem
                className={
                  RouteEndPath == "/library"
                    ? "activeboxTabs"
                    : "inActiveboxTabs"
                }
                component={Link}
                onClick={() => history.push("/library")}
                icon={
                  <Library
                    className={RouteEndPath == "/library" ? "activebox" : ""}
                  />
                }
              >
                <span
                  className={RouteEndPath == "/library" ? "activeText" : ""}
                >
                  My Library
                </span>
              </MenuItem>
              <MenuItem
                className={
                  RouteEndPath == "/mycontent"
                    ? "activeboxTabs"
                    : "inActiveboxTabs"
                }
                component={Link}
                onClick={() => history.push("/mycontent")}
                icon={
                  <Analytic
                    className={RouteEndPath == "/mycontent" ? "activebox" : ""}
                  />
                }
              >
                <span
                  className={RouteEndPath == "/mycontent" ? "activeText" : ""}
                >
                  Analytics
                </span>
              </MenuItem>
              <MenuItem
                className={
                  RouteEndPath == "/job" ? "activeboxTabs" : "inActiveboxTabs"
                }
                component={Link}
                onClick={() => history.push("/job")}
                icon={
                  <Jobs className={RouteEndPath == "/job" ? "activebox" : ""} />
                }
              >
                <span className={RouteEndPath == "/job" ? "activeText" : ""}>
                  Jobs
                </span>
              </MenuItem>
              <MenuItem
                component={Link}
                className={
                  RouteEndPath == "/asset" ? "activeboxTabs" : "inActiveboxTabs"
                }
                onClick={() => history.push("/asset")}
                icon={
                  <img
                    src="images/nft_icon.png"
                    className={RouteEndPath === "/asset" ? "activebox" : ""}
                  />
                }
              >
                <span className={RouteEndPath === "/asset" ? "activeText" : ""}>
                  Assets
                </span>
              </MenuItem>
              <MenuItem
                className={
                  RouteEndPath == "/wallet"
                    ? "activeboxTabs"
                    : "inActiveboxTabs"
                }
                component={Link}
                onClick={() => history.push("/wallet")}
                icon={
                  <IoWallet
                    color="#FFFFFF"
                    className={
                      RouteEndPath == "/wallet" ? "activebox" : "inactivebox"
                    }
                  />
                }
              >
                <span
                  className={RouteEndPath == "/wallet" ? "activeText" : ""}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  My Wallet
                  {auth?.tokenBalance && (
                    <Box
                      style={{
                        display: "flex",
                        alignItems: "center",
                        borderRadius: "50px",
                        background: "rgb(239, 239, 239)",
                        padding: "0.5px 5px",
                        marginLeft: "5px",
                      }}
                    >
                      <Typography
                        variant="body1"
                        style={{ fontWeight: "800", color: "#000" }}
                      >
                        {auth?.tokenBalance} {tokenName}
                      </Typography>
                    </Box>
                  )}
                </span>
              </MenuItem>
              <MenuItem
                component={Link}
                onClick={() => setOpenKycModal(true)}
                icon={
                  <FaClipboardCheck
                    style={{ color: "rgb(255, 255, 255)" }}
                    className={
                      RouteEndPath === "/registration" ? "activebox" : ""
                    }
                  />
                }
              >
                <span
                  className={
                    RouteEndPath === "/registration" ? "activeText" : ""
                  }
                >
                  KYC
                </span>
              </MenuItem>
            </Menu>
          </SidebarContent>
        </ProSidebar>
        <Registration
          openKycModal={openKycModal}
          setOpenKycModal={setOpenKycModal}
        />
      </div>
    </>
  );
};
export default Sidenav;
