import React, { useState, useEffect, useContext } from "react";
import {
  makeStyles,
  Dialog,
  DialogContent,
  IconButton,
  FormControl,
  Typography,
  TextField,
  Box,
  MenuItem,
  Select,
  Button,
  Avatar,
  FormHelperText,
  InputAdornment,
} from "@material-ui/core";
import { MdDelete } from "react-icons/md";
import AttachementIcon from "src/icons/AttachementIcon";
import JobDescriptionModal from "src/component/JobDescriptionModal";
import { IoClose } from "react-icons/io5";
import { getDataHandlerAPI } from "src/apiConfig/service";
import { checkLocationEnabled, handleNegativeValue } from "src/utils";
import PlacesAutocomplete from "react-places-autocomplete";
import { AuthContext } from "src/context/Auth";
import Axios from "axios";
import apiConfigs from "src/apiConfig/ApiConfig";

const useStyles = makeStyles((theme) => ({
  mainmodalBox: {
    paddingTop: "10px",
    paddingBottom: "15px",
    "& .formControl": {
      width: "100%",
      backgroundColor: "transparent",
      border: "none",
      color: "#fff",
      "&:focus-visible": {
        outline: "none",
      },
    },

    "& .addImagegIcon": {
      cursor: "pointer",
      position: "relative",
      "& input": {
        top: 0,
        left: 0,
        position: "absolute",
        width: "100%",
        height: "100%",
        opacity: 0,
      },
      "& svg": {
        color: "#ACACAC",
        fontSize: "22px",
      },
    },
  },
  cancelBtn: {
    position: "absolute",
    top: 0,
    right: 0,
    "& svg": {
      color: "#fff",
      fontWeight: "700",
    },
  },

  root: {
    width: "100%",
  },
  DialogMain: {
    display: "flex",
    alignItems: "center",

    "& svg": {
      color: "#4057A7",
    },
  },

  ButtonBox: {
    [theme.breakpoints.only("xs")]: {
      height: "45px",
      padding: "0px 22px",
      fontSize: "16px",
      marginTop: "20px",
    },
  },
}));

function HireModal({
  openCreatePost,
  handleCloseCreatePost,
  userData,
  pageData,
  pageJOBHAndler,
  jobId,
  type,
}) {
  const classes = useStyles();
  const auth = useContext(AuthContext);
  const [profileImage, setProfileImage] = useState("");
  const [imageData, setImageData] = useState("");
  const [openCreatePost1, setOpenCreatePost1] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [skillList, setSkillList] = useState([]);
  const [description, setDescription] = useState("");
  const [categoryList, setCategoryList] = useState([]);

  const [data, setData] = useState({
    jobTitle: "",
    company: "",
    exprience: "",
    workplace: "0",
    category: "1",
    jobType: "FULLTIME",
    applicant: "",
    description: description,
  });

  useEffect(() => {
    if (jobId) {
      setData({
        jobTitle: jobId?.jobTitle ? jobId?.jobTitle : "",
        company: jobId?.compnayName ? jobId?.compnayName : "",
        exprience: jobId?.jobExperiences ? jobId?.jobExperiences : "",
        workplace: jobId?.workspaceType ? jobId?.workspaceType : "",
        category: jobId?.categoryId ? jobId?.categoryId : "",
        jobType: jobId?.jobType ? jobId?.jobType : "",
        applicant: jobId?.jobTitle ? jobId?.jobTitle : "",
      });
    }
    setDescription(jobId?.description ? jobId?.description : "");
    auth.setAddress(jobId?.jobLocation);
    auth.setLatLong({
      lat: jobId?.location?.coordinates[0]
        ? jobId?.location?.coordinates[0]
        : "",
      long: jobId?.location?.coordinates[1]
        ? jobId?.location?.coordinates[1]
        : "",
    });
    setImageData(jobId?.companyLogo ? jobId?.companyLogo : "");
    setProfileImage(jobId?.companyLogo ? jobId?.companyLogo : "");
    if (skillList && jobId) {
      let skillNewaAr = [];
      for (let i = 0; i < skillList.length; i++) {
        for (let j = 0; j < jobId?.skills?.length; j++) {
          if (skillList[i]?.skillName === jobId?.skills[j]) {
            const Obj = {
              label: skillList[i]?.skillName,
              value: skillList[i]?._id,
            };
            skillNewaAr.push(Obj);
          }
        }
      }
      setSelectedOptions(skillNewaAr);
    }
  }, [jobId, skillList]);

  const _onInputChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setData({ ...data, [name]: value });
  };

  const listSkillHandler = async () => {
    const res = await getDataHandlerAPI("listSkill");
    if (res) {
      setSkillList(res?.docs);
    }
  };
  const getCategoryList = async () => {
    try {
      const response = await Axios({
        method: "POST",
        url: apiConfigs.listCategory,
      });
      if (response.data.responseCode === 200) {
        setCategoryList(response.data.result.docs);
      } else {
        setCategoryList([]);
      }
    } catch (error) {
      console.log(error);
      setCategoryList([]);
    }
  };

  useEffect(() => {
    checkLocationEnabled();
    getCategoryList();
  }, []);

  useEffect(() => {
    if (openCreatePost) {
      listSkillHandler();
    }
  }, [openCreatePost]);

  return (
    <>
      <Dialog
        open={openCreatePost}
        onClose={handleCloseCreatePost}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="sm"
        fullWidth
        className={classes.root}
      >
        <Box className="bgcolorbox">
          <Box>
            <Typography variant="h5">Find a great hire, fast</Typography>
          </Box>
        </Box>
        <DialogContent>
          <IconButton
            className={classes.cancelBtn}
            onClick={handleCloseCreatePost}
          >
            <IoClose />
          </IconButton>
          <Box style={{}}>
            <Box className={classes.mainmodalBox}>
              <Box pt={1} pb={1}>
                <TextField
                  fullWidth
                  name="jobTitle"
                  value={data?.jobTitle}
                  variant="outlined"
                  placeholder="Job Title"
                  onChange={_onInputChange}
                />
                {isSubmit && data?.jobTitle === "" && (
                  <FormHelperText error>Please enter job title</FormHelperText>
                )}
              </Box>
              <Box pt={1} pb={1}>
                <TextField
                  fullWidth
                  name="company"
                  variant="outlined"
                  value={data?.company}
                  placeholder="Company"
                  onChange={_onInputChange}
                />
                {isSubmit && data?.company === "" && (
                  <FormHelperText error>
                    Please enter company name
                  </FormHelperText>
                )}
              </Box>
              <Box pt={1} pb={1}>
                <TextField
                  fullWidth
                  type="number"
                  name="exprience"
                  variant="outlined"
                  placeholder="Exprience in years"
                  value={data.exprience}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">YEAR</InputAdornment>
                    ),
                  }}
                  onChange={(e) => {
                    if (e.target.value.length <= 2) {
                      _onInputChange(e);
                    }
                  }}
                  onKeyPress={(event) => {
                    if (
                      event?.key === "-" ||
                      event?.key === "+" ||
                      event?.key === "*" ||
                      event?.key === "/"
                    ) {
                      event.preventDefault();
                    }
                  }}
                  onKeyDown={(event) => handleNegativeValue(event)}
                />
                {isSubmit && data?.exprience === "" && (
                  <FormHelperText error>Please enter exprience</FormHelperText>
                )}
              </Box>
              <Box pt={1} pb={1}>
                <FormControl
                  inputVariant="outlined"
                  className={classes.formControl}
                  fullWidth
                  onChange={_onInputChange}
                >
                  <Select
                    variant="outlined"
                    name="category"
                    placeholder="Category"
                    style={{ padding: "4px" }}
                    fullWidth
                    className="stateField"
                    margin="dense"
                    value={data?.category}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                    }}
                    inputProps={{ "aria-label": "Without label" }}
                    onChange={_onInputChange}
                  >
                    <MenuItem value="1" disabled style={{ color: "#a5aaac" }}>
                      Select Category
                    </MenuItem>
                    {categoryList &&
                      categoryList.map((item, i) => {
                        return (
                          <MenuItem value={item?._id}>{item?.name}</MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
                {isSubmit && data?.category === "1" && (
                  <FormHelperText error>Please select category</FormHelperText>
                )}
              </Box>
              <Box pt={1} pb={1}>
                <FormControl
                  inputVariant="outlined"
                  className={classes.formControl}
                  fullWidth
                  onChange={_onInputChange}
                >
                  <Select
                    variant="outlined"
                    name="workplace"
                    style={{ padding: "4px" }}
                    fullWidth
                    className="stateField"
                    margin="dense"
                    value={data?.workplace}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                    }}
                    inputProps={{ "aria-label": "Without label" }}
                    onChange={_onInputChange}
                  >
                    <MenuItem value="0" disabled style={{ color: "#a5aaac" }}>
                      Select Workplace Type
                    </MenuItem>
                    <MenuItem value="Office">Office</MenuItem>
                    <MenuItem value="WFH">WFH</MenuItem>
                  </Select>
                </FormControl>
              </Box>

              {isSubmit && data?.workplace === "0" && (
                <FormHelperText error>
                  Please select workplace type
                </FormHelperText>
              )}
              <Box pt={1} pb={1}>
                <PlacesAutocomplete
                  className={classes.autocompleteClass}
                  value={auth.address}
                  name="jobLocation"
                  onChange={auth.handleAddressChange}
                  onSelect={auth.handleSelectLocation}
                >
                  {({
                    getInputProps,
                    suggestions,
                    getSuggestionItemProps,
                    loading,
                  }) => (
                    <Box className="minbox">
                      <Box>
                        <TextField
                          fullWidth
                          variant="outlined"
                          placeholder="Search Location"
                          {...getInputProps({
                            placeholder: "Job Location",
                            className: "location-search-input",
                          })}
                          inputProps={{ "aria-label": "search" }}
                        />
                      </Box>

                      {loading && (
                        <div style={{ marginTop: "5px" }}>Loading...</div>
                      )}
                      {suggestions.map((suggestion) => {
                        const className = suggestion.active
                          ? "suggestion-item--active"
                          : "suggestion-item";

                        const style =
                          suggestion.active === true
                            ? {
                                backgroundColor: "#fff",
                                cursor: "pointer",
                                color: "#404040",
                                borderBottom: "1px solid #e7e7e7",
                                marginTop: "9px",
                                fontSize: "14px",
                                fontWeight: "400",
                                padding: "4px 30px 8px 0px",
                              }
                            : {
                                backgroundColor: "#ffffff",
                                cursor: "pointer",
                                color: "#404040",
                                borderBottom: "1px solid #e7e7e7",
                                marginTop: "9px",
                                fontSize: "14px",
                                fontWeight: "400",
                                padding: "4px 30px 8px 0px",
                              };
                        return (
                          <div
                            {...getSuggestionItemProps(suggestion, {
                              className,
                              style,
                            })}
                          >
                            <Box className="imgbox">
                              <Box className="typo2">
                                <Typography
                                  variant="body2"
                                  style={{ fontWeight: "600" }}
                                >
                                  {suggestion.description}
                                </Typography>
                              </Box>
                            </Box>
                          </div>
                        );
                      })}
                    </Box>
                  )}
                </PlacesAutocomplete>

                {isSubmit && auth.address === "" && (
                  <FormHelperText error>
                    Please enter job location
                  </FormHelperText>
                )}
                {isSubmit && auth.address === undefined && (
                  <FormHelperText error>
                    Please enter job location
                  </FormHelperText>
                )}
              </Box>
              <Box pt={1} pb={1}>
                <FormControl
                  inputVariant="outlined"
                  className={classes.formControl}
                  fullWidth
                >
                  <Select
                    variant="outlined"
                    style={{ padding: "4px" }}
                    fullWidth
                    className="stateField"
                    name="jobType"
                    margin="dense"
                    onChange={_onInputChange}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                    }}
                    value={data?.jobType}
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    <MenuItem value="FULLTIME">Full-Time</MenuItem>
                    <MenuItem value="PARTTIME">Part-Time </MenuItem>
                    <MenuItem value="REMOTE">Remote </MenuItem>
                  </Select>
                </FormControl>
              </Box>

              <Box>
                <Box mt={1}>
                  <Box className={classes.DialogMain}>
                    <Box className="addImagegIcon">
                      {profileImage === "" ? (
                        <IconButton
                          style={{
                            border: "1px dashed #4057A7",
                            padding: "10px 10px 6px 10px",
                          }}
                        >
                          {" "}
                          <label htmlFor="raised-button-file">
                            <AttachementIcon
                              color="#4057A7 !important"
                              stroke="#4057A7 !important"
                            />
                          </label>
                        </IconButton>
                      ) : (
                        <figure className="figure" style={{ margin: "0px" }}>
                          <Avatar
                            className={classes.imgsection}
                            src={profileImage ? profileImage : <> </>}
                          />
                        </figure>
                      )}
                      <input
                        style={{ display: "none" }}
                        id="raised-button-file"
                        type="file"
                        accept="image/*"
                        onChange={(e) => {
                          setProfileImage(
                            URL.createObjectURL(e.target.files[0])
                          );
                          setImageData(e.target.files[0]);
                        }}
                      />
                    </Box>
                    &nbsp;&nbsp;&nbsp;
                    <Box display="flex" className={classes.profile}>
                      {profileImage === "" ? (
                        <>
                          <Typography variant="h6" color="primary">
                            Company Logo
                          </Typography>
                        </>
                      ) : (
                        <IconButton
                          onClick={() => {
                            setProfileImage("");
                            setImageData("");
                          }}
                        >
                          <MdDelete />
                        </IconButton>
                      )}
                    </Box>
                  </Box>

                  {isSubmit && imageData === "" && (
                    <FormHelperText error>Please select a file</FormHelperText>
                  )}
                </Box>
                <Box align="center" mt={2}>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    style={{ padding: "20px 26px" }}
                    onClick={() => {
                      if (
                        data?.jobTitle === "" ||
                        data?.company === "" ||
                        data?.category === "1" ||
                        data?.workplace === "0" ||
                        data?.exprience === "" ||
                        auth.address === "" ||
                        auth.address === undefined ||
                        imageData === "" ||
                        profileImage === ""
                      ) {
                        setIsSubmit(true);
                      } else {
                        setOpenCreatePost1(true);
                      }
                    }}
                    className={classes.ButtonBox}
                  >
                    Get Started
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
      <JobDescriptionModal
        openCreatePost1={openCreatePost1}
        handleCloseCreatePost1={() => setOpenCreatePost1(false)}
        setSelectedOptions={setSelectedOptions}
        selectedOptions={selectedOptions}
        data={skillList}
        formData={data}
        authformData={auth}
        imageData={imageData}
        _onInputChange={_onInputChange}
        setDescription={(item) => setDescription(item)}
        description={description}
        handleCloseCreatePost={handleCloseCreatePost}
        pageData={pageData}
        pageJOBHAndler={pageJOBHAndler}
        type={type}
        jobId={jobId}
      />
    </>
  );
}

export default HireModal;
