import React, { useState } from "react";
import { makeStyles, Box, IconButton, Typography } from "@material-ui/core";
import { HiDownload } from "react-icons/hi";
import { MdDelete } from "react-icons/md";
import { toast } from "react-toastify";
import ConfirmationDialog from "src/component/ConfirmationDialog";
import Axios from "axios";
import apiConfigs from "src/apiConfig/ApiConfig";
import ViewPdfAdDocx from "./ViewPdfAdDocx";
import ViewImageModal from "./ViewImageModal";

const useStyles = makeStyles((theme) => ({
  mainCards: {
    cursor: "pointer",
    margin: "5px",
    overflow: "hidden",
    position: "relative",

    "& .afterTextBox": {
      border: "1px solid #E2E2E2",
      borderRadius: "5px",
      overflow: "hidden",
    },
    "& .mainTextBox": {
      padding: "15px",
      "& .innerText": {
        padding: "5px 0px",
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "start",
      },
      "& h6": {
        color: "#000000",
        fontWeight: 500,
      },
    },
    "& .hovericonbtn": {
      display: "none",
    },
    "&:hover": {
      "& .hovericonbtn": {
        display: "block",
        position: "absolute",
        top: "10px",
        right: "10px",
      },
    },
    "& .iconstyle": {
      border: "3px solid #FFFFFF",
      backgroundColor: "#4057A7",
      width: "18px",
      height: "18px",
      borderRadius: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      color: "#fff",
      "& svg": {
        color: "#FFFFFF !important",
      },
    },
  },
  PhotosStyle: {
    "& figure": {
      width: "100%",
      borderRadius: "5px",
      overflow: "hidden",
      transform: "scale(1)",
      margin: "0px",
      maxHeight: "227px",
      minHeight: "227px",
      // "&:hover": {
      //   transition: "0.5s",
      //   transform: "scale(1.1)",
      // },
    },
    "& img": {
      width: "100%",
      maxHeight: "227px",
      minHeight: "227px",
    },
  },
  UserNameText: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "5px 0px",
    "& svg": {
      fontSize: "12px",
    },
    "& p": {
      fontSize: "12px",
    },
  },
  trackButton: {
    "& button": {
      padding: "5px 0px",
      fontSize: "10px",
    },
  },
  iconbtn: {
    position: "absolute",
    top: "0",
    right: "0",
  },
  figureMainBox: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    "& img": {
      width: "78px",
      minHeight: "84px",
    },
  },
}));

function PhotosCards({ data, getPhotoLibraryHandle, type }) {
  const isDocx = data.mediaUrl.includes("docx");
  const isPdf = data.mediaUrl.includes("pdf");
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [viewModal, setViewModal] = useState(false);

  const handleDeletePhoto = async (id) => {
    try {
      setIsLoading(true);
      const response = await Axios({
        method: "DELETE",
        url: apiConfigs.deleteMedia,
        headers: {
          token: window.sessionStorage.getItem("token"),
        },
        params: {
          _id: id,
        },
      });
      if (response.data.responseCode === 200) {
        toast.success(response.data.responseMessage);
        getPhotoLibraryHandle();
        setIsLoading(false);
        setOpen(false);
      } else {
        setIsLoading(false);
        toast.error(response.data.responseMessage);
      }
    } catch (error) {
      setIsLoading(false);
      toast.error(error.response.data.responseMessage);
    }
  };

  const handleDownload = (imageUrl) => {
    fetch(imageUrl)
      .then((response) => response.blob())
      .then((blob) => {
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = "image.jpg";
        link.click();
        URL.revokeObjectURL(link.href);
      })
      .catch((error) => {
        console.error("Error downloading image:", error);
      });
  };
  function downloadPDF(url) {
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "document.pdf";
        a.click();
        URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error("Error downloading PDF:", error);
      });
  }

  const downloadDocx = (url) => {
    const link = document.createElement("a");
    link.href = url;
    link.download = "document.docx , document.pdf";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleCallFunction = (mediaUrl) => {
    data.type === "EBOOK"
      ? downloadPDF(mediaUrl)
      : data.type === "PHOTO"
      ? handleDownload(mediaUrl)
      : isDocx
      ? downloadDocx(mediaUrl)
      : downloadPDF(mediaUrl);
  };

  return (
    <Box className={classes.mainCards}>
      <Box className="afterTextBox" align="center">
        <div className={classes.PhotosStyle}>
          {type === "ebook" ? (
            <Box
              className={classes.imgsection1}
              onClick={() => isPdf && setViewModal(true)}
            >
              <figure className={classes.figureMainBox}>
                {isPdf ? (
                  <img src="images/pdf.png" alt="" />
                ) : (
                  <img src="images/docs.png" alt="" />
                )}
                <Box m={1} style={{ wordBreak: "break-all" }}>
                  <Typography variant="body2">{data?.fileName}</Typography>
                </Box>
              </figure>
            </Box>
          ) : (
            <figure onClick={() => setViewModal(true)}>
              <img src={data?.mediaUrl} alt="loading" width="100%" />
            </figure>
          )}

          <Box className="hovericonbtn">
            <Box display="flex" alignItems="center">
              <Box className="iconstyle">
                <IconButton
                  disabled={isLoading}
                  onClick={() => handleCallFunction(data?.mediaUrl)}
                >
                  <HiDownload />
                </IconButton>
              </Box>
              <Box className="iconstyle" style={{ marginLeft: "5px" }}>
                <IconButton disabled={isLoading} onClick={() => setOpen(true)}>
                  <MdDelete />
                </IconButton>
              </Box>
            </Box>
          </Box>
        </div>
      </Box>
      <ViewImageModal
        viewModal={viewModal}
        handleClose={() => setViewModal(false)}
        imageData={data?.mediaUrl}
        type={isPdf}
      />
      <ConfirmationDialog
        open={open}
        handleClose={() => setOpen(false)}
        isLoading={isLoading}
        title="Delete"
        desc="Are you sure, you want to delete?"
        confirmationHandler={() => handleDeletePhoto(data?._id)}
      />
    </Box>
  );
}

export default PhotosCards;
